const SMALL = {
    big: '1.25rem',
    medium: '1rem',
    xxnormal: '1rem',
    xnormal: '0.875rem', // Sử dụng medium nếu text lớn hơn normal
    normal: '0.875rem',
    small: '0.75rem',
    verySmall: '0.65rem',
    smallest: '0.5rem',
}
const NORMAL = {
    big: '1.5rem', // Sử dụng big nếu text lớn hơn medium
    medium: '1.125rem', // Sử dụng medium nếu text lớn hơn normal
    xxnormal: '1rem',
    xnormal: '0.875rem', // Sử dụng medium nếu text lớn hơn normal
    normal: '0.8125rem', // Luôn sử dung font-size normal
    small: '0.6875rem', // Sử dụng small nếu text nhỏ hơn normal
    verySmall: '0.65rem', // Sử dụng verySmall nếu text nhỏ hơn small
    smallest: '0.5rem', // Sử dụng smallest nếu text nhỏ hơn verySmall
}
const LARGE = {
    big: '1.25rem',
    medium: '1rem',
    xxnormal: '1rem',
    xnormal: '0.875rem', // Sử dụng medium nếu text lớn hơn normal
    normal: '0.9375rem',
    small: '0.75rem',
    verySmall: '0.65rem',
    smallest: '0.5rem',
}

export default {
    SMALL,
    NORMAL,
    LARGE,
}
