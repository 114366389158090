
import { Button, Input, Modal } from 'antd';
import moment from 'moment';
import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StoreContext } from '../../store';
import { dimensions } from '../../styles';
import { allowCompanyRender, dataCryption, eventList, glb_sv, reqFunct, sendRequest } from '../../utils';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

const ServiceInfo = {
    GET_OTP_NUMBER: {
        reqFunct: reqFunct.GET_OTP_NUMBER,
        WorkerName: 'FOSxID01',
        ServiceName: 'FOSxID01_OTPManagement',
        Operation: 'I',
        ClientSentTime: '0'
    },
    SEND_OTP_FUNCTION: {
        reqFunct: reqFunct.SEND_OTP_FUNCTION,
        WorkerName: 'FOSxID01',
        ServiceName: 'FOSxID01_OTPManagement',
        Operation: 'U',
        ClientSentTime: '0'
    }
}

function OtpAuthenRequestModal() {

    const { styles } = useContext(StoreContext)
    const { t } = useTranslation();

    const [visible, setVisible] = useState(false)

    const [code, setCode] = useState('')
    const [timeOTP, setTimeOTP] = useState(0)
    const [msgOTP, setMsgOTP] = useState('')
    const [typeOTP, setTypeOTP] = useState('OTP')

    const [errors, setError] = useState('')
    const [hiddenOTP, setHiddenOTP] = useState(true)

    const timeOTPRef = useRef(0);
    const getOtpNumberFlag = useRef(false)
    const sendOTPFlag = useRef(false)

    const timeAuthenOtp = useRef({
        timeGetOTP: moment(),
        numberOTP: 0
    })
    const timeoutTimingOTP = useRef(null)

    const functionCallbackRef = useRef(null)
    const buttonGetOtpRef = useRef(null)

    useEffect(() => {
        const commonEvent = glb_sv.commonEvent.subscribe(msg => {
            if (msg.type === eventList.OTP_AUTHEN_REQUEST) {
                setVisible(true);
                setTimeOTP(msg.time)

                timeOTPRef.current = msg.time
                setTypeOTP(msg.message)
                setMsgOTP(msg.messageOTP)

                timeAuthenOtp.current = {
                    timeGetOTP: moment(),
                    numberOTP: msg.time
                }
                timingOTP()
                functionCallbackRef.current = msg.functionCallback;

                if (glb_sv.objShareGlb.userInfo.c6 !== '3' && !msg.time) {
                    getOtpNumber()
                } else {
                    if (glb_sv.objShareGlb.userInfo.c6 === '3') {
                        setTimeout(() => {
                            buttonGetOtpRef.current && buttonGetOtpRef.current.focus()
                        }, 150);
                    } else {
                        glb_sv.focusInput('otpInputAuthen', 100)
                    }
                }
            }
        })

        let isHiddenOTP = JSON.parse(localStorage.getItem('hiddenOTP'))
        if (isHiddenOTP === null || isHiddenOTP) {
            setHiddenOTP(true)
        } else {
            setHiddenOTP(false)
        }

        return () => {
            if (timeoutTimingOTP.current) clearTimeout(timeoutTimingOTP.current)
            commonEvent.unsubscribe();
        }
    }, []);

    const sendOTP = (value) => {
        setError('');
        if (sendOTPFlag.current) return;
        if (!timeOTP) return;
        const check = value || code;
        if (!check) return;
        sendOTPFlag.current = true;
        const password = dataCryption.encryptString(check.trim())
        const InputParams = ['check_otp', password + '']
        sendRequest(ServiceInfo.SEND_OTP_FUNCTION, InputParams, sendOTPProc, true, sendOTPTimeout)
    }

    const sendOTPTimeout = () => {
        sendOTPFlag.current = false;
    }

    const sendOTPProc = (reqInfoMap, message) => {
        sendOTPFlag.current = false;
        if (Number(message['Result']) === 0) {
            const errmsg = message['Message']
            setError(errmsg);
            if (message.Code === '010013') {
                getOtpNumber(true);
            }
        } else {
            // glb_sv.objShareGlb['sessionInfo']['Otp'] = reqInfoMap.inputParam[1]
            setVisible(false);
            functionCallbackRef.current && functionCallbackRef.current();

            setCode('')
        }
    }

    const getOtpNumber = (type) => {
        if (!type) setError('');
        if (getOtpNumberFlag.current) return;
        getOtpNumberFlag.current = true;
        const InputParams = ['manual_otp']
        sendRequest(ServiceInfo.GET_OTP_NUMBER, InputParams, getOtpNumberProc, true, getOtpNumberTimeout)
    }

    const getOtpNumberTimeout = () => {
        getOtpNumberFlag.current = false;
    }

    const getOtpNumberProc = (reqInfoMap, message) => {
        getOtpNumberFlag.current = false;
        if (Number(message['Result']) === 2) {
            return;
        } else {
            let dataInfo;
            if (!message['Data']) return;
            try {
                dataInfo = JSON.parse(message['Data'])[0]
                const expTimeOtp = Number(dataInfo.c2) || 60
                let reqOtpMessage = 'OTP';
                if (Number(dataInfo['c1']) === 2) { //-- dạng thẻ ma trận
                    reqOtpMessage = 'OTP ' + dataInfo['c3']
                }
                setMsgOTP(message['Message'])
                setTimeOTP(expTimeOtp)
                timeOTPRef.current = expTimeOtp
                setTypeOTP(reqOtpMessage)

                timeAuthenOtp.current = {
                    timeGetOTP: moment(),
                    numberOTP: expTimeOtp
                }
                timingOTP()
                glb_sv.focusInput('otpInputAuthen')
            } catch (err) {
                console.log('getOtpNumberProc Lỗi parse json: ' + err)
            }
        }
    }

    // const otp_interValExpideFunct = () => {
    //     otp_interValExpired.current = setInterval(() => {
    //         if (timeOTPRef.current > 0) {
    //             timeOTPRef.current -= 1;
    //             setTimeOTP(timeOTPRef.current);
    //         } else {
    //             setError('');
    //             clearInterval(otp_interValExpired.current);
    //         }
    //     }, 1000)
    // }

    const timingOTP = () => {
        if (timeOTPRef.current > 0) {
            if (timeoutTimingOTP.current) clearTimeout(timeoutTimingOTP.current)
            timeoutTimingOTP.current = setTimeout(() => {
                const remainTimeOTP = Math.max(0, timeAuthenOtp.current.numberOTP - Math.round((moment() - timeAuthenOtp.current.timeGetOTP) / 1000))
                timeOTPRef.current = remainTimeOTP
                setTimeOTP(remainTimeOTP)
                timingOTP();
            }, 1000);
        }
    }

    const handleDismiss = () => {
        setVisible(false)
    }

    const handleChangeHiddenOTP = (e) => {
        e.preventDefault();
        if (allowCompanyRender(['081'])) {
            return
        } else {
            localStorage.setItem('hiddenOTP', !hiddenOTP)
            setHiddenOTP(val => !val)
        }
    }

    return (
        <Modal
            visible={visible}
            footer={null}
            width={400}
            keyboard
            onCancel={handleDismiss}
            zIndex={222222}
        >
            <div className='flex flex-direction-column justify-content-center align-items-center'
                style={{
                    borderRadius: 12
                }}
            >
                <svg
                    width={32}
                    height={32}
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M22.748 14.406h-2.601V9.747a3.95 3.95 0 00-3.945-3.945 3.95 3.95 0 00-3.945 3.945v4.66H9.655v-4.66A6.554 6.554 0 0116.202 3.2a6.554 6.554 0 016.546 6.547v4.66z"
                        fill="var(--REF__COLOR)"
                    />
                    <path
                        d="M22.748 9.747v4.66h-2.602v-4.66a3.95 3.95 0 00-3.944-3.945V3.2a6.554 6.554 0 016.546 6.547z"
                        fill="var(--REF__COLOR)"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.434 29.334h9.535a4.339 4.339 0 004.339-4.34V14.13H7.095v10.865a4.339 4.339 0 004.339 4.339zm4.768-11.744a2.4 2.4 0 011.179 4.488v3.968H15.023v-3.968a2.398 2.398 0 011.179-4.488z"
                        fill="var(--REF__COLOR)"
                    />
                </svg>


                <span className='fw-600 fs-medium text-center text-primary' style={{
                    marginTop: dimensions.vertical(32),
                    marginBottom: dimensions.vertical(16),
                }}>
                    {t('enter_authen_otp')}
                </span>

                <span
                    className='fs-small text-center text-second'
                    style={{ marginBottom: dimensions.moderate(32), opacity: 0.64 }}>
                    {msgOTP || t('otp_sent_to_email_sms')}
                </span>

                <div className='flex flex-direction-row align-items-center'>
                    <span className='fw-500 text-primary'>
                        {typeOTP}
                    </span>
                    <div className='flex justify-content-center' style={{
                        borderRadius: 20,
                        width: 230,
                        height: 38,
                        marginLeft: 8
                    }}>
                        <Input.Group compact>
                            <Input
                                type={'text'}
                                value={code}
                                onChange={e => {
                                    const value = e.target.value;
                                    setError('')
                                    setCode(value)
                                    if (value.length === 6) {
                                        sendOTP(value)
                                    }
                                }}
                                autoFocus={true}
                                id='otpInputAuthen'
                                disabled={!timeOTP}
                                onPressEnter={() => code.length && sendOTP(code)}
                                autoComplete="off"
                                className='flex flex-1 fs-600 fs-medium text-center'
                                style={{
                                    letterSpacing: 16,
                                    width: !allowCompanyRender(['081']) ? 'calc(100% - 30px)' : '100%',
                                    textAlign: 'center',
                                    WebkitTextSecurity: hiddenOTP ? 'disc' : 'unset'
                                }} />
                            {!allowCompanyRender(['081']) && (
                                <span className='center__item'
                                    style={{
                                        height: 38,
                                        width: 30,
                                        display: 'inline-flex',
                                        border: '1px solid var(--PRIMARY__BORDER__COLOR)'
                                        // borderTopRightRadius: 50,
                                        // borderBottomRightRadius: 50
                                    }}
                                    onClick={handleChangeHiddenOTP}
                                >
                                    {hiddenOTP ? <EyeInvisibleOutlined style={{ color: 'var(--INFO__COLOR)' }} /> : <EyeOutlined style={{ color: 'var(--INFO__COLOR)' }} />}
                                </span>
                            )}
                        </Input.Group>
                    </div>
                </div>

                <span
                    className='fs-small text-second cursor-default'
                    style={{
                        marginBottom: dimensions.vertical(16),
                        opacity: timeOTP ? 1 : 0,
                        marginTop: dimensions.vertical(16)
                    }}>
                    {t('otp_timeout_after')} <span className='text-primary'>{timeOTP} {t('second')}</span>
                </span>

                <span
                    className='fs-small text-error'
                    style={{
                        marginBottom: dimensions.vertical(5),
                        opacity: errors ? 1 : 0,
                        marginTop: dimensions.vertical(5)
                    }}>
                    {errors}
                </span>

                <Button
                    style={{ backgroundColor: code.length > 3 || !timeOTP ? 'var(--PRIMARY)' : 'var(--PRIMARY__DISABLED)', borderRadius: 8 }}
                    className='justify-content-center'
                    block
                    size='large'
                    ref={buttonGetOtpRef}
                    onClick={() => timeOTP ? sendOTP(code) : getOtpNumber()}
                >
                    <span style={{ color: code.length > 3 || !timeOTP ? '#FFF' : styles.SECOND__CONTENT__COLOR, textTransform: 'uppercase' }}>
                        {timeOTP ? t('title_authen_otp') : t('get_otp_number')}
                    </span>
                </Button>

                <Button
                    ghost
                    block
                    size='large'
                    className='justify-content-center'
                    onClick={handleDismiss}
                >
                    <span className='text-second'>
                        {t('common_button_not_confirm')}
                    </span>
                </Button>

            </div>
        </Modal>
    )
}

export default memo(OtpAuthenRequestModal)