//import css thư viện đầu tiên
import './fonts/index.scss'
import './override/normalize.scss'
import 'antd/dist/antd.css';
import './bootstrap_spacing.scss'
import 'react-virtualized/styles.css' // only needs to be imported once

// Override thư viện 
import './override/antd.override.scss'
import './override/highcharts.override.scss'
import './override/react-virtualized.override.scss'

// Import Css về hình dạng, cấu trúc, common
import './atomic.scss'
import './common/index.scss'

// Import css component
import './main.scss'
import './component/index.scss'


// Css animation, custom
import './animation.scss'

// ALTISSS define
import './altisss.common.scss'

import fontSizes from './helper/fontSizes';
import * as dimensions from './helper/dimensions';
import * as scalingUtils from './helper/scalingUtils';
import fontWeights from './helper/fontWeights';
import color from './theme/color'

// Create style for css Use
//-----------------------------------
const { DARK, LIGHT, CN_DARK, CN_LIGHT } = color
const { SMALL, NORMAL, LARGE } = fontSizes
const isFirefox = (navigator.userAgent.indexOf('Firefox') !== -1);

let colorCssString = ''
// ----------------------------------
colorCssString += '.DARK {'
for (const key in DARK) {
    if (Object.hasOwnProperty.call(DARK, key)) {
        colorCssString += `--${key}: ${DARK[key]};`
    }
}
colorCssString += '}'
//-----------------------------------------
colorCssString += '.LIGHT {'
for (const key in LIGHT) {
    if (Object.hasOwnProperty.call(LIGHT, key)) {
        colorCssString += `--${key}: ${LIGHT[key]};`
    }
}
colorCssString += '}'
//-----------------------------------------
colorCssString += '.CN_DARK {'
for (const key in CN_DARK) {
    if (Object.hasOwnProperty.call(CN_DARK, key)) {
        colorCssString += `--${key}: ${CN_DARK[key]};`
    }
}
colorCssString += '}'
//-----------------------------------------
colorCssString += '.CN_LIGHT {'
for (const key in CN_LIGHT) {
    if (Object.hasOwnProperty.call(CN_LIGHT, key)) {
        colorCssString += `--${key}: ${CN_LIGHT[key]};`
    }
}
colorCssString += '}'
//-----------------------------------------
//-----------------------------------------
colorCssString += '.FS__SMALL {'
for (const key in SMALL) {
    if (Object.hasOwnProperty.call(SMALL, key)) {
        colorCssString += `--${key}: ${SMALL[key]};`
    }
}
colorCssString += '}'
//-----------------------------------------
colorCssString += '.FS__NORMAL {'
for (const key in NORMAL) {
    if (Object.hasOwnProperty.call(NORMAL, key)) {
        colorCssString += `--${key}: ${NORMAL[key]};`
    }
}
colorCssString += '}'
//-----------------------------------------
colorCssString += '.FS__LARGE {'
for (const key in LARGE) {
    if (Object.hasOwnProperty.call(LARGE, key)) {
        colorCssString += `--${key}: ${LARGE[key]};`
    }
}
colorCssString += '}'
//-----------------------------------------
colorCssString += ':root {'
for (const key in fontWeights) {
    if (Object.hasOwnProperty.call(fontWeights, key)) {
        colorCssString += `--fw__${key}: ${fontWeights[key]};`
    }
}
colorCssString += '}'
//-----------------------------------------
if (isFirefox) {
    colorCssString += '.ReactVirtualized__Grid.ReactVirtualized__Table__Grid {'
    colorCssString += `scrollbar-width: thin;` // set scrollbar cho firefox
    colorCssString += '}'
}

//-----------------------------------------
const style = document.createElement('style');
style.innerHTML = colorCssString;
document.getElementsByTagName('head')[0].appendChild(style);
//----------------------------------------

export { dimensions, fontSizes, scalingUtils, fontWeights, color };
