const COMMON = {
    BG__NEUTRAL__COLOR: '#605E61',
    EKYC__COLOR: '#40C8F4',
}

const DARK = {
    ...COMMON,
    PRIMARY: '#40C8F4',
    LIQUIDITY__AREA__BRAND__COLOR__1: '#40C8F4',
    LIQUIDITY__AREA__BRAND__COLOR__2: '#e0a026',
}

const LIGHT = {
    ...COMMON,
    PRIMARY: '#0089D0',
    BG__NEUTRAL__COLOR: '#c5c5c5',
    LIQUIDITY__AREA__BRAND__COLOR__1: '#0089D0',
    LIQUIDITY__AREA__BRAND__COLOR__2: '#e0a026',
}

const CN_DARK = {
    ...COMMON,
    ...DARK,
    //--Các value thay đổi cho china
}

const CN_LIGHT = {
    ...COMMON,
    ...LIGHT,
    //--Các value thay đổi cho china
}

export default {
    DARK,
    LIGHT,
    CN_DARK,
    CN_LIGHT,
}
