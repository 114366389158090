import React, { useState, useRef } from 'react'
/**
 * Dùng để delay loading không bị set lại quá nhanh
 * @param {boolean} initStateLoading
 * @returns [isLoading, setLoadingDelay]
 *
 */

const MAX_LOADING_TIME = 20000

const useLoading = (initStateLoading = false): [isLoading: boolean, setLoadingDelay: (loadingState: boolean, delayTime?: number) => void] => {
    const [isLoading, setIsLoading] = useState(initStateLoading)
    const timeStartLoading = useRef(null)
    const maxLoadingTimeTracker = useRef(null)
    // ---
    const setLoadingDelay = (loadingState: boolean, delayTime = 500): void => {
        if (loadingState === true) {
            setIsLoading(true)
            timeStartLoading.current = new Date().getTime()
            //------------
            clearTimeout(maxLoadingTimeTracker.current)
            maxLoadingTimeTracker.current = setTimeout(() => {
                console.warn('Đã có lỗi xảy ra MAX_LOADING_TIME')
                setIsLoading(false)
            }, MAX_LOADING_TIME)
        }
        if (loadingState === false) {
            clearTimeout(maxLoadingTimeTracker.current)
            //------------
            const timeEndLoading = new Date().getTime()
            const continueDelayTime = delayTime - (timeEndLoading - timeStartLoading.current)
            setTimeout(
                () => {
                    setIsLoading(false)
                },
                continueDelayTime > 0 ? continueDelayTime : 10,
            )
        }
    }
    return [isLoading, setLoadingDelay]
}

export default useLoading
