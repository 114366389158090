import React, { useRef, useEffect } from 'react'

/**
 * Hàm có tác dụng: Như useEffect nhưng bỏ qua trường hợp thực hiện ngay lần đầu Render.
 * Tương tự componentDidUpdate
 * @param {Function} effect Truyền vào function như UseEffect
 * @param {Array} dependencies Array dependencies tương tự UseEffect
 */

const useUpdateEffect = (effect: Function, dependencies: any[]): void => {
    const hasMounted = useRef(false)
    useEffect(() => {
        if (!hasMounted.current) {
            hasMounted.current = true
            return
        }
        effect()
    }, dependencies)
}

export default useUpdateEffect
