import React from 'react'
import IC_MARKET_023 from '../../assets/images/common/ic_tabbar_home.png'
import IC_MARKET_888 from '../../assets/images/common/ic3_market.png'
import IC_MARKET_102 from '../../assets/images/common/ic_tabbar_home.png'

import IC_PORTFOLIO_888 from '../../assets/images/common/ic3_portfolio.png'

import IC_ORDER_888 from '../../assets/images/common/ic3_order.png'

import IC_NEWS_888 from '../../assets/images/common/ic3_news.png'

import IC_SERVICE_888 from '../../assets/images/common/ic3_service.png'

import LOGO_DARK_888 from '../../assets/images/logo/alt_login_logo.png'
import LOGO_LIGHT_888 from '../../assets/images/logo/alt_login_logo.png'
import NOTIFY_IMG_888 from '../../assets/images/logo/alt_favicon.ico'
import LOGO_DARK_023 from '../../assets/images/logo/023_login_logo.png'
import LOGO_LIGHT_023 from '../../assets/images/logo/023_login_logo.png'
import LOGO_DARK_020 from '../../assets/images/logo/020_login_logo.png'
import LOGO_LIGHT_020 from '../../assets/images/logo/020_login_logo.png'
import LOGO_DARK_036 from '../../assets/images/logo/036_login_logo.png'
import LOGO_LIGHT_036 from '../../assets/images/logo/036_login_logo.png'
import LOGO_DARK_075 from '../../assets/images/logo/075_login_logo.png'
import LOGO_LIGHT_075 from '../../assets/images/logo/075_login_logo.png'
import LOGO_DARK_082 from '../../assets/images/logo/082_login_logo.png'
import LOGO_LIGHT_082 from '../../assets/images/logo/082_login_logo.png'
import LOGO_DARK_102 from '../../assets/images/logo/102_login_logo.png'
import LOGO_LIGHT_102 from '../../assets/images/logo/102_login_logo.png'
import LOGO_DARK_061 from '../../assets/images/logo/061_login_logo.png'
import LOGO_LIGHT_061 from '../../assets/images/logo/061_login_logo.png'
import LOGO_DARK_028 from '../../assets/images/logo/028_login_logo.png'
import LOGO_LIGHT_028 from '../../assets/images/logo/028_login_logo.png'
import LOGO_DARK_012 from '../../assets/images/logo/012_login_logo.png'
import LOGO_LIGHT_012 from '../../assets/images/logo/012_login_logo.png'

// import LOGO_081 from '../../assets/images/logo/081_login_logo.png'
import LOGO_DARK_081 from '../../assets/images/logo/081_header_logo_light.png'
import LOGO_LIGHT_081 from '../../assets/images/logo/081_header_logo_dark.png'
import LOADING_DARK_081 from '../../assets/images/081_splash_logo_light.png'
import LOADING_LIGHT_081 from '../../assets/images/081_splash_logo_dark.png'
import NOTIFY_IMG_081 from '../../assets/images/loading-shinhan.png'
import BG_LOGIN_081 from '../../assets/images/081_new_year_login.jpg'
import BG_LOGIN_FORM_081 from '../../assets/images/081_login_bound.png'

import LOGO_LIGHT_099 from '../../assets/images/logo/099_logo.png'
import LOGO_DARK_099 from '../../assets/images/logo/099_logo.png'

import TOUCH_ICON from '../../assets/images/common/ic_touch_id.png'
import SLIDE_ICON from '../../assets/images/common/icons8-cat-head-90.png'
// -----------------------------------------------------
import IC_ORDER_LIST from '../../assets/images/common/ic_order_list.svg'
import IC_RIGHTS_INFO from '../../assets/images/common/ic_rights_info.svg'
import IC_ANNOUNCEMENT_ORDER from '../../assets/images/common/ic_announcement_order.svg'
import IC_ODD_LOT_ORDER from '../../assets/images/common/ic_odd_lot_order.svg'
import IC_INTERNAL_STOCK_TRANSFER from '../../assets/images/common/ic_internal_stock_transfer.svg'
import IC_CONFIRM_ORDER from '../../assets/images/common/ic_confirm_order.svg'
import IC_ADDITIONAL_STOCK from '../../assets/images/common/ic_additional_stock.svg'
import IC_PRE_ORDER from '../../assets/images/common/ic_pre_order.png'
import IC_TRANSACTION_STATEMENT from '../../assets/images/common/ic_transaction_statement.svg'
import IC_USER from '../../assets/images/common/ic_tabbar_profile.png'
// import IC_EXCHANGE_STATEMENT from '../../assets/images/common/ic_exchange_statement.png';
//Ion GD tiền -----------------------

import IC_PAYMENT from '../../assets/images/common/ic_payment.svg'
import IC_MONEY_WITHDRAW from '../../assets/images/common/ic_money_withdraw.svg'
import IC_CASH_TRANSFER from '../../assets/images/common/ic_cash_transfer.svg'
import IC_ADVANCE_LENDING from '../../assets/images/common/ic_advance_lending.svg'
import IC_MARGIN_REFUND from '../../assets/images/common/ic_margin_refund.svg'
import IC_MARGIN_POSTPONE from '../../assets/images/common/ic_margin_postpone.svg'
import IC_EXCHANGE_STATEMENT from '../../assets/images/common/ic_transaction_statement.svg'
import IC_ONLINE_BANKING from '../../assets/images/common/ic_online_banking.svg'

// màn hình login
import BG__LOGIN__888 from '../../assets/images/common/bg-image.jpg'
import BG__LOGIN__061 from '../../assets/images/common/bg-image-061.jpg'


type IConfigImageConstant = Record<
    EnumSecCode,
    {   
        LOGO_DARK: any;
        LOGO_LIGHT: any;
        LOADING_DARK?: any;
        LOADING_LIGHT?: any;
        DEFAULT_NOTIFY_IMG: any
    }
>;


const ICON_EXCHANGE: { [key: string]: any } = {
    //GD chứng khoán
    // @ts-expect-error
    IC_ORDER_LIST: <IC_ORDER_LIST fill='red' fillFirst='blue' height={50} style={{ textAlign: 'center' }} />,
    // @ts-expect-error
    IC_RIGHTS_INFO: <IC_RIGHTS_INFO fill='red' fillFirst='blue' height={50} style={{ textAlign: 'center' }} />,
    // @ts-expect-error
    IC_ANNOUNCEMENT_ORDER: <IC_ANNOUNCEMENT_ORDER fill='red' fillFirst='blue' height={50} style={{ textAlign: 'center' }} />,
    // @ts-expect-error
    IC_ODD_LOT_ORDER: <IC_ODD_LOT_ORDER fill='red' fillFirst='blue' height={50} style={{ textAlign: 'center' }} />,
    IC_INTERNAL_STOCK_TRANSFER: (
        // @ts-expect-error
        <IC_INTERNAL_STOCK_TRANSFER fill='red' fillFirst='blue' height={50} style={{ textAlign: 'center' }} />
    ),
    // @ts-expect-error
    IC_CONFIRM_ORDER: <IC_CONFIRM_ORDER fill='red' fillFirst='blue' height={50} style={{ textAlign: 'center' }} />,
    // @ts-expect-error
    IC_ADDITIONAL_STOCK: <IC_ADDITIONAL_STOCK fill='red' fillFirst='blue' height={50} style={{ textAlign: 'center' }} />,
    // @ts-expect-error
    IC_PRE_ORDER: <IC_TRANSACTION_STATEMENT fill='red' fillFirst='blue' height={50} style={{ textAlign: 'center' }} />,
    // @ts-expect-error
    IC_TRANSACTION_STATEMENT: <IC_TRANSACTION_STATEMENT fill='red' fillFirst='blue' height={50} style={{ textAlign: 'center' }} />,
    // "IC_EXCHANGE_STATEMENT": IC_EXCHANGE_STATEMENT,

    //GD tiền
    // @ts-expect-error
    IC_PAYMENT: <IC_PAYMENT fill='red' fillFirst='blue' height={50} style={{ textAlign: 'center' }} />,
    // @ts-expect-error
    IC_MONEY_WITHDRAW: <IC_MONEY_WITHDRAW fill='red' fillFirst='blue' height={50} style={{ textAlign: 'center' }} />,
    // @ts-expect-error
    IC_CASH_TRANSFER: <IC_CASH_TRANSFER fill='red' fillFirst='blue' height={50} style={{ textAlign: 'center' }} />,
    // @ts-expect-error
    IC_ADVANCE_LENDING: <IC_ADVANCE_LENDING fill='red' fillFirst='blue' height={50} style={{ textAlign: 'center' }} />,
    // @ts-expect-error
    IC_MARGIN_REFUND: <IC_MARGIN_REFUND fill='red' fillFirst='blue' height={50} style={{ textAlign: 'center' }} />,
    // @ts-expect-error
    IC_MARGIN_POSTPONE: <IC_MARGIN_POSTPONE fill='red' fillFirst='blue' height={50} style={{ textAlign: 'center' }} />,
    // @ts-expect-error
    IC_EXCHANGE_STATEMENT: <IC_EXCHANGE_STATEMENT fill='red' fillFirst='blue' height={50} style={{ textAlign: 'center' }} />,
    // @ts-expect-error
    IC_ONLINE_BANKING: <IC_ONLINE_BANKING fill='red' fillFirst='blue' height={50} style={{ textAlign: 'center' }} />
};

const ICON_COMMON = {
    MARKET_TABBAR: IC_MARKET_888,
    PORTFOLIO_TABBAR: IC_PORTFOLIO_888,
    ORDER_TABBAR: IC_ORDER_888,
    NEWS_TABBAR: IC_NEWS_888,
    SERVICE_TABBAR: IC_SERVICE_888,

    LOGO_DARK: LOGO_DARK_888,
    LOGO_LIGHT: LOGO_LIGHT_888,
    TOUCH_ICON: TOUCH_ICON,
    SLIDE_ICON: SLIDE_ICON,
    IC_USER: IC_USER,
    BG__LOGIN: BG__LOGIN__888,
    DEFAULT_NOTIFY_IMG: IC_USER,
}


const configImage: IConfigImageConstant = {
    '888': {
        ...ICON_COMMON,
        ...ICON_EXCHANGE,
        LOGO_DARK: LOGO_DARK_888,
        LOGO_LIGHT: LOGO_LIGHT_888,
        DEFAULT_NOTIFY_IMG: NOTIFY_IMG_888,
    },
    '023': {
        ...ICON_COMMON,
        LOGO_DARK: LOGO_DARK_023,
        LOGO_LIGHT: LOGO_LIGHT_023,
    },
    '036': {
        ...ICON_COMMON,
        LOGO_DARK: LOGO_DARK_036,
        LOGO_LIGHT: LOGO_LIGHT_036,
    },
    '102': {
        ...ICON_COMMON,
        LOGO_DARK: LOGO_DARK_102,
        LOGO_LIGHT: LOGO_LIGHT_102,
    },
    '061': {
        ...ICON_COMMON,
        ...{ BG__LOGIN: BG__LOGIN__061 },
        LOGO_DARK: LOGO_DARK_061,
        LOGO_LIGHT: LOGO_LIGHT_061,
    },
    '075': {
        ...ICON_COMMON,
        LOGO_DARK: LOGO_DARK_075,
        LOGO_LIGHT: LOGO_LIGHT_075,
    },
    '028': {
        ...ICON_COMMON,
        LOGO_DARK: LOGO_DARK_028,
        LOGO_LIGHT: LOGO_DARK_028,
    },
    '081': {
        ...ICON_COMMON,
        ...{BG_LOGIN_FORM: BG_LOGIN_FORM_081},
        ...{BG__LOGIN: BG_LOGIN_081},
        LOGO_DARK: LOGO_DARK_081,
        LOGO_LIGHT: LOGO_LIGHT_081,
        LOADING_DARK: LOADING_LIGHT_081,
        LOADING_LIGHT: LOADING_DARK_081,
        DEFAULT_NOTIFY_IMG: NOTIFY_IMG_081,
    },
    '099': {
        ...ICON_COMMON,
        LOGO_DARK: LOGO_DARK_099,
        LOGO_LIGHT: LOGO_LIGHT_099,
    },
    '020': {
        ...ICON_COMMON,
        LOGO_DARK: LOGO_DARK_020,
        LOGO_LIGHT: LOGO_DARK_020,
    },
    '082': {
        ...ICON_COMMON,
        LOGO_DARK: LOGO_DARK_082,
        LOGO_LIGHT: LOGO_DARK_082,
    },
    '004': {
        ...ICON_COMMON,
        LOGO_DARK: LOGO_DARK_888,
        LOGO_LIGHT: LOGO_DARK_888,
    },
    '012': {
        ...ICON_COMMON,
        LOGO_DARK: LOGO_DARK_012,
        LOGO_LIGHT: LOGO_LIGHT_012,
    },
}
export default configImage
