import { Modal, Button, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { StoreTrading } from 'store-trading';
import { useDeviceSelectors } from 'react-device-detect';
import { eventList, glb_sv, reqFunct, sendRequest } from 'utils';
import userPolicyImg from 'assets/images/075_user_policy.png'
import Text from 'antd/lib/typography/Text';
import { fontSizes, fontWeights } from 'styles';
import { color } from 'highcharts';
import './index.scss'
import { fontStyle } from '@mui/system';
import { borderRadius } from 'styles/helper/dimensions';
import { Link  } from 'react-router-dom'

const ServiceInfo = {
	CHECK_DECREE: {
		reqFunct: reqFunct.CHECK_DECREE,
		WorkerName: 'FOSqAccount01',
		ServiceName: 'FOSqAccount01_ProInfoPol_Onl',
		ClientSentTime: '0',
		Operation: 'Q'
	},
	CONFIRM_DECREE: {
		reqFunct: reqFunct.CONFIRM_DECREE,
		WorkerName: 'FOSxAccount01',
		ServiceName: 'FOSxAccount01_ProInfoPol_Onl',
		ClientSentTime: '0',
		Operation: 'I'
	}
};
export default function PersonalDataPolicyModal() {
	const { t } = useTranslation();

	const { userInfo } = useContext(StoreTrading);

	const [isShowPopupDecree, setIsShowPopupDecree] = useState(false);
	const [selectors, data] = useDeviceSelectors();

	const deviceInfo = useRef('');

	useEffect(() => {
		const commonEvent = glb_sv.commonEvent.subscribe(msg => {
			if (msg.type === eventList.OPEN_MODAL_PERSONAL_POLICY) {
				checkDecree();
			}
		});
        getDeviceInfo();
		return () => {
			commonEvent.unsubscribe();
		};
	});

    const getDeviceInfo = () => {
        deviceInfo.current = "browser:" +
            data.browser.name + '|' +
            data.browser.version + ',' +
            "os:" +
            data.os.name + '|' +
            data.os.version;
    }

	const checkDecree = () => {
		console.log(userInfo);
		const InputParam = [glb_sv.objShareGlb['acntNoInfo'][0]?.AcntNo];
		sendRequest(ServiceInfo.CHECK_DECREE, InputParam, handleCheckDecree, true);
	};

	const handleCheckDecree = (reqInfoMap, message) => {
		if (Number(message['Result']) === 0) {
		} else {
			let jsondata = {};
			if (message['Data']) {
				try {
					if (message['Data']) {
						jsondata = JSON.parse(message['Data'])[0];
					}
				} catch (err) {
					console.error('getNewsResultProc error', err);
					return;
				}
			}
			if (jsondata.c1 === 'N') {
				setIsShowPopupDecree(true);
			} 
		}
	};
	const confirmDecree = () => {
		setIsShowPopupDecree(false);
		const InputParam = [glb_sv.objShareGlb['acntNoInfo'][0]?.AcntNo, '', deviceInfo.current, ''];
		sendRequest(ServiceInfo.CONFIRM_DECREE, InputParam, handleConfirmDecree, true);
	};
	const handleConfirmDecree = (reqInfoMap, message) => {
		if (Number(message['Result']) === 0) {
			notification.warning({
				message: t('common_notify'),
				description: message['Message'],
				duration: 3
			});
			setIsShowPopupDecree(false);
		} else {
			let jsondata = [];
			if (message['Data']) {
				try {
					if (message['Data']) {
						jsondata = JSON.parse(message['Data']);
					}
					notification.warning({
						message: t('common_notify'),
						description: message['Message'],
						duration: 3
					});
					setIsShowPopupDecree(false);
				} catch (err) {
					console.error('getNewsResultProc error', err);
					return;
				}
			}
		}
	};

	return (
		<>
			{isShowPopupDecree ? (
				<Modal visible={isShowPopupDecree} footer={null} width={400}
					className='personal-policy-modal'
				>
					<div style={UI.container} >
						<img src={userPolicyImg} width='81px' height='81px' alt="user-policy-img" />
						<Text style={UI.mainTitle}>
							{t("personal_data_processing_policy")}
						</Text>
						<Text style={UI.detailContent}
						>
							{t("government_decree")}
						</Text>
						<Text style={UI.detailContent}>
							{t("policy_details")}
						</Text>
						<Link to={{pathname:glb_sv.configInfo?.personal_policy_link}}
							target="_blank" rel="noopener noreferrer">
							<Text style={UI.linkPolicyContent} >
								{t("personal_data_processing_policy")}
							</Text>
						</Link>
						
						<Button
							style={UI.confirmButton}
                            onClick={confirmDecree}
                        >
								{t("read_confirm")}
                        </Button>
					</div>
				</Modal>
			) : null}
		</>
	);
}


const UI = {
    container: {
		display:'flex',
		flexDirection:'column',
		alignItems:'center',
		justifyContent:'center',
		rowGap:12
	},
	mainTitle:{
		display:'block',
		textAlign: 'center',
		fontSize: fontSizes.NORMAL,
		fontWeight: fontWeights.semiBold,
		color: 'var(--USER_POLICY_TEXT)',
	},
    detailContent: {
		display:'block',
		textAlign: 'center',
		fontSize: fontSizes.small,
		fontWeight: fontWeights.normal,
		color: 'var(--USER_POLICY_TEXT)',
    },
	linkPolicyContent:{
		fontSize: fontSizes.small,
		fontWeight: fontWeights.semiBold,
		color: 'var(--USER_POLICY_TEXT)',
		cursor:'pointer',
		fontStyle:'italic'
	},
	confirmButton:{
		fontWeight: fontWeights.bold,
		backgroundColor:'var(--PRIMARY)',
		color: 'var(--USER_POLICY_TEXT)',
		border : '1px solid var(--PRIMARY)',
		borderRadius: 4,
		width:'80%'
	},
}
