// @flow weak

// import React from 'react';
import PropTypes from 'prop-types'

interface IFBigNumberOptions {
    fractionSize?: 0 | number
    empty?: 1
    key?: 'short' | 'dash' | 'input_plcord' | 'finance_report_short' | 'no_unit' | 'finance_report' | 'finance_report_billions'
    translateFunc?: Function
    unit?: string
    fixTo?: 'K' | 'M' | 'B' | 'T' | ''
    noShortUnit?: boolean

}

const FBigNumber = (value: number | '0' | ' ', { fractionSize = 2, empty = 1, key = 'short', translateFunc, unit = '', fixTo = '', noShortUnit = false }: IFBigNumberOptions) => {
    if (value === 777777710000) return 'ATO'
    if (value === 777777720000) return 'ATC'
    
    if (!value || value === '0' || value === ' ' || isNaN(value)) {
        if (key === 'dash') return '---'
        if (empty === 1 && value === null) {
            return '---' // !!! {Dung} Test lại
        } else if (fractionSize === 0) return '0'
        else if (fractionSize === 1) return '0.0'
        else if (fractionSize === 2) return '0.00'
        else if (fractionSize === 3) return '0.000'
        return '0'
    }
    const DECIMAL_SEPARATOR = '.'
    const THOUSANDS_SEPARATOR = ','
    let precision = 10 ** (fractionSize || 1)
    let valueRound = Math.round(Number(value) * precision) / precision
    let [integer, fraction = ''] = (valueRound || '').toString().split('.')
    fraction = fractionSize > 0 ? DECIMAL_SEPARATOR + (fraction + '000000').substring(0, fractionSize) : ''
    // integer = (Number(integer) * 10).toString();
    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, THOUSANDS_SEPARATOR)

    if (key === 'input_plcord') {
        if (integer.substr(0, integer.length) + fraction === '.00') return ''
        else return integer.substr(0, integer.length) + fraction
    }
    if (key === 'short' && fixTo === '') {
        if (Math.abs(value) < 999) return value
        let newValue = value,
            valueUnit = ''
        if (Math.abs(value) >= 10 ** 3) {
            newValue = value / 10 ** 3
            valueUnit = translateFunc ? translateFunc('unit_thousand') + unit : ' K'
        }
        if (Math.abs(value) > 10 ** 6) {
            valueUnit = translateFunc ? translateFunc('unit_millions') + unit : ' M'
            newValue = value / 10 ** 6
        }
        if (Math.abs(value) > 10 ** 9) {
            valueUnit = translateFunc ? translateFunc('unit_billions') + unit : ' B'
            newValue = value / 10 ** 9
        }
        if (Math.abs(value) > 10 ** 12) {
            valueUnit = translateFunc ? translateFunc('unit_trilions') + unit : ' T'
            newValue = value / 10 ** 12
        }
        const precision = 10 ** (fractionSize || 1)
        const valueRound = Math.round(Number(newValue) * precision) / precision
        let [integer, fraction = ''] = (valueRound || '').toString().split('.')
        fraction = fractionSize > 0 ? DECIMAL_SEPARATOR + (fraction + '000000').substring(0, fractionSize) : ''
        integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, THOUSANDS_SEPARATOR)
        return integer.substr(0, integer.length) + fraction + valueUnit
    } else if (key === 'finance_report_short') {
        // Dùng cho báo cáo tài chính
        let newValue = value,
            valueUnit = ''
        if (Math.abs(value) > 10 ** 6) {
            valueUnit = translateFunc ? translateFunc('unit_millions') + unit : ' M'
            newValue = value / 10 ** 6
        }
        if (Math.abs(value) > 10 ** 9) {
            valueUnit = translateFunc ? translateFunc('unit_billions') + unit : noShortUnit ? '' :' B'
            newValue = value / 10 ** 9
        }
        const precision = 10 ** (fractionSize || 1)
        const valueRound = Math.round(Number(newValue) * precision) / precision
        let [integer, fraction = ''] = (valueRound || '').toString().split('.')
        fraction = fractionSize > 0 ? DECIMAL_SEPARATOR + (fraction + '000000').substring(0, fractionSize) : ''
        // integer = (Number(integer) * 10).toString();
        integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, THOUSANDS_SEPARATOR)
        return integer.substr(0, integer.length) + fraction + valueUnit
    } else if (key === 'finance_report_billions') {
        // Dùng cho báo cáo tài chính
        let newValue = value,
            valueUnit = ''

        // if (Math.abs(value) > 10 ** 6) {
        //     valueUnit = translateFunc ? translateFunc('unit_millions') + unit : ' M'
        //     newValue = value / 10 ** 6
        // }
        // if (Math.abs(value) > 10 ** 6) {
            valueUnit = translateFunc ? translateFunc('unit_billions') + unit : noShortUnit ? '' :' B'
            newValue = value / 10 ** 9
        // }
        // console.log({ newValue })
        const precision = 10 ** (fractionSize || 1)
        const valueRound = Math.round(Number(newValue) * precision) / precision
        let [integer, fraction = ''] = (valueRound || '').toString().split('.')
        fraction = fractionSize > 0 ? DECIMAL_SEPARATOR + (fraction + '000000').substring(0, fractionSize) : ''
        integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, THOUSANDS_SEPARATOR)
        return integer.substr(0, integer.length) + fraction + valueUnit
    } else if (key === 'finance_report') {
        // Dùng cho báo cáo tài chính
        let newValue = value,
            valueUnit = ''
        // if (Math.abs(value) > 10 ** 9) {
        //     valueUnit = translateFunc ? translateFunc('unit_billions') + unit : ' B'
        //     newValue = value / 10 ** 9
        // }
        const precision = 10 ** (fractionSize || 1)
        const valueRound = Math.round(Number(newValue) * precision) / precision
        let [integer, fraction = ''] = (valueRound || '').toString().split('.')
        fraction = fractionSize > 0 ? DECIMAL_SEPARATOR + (fraction + '000000').substring(0, fractionSize) : ''
        integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, THOUSANDS_SEPARATOR)
        return integer.substr(0, integer.length) + fraction + valueUnit
    } else {
        let newValue = value,
            valueUnit = ''
        if (fixTo === 'K') {
            newValue = value / 10 ** 3
            valueUnit = translateFunc ? translateFunc('unit_thousand') + unit : ' K'
        }
        if (fixTo === 'M') {
            valueUnit = translateFunc ? translateFunc('unit_millions') + unit : ' M'
            newValue = value / 10 ** 6
        }
        if (fixTo === 'B') {
            valueUnit = translateFunc ? translateFunc('unit_billions') + unit : ' B'
            newValue = value / 10 ** 9
        }
        if (fixTo === 'T') {
            valueUnit = translateFunc ? translateFunc('unit_trilions') + unit : ' T'
            newValue = value / 10 ** 12
        }
        if (key === 'no_unit') {
            valueUnit = ''
        }
        const precision = 10 ** (fractionSize || 1)
        const valueRound = Math.round(Number(newValue) * precision) / precision
        let [integer, fraction = ''] = (valueRound || '').toString().split('.')
        fraction = fractionSize > 0 ? DECIMAL_SEPARATOR + (fraction + '000000').substring(0, fractionSize) : ''
        integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, THOUSANDS_SEPARATOR)
        return integer.substr(0, integer.length) + fraction + valueUnit
    }
    return integer.substr(0, integer.length) + fraction
}

FBigNumber.propTypes = {
    value: PropTypes.any,
    fractionSize: PropTypes.number,
    empty: PropTypes.number,
    key: PropTypes.string,
}

FBigNumber.defaultProps = {
    fractionSize: 0,
    empty: 1,
}
export default FBigNumber
