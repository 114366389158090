import glb_sv from "./service/global_service"

/**
 * 
 * @param {Array} preventSecCode : danh sách các công ty chặn 
 * @returns {Boolean}: Nếu preventSecCode.includes(glb_sv.activeCode) thì sẽ trả về false
 */
const preventCompanyRender = (preventSecCode: ISecCode[] = []) => {
    return !preventSecCode.includes(glb_sv.activeCode)
}

export default preventCompanyRender