function changeTheme(currentTheme: 'NULL' | 'DARK' | 'LIGHT' | 'null', newTheme: "DARK" | 'LIGHT' | 'null') {
    const body = document.body
    if (newTheme && newTheme !== 'null' && currentTheme !== newTheme) {
        body.classList.add(newTheme)
        if (currentTheme !== 'NULL') body.classList.remove(currentTheme)
        localStorage.setItem('THEME', newTheme)
    } else {
        body.classList.add('DARK')
        if (currentTheme !== 'NULL' && currentTheme !== 'null') body.classList.remove(currentTheme)
        localStorage.setItem('THEME', 'DARK') 
    }
}

function changeFontsize(currentFontsize, newFontsize) {
    const body = document.body
    if (currentFontsize !== newFontsize) {
        body.classList.add(newFontsize)
        body.classList.remove(currentFontsize)
        localStorage.setItem('FONTSIZE', newFontsize)
    }
}


export {
    changeTheme,
    changeFontsize
}