/**
 * Defined all of colors that using for project
 */
// .highcharts-point.highcharts-color-0:nth-child(1) {
//     fill: #EFADFF;
//   }
//   .highcharts-point.highcharts-color-0:nth-child(2) {
//     fill: #AFAAFF;
//   }
//   .highcharts-point.highcharts-color-0:nth-child(3) {
//     fill: #92EB7E;
//   }
//   .highcharts-point.highcharts-color-0:nth-child(4) {
//     fill: #FF3300;
//   }
//   .highcharts-point.highcharts-color-0:nth-child(5) {
//     fill: #5B9BD5;
//   }
const COMMON = {
    NOTIFY__INFO: '#3598DB',
    NOTIFY__WARN: '#FF9801',
    NOTIFY__ERROR: '#E74C3C',
    match_a_pieces_color: '#00D3B8',
    wait_to_match_color: '#e0780e',
    reject_color: '#E64A19',
    send_to_exchange_color: 'orange',
    LINE_MA5: '#FFA43B',
    LINE_MA10: '#00CFED',
    LINE_MA20: '#FF725B', // '#4CAF50',
    LINE_MA30: '#196EEE',
    GREEN__COLOR: '#07923D',
    GREEN__DARK__COLOR: '#008E7E',
    YELLOW__COLOR: '#FFBC16',
    RED__COLOR: '#F52B1F',
    WHITE__COLOR: '#FFFF',

    CASH__COLOR: '#EFADFF',
    STOCK__COLOR: '#AFAAFF',
    TOTAL_ASSETS__COLOR: '#92EB7E',
    DEBT__COLOR: '#FF3300',
    NET_ASSET__COLOR: '#5B9BD5',

    PROFIT_LOSS_DOWN: '#FF6973',
    PROFIT_LOSS_UP: '#4CB593',

    PUSH_ASSETS_COLOR: 'rgb(0, 128, 128)',
    DEBT_COLOR: 'rgb(255, 128, 0)',
    MARGIN_LOAN_COLOR: 'rgb(255, 0, 0)',
    BUY_POWER_MIN_COLOR: 'rgb(0, 192, 192)',
    BUY_POWER_MAX_COLOR: 'rgb(255, 0, 255)',
    EKYC__COLOR: '#0F70B8',
    USER_POLICY_TEXT:'#203864'
}
const DARK = {
    ...COMMON,
    BG__1: '#191919', // Màu chính
    BG__2: '#2e3034', // Màu phụ
    BG__3: '#05040E', // Màu backgound table bảng điện
    BG__4: '#262626',
    HOVER__BG__1: '#05040F', // Hover chính
    HOVER__BG__2: '#05040F', // Hover phụ

    TEXT__TITLE__1: '#CE9B51', // Màu title 1
    TEXT__TITLE__2: '#FFFFFF', // Màu title 2
    TEXT__1: '#F5F5F5', // Màu chính
    TEXT__2: '#A3A4AC', // Màu phụ (Nhạt hơn)
    TEXT__3: '#ffffff64', // Màu trắng opacity, plaholer
    TEXT__4: 'green', // Màu Thương hiệu
    ICON__COLOR: '#ABADAE60', // Màu icon
    ICON__ACTIVE: '#ABADAE', // Màu icon active
    REF__COLOR: '#F2C94C', // màu giá tham chiếu
    UP__COLOR: '#12DA35', // Màu Xanh tăng
    DOWN__COLOR: '#EF0210', // Màu đỏ giảm
    CEIL__COLOR: '#f618fb', // Màu tím
    FLOOR__COLOR: '#00CFED', // Màu xanh sàn
    REF__BG: '#e2ae02', // Màu background tham chiểu
    UP__BG: '#2ECC71', // Màu background tăng
    DOWN__BG: '#EB5C55', // Màu background giảm
    CEIL__BG: '#f618fb', // Màu background Trần
    FLOOR__BG: '#00d3b8', // Màu background Sàn
    PRIMARY: '#0F70B8', //
    PRIMARY__BG: '#0F70B8', //
    ERROR__COLOR: '#EF0210', // Màu error
    WARN__COLOR: '#FAD416', // Màu Cảnh báo
    SUCCESS__COLOR: '#07923D', // Màu thành công
    INFO__COLOR: '#389BFF', // Màu info
    BG__MODAL: '#201D30', // Màu Modal
    BG__INPUT: '#20242B', // Màu input
    BORDER__COLOR: '#494949', // Màu border
    BORDER__CHECKBOX: '#434343', // Màu border checkbox

    // Màu table
    TABLE__HEADER__BG: '#3A3A3A', // Màu header table
    TABLE__EVEN__BG: '#171225', // Màu table hàng chẵn
    TABLE__ODD__BG: '#05040E', // Màu table hàng lẻ
    TABLE__HOVER__BG: '#636363ab', //'#0dedf86b', // Màu hover row table
    TABLE__BORDER__COLOR: '#47475E', // Màu border table

    /**
     * Tạo mới mọi người tạo ở đây em sẽ duyệt sau -----------------------------------------------------------------------------
     */
    PRIMARY__DISABLED: '#0F70B84D',
    INPUT__DISABLED: '#333333',
    TABS_TAB: '#3A3A3A',
    DATE_DISABLED: '#3C3C3C',
    /**
     * Code cũ -----------------------------------------------------------------------------
     */
    PRIMARY__BG__COLOR: '#181B1F', // edit
    SECOND__BG__COLOR: '#2e3034',
    THIRD__BG__COLOR: '#141828',
    FOURTH__BG__COLOR: '#151829',
    BOTTOM_TAB: '#1E2230',
    INPUT__BG: '#3A3A3A', //Công edit
    MODAL__BG__COLOR: '#21252A',
    BOTTOM__MODAL__COLOR: '#56575B', // edit
    INPUT__BG__LOGIN: '#FFFFFF1a',
    INPUT__BG__OTP: '#373B3F',
    HEADER__BG__COLOR: '#2E3135', //edit
    TABBAR__BG__COLOR: '#232324', //edit
    TABBAR__SHADOW__COLOR: '#181827', //edit
    BG__DOWN__COLOR: '#ef6378',
    BG__UP__COLOR: '#21b985', //'#17a290',
    PRIMARY__CONTENT__COLOR: '#F9F9F9', //edit
    SECOND__CONTENT__COLOR: '#A3A4AC', //edit
    THIRD__CONTENT__COLOR: '#F52B1F',
    FOURTH__CONTENT__COLOR: '#8B8C8F', //edit
    FIFTH__CONTENT__COLOR: '#A8A9AB',
    SIXTH__CONTENT__COLOR: '#949494',
    PLACEHODLER__COLOR: '#545769',
    HEADER__CONTENT__COLOR: '#A7A8AA', //edit
    INPUT__BORDER: '#494949', //Công thêm
    PRIMARY__BORDER__COLOR: '#55555575',
    SECOND__BORDER__COLOR: '#6c757d',
    THIRD__BORDER__COLOR: '#d7b21c',
    FOURTH__BORDER__COLOR: '#646669', //edit
    FIFTH__BORDER__COLOR: '#2E3135',
    BORDER__MODAL: '#2C3034',
    DIVIDER__VIEW__COLOR: '#080B14',
    DIVIDER__COLOR: '#2F3135', //edit
    PRIMARY__TABLE__BG: '#273040',
    SECOND__TABLE__BG: '#232931',
    THIRD__TABLE__BG: '#273040',
    ACTIVE__TABLE__BG: '#28566c',
    BUTTON__PRIMARY: '#529FF3',
    BUTTON__PRIMARY__DISABLED: '#529ff380',
    BUTTON__SECONDARY: '#21252A',
    BUTTON__THIRD: '#24262A',
    BUTTON__ACTIVE: '#092740',
    BUTTON__BORDER__ACTIVE: '#063061',
    BUTTON__TEXT__ACTIVE: '#1892CD',
    BUTTON__SELL__BUY: '#2E3135',
    BUTTON__UP__COLOR: '#2ECC71',
    BUTTON__DOWN__COLOR: '#EB5C55',
    ICON__PRIMARY: '#FFFFFF',
    ICON__SECONDARY: '#9EA1A9',
    ICON__THIRD: '#282D3C',
    ICON__FOURTH: '#ccc',
    ICON__TRADINGVIEW: '#728393',
    BLUE__COLOR: '#529FF3',
    BID__COLOR: '#133E37' || '#88CDA5',
    ASK__COLOR: '#432332' || '#F19C9B',
    CHART__TEXT: 'gray',
    UNCHECK_TEXT_CHART: '#A6A9AD',
    NOTIFY__NOT__READ: '#1E2230',
    BUY__BG: '#03BD85' || '#092C2D',
    BUY1__BG: '#0E3C39',
    BUY2__BG: '#09423B',
    BUY3__BG: '#647D81',
    BUY4__BG: '#14BF8A',
    SELL__BG: '#FA4C67' || '#2E1123',
    SELL1__BG: '#421428',
    SELL2__BG: '#53142E',
    SELL3__BG: '#9E8696',
    SELL4__BG: '#D52051',

    // CANCEL__BG: '#ef0210',
    KEYBOARD__BG: '#161C2C',
    KEYBOARD__BORDER: '#2B2E47',
    ICON__CLOCK: '#ABADAE',
    ICON__UN__ACTIVE: '#707275',
    MODAL_BG: '#21252A',
    DIVIDER__PB: '#2F3135',
    HIGHTLIGHT_PB: '#353f4c80',
    TEXT_COLOR: '#ABADAE',

    GRID_CHART: '#4d5057',
    BG__NEUTRAL__COLOR: '#707275',

    // Ti
    BUTTON__TEXT__PRIMARY: '#FFF',
    BUTTON__BG__DEFAULT: '#494949',
    COLOR__TEXT__TAB: '#808080',
    WRAPPER__BORDER: '#494949',

    CANCEL__BG: '#494949',
    LIQUIDITY__AREA__BRAND__COLOR__1: '#704DFB',
    LIQUIDITY__AREA__BRAND__COLOR__2: '#27D1E6',
}
const LIGHT = {
    ...COMMON,
    BG__1: '#FBFBFB', // Màu chính
    BG__2: '#F1F1F3', // Màu phụ
    BG__3: '#FFFFFF', // Màu backgound table bảng điện
    BG__4: '#ecececd4',
    HOVER__BG__1: '#F1F1F3', // Hover chính
    HOVER__BG__2: '#F1F1F3', // Hover phụ

    TEXT__TITLE__1: '#CE9B51', // Màu title 1
    TEXT__TITLE__2: '#000000', // Màu title 2

    TEXT__1: '#25253E', // Màu chính
    TEXT__2: '#A3A4AC', // Màu phụ (Nhạt hơn)
    TEXT__3: '#00000064', // Màu trắng opacity, plaholer
    TEXT__4: 'green', // Màu Thương hiệu
    ICON__COLOR: '#ABADAE60', // Màu icon
    ICON__ACTIVE: '#ABADAE', // Màu icon active
    REF__COLOR: '#f69b0b', // màu giá tham chiếu
    UP__COLOR: '#2ECC71', // Màu Xanh tăng
    DOWN__COLOR: '#EB5C55', // Màu đỏ giảm
    CEIL__COLOR: '#f618fb', // Màu tím
    FLOOR__COLOR: '#00d3b8', // Màu xanh sàn
    REF__BG: '#ecba17', //'#e2ae02', // Màu background tham chiểu
    UP__BG: '#2ECC71', // Màu background tăng
    DOWN__BG: '#EB5C55', // Màu background giảm
    CEIL__BG: '#f618fb', // Màu background Trần
    FLOOR__BG: '#00d3b8', // Màu background Sàn

    PRIMARY: '#0F70B8',
    PRIMARY__BG: '#0F70B8',
    ERROR__COLOR: '#F42A1F', // Màu error
    WARN__COLOR: '#FAD416', // Màu Cảnh báo
    SUCCESS__COLOR: '#06923D', // Màu thành công
    INFO__COLOR: '#389BFF', // Màu info
    BG__MODAL: '#201D30', // Màu Modal
    BG__INPUT: '#20242B', // Màu input
    BORDER__COLOR: '#F0F0F0', // Màu border
    BORDER__CHECKBOX: '#D9D9D9', // Màu border checkbox

    // Màu table
    TABLE__HEADER__BG: '#E8E8E8', // Màu header table
    TABLE__EVEN__BG: '#FFFFFF', // Màu table hàng chẵn
    TABLE__ODD__BG: '#F7F7F7', // Màu table hàng lẻ
    TABLE__HOVER__BG: '#dad1d1cf', //'#0F70B850', // Màu hover row table
    TABLE__BORDER__COLOR: '#D4D4D4', // Màu border table

    /**
     * Tạo mới mọi người tạo ở đây em sẽ duyệt sau -----------------------------------------------------------------------------
     */
    PRIMARY__DISABLED: '#0F70B84D',
    INPUT__DISABLED: '#f5f5f5',
    TABS_TAB: '#FAFAFA',
    DATE_DISABLED: '#DFDFDF',
    /**
     * Code cũ -----------------------------------------------------------------------------
     */
    PRIMARY__BG__COLOR: '#FFFFFF', // edit
    SECOND__BG__COLOR: '#f3f4f8',
    THIRD__BG__COLOR: '#F2F4F7',
    FOURTH__BG__COLOR: '#FFF9F9',
    INPUT__BG: '#F8F8F8',
    BOTTOM_TAB: '#FFFFFF',
    MODAL__BG__COLOR: '#F8F8F8',
    BOTTOM__MODAL__COLOR: '#F5F6F9', // edit
    INPUT__BG__LOGIN: '#F5F6F9',
    INPUT__BG__OTP: '#373B3F',
    HEADER__BG__COLOR: '#F8F8F8', //edit
    TABBAR__BG__COLOR: '#FFFFFF', //edit
    TABBAR__SHADOW__COLOR: '#898c93', //edit
    BG__DOWN__COLOR: '#ff5252', //'#ff3737',
    BG__UP__COLOR: '#00c3aa', //'#17a290',
    PRIMARY__CONTENT__COLOR: '#25253E', // edit
    SECOND__CONTENT__COLOR: '#A3A4AC', //edit
    THIRD__CONTENT__COLOR: '#2ECC71',
    FOURTH__CONTENT__COLOR: '#5C5D82', //edit
    FIFTH__CONTENT__COLOR: '#737383',
    SIXTH__CONTENT__COLOR: '#848692',
    PLACEHODLER__COLOR: '#B4B2BA',
    HEADER__CONTENT__COLOR: '#737383', // edit
    INPUT__BORDER: '#D9D9D9', //Công edit
    PRIMARY__BORDER__COLOR: '#D9D9D9',
    SECOND__BORDER__COLOR: '#E1E1E1',
    THIRD__BORDER__COLOR: '#d7b21c',
    FOURTH__BORDER__COLOR: '#EEEEEE',
    FIFTH__BORDER__COLOR: '#E9E9EC',
    BORDER__MODAL: '#d0d0d0',
    DIVIDER__VIEW__COLOR: '#eee',
    DIVIDER__COLOR: '#F2F2F2', // edit
    PRIMARY__TABLE__BG: '#e1e1e1',
    SECOND__TABLE__BG: '#eee',
    THIRD__TABLE__BG: '#FBFBFB',
    ACTIVE__TABLE__BG: '#BDCFEE',
    BID__COLOR: '#CEF2E6' || '#87CDA5',
    ASK__COLOR: '#FEDCE2' || '#F19C9B',
    BUTTON__PRIMARY: '#529FF3',
    BUTTON__PRIMARY__DISABLED: '#529ff380',
    BUTTON__SECONDARY: '#F8F8F8',
    BUTTON__THIRD: '#F5F6F9', //edit
    BUTTON__ACTIVE: '#DAF6FF',
    BUTTON__BORDER__ACTIVE: '#ADDAFE',
    BUTTON__TEXT__ACTIVE: '#529FF3',
    BUTTON__SELL__BUY: '#E9E9EC',
    BUTTON__UP__COLOR: '#2ECC71',
    BUTTON__DOWN__COLOR: '#EB5C55',
    ICON__PRIMARY: '#24253D',
    ICON__SECONDARY: '#666666',
    ICON__THIRD: '#ddd',
    ICON__FOURTH: '#ccc',
    ICON__TRADINGVIEW: '#728393',
    BLUE__COLOR: '#529FF3',
    CHART__TEXT: '#CCC',
    UNCHECK_TEXT_CHART: '#aaa',
    NOTIFY__NOT__READ: '#ccc',
    BUY__BG: '#03BD85',
    BUY1__BG: '#1FC593',
    BUY2__BG: '#3FCDA2',
    BUY3__BG: '#A6E7D4',
    BUY4__BG: '#FFF',
    SELL__BG: '#FA4C67',
    SELL1__BG: '#FB6179',
    SELL2__BG: '#FB778B',
    SELL3__BG: '#FEE3E7',
    SELL4__BG: '#FFF',
    // CANCEL__BG: '#ef0210',

    KEYBOARD__BG: '#FFFFFF',
    KEYBOARD__BORDER: '#a5a5a5',
    ICON__CLOCK: '#A3A4AC',
    ICON__UN__ACTIVE: '#707275',
    MODAL_BG: '#F8F8F8',
    DIVIDER__PB__COLOR: '#CBCDDE',
    HIGHTLIGHT_PB: '#F2F4F7',
    TEXT_COLOR: '#24253da3',

    GRID_CHART: '#CCD6EB',
    BG__NEUTRAL__COLOR: '#c5c5c5',

    // Ti
    BUTTON__TEXT__PRIMARY: '#FFF',
    BUTTON__BG__DEFAULT: '#494949',
    COLOR__TEXT__TAB: '#808080',
    WRAPPER__BORDER: '#494949',

    CANCEL__BG: '#D4D4D4',
    LIQUIDITY__AREA__BRAND__COLOR__1: '#704DFB',
    LIQUIDITY__AREA__BRAND__COLOR__2: '#27D1E6',
}
const CN_DARK = {
    ...DARK,
    //--Các value thay đổi cho china
    REF__COLOR: '#fad416',
    UP__COLOR: '#EB5C55',
    DOWN__COLOR: '#2ECC71',
    CEIL__COLOR: '#f618fb',
    FLOOR__COLOR: '#00d3b8',
    ASK__COLOR: '#F19C9B',
    BID__COLOR: '#87CDA5',
    BUY__BG: '#FA4C67',
    SELL__BG: '#03BD85',
    BUTTON__DOWN__COLOR: '#2ECC71',
    BUTTON__UP__COLOR: '#EB5C55',
}
const CN_LIGHT = {
    ...LIGHT,
    //--Các value thay đổi cho china
    REF__COLOR: '#fad416',
    UP__COLOR: '#2ECC71',
    DOWN__COLOR: '#F52B1F',
    CEIL__COLOR: '#f618fb',
    FLOOR__COLOR: '#00d3b8',
    ASK__COLOR: '#F19C9B',
    BID__COLOR: '#87CDA5',
    BUY__BG: '#FA4C67',
    SELL__BG: '#03BD85',
    BUTTON__DOWN__COLOR: '#2ECC71',
    BUTTON__UP__COLOR: '#EB5C55',
}
const colors = {
    DARK,
    LIGHT,
    CN_DARK,
    CN_LIGHT,
}
export default colors
