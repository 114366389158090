import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import colors from './styles/theme/color'
import { eventList, FormatNumber, glb_sv, InfoIndex, STORE_KEY, useUpdateEffect, allowCompanyRender } from './utils'
import preventCompanyRender from './utils/checkCompanyApplyFunct'
import { cloneDeep, isEqual, merge } from 'lodash'

export interface IStoreContext {
	styles: any; // TODO: Fix later
	// setAuth: (authFlag: boolean) => void;
	language: 'VI' | 'EN' | 'CN' | 'KO' | 'ZH' | 'JA';
	setLanguage: React.Dispatch<React.SetStateAction<'VI' | 'EN' | 'CN' | 'KO' | 'ZH' | 'JA'>>;
	applicationSettings: any; // TODO: Fix later
	theme: 'DARK' | 'LIGHT' | 'CN_DARK' | 'CN_LIGHT';
	setTheme: React.Dispatch<React.SetStateAction<'DARK' | 'LIGHT' | 'CN_DARK' | 'CN_LIGHT'>>;
	oddLotFlag: boolean;
	setOddLotFlag: React.Dispatch<React.SetStateAction<boolean>>;

	setStyles: any; // TODO: Fix later
	recentStkList: any[];
	setRecentStkList: React.Dispatch<React.SetStateAction<any[]>>;
	connected: boolean;
	setConnected: React.Dispatch<React.SetStateAction<boolean>>;

	fractionPrice: boolean;
	fractionQty: boolean;
	setFractionPrice: React.Dispatch<React.SetStateAction<boolean>>;
	setFractionQty: React.Dispatch<React.SetStateAction<boolean>>;
	fractionPriceOrder: boolean;
	setFractionPriceOrder: React.Dispatch<React.SetStateAction<boolean>>;
	size: 'middle';
	setApplicationSettings: React.Dispatch<React.SetStateAction<IApplicationSettings>>;
}

export const StoreContext = React.createContext<IStoreContext | null>(null);

const defaultApplicationSettings: IApplicationSettings = {
	application_style: {
		priceboard_is_ratio: true, // number|percent --> Đơn vị cho bảng giá chỗ tăng giảm
		current_select_priceboard: '' // Priceboard đang được chọn
	}
};
const ableSaveChangeApplicationSettingsInLocal = {
    // Có thể lưu/ thay đổi từ user local hay không ?
    // true: lưu biến đó vào local store
    // false: tương đương với lưu state global, tương đương với lưu session store
    application_style: {
        priceboard_is_ratio: true,
        current_select_priceboard: false,
    },
}

export default ({ children }) => {
    const { i18n, t } = useTranslation()

    const [language, setLanguage] = useState<IStoreContext['language']>('VI');
    const [theme, setTheme] = useState<IStoreContext['theme']>('DARK'); // ['DARK', 'LIGHT', 'CN_DARK', 'CN_LIGHT']
    // const [currentNodeServer, setCurrentNodeServer] = useState(0)
    const [styles, setStyles] = useState({ ...colors['DARK'] })
    const [fractionPrice, setFractionPrice] = useState<boolean>(true)
    const [fractionQty, setFractionQty] = useState<boolean>(true)
    const [fractionPriceOrder, setFractionPriceOrder] = useState<boolean>(false)

    const [size, setSize] = useState<'middle'>('middle')

    const [connected, setConnected] = useState(true)
    const [oddLotFlag, setOddLotFlag] = useState<boolean>(false) // Cờ dùng khi chuyển đổi bảng điện qua lô lẻ
    // const [timeoutConnect, setTimeoutConnect] = useState('timeout_30m') // Thời gian đăng xuất sau khi không sử dụng

    // recent stock list
    const [recentStkList, setRecentStkList] = useState<any[]>([])
    const [applicationSettings, setApplicationSettings] = useState<IApplicationSettings>(cloneDeep(defaultApplicationSettings))

    const timeResize = useRef(null)

    useUpdateEffect(() => {
        let saveAbleApplicationSettings = checkValueAbleToSave(
            applicationSettings,
            ableSaveChangeApplicationSettingsInLocal
        )
        localStorage.setItem(
            glb_sv.activeCode + '_' + STORE_KEY.LOCAL_APPLICATION_SETTINGS,
            JSON.stringify(saveAbleApplicationSettings)
        )
    }, [applicationSettings])

    useEffect(() => {
        glb_sv.theme = 'DARK'
        loadData()
        const commonEvent = glb_sv.commonEvent.subscribe((msg) => {
            if (msg.type === eventList.NOTIFY_SERVER) {
                handleNotify(msg.data)
            }
            if (msg.type === eventList.PRC_BOARD_STATUS) {
                const title = msg.data1 + ': ' + t(msg.data)
                // showToast(title, msg.data === 'priceboard_Release' ? styles.WARN__COLOR : styles.NOTIFY__INFO)
            }
            if (msg.type === eventList.RECONNECT_MARKET) {
                if (!glb_sv.isConnectApp) {
                    setConnected(true)
                    glb_sv.isConnectApp = true
                }
            }
        })

        // if (window.innerWidth < 1367 || window.innerHeight < 769) {
        //     setSize('middle')
        // } else setSize('large')
        // window.onresize = function(event) {
        //     if (timeResize.current) clearTimeout(timeResize.current)
        //     timeResize.current = setTimeout(() => {
        //         if (window.innerWidth < 1367 || window.innerHeight < 769) {
        //             setSize('middle')
        //         } else setSize('large')
        //     }, 100);
        // };

        return () => {
            commonEvent.unsubscribe()
        }
    }, [])

    const loadData = () => {
        const localApplicationSettings = localStorage.getItem(
            glb_sv.activeCode + '_' + STORE_KEY.LOCAL_APPLICATION_SETTINGS
        )
        if (localApplicationSettings) {
            try {
                setApplicationSettings(merge(defaultApplicationSettings, JSON.parse(localApplicationSettings)))
            } catch (error) {
                console.error('error setApplicationSettings', error)
            }
        }

        // -----------------------
        const configPrice = localStorage.getItem(STORE_KEY.CONFIG_PRICE)
        if (configPrice === '1') setFractionPrice(true)
        else if (configPrice === '') setFractionPrice(false)

        const configQty = localStorage.getItem(STORE_KEY.CONFIG_QTY)
        if (configQty === '1') setFractionQty(true)
        else if (configQty === '') setFractionQty(false)

        const configPriceOrder = localStorage.getItem(STORE_KEY.CONFIG_PRICE_ORDER)
        if (configPriceOrder === '1') setFractionPriceOrder(true)
        else if (configPriceOrder === '') setFractionPriceOrder(false)
        else if (allowCompanyRender(['075'])) {
            // Nếu chưa có lưu trong local => set cho BETA default x1000
            localStorage.setItem(STORE_KEY.CONFIG_PRICE_ORDER, '1')
            setFractionPriceOrder(true)
        }

        // @ts-expect-error
        const langStore: IStoreContext['language'] = localStorage.getItem(STORE_KEY.LANGAGE)
        if (langStore) {
            setLanguage(langStore)
            i18n.changeLanguage(langStore)
            glb_sv.language = langStore
        } else {
            setLanguage('VI')
            i18n.changeLanguage('VI')
            glb_sv.language = 'VI'
        }
         // @ts-expect-error
        const themeStore: IStoreContext['theme'] = localStorage.getItem(STORE_KEY.THEME)
        if (themeStore) {
            glb_sv.theme = themeStore;
            setTheme(themeStore);
            // @ts-expect-error
            setStyles({ ...colors[themeStore] });
        }

        const newRecentStkList = localStorage.getItem(STORE_KEY.RECENT_STOCK_LIST)
        if (newRecentStkList) setRecentStkList(JSON.parse(newRecentStkList))

        const listHeaderPb = localStorage.getItem(STORE_KEY.LIST_HEADER_PRICEBOARD)
        if (listHeaderPb) glb_sv.LIST_HEADER_PRICEBOARD = JSON.parse(listHeaderPb)

        if (!glb_sv.IndexMarket['HSXIndex']) glb_sv.IndexMarket['HSXIndex'] = new InfoIndex()
        if (!glb_sv.IndexMarket['HNXIndex']) glb_sv.IndexMarket['HNXIndex'] = new InfoIndex()
        if (!glb_sv.IndexMarket['HNXUpcomIndex']) glb_sv.IndexMarket['HNXUpcomIndex'] = new InfoIndex()

        const timeoutConnectStore = localStorage.getItem(STORE_KEY.TIMEOUT_CONNECT)
        if (timeoutConnectStore) {
            // setTimeoutConnect(timeoutConnectStore);
            glb_sv.timeoutConnect = timeoutConnectStore
        }

        // check xem lần time user login và hiện tại với timeoutConnect để check AutoLogin
        // const lastTimeLogin = localStorage.getItem(STORE_KEY.LAST_TIME_LOGIN);
        // let time = 30 * 60000;
        // if (glb_sv.timeoutConnect === 'timeout_1h') {
        //     time = 60 * 60000
        // }
        // if (glb_sv.timeoutConnect === 'timeout_2h') {
        //     time = 2 * 60 * 60000
        // }
        // if (glb_sv.timeoutConnect === 'timeout_4h') {
        //     time = 4 * 60 * 60000
        // }
        // if (glb_sv.timeoutConnect === 'timeout_8h') {
        //     time = 8 * 60 * 60000
        // }
        // if (moment() - moment(lastTimeLogin) > time) {
        //     localStorage.setItem(STORE_KEY.AutoLoginMode, false)
        // }
    }

    const handleNotify = (message) => {
        const notifyTp = message['MsgTp']
        let jsonArr = []
        try {
            const temp = glb_sv.filterStrBfParse(message['Data'])
            jsonArr = JSON.parse(temp)
        } catch (error) {
            console.log('Error when parse json the notify info: ' + error)
            return
        }
        process_NotifyInfoMsg(notifyTp, jsonArr)
    }

    const process_NotifyInfoMsg = (ntfTp, msgInfo) => {
        if (msgInfo === null || msgInfo === undefined) {
            return
        }
        //-- kiểm tra USER có từ chối nhận Notify không
        // if (!this.checkToastYN(ntfTp)) {
        //     return
        // }

        let ntf_msg = ''
        switch (ntfTp) {
            case 'ORD_NEW': {
                if (!preventCompanyRender(['081'])) break // nếu seccode 081 => không show thông báo
                const StkCd = msgInfo['StkCd']
                const orderNo = msgInfo['OrdNo']
                const msg1 = t('inform_confirm_order_with_order_no')
                const msg2 = t('symbol')
                // ntf_msg = "Thông báo xác nhận lệnh, SHL: " + newNotify["OrdNo"] + ", CK: " + newNotify["StkCd"];
                ntf_msg = msg1 + orderNo + ', ' + msg2 + ': ' + StkCd
                // -- day du lieu vao mang notify

                break
            }
            case 'ORD_REJ': {
                const StkCd = msgInfo['StkCd']
                const orderNo = msgInfo['OrdNo']
                const reason = msgInfo['RejReason']
                const msg1 = t('inform_confirm_cancel_order_with_order_no')
                const msg2 = t('symbol')
                const msg3 = t('reject_reason')
                // ntf_msg = "Thông báo từ chối lệnh, SHL: " + newNotify["OrdNo"] + ", CK: " + newNotify["StkCd"] +
                //                ", lý do từ chối: " + newNotify["RejReason"];
                ntf_msg = msg1 + orderNo + ', ' + msg2 + ': ' + StkCd + ', ' + msg3 + reason

                break
            }
            case 'ORD_MTH': {
                const acntNo = glb_sv.objShareGlb['acntNoList'] ? glb_sv.objShareGlb['acntNoList'][0] : {}
                const acntArr = acntNo && acntNo['id'] ? acntNo['id'].split('.') : []
                const msg1 = t('inform_order_match_with_order_no')
                const msg2 = t('symbol')
                const msg3 = t('match_volume')
                const msg4 = t('match_price')
                // ntf_msg = "Thông báo từ chối lệnh, SHL: " + newNotify["OrdNo"] + ", CK: " + newNotify["StkCd"] +
                //                ", lý do từ chối: " + newNotify["RejReason"];
                if (acntArr[0] === msgInfo['AcntNoB']) {
                    ntf_msg =
                        msg1 +
                        msgInfo['OrdNoB'] +
                        ', ' +
                        msg2 +
                        ': ' +
                        msgInfo['StkCd'] +
                        ', ' +
                        msg3 +
                        FormatNumber(msgInfo['MthQty'], 0, 0) +
                        ', ' +
                        msg4 +
                        FormatNumber(msgInfo['MthPri'], 0, 0) +
                        ' VNĐ'
                } else {
                    ntf_msg =
                        msg1 +
                        msgInfo['OrdNoS'] +
                        ', ' +
                        msg2 +
                        ': ' +
                        msgInfo['StkCd'] +
                        ', ' +
                        msg3 +
                        FormatNumber(msgInfo['MthQty'], 0, 0) +
                        ', ' +
                        msg4 +
                        FormatNumber(msgInfo['MthPri'], 0, 0) +
                        ' VNĐ'
                }

                break
            }
            case 'ORD_CNL': {
                const StkCd = msgInfo['StkCd']
                const orderNo = msgInfo['OrdNo']
                // const reason = msgInfo['RejReason'];
                const msg1 = t('inform_confirm_cancel_order')
                const msg2 = t('order_number')
                const msg3 = t('symbol')
                // ntf_msg = "Thông báo xác nhận hủy lệnh " + newNotify["Note"] + ", SHL: " +  newNotify["OrdNo"] + ", CK: " + newNotify["StkCd"];
                ntf_msg = msg1 + msgInfo['Note'] + ', ' + msg2 + ': ' + orderNo + ', ' + msg3 + ': ' + StkCd

                break
            }
            case 'ORD_MOD': {
                const StkCd = msgInfo['StkCd']
                const orderNo = msgInfo['OrdNo']
                // const reason = msgInfo['RejReason'];
                const msg1 = t('inform_confirm_modify_order')
                const msg2 = t('order_number')
                const msg3 = t('symbol')
                // ntf_msg = "Thông báo xác nhận sửa lệnh " + newNotify["Note"] + ", SHL: " +  newNotify["OrdNo"] + ", CK: " + newNotify["StkCd"];
                ntf_msg = msg1 + msgInfo['Note'] + ', ' + msg2 + ': ' + orderNo + ', ' + msg3 + ': ' + StkCd

                break
            }
            case 'ORD_ADV': {
                const StkCd = msgInfo['StkCd']
                const orderNo = msgInfo['OrdNo']
                const reason = msgInfo['Note']
                const msg1 = t('inform_confirm_advance_order_success_with_create_order_no')
                const msg2 = t('symbol')
                const msg3 = t('inform_confirm_advance_order_unsuccess_with_create_order_no')
                const msg4 = t('fail_reason')
                if (msgInfo['ProcTp'] === '2') {
                    ntf_msg = msg1 + orderNo + ', ' + msg2 + ': ' + StkCd
                    // title = msg1;
                } else {
                    ntf_msg = msg3 + orderNo + ', ' + msg2 + ': ' + StkCd + ', ' + msg4 + reason
                    // title = msg3;
                }

                break
            }
            case 'ORD_REJ8': {
                let Time = msgInfo['Time'] // yyyymmddhh24miss
                Time =
                    Time.substr(6, 2) +
                    '/' +
                    Time.substr(4, 2) +
                    '/' +
                    Time.substr(0, 4) +
                    ' ' +
                    Time.substr(8, 2) +
                    ':' +
                    Time.substr(10, 2) +
                    ':' +
                    Time.substr(12, 2)
                const StkCd = msgInfo['StkCd']
                const orderNo = msgInfo['OrdNo']
                const reason = msgInfo['Note']
                const RejectType = msgInfo['RejectType']
                let msg1 = t('order_not_match_intime')
                const msg2 = t('symbol')
                const msg3 = t('order_number')
                if (RejectType === '2') {
                    msg1 = t('order_not_match_intime_of_mokmakmtl_order')
                }
                // ntf_msg = "Thông báo hủy lệnh không khớp cuối phiên " + newNotify["Note"] + ", SHL: " +
                //                newNotify["OrdNo"] + ", CK " + newNotify["StkCd"];
                ntf_msg = Time + ' ' + msg1 + reason + ', ' + msg3 + ': ' + orderNo + ', ' + msg2 + ': ' + StkCd

                break
            }
            case 'MSS_ORD_OK': {
                const StkCd = msgInfo['StkCd']
                const orderNo = msgInfo['OrdNo']
                const reason = msgInfo['Note']
                const msg1 = t('inform_send_MSS_order_success')
                const msg2 = t('symbol')
                const msg3 = t('order_number')
                const sell = t('common_sell')
                const buy = t('common_buy')
                let msg4
                // ntf_msg = "Thông báo thành công kích hoạt lệnh điều kiện lệnh " + SellBuy +
                //                    + ", CK: " + newNotify["StkCd"] + ", SHL: " + newNotify["OrdNo"];
                if (orderNo === null || orderNo === '') {
                    ntf_msg = reason
                } else {
                    if (msgInfo['SellBuy'] === 1) {
                        msg4 = buy
                    } else {
                        msg4 = sell
                    }
                    ntf_msg = msg1 + msg4 + ', ' + msg2 + ': ' + StkCd + ', ' + msg3 + ': ' + orderNo
                }

                break
            }
            case 'MSS_ORD_ERR': {
                const StkCd = msgInfo['StkCd']
                const reason = msgInfo['Note']
                const msg1 = t('inform_send_MSS_order_unsuccess')
                const msg2 = t('symbol')
                const msg3 = t('fail_reason')
                const sell = t('common_sell')
                const buy = t('common_buy')
                let msg4
                // ntf_msg = "Thông báo từ chối kích hoạt lệnh điều kiện lệnh " + SellBuy +
                //                     ", CK: " + newNotify["StkCd"] + ", Lý do: " + newNotify["Note"];
                if (msgInfo['SellBuy'] === 1) {
                    msg4 = buy
                } else {
                    msg4 = sell
                }
                ntf_msg = msg1 + msg4 + ', ' + msg2 + ': ' + StkCd + ', ' + msg3 + ': ' + reason

                break
            }
            case 'CASH_INC': {
                const amount = msgInfo['Amt']
                const TransDesc = language === 'VI' ? msgInfo['TransDesc'] + ', ' : ''
                const msg1 = t('inform_increase_cash')
                const msg2 = t('cash_amount_increase')
                // ntf_msg = "Thông báo tăng tiền: " + newNotify["TransDesc"] + ", số tiền: " + $filter('number')(newNotify["Amt"], 0);
                ntf_msg = msg1 + TransDesc + msg2 + ': ' + FormatNumber(amount, 0, 0) + ' VNĐ'

                break
            }
            case 'CASH_DEC': {
                const amount = msgInfo['Amt']
                const TransDesc = language === 'VI' ? msgInfo['TransDesc'] + ', ' : ''
                const msg1 = t('inform_decrease_cash')
                const msg2 = t('cash_amount_increase')
                // ntf_msg = "Thông báo giảm tiền: " + newNotify["TransDesc"] + ", số tiền: " + $filter('number')(newNotify["Amt"], 0);
                ntf_msg = msg1 + TransDesc + msg2 + ': ' + FormatNumber(amount, 0, 0) + ' VNĐ'

                break
            }
            case 'CASH_HLD': {
                const amount = msgInfo['Amt']
                const TransDesc = language === 'VI' ? msgInfo['TransDesc'] + ', ' : ''
                const msg1 = t('inform_hold_cash')
                const msg2 = t('cash_amount_increase')
                // ntf_msg = "Thông báo tạm phong tỏa tiền: " + newNotify["TransDesc"] + ", số tiền: " + $filter('number')(newNotify["Amt"], 0);
                ntf_msg = msg1 + TransDesc + msg2 + ': ' + FormatNumber(amount, 0, 0) + ' VNĐ'

                break
            }
            case 'CASH_RLE': {
                const amount = msgInfo['Amt']
                const TransDesc = language === 'VI' ? msgInfo['TransDesc'] + ', ' : ''
                const msg1 = t('inform_release_cash')
                const msg2 = t('cash_amount_increase')
                // ntf_msg = "Thông báo giải tỏa tiền: " + newNotify["TransDesc"] + ", số tiền: " + $filter('number')(newNotify["Amt"], 0);
                ntf_msg = msg1 + TransDesc + msg2 + ': ' + FormatNumber(amount, 0, 0) + ' VNĐ'

                break
            }
            case 'STK_INC': {
                const Qty = msgInfo['Qty']
                const TransDesc = language === 'VI' ? msgInfo['TransDesc'] + ', ' : ''
                const msg1 = t('inform_increase_stock')
                const msg2 = t('stock_quantity_increase')
                // ntf_msg = "Thông báo tăng CK: " + newNotify["TransDesc"] + ", số lượng: " + $filter('number')(newNotify["Qty"], 0);
                ntf_msg = msg1 + TransDesc + msg2 + ': ' + FormatNumber(Qty, 0, 0)

                break
            }
            case 'STK_DEC': {
                const Qty = msgInfo['Qty']
                const TransDesc = language === 'VI' ? msgInfo['TransDesc'] + ', ' : ''
                const msg1 = t('inform_decrease_stock')
                const msg2 = t('stock_quantity_increase')
                // ntf_msg = "Thông báo tăng CK: " + newNotify["TransDesc"] + ", số lượng: " + $filter('number')(newNotify["Qty"], 0);
                ntf_msg = msg1 + TransDesc + msg2 + ': ' + FormatNumber(Qty, 0, 0)

                break
            }
            case 'STK_HLD': {
                const Qty = msgInfo['Qty']
                const TransDesc = language === 'VI' ? msgInfo['TransDesc'] + ', ' : ''
                const msg1 = t('inform_hold_stock')
                const msg2 = t('stock_quantity_increase')
                // ntf_msg = "Thông báo tạm phong tỏa CK: " + newNotify["TransDesc"] + ", số lượng: " + $filter('number')(newNotify["Qty"], 0);
                ntf_msg = msg1 + TransDesc + msg2 + ': ' + FormatNumber(Qty, 0, 0)

                break
            }
            case 'STK_RLE': {
                const Qty = msgInfo['Qty']
                const TransDesc = language === 'VI' ? msgInfo['TransDesc'] + ', ' : ''
                const msg1 = t('inform_release_stock')
                const msg2 = t('stock_quantity_increase')
                // ntf_msg = "Thông báo giải tỏa CK: " + newNotify["TransDesc"] + ", số lượng: " + $filter('number')(newNotify["Qty"], 0);
                ntf_msg = msg1 + TransDesc + msg2 + ': ' + FormatNumber(Qty, 0, 0)

                break
            }
            case 'PIA_AINC': {
                const msg1 = t('inform_increase_PIA_auto')
                // ntf_msg = Thông báo khả năng sử dụng PIA tự động của KH tăng
                ntf_msg = msg1

                break
            }
            case 'PIA_ADEC': {
                const msg1 = t('inform_decrease_PIA_auto')
                // ntf_msg = Thông báo khả năng sử dụng PIA tự động của KH tăng
                ntf_msg = msg1

                break
            }
            case 'TEXT': {
                // -- day du lieu vao mang notify
                ntf_msg = msgInfo['Title'] + ': ' + msgInfo['Content']

                break
            }
            case 'NEWS': {
                // -- day du lieu vao mang notify

                ntf_msg = msgInfo['Title']

                break
            }
            case 'CHAT': {
                if (msgInfo['Sender'] !== glb_sv.objShareGlb['sessionInfo']['userID']) {
                    ntf_msg = msgInfo['Sender'] + ': ' + msgInfo['Content']
                }

                break
            }
            default: {
                break
            }
        }
        if (ntf_msg && ntf_msg.trim().length > 0) {
            // if (glb_sv.permissNotify) {
            //     const title = t('notify_info')
            //     const content = ntf_msg.trim()
            //     this.pushWebNotify(title, content)
            // } else {
            //     toast.info(ntf_msg.trim())
            // }
            // const msg = { type: glb_sv.NOTIFY }
            // glb_sv.commonEvent.next(msg)
            // showToast(ntf_msg, styles.NOTIFY__INFO);
        }
    }

    const store: IStoreContext = {
        language,
        setLanguage,
        theme,
        setTheme,
        // currentNodeServer,
        // setCurrentNodeServer,

        oddLotFlag,
        setOddLotFlag,

        styles,
        setStyles,
        recentStkList,
        setRecentStkList,
        connected,
        setConnected,

        fractionPrice,
        fractionQty,
        setFractionPrice,
        setFractionQty,
        fractionPriceOrder,
        setFractionPriceOrder,
        size,
        applicationSettings,
        setApplicationSettings,
    }

    return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
}

const checkValueAbleToSave = (currAppSettings, configAble2Save) => {
    let result = {}
    for (const key1 in configAble2Save) {
        if (Object.hasOwnProperty.call(configAble2Save, key1)) {
            const groupSetting = configAble2Save[key1]
            result[key1] = {}
            if (typeof groupSetting === 'object') {
                for (const key2 in groupSetting) {
                    if (Object.hasOwnProperty.call(groupSetting, key2)) {
                        const setting = groupSetting[key2]
                        // console.log('setting', key2, groupSetting[key2], setting === true)
                        if (setting === true) {
                            result[key1][key2] = currAppSettings[key1][key2]
                        }
                    }
                }
            }
        }
    }

    return result
}
