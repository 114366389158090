const COMMON = {

}

const DARK = {
    ...COMMON,
    PRIMARY: '#e0b056',
    EKYC__COLOR: '#e0b056',
}

const LIGHT = {
    ...COMMON,
    PRIMARY: '#234098',
    EKYC__COLOR: '#234098',
}

const CN_DARK = {
    ...DARK,
    //--Các value thay đổi cho china

}

const CN_LIGHT = {
    ...LIGHT,
    //--Các value thay đổi cho china

}

export default {
    DARK,
    LIGHT,
    CN_DARK,
    CN_LIGHT
}