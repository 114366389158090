import { DatePicker } from 'antd'
import { useState } from 'react'
import { ReactComponent as IC_CALENDAR } from '../../../assets/images/ic_calendar.svg'
import React from 'react'
import moment from 'moment';

const dateFormatList = 'YYYYMMDD'
const { RangePicker } = DatePicker

interface IRangeDatePickerProps {
	label?: string;
	defaultValue?: string;
	onValueChange?: Function;
	placeholder?: string;
	labelStyle?: React.CSSProperties;
	style?: React.CSSProperties;
	readOnly?: boolean;
	suffixIcon?: React.ReactNode;
    isRow?: boolean;
}

const RangeDatePicker = ({
	label = '',
	defaultValue = '20210325',
	onValueChange = e => null,
	placeholder = '',
	labelStyle = {},
	style = {},
	readOnly = false,
	suffixIcon = <IC_CALENDAR fill='var(--TEXT__1)' />,
	isRow = false
}: IRangeDatePickerProps) => {
	const [dates, setDates] = useState([]);
	const [hackValue, setHackValue] = useState<[moment.Moment, moment.Moment] | undefined>();
	const [value, setValue] = useState<[moment.Moment, moment.Moment] | undefined>();
	const disabledDate = current => {
		if (!dates || dates.length === 0) {
			return false;
		}
		const tooLate = dates[0] && current.diff(dates[0], 'days') > 7;
		const tooEarly = dates[1] && dates[1].diff(current, 'days') > 7;
		return tooEarly || tooLate;
	};

	const onOpenChange = open => {
		if (open) {
            // @ts-expect-error
			setHackValue([]);
			setDates([]);
		} else {
			setHackValue(undefined);
		}
	};

	return (
		<RangePicker
			value={hackValue || value}
			disabledDate={disabledDate}
			onCalendarChange={val => setDates(val)}
			onChange={val => setValue(val)}
			onOpenChange={onOpenChange}
			inputReadOnly={true}
			format={['DD/MM/YYYY']}
		/>
	);
};

export default RangeDatePicker