import { Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { glb_sv } from '../../../utils'

interface IRouteSelectProps {
	centered?: boolean;
	className?: string
}

const RouteSelect = ({ centered, className }: IRouteSelectProps) => {
	const history = useHistory();
	const { t } = useTranslation();
	const [listRouter, setListRouter] = useState([]);
	useEffect(() => {
		setListRouter(glb_sv.configInfo['menuConfig'][1]['subMenu'] || []);
		// console.log("glb_sv.configInfo['menuConfig']", glb_sv.configInfo['menuConfig'][2]['subMenu'])
	}, [glb_sv.configInfo['menuConfig']]);

	const routerLink = ['mkt-view', 'technical-analyze', 'mkt-liquidity', 'mkt-cap', 'foreigner-trade', 'fundamental-analysis', 'company-profile'];
	const changeRouter = link => {
		if (routerLink.includes(link)) {
			history.push(`/${link}`);
		} else {
			return;
		}
	};
	const checkRouter = curr => {
		const mktRouter = curr.slice(6, 100);
		if (routerLink.includes(mktRouter)) {
			return mktRouter;
		}
		return 'mkt-view';
	};
	return (
		<div className={`router_select flex align-items-center ${centered ? 'justify-content-center' : ''} ${className}`}>
			<Select
				defaultValue={checkRouter(history.location.pathname)}
				onChange={value => changeRouter(value)}
				suffixIcon={<span />} //{<IconSelect width={24} height={24}/>}
				dropdownStyle={{ minWidth: '200px' }}
				disabled>
				{listRouter.map((route, i) => {
					return (
						<Select.Option key={i} value={route.routerLink}>
							{t(route.title)}
						</Select.Option>
					);
				})}
			</Select>
		</div>
	);
};

export default RouteSelect
