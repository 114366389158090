import { reqFunct, socket_sv, glb_sv, control_sv, requestInfo, channels, eventList } from '../index'
import moment from 'moment'
import { number } from 'prop-types'
/**
 * @param {Object} options                      Các options như ControlTimeout, default timeout
 * @param {Array} seq                           Seq
 * @param {Function} Command                    SUB|UNSUB|GET_HIST
 * @param {Array} topic                         Các topic cần sub
 * @param {Array} value                         Value Cần sub
 * @param {Number} fromseq
 * @param {Number} size
 * @param {String} type
 *
 * TODO {Dung} Tách RealTimeHandler, các function SUB ra các file riêng
 */

export class RealTimeHandler {
    commonEvent: any
    component: string
    eventMarket: any
    hisSubInfo: any[]
    hisGetHist: { [key: string]: ISubInfo }
    // -----
    constructor(component: string) {
        this.component = component || 'CPN_DEFAULT'
        this.eventMarket = null
        this.hisSubInfo = []
        this.hisGetHist = {}
        this.commonEvent = null
    }
    // ------------------------------------
    listen(func) {
        this.eventMarket = glb_sv.eventMarket.subscribe(func)
    }
    // ------------------------------------
    _listenCommonEventForGethist() {
        this.commonEvent = glb_sv.commonEvent.subscribe((msg) => {
            if (msg.type === eventList.RECONNECT_MARKET) {
                for (const [getHistKey, getHistInfo] of Object.entries(this.hisGetHist)) {
                    subcribeFunctWithControl({
                        ...getHistInfo,
                        time: getHistInfo.time || 10000,
                        component: this.component,
                        Command: 'GET_HIST',
                    })
                }
            }
        })
    }
    // -------------------------------------
    subscribe({ onTimeout, time, topic, value, onSuccess = (info) => null, onFailed = (info) => null }) {
        this.hisSubInfo.push({ component: this.component, onTimeout, time, Command: 'SUB', topic, value })
        subcribeFunctWithControl({
            component: this.component,
            onTimeout,
            time,
            Command: 'SUB',
            topic,
            value,
            onSuccess,
            onFailed,
        })
    }
    unsubscribe({ onTimeout, time, topic, value, onSuccess = (info) => null, onFailed = (info) => null }) {
        subcribeFunctWithControl({
            component: this.component,
            onTimeout,
            time,
            Command: 'UNSUB',
            topic,
            value,
            onSuccess,
            onFailed,
        })
    }
    getHist({
        onTimeout,
        time,
        topic,
        value,
        fromseq,
        size,
        type,
        onSuccess = (info) => null,
        onFailed = (info) => null,
    }) {
        // console.log(onTimeout, time, topic, value, fromseq, size, type);
        // Clear hist data cũ trong glb_sv nếu seq get === 0 (Lấy lại từ đầu)
        // if (fromseq[0] === 0) {
        //     console.log('getHist clear data')
        //     if (type === 'INDEX') {
        //         glb_sv.IndexMarket.setIndexData({
        //             key: value[0],
        //             time: time || '1D',
        //             topic,
        //             data: [],
        //         })
        //     }
        //     if (type === 'STOCK') {
        //         glb_sv.StockMarket.setStockData({
        //             key: value[0],
        //             time: time || '1D',
        //             topic,
        //             data: []
        //         })
        //     }
        // }
        // TODO {Dung} xem và xóa nếu không cần thiết

        if (this.commonEvent === null) this._listenCommonEventForGethist()
        // -------------------------
        const hisGetHistKey =
            'GET_HIST' +
            '|' +
            JSON.stringify(topic) +
            '|' +
            JSON.stringify(value) +
            '|' +
            JSON.stringify(fromseq) +
            '|' +
            JSON.stringify(size)
        this.hisGetHist[hisGetHistKey] = { onTimeout, time, topic, value, fromseq, size, type }
        subcribeFunctWithControl({
            component: this.component,
            onTimeout,
            time: time || 10000,
            topic,
            value,
            Command: 'GET_HIST',
            fromseq,
            size,
            type,
            onSuccess,
            onFailed,
        })
    }
    /**
     * @param {String} options.unsubscribe Default value = true, luôn unsubrice khi component Unmount
     * @param {Object} options Các option khi clear listener
     */
    clearRealTimeListener(options = { unsubscribe: true }) {
        const { unsubscribe = true } = options
        if (!unsubscribe) console.warn('Bạn chưa tự động unsub: ', this.component, ', Lịch sử sub: ', this.hisSubInfo)
        if (unsubscribe === true) {
            this.hisSubInfo.map((unsubInfo: ISubInfo) => {
                subcribeFunctWithControl({
                    ...unsubInfo,
                    Command: 'UNSUB',
                })
            })
        }
        this.eventMarket?.unsubscribe() // Unsub RxJS Listener
        this.commonEvent?.unsubscribe() // Unsub RxJS Listener
    }
}

// subcribeFunct:  Unsafe, tương lai có thể remove. Hãy dùng subcribeFunctWithControl thay thế
export const subcribeFunct = (onTimeout, time, Command, topic, value, fromseq, size, type) => {
    subcribeFunctWithControl({
        component: 'DefautComponent_subcribeFunct',
        onTimeout,
        time: time || 6000,
        Command,
        topic,
        value,
        fromseq,
        size,
        type,
    })
}

export const subcribeFunctWithControl = ({
    component,
    onTimeout,
    time = 6000,
    Command,
    topic,
    value,
    fromseq,
    size,
    type,
    onSuccess,
    onFailed,
}: ISubInfo) => {
    /**
     * @param {String} controlTimeOutKey Là key giúp control biết SUB_REQ nào đang bị timeout
     */
    /**
     * TODO {Dung} check value: [null, undefined, ''] thì return
     */
    let controlTimeOutKey = ''

    if (!socket_sv.socket_stream?.connected) {
        console.warn(
            'Mạng không ổn định, vui lòng thử lại sau, sendSubStream:',
            Command,
            topic,
            value,
            fromseq,
            size,
            type
        )
        // Không xử lý gì khi mạng không ổn định
        return
        // Nếu subcribeFunctStream có hàm time out thì cần phải xử lý ==> dể các state trở về trạng thái ban đầu
        onTimeout && onTimeout({ type: 'not_network' })
        if (Command === 'SUB' || Command === 'GET_HIST') {
            console.log('Retry SUB, GET_HIST khi chưa kết nối thành công');
            setTimeout(() => {
                subcribeFunctWithControl({
                    component,
                    onTimeout,
                    time,
                    Command,
                    topic,
                    value,
                    fromseq,
                    size,
                    type,
                    onSuccess,
                    onFailed,
                })
            }, 1000);
        }
        return
    }
    /**
     * Kiểm tra các trường hợp SUB|UNSUB|GET_HIST và tiến hành
     * Control Timeout, Thông tin sub khác nhau
     */
    if (Command === 'SUB') {
        const arraySubInfo = control_sv.checkSubMapBeforeSub({ topic, value, component })
        // console.log('Chuẩn bị Sub:', arraySubInfo)
        arraySubInfo.map((subInfo) => {
            if (subInfo['value'].length === 0) return
            if (subInfo['topic'].length === 0) return
            // Add info to subcontrol
            control_sv.addNewSub({
                topic: subInfo['topic'],
                value: subInfo['value'],
                component: subInfo['component'],
            })
            // Generate key subscribe
            controlTimeOutKey =
                String(Command) + '|' + JSON.stringify(subInfo.topic) + '|' + JSON.stringify(subInfo.value)
            // Clear timeout của UNSUB tương đương khi SUB => tránh trường hợp UNSUB đang timeout mà SUB đã thành công => 10s sau UNSUB thành công => sai
            control_sv.clearTimeOutRequest(String("UN" + controlTimeOutKey))
            /**
             * Nếu trước đó hàm Sub đang đợi phản hồi thì return
             *
             */
            if (control_sv.ControlTimeOutObj[controlTimeOutKey]) {
                console.log(
                    '[sendSubStream] sendSubStream đang được xử lý, vui lòng đợi! Thông tin Sub: ',
                    Command,
                    subInfo
                )
                return
            }
            // Send subscribe
            subcribeFunctStream({
                Command,
                topic: subInfo.topic,
                value: subInfo.value,
                onSuccess,
                onFailed,
                key: controlTimeOutKey,
            })
            // SetTimeout cho subcribeFunctStream
            control_sv.ControlTimeOutObj[controlTimeOutKey] = setTimeout(
                handleTimeOut,
                time,
                onTimeout,
                controlTimeOutKey,
                Command,
                subInfo.topic,
                subInfo.value,
                component,
            )
        })
        // console.log('Sub control mới sau khi SUB: ', control_sv.subControlMap)
    }
    if (Command === 'UNSUB') {
        // arrayUnsubInfo (không còn thằng nào SUB thì mới có data)
        const arrayUnsubInfo = control_sv.removeSubInfoFromMap({ topic, value, component })
        // console.log('arrayUnsubInfo', arrayUnsubInfo)
        arrayUnsubInfo.map((unsubInfo) => {
            // Generate key subscribe
            controlTimeOutKey =
                String(Command) + '|' + JSON.stringify(unsubInfo.topic) + '|' + JSON.stringify(unsubInfo.value)
            // Clear timeout của SUB tương đương khi UNSUB => tránh trường hợp SUB đang timeout mà UNSUB đã thành công => sau 10s SUB lại => vô nghĩa
            control_sv.clearTimeOutRequest(controlTimeOutKey.slice(2))
            /**
             * Nếu trước đó hàm Sub đang đợi phản hồi thì return
             *
             */
            if (control_sv.ControlTimeOutObj[controlTimeOutKey]) {
                console.log(
                    '[sendSubStream] sendSubStream đang được xử lý, vui lòng đợi! Thông tin Sub: ',
                    Command,
                    unsubInfo
                )
                return
            }
            // Send subscribe
            subcribeFunctStream({
                Command,
                topic: unsubInfo.topic,
                value: unsubInfo.value,
                onSuccess,
                onFailed,
                key: controlTimeOutKey,
            })
            // SetTimeout cho subcribeFunctStream
            control_sv.ControlTimeOutObj[controlTimeOutKey] = setTimeout(
                handleTimeOut,
                time,
                onTimeout,
                controlTimeOutKey,
                Command,
                unsubInfo.topic,
                unsubInfo.value,
                component,
            )
        })
        // console.log('arrayUnsubInfo: ', arrayUnsubInfo)
        // console.log('Sub control còn lại sau khi UNSUB: ', control_sv.subControlMap)
    }
    if (Command === 'GET_HIST') {
        // Generate key subscribe
        controlTimeOutKey =
            String(Command) +
            '|' +
            JSON.stringify(topic) +
            '|' +
            JSON.stringify(value) +
            '|' +
            JSON.stringify(fromseq) +
            '|' +
            JSON.stringify(size)
        subcribeFunctStream({ Command, topic, value, fromseq, size, type, onSuccess, onFailed, key: controlTimeOutKey })
        /**
         * Nếu trước đó hàm Sub đang đợi phản hồi thì return
         *
         */
        if (control_sv.ControlTimeOutObj[controlTimeOutKey]) {
            console.log(
                '[sendSubStream] sendSubStream đang được xử lý, vui lòng đợi! Thông tin Sub: ',
                Command,
                topic,
                value,
                fromseq,
                size,
                type
            )
            return
        }
        // SetTimeout cho subcribeFunctStream
        control_sv.ControlTimeOutObj[controlTimeOutKey] = setTimeout(
            handleTimeOut,
            time,
            onTimeout,
            controlTimeOutKey,
            Command,
            topic,
            value,
            fromseq,
            size,
            type,
            component,
        )
    }
}

const handleTimeOut = (onTimeout, controlTimeOutKey, Command, topic, value, fromseq, size, type, component) => {
    console.log('subcribeFunctStream bị timeout: ', controlTimeOutKey)
    console.log(Command, topic, value, fromseq, size, type)
    control_sv.removeSubInfoFromMap({ topic, value, component })
    control_sv.clearTimeOutRequest(controlTimeOutKey)
    // Xử lý time out cho từng subcribeFunctStream nếu có
    onTimeout && onTimeout({ type: 'timeout', controlTimeOutKey })
}

/**
* @param {String} Command dùng để phân biệt các loại request SUB, UNSUB, GET_HIST
* @param {Array} topic: mảng chứa các loại topic:
* @param {Array} topic:  ** below topics for SUB
* @param {Array} topic:  • MDDS: Market data delivery system of stock/Index all information. The topic actually server side automatically send all topics: MDDS|SI, MDDS|TP, MDDS|EP, MDDS|I, MDDS|BI. Be carefully when use MDDS topic.
* @param {Array} topic:  • MDDS|SI: Market data delivery system of stock information
* @param {Array} topic:  • MDDS|TP: Market data delivery system of stock top price
* @param {Array} topic:  • MDDS|EP: Market data delivery system of stock matched deal in real-time
* @param {Array} topic:  • MDDS|AA: Market data delivery system of stock advertisement announcement.
* @param {Array} topic:  • MDDS|I: Market data delivery system of Index data
* @param {Array} topic:  • MDDS|BI: Market data delivery system of Board Index data
* @param {Array} topic:  • TOP_PRI_UP: Topic get top of stock price up
* @param {Array} topic:  • TOP_PRI_DOWN: Topic get top of stock price down
* @param {Array} topic:  • TOP_QTY_UP: Topic get top of stock has QTY up.
* @param {Array} topic:  • TOP_QTY_DOWN: Topic get top of stock has QTY down.
* @param {Array} topic:  • TOP_VAL_UP: Topic get top of stock has value up.
* @param {Array} topic:  • TOP_VAL_DOWN: Topic get top of stock has value down
* @param {Array} topic:  • FRG_QTY_UP: Topic get top of stock has foreigner investor trading with qty up.
* @param {Array} topic:  • FRG_VAL_UP: Topic get top of stock has foreigner investor trading with value up.
* @param {Array} topic:  • INTRADAY_1s: Get intraday match data in 1s.
* @param {Array} topic:  • INTRADAY_5s: Get intraday match data in 5s.
* @param {Array} topic:  • INTRADAY_15s: Get intraday match data in 15s.
* @param {Array} topic:  • INTRADAY_30s: Get intraday match data in 30s.
* @param {Array} topic:  • INTRADAY_1m: Get intraday match data in 1m.
* @param {Array} topic:  • INTRADAY_5m: Get intraday match data in 5m.
* @param {Array} topic:  • INTRADAY_15m: Get intraday match data in 15m.
* @param {Array} topic:  • INTRADAY_30m: Get intraday match data in 30m.
* @param {Array} topic:  • INTRADAY_1h: Get intraday match data in 1h.
* @param {Array} topic:  ** below topics for GET_HIST
* @param {Array} topic:  ▪ MDDS|EP: Market data delivery system of stock matched deal in real-time
* @param {Array} topic:  ▪ MDDS|I: Market data delivery system of Index data
* @param {Array} topic:  ▪ INTRADAY_1s: Get intraday match data in 1s.
* @param {Array} topic:  ▪ INTRADAY_5s: Get intraday match data in 5s.
* @param {Array} topic:  ▪ INTRADAY_15s: Get intraday match data in 15s.
* @param {Array} topic:  ▪ INTRADAY_30s: Get intraday match data in 30s.
* @param {Array} topic:  ▪ INTRADAY_1m: Get intraday match data in 1m.
* @param {Array} topic:  ▪ INTRADAY_5m: Get intraday match data in 5m.
* @param {Array} topic:  ▪ INTRADAY_15m: Get intraday match data in 15m.
* @param {Array} topic:  ▪ INTRADAY_30m: Get intraday match data in 30m.
* @param {Array} topic:  ▪ INTRADAY_1h: Get intraday match data in 1h.
* @param {Array} value: mảng các mã chứng khoán t55, chỉ số t2
* @param {Array} fromseq Array json data item type is number. From Seq to get history data previous. If input <= 0
server will be get from latest seq. The data response exclude FromSeq.
* @param {Number} size: Size of history item data. If no input size or input <= 0 then server send 200 point.
* @param {String} type: type INDEX || STOCK.
*/
export function subcribeFunctStream({
    Command = '',
    topic = [],
    value = [],
    fromseq = '',
    size = [0],
    type = '',
    onSuccess = () => null,
    onFailed = () => null,
    key,
}: {
        Command: 'SUB' | 'UNSUB' | 'GET_HIST' | ''
        topic: string[]
        value: string[]
        fromseq?: string
        size?: number[]
        type?: string
        onSuccess?: Function
        onFailed?: Function
        key?: string
}) {
    if (Command === 'SUB') {
        const clientSeq = socket_sv.getRqSeq()
        const msgObj2 = {
            ClientSeq: clientSeq,
            TransId: glb_sv.objShareGlb.sessionInfo.sessionId || '123-abc',
            topic,
            value,
        }
        const reqInfo = new requestInfo(reqFunct.SUBSCRIBE_INFO, msgObj2, () => null, '', '', onSuccess, onFailed, key)

        glb_sv.setReqInfoMapValue(clientSeq, reqInfo)
        socket_sv.send2Sv(channels.SUB_REQ, msgObj2)
    }
    if (Command === 'UNSUB') {
        const clientSeq = socket_sv.getRqSeq()
        const msgObj2 = {
            ClientSeq: clientSeq,
            TransId: glb_sv.objShareGlb.sessionInfo.sessionId || '123-abc',
            topic,
            value,
        }
        const reqInfo = new requestInfo(
            reqFunct.UNSUBSCRIBE_INFO,
            msgObj2,
            () => null,
            '',
            '',
            onSuccess,
            onFailed,
            key
        )
        glb_sv.setReqInfoMapValue(clientSeq, reqInfo)
        socket_sv.send2Sv(channels.UNSUB_REQ, msgObj2)
    }
    if (Command === 'GET_HIST') {
        /**
         * TODO {Tuan} Rảnh anh comment hoặc note lại chỗ này kỹ
         */
        const newValue = []
        value.forEach((msgKey) => {
            if (topic[0] === 'INTRADAY_1m') {
                const fullData = glb_sv.mapDataEP.get(msgKey)
                const smlData = glb_sv.mapSMLDataEP.get(msgKey)
                if (size[0] === 50) {
                    if (smlData) {
                        if (fullData && moment().diff(fullData.time) < 60000) {
                            glb_sv.eventMarket.next({ type: eventList.GET_DATA_EP_CHART_DONE, msgKey, isOldData: true })
                        } else if (moment().diff(smlData.time) < 60000) {
                            glb_sv.eventMarket.next({ type: eventList.GET_DATA_EP_CHART_DONE, msgKey, isOldData: true })
                        } else {
                            const newData = {
                                time: moment(),
                            }
                            glb_sv.mapSMLDataEP.set(msgKey, newData)
                            newValue.push(msgKey)
                        }
                    } else {
                        const newData = {
                            time: moment(),
                        }
                        glb_sv.mapSMLDataEP.set(msgKey, newData)
                        newValue.push(msgKey)
                    }
                }
                if (size[0] === 500) {
                    if (fullData) {
                        if (moment().diff(fullData.time) < 60000) {
                            glb_sv.eventMarket.next({ type: eventList.GET_DATA_EP_CHART_DONE, msgKey, isOldData: true })
                        } else {
                            const newData = {
                                time: moment(),
                            }
                            glb_sv.mapDataEP.set(msgKey, newData)
                            newValue.push(msgKey)
                        }
                    } else {
                        const newData = {
                            time: moment(),
                        }
                        glb_sv.mapDataEP.set(msgKey, newData)
                        newValue.push(msgKey)
                    }
                }
            } else newValue.push(msgKey)
        })

        if (!newValue.length) return // TODO {Tuan} Dung: Có gì anh xem xét viết thêm 1 đoạn log chỗ này, em gọi GET_HIST mà bị TimeOut
        const clientSeq = socket_sv.getRqSeq()
        const msgObj2 = {
            ClientSeq: clientSeq,
            TransId: glb_sv.objShareGlb.sessionInfo.sessionId || '123-abc',
            topic,
            value: newValue,
            fromseq,
            size,
        }
        const reqInfo = new requestInfo(
            type === 'INDEX' ? reqFunct.GET_HIST_INDEX : reqFunct.GET_HIST_STOCK,
            msgObj2,
            () => null,
            '',
            '',
            onSuccess,
            onFailed,
            key
        )
        glb_sv.setReqInfoMapValue(clientSeq, reqInfo)
        socket_sv.send2Sv(channels.HIST_REQ, msgObj2)
    }
}
