import React, { useEffect, useState, memo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
    UploadOutlined,
    LoadingOutlined,
    CloseOutlined,
} from '@ant-design/icons';
import { allowCompanyRender, glb_sv } from '../../utils'
import { message, Upload } from 'antd'
import SignaturePad from 'react-signature-canvas'
import { Button } from 'components/basic-component';

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.onload = readSuccess
    function readSuccess(evt) {
        callback(evt.target.result)
    }
    reader.readAsBinaryString(img)
}

const UploadSignature = ({ setUploadSignatureState = () => null, handSignature }) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const [urlSuccess, setUrlSuccess] = useState('')
    const inputImageRef = useRef(null)
    const signatureRef = useRef(null)
    const [hideNote, setHideNote] = useState(false)
    const avatarDomain = glb_sv.configInfo.domain

    const handleUploadImage = (e) => {
        const file = e.target.files[0]
        const isJpgOrPng = file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.warning(t('img_type_incorrect'));
            return
        }
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
            message.warning(t('file_to_large_5mb'));
            return
        }
        setLoading(true)
        getBase64(file, url => {
            
            const requestOptions = {
                method: 'POST',
                headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    faceType: 4,
                    size: file.size,
                    imgType: file.type.split('/')[1],
                    image_data: url
                }),
            }
            
            fetch(`${avatarDomain}/file/storeimage`, requestOptions)
                .then((res) => {
                    return res.json()
                })
                .then((data) => {
                    if (data.success) {
                        setTimeout(() => {
                            setUrlSuccess(`${avatarDomain}/assets/upload_image/${data.data?.imageName}`)
                            setUploadSignatureState(data.data?.imageName || '')
                            message.success(t('common_uploaded'))
                            setLoading(false)
                        }, allowCompanyRender(['036']) ? 3000 : 0);
                    } else {
                        setUrlSuccess('')
                        message.warning(t('cancel_failure'))
                        inputImageRef.current.value = ''
                        setLoading(false)
                    }
                })
                .catch((err) => {
                    setUrlSuccess('')
                    inputImageRef.current.value = ''
                    setLoading(false)
                    message.warning(t('cancel_failure'))
                })
        });
    }

    const handleUploadSignature = () => {
        try {
            setLoading(true)
            const signature = signatureRef.current?.getTrimmedCanvas()?.toDataURL('image/jpeg')
            var base64 = signature?.split('data:image/jpeg;base64,');
            base64 = base64[1];
            const requestOptions = {
                method: 'POST',
                headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    faceType: 4,
                    size: 1000,
                    imgType: 'jpeg',
                    image_data: base64,
                    encodeType: 'base64',
                }),
            }
            fetch(`${avatarDomain}/file/storeimage`, requestOptions)
                .then((res) => {
                    return res.json()
                })
                .then((data) => {
                    if (data.success) {
                        setTimeout(() => {
                            setUrlSuccess(`${avatarDomain}/assets/upload_image/${data.data?.imageName}`)
                            setUploadSignatureState(data.data?.imageName || '')
                            message.success(t('common_uploaded'))
                            setLoading(false)
                        }, 3000);
                    } else {
                        setUrlSuccess('')
                        message.warning(t('cancel_failure'))
                        inputImageRef.current.value = ''
                        setLoading(false)
                    }
                })
                .catch((err) => {
                    setUrlSuccess('')
                    inputImageRef.current.value = ''
                    setLoading(false)
                    message.warning(t('cancel_failure'))
                })
        } catch (error) {
            console.log('error', error);
        }
    }

    // Function handle chữ ký trực tiếp
    const onBeginSign = () => {
        console.log('onBeginSign');
        setHideNote(true)
    }

    const handleClearSign = () => {
        console.log('handleClearSign');
        setUrlSuccess('')
        inputImageRef.current.value = ''
        setUploadSignatureState('')
        signatureRef.current?.clear()
        setHideNote(false)
    }

    const handleUndoSign = () => {
        console.log('handleUndoSign');
        var data = signatureRef.current?.toData();
        if (data) {
            data.pop(); // remove the last dot or line
            signatureRef.current?.fromData(data);
            if (signatureRef.current?.isEmpty()) {
                setHideNote(false)
            }
        }
    }

    if (handSignature) {
        return (
            <>
                <div className='fw-600' style={{ marginTop: 5 }}>
                    {t('sample_signature')}
                </div>
                <span className='price-ref fs-verySmall font-italic fw-500'>
                    {t('new_signature_note')}
                </span>
                
                {urlSuccess ? (
                    <div
                        onClick={() => inputImageRef.current.click()}
                        className='cursor-pointer position-relative mt-2'
                        style={{
                            width: '100%',
                            height: 160,
                            border: '1px dashed var(--BORDER__CHECKBOX)',
                            borderRadius: 8
                        }}>
                        <img src={urlSuccess} alt="signature" style={{ height: '100%', width: '100%' }} />

                        <CloseOutlined
                            onClick={(e) => {
                                e.stopPropagation()
                                setUrlSuccess('')
                                setHideNote(false)
                                inputImageRef.current.value = ''
                                setUploadSignatureState('')
                            }}
                            style={{
                                fontSize: 20,
                                right: 2,
                                top: 2,
                                color: '#111'
                            }}
                            className='cursor-pointer position-absolute' />
                    </div>
                ) : (
                    <div style={{ position: 'relative' }} className='mt-2 flex align-items-center justify-content-center'>
                        <span style={{ position: 'absolute', color: '#111', pointerEvents: 'none' }}>
                            {!hideNote ? t('signature_canvas_note') : null}
                        </span>
                        <SignaturePad
                            ref={signatureRef}
                            onBegin={onBeginSign}
                            clearOnResize
                            backgroundColor='rgb(255,255,255)'
                            canvasProps={{
                                style: {
                                    width: '100%',
                                    height: 160,
                                    backgroundColor: 'rgb(255,255,255)'
                                }
                            }}
                            penColor='black'
                        />
                    </div>
                )}
                
                <div className='mt-2 flex justify-content-between align-items-center w-100'>
                    <div style={{ visibility: !hideNote ? 'hidden' : 'visible'}}>
                        <Button type='sell' className='mr-2' onClick={handleClearSign} disabled={loading}>
                            {t('delete')}
                        </Button>
                        <Button type='cancel' className='mr-2' onClick={handleUndoSign} disabled={!!urlSuccess || loading}>
                            {t('common_Undo')}
                        </Button>
                    </div>
                    {!hideNote ? (
                        <div className='flex align-items-center cursor-pointer' onClick={() => inputImageRef.current?.click()}>
                            <UploadOutlined style={{ fontSize: '1.5rem' }} />
                            <div className='ml-2'>{t('upload_signature')}</div>
                        </div>
                    ) : (
                        <Button hasLoading={loading} loading={loading} type='primary' onClick={handleUploadSignature} disabled={!!urlSuccess || loading}>
                            {t('common_Ok')}
                        </Button>
                    )}
                </div>
                <input
                    type="file"
                    title=""
                    accept="image/png,image/jpg,image/jpeg"
                    ref={inputImageRef}
                    style={{ visibility: 'hidden' }}
                    onChange={handleUploadImage}
                />
            </>
        )
    } else return (
        <>
            <div className='fw-600' style={{ marginTop: 5 }}>
                {t('add_sign_authen')}
            </div>
            <span className='price-ref fs-verySmall font-italic fw-500'>
                {allowCompanyRender(['036']) ? t('signed_warning') : t('signature_note')}
            </span>

            <div
                className='flex justify-content-center upload-sign'
                style={{ paddingLeft: 204, height: 160 }}>
                {urlSuccess ?
                    <div
                        onClick={() => inputImageRef.current.click()}
                        className='cursor-pointer position-relative'
                        style={{
                            width: 160,
                            height: 160,
                            border: '1px dashed var(--BORDER__CHECKBOX)',
                            borderRadius: 8
                        }}>
                        <img src={urlSuccess} alt="signature" style={{ height: '100%', width: '100%' }} />

                        <CloseOutlined
                            onClick={(e) => {
                                e.stopPropagation()
                                setUrlSuccess('')
                                inputImageRef.current.value = ''
                                setUploadSignatureState('')
                            }}
                            style={{
                                fontSize: 20,
                                right: 2,
                                top: 2
                            }}
                            className='cursor-pointer position-absolute' />
                    </div>
                    :
                    <div
                        className='flex flex-direction-column justify-content-center align-items-center cursor-pointer'
                        style={{
                            width: 160,
                            height: 160,
                            border: '1px dashed var(--BORDER__CHECKBOX)',
                            borderRadius: 8
                        }}
                        onClick={() => inputImageRef.current.click()}>
                        {loading ? <LoadingOutlined style={{ fontSize: '1.5rem' }} /> : <UploadOutlined style={{ fontSize: '1.5rem' }} />}
                        <div>{t('upload_signature')}</div>
                    </div>}
                <input
                    type="file"
                    title=""
                    accept="image/png,image/jpg,image/jpeg"
                    ref={inputImageRef}
                    style={{ visibility: 'hidden' }}
                    onChange={handleUploadImage}
                />
            </div>

        </>
    )
}

export default memo(UploadSignature)