import React from "react";
import { IMAGE } from "utils";
import LoadingShinhan from '../../assets/images/081_splash_logo_light.png';

const LoadingView = () => {
    return (
        <div className="h-100 w-100 flex align-items-center justify-content-center bg-primary">
            <div className="lds-grid"><div></div><div></div><div></div><div>
            </div><div></div><div></div><div></div><div></div><div></div></div>
            <div className='loading-shinhan'>
                <img src={localStorage.THEME == 'DARK' ? IMAGE[window.secCodeTheme].LOADING_DARK : IMAGE[window.secCodeTheme].LOADING_LIGHT} alt="" width={50} height={50} />
            </div>
        </div>
    );
};


export default LoadingView;
