const DARK = {}

const LIGHT = {}

const CN_DARK = {
    ...DARK,
    //--Các value thay đổi cho china
}

const CN_LIGHT = {
    ...LIGHT,
    //--Các value thay đổi cho china
}

export default {
    DARK,
    LIGHT,
    CN_DARK,
    CN_LIGHT,
}
