import { Select, Tabs } from 'antd'
import React, { memo, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { StoreContext } from '../../../store'
import { StoreTrading } from '../../../store-trading'

const { Option } = Select

const SelectExchange = ({}) => {
    const { size } = useContext(StoreContext)
    const { filterOption, setFilterOption } = useContext(StoreTrading)
    const { t } = useTranslation()

    const exchangeType = [
        { value: '01', label: 'HOSE' },
        { value: '03', label: 'HNX' },
        { value: '05', label: 'UPC' },
    ]

    const onChange = (value) => {
        console.log('value onChange', value)
        setFilterOption({
            ...filterOption,
            exchangeType: value,
        })
    }

    return (
        <Tabs defaultActiveKey={filterOption.exchangeType} className='' onChange={onChange}>
            {exchangeType.map((exchange) => {
                return <Tabs.TabPane tab={t(exchange.label)} key={exchange.value}></Tabs.TabPane>
            })}
        </Tabs>
    )
}

export default memo(SelectExchange)
