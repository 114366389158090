// @flow weak

// import React from 'react';
import PropTypes from 'prop-types'

const FormatNumber = (value: number | '0' | ' ' | '', fractionSize?: 0 | 1 | 2 | 3, empty?: 1 | 0, key?: 'dash' | 'input_plcord' | 'short', translateFunc?: Function) => {
    if (value === 777777710000 || value === 777777710) return 'ATO'
    if (value === 777777720000 || value === 777777720) return 'ATC'
    if (!value || value === '0' || value === ' ' || isNaN(value)) {
        if (key === 'dash') return '---'
        if (empty === 1) {
            return ''
        } else if (fractionSize === 0) return '0'
        else if (fractionSize === 1) return '0.0'
        else if (fractionSize === 2) return '0.00'
        else if (fractionSize === 3) return '0.000'
        return '0'
    }
    const DECIMAL_SEPARATOR = '.'
    const THOUSANDS_SEPARATOR = ','
    const precision = 10 ** (fractionSize || 1)
    const valueRound = Math.round(Number(value) * precision) / precision
    let [integer, fraction = ''] = (valueRound || '').toString().split('.')
    fraction = fractionSize > 0 ? DECIMAL_SEPARATOR + (fraction + '000000').substring(0, fractionSize) : ''
    // integer = (Number(integer) * 10).toString();
    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, THOUSANDS_SEPARATOR)

    if (key === 'input_plcord') {
        if (integer.substr(0, integer.length) + fraction === '.00') return ''
        else return integer.substr(0, integer.length) + fraction
    }
    if (key === 'short') {
        if (Math.abs(value) < 999) return value
        let newValue = value,
            unit = ''
        if (Math.abs(value) >= 10 ** 3) {
            newValue = value / 10 ** 3
            unit = translateFunc ? translateFunc('unit_thousand') : ' K'
        }
        if (Math.abs(value) > 10 ** 6) {
            unit = translateFunc ? translateFunc('unit_millions') : ' M'
            newValue = value / 10 ** 6
        }
        if (Math.abs(value) > 10 ** 9) {
            unit = translateFunc ? translateFunc('unit_billions') : ' B'
            newValue = value / 10 ** 9
        }
        // if (Math.abs(value) > 10 ** 12) {
        //     unit = translateFunc ? translateFunc('unit_trilions') : ' T'
        //     newValue = value / 10 ** 12
        // }
        let [integerS, fractionS = ''] = (newValue || '').toString().split('.')
        fractionS = DECIMAL_SEPARATOR + (fractionS + '000000').substring(0, 2)
        integerS = integerS.replace(/\B(?=(\d{3})+(?!\d))/g, THOUSANDS_SEPARATOR)
        return integerS.substr(0, integerS.length) + fractionS + unit
    }
    return integer.substr(0, integer.length) + fraction
}

FormatNumber.propTypes = {
    value: PropTypes.any,
    fractionSize: PropTypes.number,
    empty: PropTypes.number,
    key: PropTypes.string,
}

FormatNumber.defaultProps = {
    fractionSize: 0,
    empty: 1,
}
export default FormatNumber
