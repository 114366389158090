import React, { memo, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AlertModal from '../../components/alert-modal'
import EkycModal from '../../components/ekyc/modal-ekyc'
import OpenAccountSSV from '../../components/ekyc/open-account-ssv'
import OtpModal from '../../components/otp-modal'
import { StoreContext } from '../../store'
import { StoreTrading } from '../../store-trading'
import {
    allowCompanyRender,
    eventList,
    glb_sv,
    InfoIndex,
    RealTimeHandler,
    reqFunct,
    Screens,
    sendRequest,
    socket_sv,
    STORE_KEY,
    useUpdateEffect,
} from '../../utils'
import { Button, Carousel, Modal, Checkbox } from 'antd';
import { useHistory } from 'react-router';
import { ReactComponent as IC_CLOSE } from '../../assets/images/common/ic_close.svg';
import OtpAuthenRequestModal from '../../components/otp-authen-request-modal'
import EventModal from '../../components/eventModal'
import IndividualBondDetail from '../../views/PriceBoard/PriceBoardContainer/TableIndividualBonds/individual_bond_detail'
import { useDeviceSelectors } from 'react-device-detect';
import { ReactComponent as IC_NOTIFY } from '../../assets/images/common/ic_notify.svg'
import PersonalDataPolicyModal from 'components/modal/personal-data-policy-modal'

const ServiceInfo = {
    LOGIN_FUNCTION: {
        reqFunct: reqFunct.LOGIN_FUNCTION,
        WorkerName: 'FOSxID02',
        ServiceName: 'FOSxID02_Login',
        ClientSentTime: '0',
        Operation: 'Q',
    },
    getFav: {
        reqFunct: reqFunct.GET_FAV_INFO,
        WorkerName: 'FOSqID01',
        ServiceName: 'FOSqID01_FavoritesMgt',
        Operation: 'Q',
        ClientSentTime: '0',
    },
    getDetailList: {
        reqFunct: reqFunct.GET_DETAIL_FAV_LIST,
        WorkerName: 'FOSqID01',
        ServiceName: 'FOSqID01_FavoritesMgt',
        Operation: 'Q',
        ClientSentTime: '0',
    },
    AddStockToFavList: {
        reqFunct: reqFunct.ADD_STOCK_TO_FAV_LIST,
        WorkerName: 'FOSxID01',
        ServiceName: 'FOSxID01_FavoritesMgt',
        ClientSentTime: '0',
        Operation: 'I',
    },
    GET_INDEX_LIST: {
        reqFunct: reqFunct.GET_INDEX_LIST,
        WorkerName: 'FOSqMkt02',
        ServiceName: 'FOSqMkt02_IndexMgt',
        ClientSentTime: '0',
        Operation: 'Q',
    },
    GET_LIST_OWN: {
        reqFunct: reqFunct.GET_LIST_OWN,
        WorkerName: 'FOSqStock',
        ServiceName: 'FOSqStock_01_online',
        Operation: 'Q',
        ClientSentTime: '0',
    },
    CONFIG_NOTIFY: {
        reqFunct: reqFunct.CONFIG_NOTIFY,
        WorkerName: 'FOSxID01',
        ServiceName: 'FOSxID01_Token_Mgt',
        Operation: 'I',
        ClientSentTime: '0',
    },
    // SSV
    CHECK_EVENT_MGT: {
        reqFunct: reqFunct.CHECK_EVENT_MGT,
        WorkerName: 'FOSxCommon',
        ServiceName: 'FOSxCommon_Event_Mgt',
        Operation: 'Q',
        ClientSentTime: '0',
    },
    EKYC_INFO: {
        reqFunct: reqFunct.EKYC_INFO,
        WorkerName: 'FOSqID01',
        ServiceName: 'FOSqID01_EKYCInfo',
        ClientSentTime: '0',
        Operation: 'Q',
    },
    IS_FIRST_IN_MONTH: {
        reqFunct: reqFunct.IS_FIRST_IN_MONTH, 
		WorkerName: 'FOSqAccount01',
		ServiceName: 'FOSqAccount01_EvaluateAgent_Reg',
		ClientSentTime: '0',
		Operation: 'Q'
    },
    UPDATE_IS_FIRST_IN_MONTH: {
        reqFunct: reqFunct.UPDATE_IS_FIRST_IN_MONTH, 
		WorkerName: 'FOSxAccount01',
		ServiceName: 'FOSxAccount01_EvaluateAgent_Reg',
		ClientSentTime: '0',
		Operation: 'I'
    },
}

function BackgroundServiceMemo() {
    const { t } = useTranslation()
    const { setConnected, language } = useContext(StoreContext)
    const { setAuth, setUserInfo, authFlag } = useContext(StoreTrading)

    const reLoginCallTimeout = useRef(null)

    const flagListIndex = useRef(false)

    const reLoginFlag = useRef(false)
    const flagGetWatchlistOwner = useRef(0)
    const BackgroundServiceRealTime = new RealTimeHandler(Screens.BACKGROUND_SERVICE)

    const [, updateState] = useState();
    const countReloginFalse = useRef(0)
    const history = useHistory()
    const [eventMgt, setEventMgt] = useState([])
    const [isShowEventMgtModal, setIsShowEventMgtModal] = useState(false)
    const [dataEvent, setDataEvent] = useState({})
    const [isDontShowAgain, setIsDontShowAgain] = useState(false)

    // state for banner
    const [bannerOutLink, setBannerOutLink] = useState('')
    const [bannerImage, setBannerImage] = useState('')
    const [shouldShowBanner, setShouldShowBanner] = useState(false)
    // Lưu lại để check sau login
    const bannerShowAgain = useRef(true)
    const isAskPluginAgain = useRef(true)
    const [selectors, deviceInfo] = useDeviceSelectors()
    const pluginCallbackTimeout = useRef(null)

    useEffect(() => {
        getEventMgt()
        getShowBanner()
        checkPluginHardware()
        history.listen(e => {
            let pathName = history.location.pathname;
            let params = new URLSearchParams(window.location.search)
            const isScroll = params.get('autoscroll') === ''
            if ([
                '/login',
                '/tradingview',
                '/order',
                '/technical-analyze',
                '/asset-manage',
                '/performance',
                '/take-profit-stop-loss',
                '/advance-order',
                '/mkt-view',
                '/mkt-liquidity',
                '/mkt-cap',
                '/foreigner-trade',
                '/fundamental-analysis',
                '/company-profile'
            ].includes(pathName) || isScroll) {
                setShouldShowBanner(false)
            } else if (bannerShowAgain.current) {
                if (!!glb_sv.configInfo.banner_img_name) {
                    getShowBanner()
                }
                // setShouldShowBanner(true)
            } else if (glb_sv.configInfo.banner_img_name) getShowBanner()
        })

        if (glb_sv.showAlertRedirectTableIpad) {
            showModalRedirectTabletIpad()
        }

        const handleVisibilityChange = () => {
            if (document.hidden) {
                console.log("Tab không còn active")
                // Tab không còn active
            } else {
                // Tab được active lại
                console.log("Tab active lại")
                checkForReload()
            }
        };

        // Đăng ký sự kiện khi tab thay đổi trạng thái
        document.addEventListener('visibilitychange', handleVisibilityChange);

        // Loại bỏ sự kiện khi component unmount
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [])

    useUpdateEffect(() => {
        checkEkyc()
    }, [language])

    useEffect(() => {
        // Lắng nghe thay đổi về trạng thái network và authen
        const eventConnect = glb_sv.eventConnect.subscribe((msg) => {
            if (msg.type === socket_sv.connect_event.REQ_RELOGIN) {
                if (reLoginCallTimeout.current) clearTimeout(reLoginCallTimeout.current)
                reLoginCallTimeout.current = setTimeout(() => {
                    reLogin()
                }, 100)
            }
        })
        // Lắng nghe common Event

        if (socket_sv.stream_conect_first) {
            BackgroundServiceRealTime.subscribe({
                onTimeout: () => handleSubcribeTimeout(['EXCHANGE_TIME'], ['']),
                topic: ['EXCHANGE_TIME'],
                value: [''],
            })
            BackgroundServiceRealTime.subscribe({
                onTimeout: () => handleSubcribeTimeout(['MDDS|BI'], ['HSXIndex', 'LIS_BRD_01', 'UPC_BRD_01', 'LIS_BRD_BOND']),
                topic: ['MDDS|BI'],
                value: ['HSXIndex', 'LIS_BRD_01', 'UPC_BRD_01', 'LIS_BRD_BOND'],
            })
        }
        if (socket_sv.trading_conect_first) {
            if (!flagListIndex.current) getListIndex()
        }
        const commonEvent = glb_sv.commonEvent.subscribe((msg) => {
            if (msg.type === eventList.LOGIN_SUCCESSFUL) {
                getEventMgt()
                if (glb_sv.configInfo.show_broker_rating) getIsFirstInMonth()
            }
            if (msg.type === eventList.MESSAGE_SYSTEM) {
                const message = msg.data
                if (
                    message['Code'] === 'XXXXX5' ||
                    message['Code'] === 'XXXX12' ||
                    message['Code'] === '080063'
                ) {
                    if (reLoginCallTimeout.current) clearTimeout(reLoginCallTimeout.current)
                    reLoginCallTimeout.current = setTimeout(() => {
                        reLogin()
                    }, 100)
                } else if (message['Code'] === 'XXXXX6') {
                    const ls_msg = 'common_login_expire_bc_newsession'
                    glb_sv.showAlert({
                        title: t('common_notify'),
                        content: t(ls_msg),
                        type: 'warn',
                        linkCallback: () => window.location.reload(),
                    })
                }
                else if (message.Code === '080001' || message.Code === 'XXXXX7') {
                    const ls_msg = 'Your_session_time_out_System_will_disconnected_this_session_now'
                    glb_sv.showAlert({
                        title: t('common_notify'),
                        content: t(ls_msg),
                        type: 'warn',
                        linkCallback: () => window.location.reload(),
                    })
                }
            }
            if (msg.type === eventList.RECONNECT_MARKET || msg.type === eventList.CONNECT_MARKET) {
                BackgroundServiceRealTime.subscribe({
                    onTimeout: () => handleSubcribeTimeout(['EXCHANGE_TIME'], ['']),
                    topic: ['EXCHANGE_TIME'],
                    value: [''],
                })
                BackgroundServiceRealTime.subscribe({
                    onTimeout: () => handleSubcribeTimeout(['MDDS|BI'], ['HSXIndex', 'LIS_BRD_01', 'UPC_BRD_01', 'LIS_BRD_BOND']),
                    topic: ['MDDS|BI'],
                    value: ['HSXIndex', 'LIS_BRD_01', 'UPC_BRD_01', 'LIS_BRD_BOND'],
                })
            }
            if (msg.type === eventList.RES_COMMON_MSG) {
                if (msg.Code === '080063' || msg.Code == 'XXXXX5' || msg.Code === '010012') {
                    if (glb_sv.objShareGlb.sessionInfo?.sessionId) {
                        let data
                        try {
                            data = msg.Data ? JSON.parse(msg.Data)[0] : {}
                        } catch (err) { };
                        if (data.c0 === glb_sv.objShareGlb.sessionInfo?.sessionId) return;
                    }
                    if (reLoginCallTimeout.current) clearTimeout(reLoginCallTimeout.current)
                    if (socket_sv.anotherLoginFlag) return;
                    reLoginCallTimeout.current = setTimeout(() => {
                        reLogin();
                    }, 800);
                }
            }
            if (msg.type === eventList.CONNECT_TRADING) {
                if (!flagListIndex.current) getListIndex()
            }
            if (msg.type === eventList.MSG_ADD_NEW_STK_2FAV) {
                try {
                    const stk = msg.data.stk
                    const keyFlv = msg.data.keyFlv
                    if (!stk || !keyFlv) return
                    //-- xử lý thêm stk vào danh mục được chọn
                    if (glb_sv.authFlag) {
                        const InputParams = ['FAV_ITEM_ADD', keyFlv, stk]
                        sendRequest(ServiceInfo.AddStockToFavList, InputParams, handleAddStockToFavList, true, null)
                    } else {
                        let fvlArr,
                            fvlstr = localStorage.getItem('watchListNew')
                        try {
                            fvlArr = JSON.parse(fvlstr)
                        } catch (error) {
                            return
                        }
                        if (!fvlArr || fvlArr.length === 0) return
                        const findIndex1 = fvlArr.findIndex((item) => item.key === keyFlv)
                        if (findIndex1 >= 0) {
                            fvlArr[findIndex1]['stkList'].unshift(stk)
                            localStorage.setItem('watchListNew', JSON.stringify(fvlArr))
                            glb_sv.commonEvent.next({ type: eventList.CHANGE_LIST_ACTIVE, value: null })
                        }
                    }
                } catch (error) {
                    return
                }
            }
            else if (msg.type === eventList.CHANGE_ACTIVE_CODE) {
                updateState({})
            }
            else if (msg.type === eventList.SHOW_MODAL_REDIRECT_TABLET_IPAD) {
                showModalRedirectTabletIpad()
            } 
            else if (msg.type === eventList.OPEN_PLUGIN_POPUP) {
                if (msg.isForce) alertForceInstallPlugin(msg.callBack)
                else alertInstallPlugin()
            }
        })

        const eventMarket = glb_sv.eventMarket.subscribe(msg => {
            if (msg.type === eventList.RESET_DATA) {
                let timeout = setTimeout(() => {
                    window.location.reload()
                }, 3000);
                glb_sv.showAlert({
                    title: t('common_notify'),
                    content: t('notify_data_market_expired_please_reload_web'),
                    type: 'warn',
                    linkCallback: () => {
                        if (timeout) clearTimeout(timeout)
                        window.location.reload()
                    },
                })
            }
        })

        return () => {
            commonEvent.unsubscribe()
            eventConnect.unsubscribe()
            eventMarket.unsubscribe()
        }
    }, [t])

    const checkForReload = () => {
        if (glb_sv.callPlugin) {
            // check xem plugin đã callback thì lưu mac address vào glb_sv và tắt popup alert
            const macAddress = localStorage.getItem('macaddress')
            if (macAddress) {
                // safari sẽ lấy được mac address sau lần đầu ấn mở plugin từ safari
                glb_sv.deviceMACAddress = macAddress
                glb_sv.callPlugin = false
                glb_sv.commonEvent.next({ type: eventList.CLOSE_ALERT_MODAL, screen: 'plugin' })
            } else {
                // trình duyệt khác safari sẽ chạy vào hàm này để gọi lại plugin 1 lần nếu chưa có mac address
                try {
                    window.getDeviceInfo
                        .start()
                        .then(data => {
                            var info = JSON.parse(data);
                            handleHardwarePluginCallback({ success: true, info: info })
                        })
                        .catch(e => {
                            handleHardwarePluginCallback({ success: false })
                        })
                } catch (error) {
                    console.log('error plugin', error);
                }
            }
        }
    }

    const checkPluginHardware = (isRecall = false) => {
        if (window.location.pathname === '/tradingview' || window.location.pathname.includes('html')) return

        const browserName = deviceInfo?.browser?.name || ''
        if (!!localStorage.getItem('macaddress') && browserName === 'Safari') {
            glb_sv.deviceMACAddress = localStorage.getItem('macaddress')
            glb_sv.commonEvent.next({ type: eventList.CLOSE_ALERT_MODAL, screen: 'plugin' })
            return
        }
        const askPlugin = JSON.parse(localStorage.getItem(STORE_KEY.REQUEST_HARDWARE_PLUGIN))
        if ((!glb_sv.configInfo.is_open_plugin_securities || askPlugin == false) && !glb_sv.configInfo.force_install_plugin) return

        if (!glb_sv.callPlugin) {
            glb_sv.callPlugin = true
        } 
        // else if (isRecall) {
        //     alertInstallPlugin(isRecall)
        //     return
        // }
        if (browserName === 'Safari') {
            setTimeout(() => {
                alertInstallPlugin(isRecall)
            }, 10);
        } else {
            try {
                window.getDeviceInfo
                .start()
                .then(data => {
                    var info = JSON.parse(data);
                    console.log('getDeviceInfo :', info);
                    handleHardwarePluginCallback({success: true, info: info})
                })
                .catch(e => {
                    if (askPlugin !== null) isAskPluginAgain.current = askPlugin
                    if (isAskPluginAgain.current) {
                        alertInstallPlugin()
                    }
                })
            } catch (error) {
                console.log('error plugin', error);
            }
        }
    }

    const handleHardwarePluginCallback = ({ success, info = {}, download, os }) => {
        if (download) {
            window.open(glb_sv.configInfo[`plugin_${os}_url`],"_blank")
            return
        }
        if (success) {
            glb_sv.deviceMACAddress = info.macAddress
            // localStorage.setItem('macaddress', info.macAddress)
            glb_sv.commonEvent.next({ type: eventList.CLOSE_ALERT_MODAL, screen: 'plugin' })
        } else {
            localStorage.setItem(STORE_KEY.REQUEST_HARDWARE_PLUGIN, isAskPluginAgain.current)
        }
        glb_sv.callPlugin = false
    }

    const alertForceInstallPlugin = (callBack = () => {}) => {
        const browserName = deviceInfo?.browser?.name || ''
        if (!!localStorage.getItem('macaddress') && browserName === 'Safari') {
            glb_sv.deviceMACAddress = localStorage.getItem('macaddress')
            glb_sv.commonEvent.next({ type: eventList.CLOSE_ALERT_MODAL, screen: 'plugin' })
            return
        }
        const os = deviceInfo?.os?.name?.includes('Mac') ? 'mac' : 'win'
        let deeplink = ''
        //deeplink: companyname-plugin://{callbackUrl}
        if (browserName === 'Safari') {
            deeplink = `${glb_sv.configInfo?.comp_nm}-plugin://${glb_sv.configInfo?.domain?.replace("https://", "")}`
        } else {
            deeplink = `${glb_sv.configInfo?.comp_nm}-plugin://open`
        }
        const showPlugin = () => {
            glb_sv.showAlert({
                icon: <IC_NOTIFY width={130} />,
                title: t('common_notify'),
                showCancel: true,
                cancelCallBack: () => handleHardwarePluginCallback({ success: false }),
                layoutAfterContentRender: () => {
                    return (
                        <div className='text-center pb-2'>
                            <div className='flex flex-direction-column'>
                                <span className='text-primary fw-600 fs-14'>
                                    {t('plugin_install_guide_title_1')}
                                </span>
                                <span
                                    className='text-primary fw-600 fs-14'
                                    style={{ marginBottom: 32 }}>
                                    {t('plugin_install_guide_title_2')}
                                </span>
                                <span>{t('plugin_install_guide_content')}</span>
                                <span onClick={() => {
                                    setTimeout(() => {
                                        checkPluginHardware(true)
                                    }, 3000);
                                }} style={{ marginBlock: 16 }} dangerouslySetInnerHTML={{ __html: t('safari_request', { link: `<a href='${deeplink}'>${t('link')}</a>` }) }} />
                            </div>
                        </div>
                    )
                },
                linkCallback: () => handleHardwarePluginCallback({ download: true, os: os }),
                screen: 'plugin'
            });
        }
        if (browserName === 'Safari') {
            showPlugin()
        } else {
            try {
                window.getDeviceInfo
                    .start()
                    .then(data => {
                        var info = JSON.parse(data);
                        console.log('getDeviceInfo :', info);
                        handleHardwarePluginCallback({ success: true, info: info })
                        setTimeout(() => {
                            callBack()
                        }, 50);
                    })
                    .catch(e => {
                        showPlugin()
                    })
            } catch (error) {
                console.log('error plugin', error);
            }
        }  
    }

    const alertInstallPlugin = (isRecall) => {
        const browserName = deviceInfo?.browser?.name || ''
        const os = deviceInfo?.os?.name?.includes('Mac') ? 'mac' : 'win'
        let deeplink = ''
        //deeplink: companyname-plugin://{callbackUrl}
        if (browserName === 'Safari') {
            deeplink = `${glb_sv.configInfo?.comp_nm}-plugin://${glb_sv.configInfo?.domain?.replace("https://", "")}`
        } else {
            deeplink = `${glb_sv.configInfo?.comp_nm}-plugin://open`
        }
        // if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        //     deeplink = `${glb_sv.configInfo?.comp_nm}-plugin://localhost:4200`
        // }
        //plugin prod
        if(allowCompanyRender(['020'])){ //UI popup riêng cho VISE
            glb_sv.showAlert({
                type: 'info',
                title: t('common_notify').toUpperCase(),
                icon: <></>,
                showCancel: true,
                titleConfirm : t('content_download_plugin'),
                titleCancel: t('alert_down_plugin_close_content'),
                cancelCallBack: () => handleHardwarePluginCallback({ success: false }),
                layoutAfterContentRender: () => {
                    return (
                        <div className='text-center pb-2'>
                            <div className='flex flex-direction-column' style={{gap:16}}>
                                <div className='flex flex-direction-column'>
                                    <span className='text-primary fw-600 fs-14'>
                                        {t('installation_device_identification_title')}
                                    </span>
                                    <span
                                        className='text-primary fw-600 fs-14'
                                        style={{ marginBottom: 16 }}
                                        >
                                        {t('installation_device_identification_title_2')}
                                    </span>
                                </div>
                                <span >{t('plugin_install_guide_content_2')}</span>
                                <span onClick={() => {
                                setTimeout(() => {
                                    checkPluginHardware(true)
                                }, 3000);
                                }} dangerouslySetInnerHTML={{ __html: t('safari_request', { link: `<a href='${deeplink}'>${t('link')}</a>` }) }} />
                                <span>{t('plugin_note_download_2')} : </span>
                                {isRecall && (
                                    <span className='text-warn fw-bold' style={{ marginBottom: 16 }}>{t('get_device_info_fail')}</span>
                                )}
                            </div>
                        </div>
                    )
                },
                linkCallback: () => handleHardwarePluginCallback({ download: true, os: os }),
                screen: 'plugin'
            });
            return
        }
        //UI popup cho các công ty còn lại
        glb_sv.showAlert({
            type: 'warn',
            title: t('common_notify'),
            icon: <IC_NOTIFY width={130} />,
            showCancel: true,
            cancelCallBack: () => handleHardwarePluginCallback({ success: false }),
            layoutAfterContentRender: () => {
                return (
                    <div className='text-center pb-2'>
                        <div className='flex flex-direction-column'>
                            <span className='text-primary fw-600 fs-14'>
                                {t('plugin_install_guide_title_1')}
                            </span>
                            <span
                                className='text-primary fw-600 fs-14'
                                style={{ marginBottom: 32 }}>
                                {t('plugin_install_guide_title_2')}
                            </span>
                            <span>{t('plugin_install_guide_content')}</span>
                            {/* <span style={{ marginTop: 16 }}>
                                    {t('download_plugin')}{' '}
                                    <a target="_blank" href={glb_sv.configInfo[`plugin_${os}_url`]}>{t('in_here')}</a>
                                </span> */}
                            <span onClick={() => {
                                setTimeout(() => {
                                    checkPluginHardware(true)
                                }, 3000);
                            }} style={{ marginBlock: 16 }} dangerouslySetInnerHTML={{ __html: t('safari_request', { link: `<a href='${deeplink}'>${t('link')}</a>` }) }} />
                            {isRecall ? (
                                <span className='text-warn fw-bold' style={{ marginBottom: 16 }}>{t('get_device_info_fail')}</span>
                            ): <></>}
                        </div>
                        {!glb_sv.configInfo.force_install_plugin ? (
                            <Checkbox
                                style={{ marginBottom: 16 }}
                                onChange={(e) => {
                                    isAskPluginAgain.current = !e.target.checked
                                }}
                            >
                                <span className='fs-small text-second'>
                                    {' '} {t('do_not_ask_again')}
                                </span>
                            </Checkbox>
                        ) : <></>}
                    </div>
                )
            },
            linkCallback: () => handleHardwarePluginCallback({ download: true, os: os }),
            screen: 'plugin'
        });
    }

    const showModalRedirectTabletIpad = () => {
        const isShare = window.location.pathname + window.location.search
        const ipadOS = window.navigator.userAgent.toLowerCase().indexOf('ipad') > -1 ? true : false
        const callBackFunc = () => {
            window.location.href = ipadOS // Thiết bị Tablet đang chạy ipadOS
                ? (
                    glb_sv.configInfo['ios_link'] // Có IOS Link
                        ? glb_sv.configInfo['ios_link'] // Vào AppStore
                        : glb_sv.configInfo.mobile_web_domain + (isShare ? isShare : '') // Vào link mobile web
                )
                : (
                    glb_sv.configInfo['android_link'] // Có Android Link
                        ? glb_sv.configInfo['android_link'] // Vào CHPlay
                        : glb_sv.configInfo.mobile_web_domain + (isShare ? isShare : '') // Vào link mobile web
                )
            return
        }
        glb_sv.showAlert({
            title: t('common_notify'),
            content: ipadOS
                ? (
                    glb_sv.configInfo['ios_link']
                        ? t('confirm_redirect_to_mobile_app')
                        : t('confirm_redirect_to_mobile_web')
                )
                : (
                    glb_sv.configInfo['android_link']
                        ? t('confirm_redirect_to_mobile_app')
                        : t('confirm_redirect_to_mobile_web')
                ),
            type: 'info',
            showCancel: true,
            linkCallback: callBackFunc,
        })
    }

    // Gọi lại SUB khi 1 subcribe bị timeout
    const handleSubcribeTimeout = (topic, value) => {
        BackgroundServiceRealTime.subscribe({
            topic,
            value,
            onTimeout: () => handleSubcribeTimeout(topic, value)
        })
    }

    const getShowBanner = () => {
        let location = window.location
        let params = new URLSearchParams(location.search)
        const isScroll = params.get('autoscroll') === ''
        if (location.pathname === '/tradingview' || location.pathname === '/login' || isScroll) {
            setBannerOutLink('')
            setBannerImage('')
            setShouldShowBanner(false)
            bannerShowAgain.current = false
            return
        }
        setBannerOutLink(glb_sv.configInfo.banner_link || '')
        setBannerImage(glb_sv.configInfo.banner_img_name ? '/assets/images/' + glb_sv.configInfo.banner_img_name : '')
        setShouldShowBanner(glb_sv.configInfo.banner_show || false)
        bannerShowAgain.current = glb_sv.configInfo.banner_show || false
    }

    const getEventMgt = () => {
        let location = window.location
        let params = new URLSearchParams(location.search)
        const isScroll = params.get('autoscroll') === ''
        if (location.pathname === '/tradingview' || location.pathname === '/login' || isScroll || !process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            setEventMgt([])
            setDataEvent({})
            setIsShowEventMgtModal(false)
            return
        }
        setEventMgt([])
        setIsShowEventMgtModal(false)
        const InputParams = ['CHK_EVENT']
        sendRequest(ServiceInfo.CHECK_EVENT_MGT, InputParams, handleGetEventMgt, true, handleTimeoutGetEventMgt)
    }

	const getCurrentBroker = () => {
		if (!glb_sv.credentials.username) {
			return;
		}
		const inputParams = ["02", glb_sv.credentials.username.toUpperCase()];
		sendRequest(ServiceInfo.IS_FIRST_IN_MONTH, inputParams, handleGetCurrentBrokerResponse);
	}

    const getIsFirstInMonth = () => {
        if (!glb_sv.credentials.username) {
            return;
        }
        // input params "01": BackEnd kiểm tra is first login.
        // input params "02": BackEnd lấy nhân viên tư vấn hiện tại.
        const inputParams = ['01', glb_sv.credentials.username.toUpperCase()];
        sendRequest(ServiceInfo.IS_FIRST_IN_MONTH, inputParams, handlGetIsFirstInMonth);
    };

    // Nếu là lần login đầu tiên trong tháng + đã có Nhân viên tư vấn => Hiện popup đánh giá nhân viên tư vấn.
	const handleGetCurrentBrokerResponse = (reqFunct, message) => {
		if (Number(message['Result']) === 0) {
			return;
		}
		try {
			const jsonData = JSON.parse(message['Data'])
			if (jsonData[0]?.c2) {
				glb_sv.showAlert({
                    title: t('common_notify'),
                    content: t('evaluate_message'),
                    type: 'info',
                    showCancel:true,
                    linkCallback: () => {
                        updateFirstInMonth()
                        glb_sv.commonEvent.next({  
                            type: eventList.CHANGE_ROUTER,
                            "title":"title_broker_rating",
                            "routerLink":"brokerRatting",
                            "key":"title_broker_rating"
                        })
                    },
                    cancelCallBack: updateFirstInMonth
                })
			}
		} catch (err) {
			return;
		}
	}

    const updateFirstInMonth = () => {
        if (!glb_sv.credentials.username) {
            return;
        }
        const inputParams = ["01", glb_sv.credentials.username.toUpperCase()];
        sendRequest(ServiceInfo.UPDATE_IS_FIRST_IN_MONTH, inputParams, handlUpdateIsFirstInMonth);
    }

    const handlUpdateIsFirstInMonth = (reqFunct, message) => {
        if (message['Result'] ==='0') {
            glb_sv.showAlert({
                title: t('common_notify'),
                content: message["Message"],
                type: 'warn',
                showCancel: true,
            })
        }
    }

	const handlGetIsFirstInMonth = (reqFunct, message) => {
		if (message['Result'] == 0) {
		} else {
			try {
				const jsonData = message['Data'] ? JSON.parse(message['Data']) : [];
				if (jsonData[0].c0 === 'Y') {
					getCurrentBroker();
				}
			} catch (err) {
				console.log('error get getIsFirstInMonth ', err);
				return;
			}
		}
	};

    const handleGetEventMgt = (reqInfoMap, message) => {
        if (Number(message['Result']) === 0) {
            setIsShowEventMgtModal(false)
            return
        } else {
            let dataArr = {}
            try {
                let strdata = message['Data'] || '[]'
                dataArr = message['Data']
                    ? JSON.parse(message.Data.replace(/\\n/g, '').replace(/\n/g, ''))
                    : []
            } catch (error) {
                console.log('handleGetEventMgt error: ', error)
            }

            if (Number(message['Packet']) <= 0) {
                const openEvent = dataArr.find((e) => e.c13 === 'Y')
                setEventMgt(dataArr)
                if (openEvent) {
                    setIsDontShowAgain(true)
                    setIsShowEventMgtModal(true)
                    handleUpdateEvent(openEvent)
                }
            }
        }
    }

    const confirmEvent = (confirm = 'D') => {
        const InputParams = ['REG_EVENT', dataEvent.eventCode, confirm]
        ServiceInfo.CHECK_EVENT_MGT['Operation'] = 'I'
        sendRequest(ServiceInfo.CHECK_EVENT_MGT, InputParams, handleConfirmEvent)
    }

    const handleConfirmEvent = (reqInfoMap, message) => {
        console.log('handleConfirmEvent: ', reqInfoMap, message)
        if (Number(message['Result']) === 0) {
            return
        } else {
            let InVal = reqInfoMap.inputParam
            const newDataEvent = { ...dataEvent, active: 'U' }
            setDataEvent({ newDataEvent })
            const listEvent = eventMgt
            const obj = listEvent.find((e) => e.c0 === InVal[1])
            if (obj) {
                obj.c9 = InVal[2]
                setEventMgt([...listEvent])
                setIsShowEventMgtModal(false)
            }
        }
    }

    const handleUpdateEvent = (data) => {
        setIsShowEventMgtModal(true)
        setDataEvent({
            ...data,
            eventCode: data.c0,
            eventType: data.c4,
            contentType: data.c5,
            content: data.c6,
            icon: data.c7,
            name: data.c8,
            active: data.c9,
        })
        setTextHtml({
            ...data,
            eventCode: data.c0,
            eventType: data.c4,
            contentType: data.c5,
            content: data.c6,
            icon: data.c7,
            name: data.c8,
            active: data.c9,
        })
    }

    const setTextHtml = (obj) => {
        if (document.getElementById('text_html_event')) {
            document.getElementById('text_html_event').innerHTML = obj.content || dataEvent.content
        } else {
            setTimeout(() => {
                setTextHtml(obj || dataEvent.content)
            }, 100)
        }
    }

    const handleTimeoutGetEventMgt = e => {
        setEventMgt([])
        setIsShowEventMgtModal(false)
    }

    const handleAddStockToFavList = (reqInfoMap, message) => {
        if (Number(message['Result']) === 0) {
            return
        } else {
            const findIndex = glb_sv.allListFav.findIndex((x) => x.key == reqInfoMap.inputParam[1])
            if (findIndex >= 0) {
                glb_sv.allListFav[findIndex].stkList.unshift(reqInfoMap.inputParam[2])
                glb_sv.commonEvent.next({ type: eventList.CHANGE_LIST_ACTIVE, value: null })
            }
        }
    }

    // const Autologin = (username, password) => {
    //     if (!username || !password) return;
    //     const OTP = 'NONE';
    //     const InputParams = ['login', username, password, DeviceInfo.getUniqueId(), glb_sv.notifyOnesignal.userId || '', 'Y'];
    //     sendRequest(ServiceInfo.LOGIN_FUNCTION, InputParams, handleAutoLogin, true, handleTimeout, OTP)
    // }

    const reLogin = () => {
        if (!glb_sv.isConnectApp) {
            if (reLoginCallTimeout.current) clearTimeout(reLoginCallTimeout.current)
            reLoginCallTimeout.current = setTimeout(() => {
                reLogin()
            }, 100)
            return
        }
        const username = glb_sv.credentials.username
        const password = glb_sv.credentials.password
        if (!username || !password) return
        const InputParams = [
            'login',
            username,
            password,
            glb_sv.notifyOnesignal.userId || '',
            glb_sv.notifyOnesignal.userId || '',
            'Y',
        ]
        const OTP = glb_sv.objShareGlb['sessionInfo']['Otp'] || 'NONE'
        sendRequest(ServiceInfo.LOGIN_FUNCTION, InputParams, handleReloginResult, true, handleTimeoutRelogin, OTP)
        glb_sv.isWaitSendResultLogin = true

    }

    const handleReloginResult = (reqInfoMap, message) => {
        console.log("handleReloginResult -> message", message)
        glb_sv.isWaitSendResultLogin = false
        if (Number(message['Result']) === 0) {
            if (message.Code === '080001') {
                socket_sv.anotherLoginFlag = true;
                glb_sv.commonEvent.next({ type: eventList.MESSAGE_SYSTEM, data: { Code: '080001' } })
            }
            if (message.Code === '010012') {
                if (countReloginFalse.current === 3) {
                    socket_sv.anotherLoginFlag = true;
                    glb_sv.commonEvent.next({ type: eventList.MESSAGE_SYSTEM, data: { Code: 'XXXXX6' } })
                    return;
                }
                countReloginFalse.current += 1;
            }
        } else {
            if (reLoginCallTimeout.current) clearTimeout(reLoginCallTimeout.current)
            countReloginFalse.current = 0
            console.warn('----------------------------- Relogin thành công')
            setConnected(true)
            glb_sv.isConnectApp = true

            let dataArr = {}
            try {
                let strdata = message['Data']
                dataArr = JSON.parse(strdata)[0]
            } catch (error) {
                return
            }
            glb_sv.objShareGlb['userInfo'] = dataArr
            glb_sv.objShareGlb.otpType = Number(dataArr.c6)
            glb_sv.objShareGlb['sessionInfo'].sessionId = dataArr.c0

            const dataLocal = localStorage.getItem(STORE_KEY.USER_INFO)
            if (dataLocal) {
                const data = JSON.parse(dataLocal)
                if (data && data.email === reqInfoMap.inputParam[1]) {
                    glb_sv.userInfo = { ...data }
                }
            }

            setTimeout(() => {
                glb_sv.sendAuthReqSocketStream({
                    LoginID: dataArr.c1,
                    MdmTp: '02',
                    Token: dataArr.c0,
                })
            }, 2000)

            storeAccountInfo(dataArr)

            // reLoginFlag.current = true
            // flagGetWatchlistOwner.current = 0
            // getFavInfo()

            socket_sv.establishSocketConnect('authen_success', {})
        }
    }

    const handleTimeoutRelogin = () => {
        console.log('Relogin Timeout')
        glb_sv.isWaitSendResultLogin = false
        if (reLoginCallTimeout.current) clearTimeout(reLoginCallTimeout.current)
        reLoginCallTimeout.current = setTimeout(() => {
            reLogin()
        }, 100)
    }

    const storeAccountInfo = (userInfo) => {
        const accountInfo = userInfo.c12
        // const accountInfo = '888C000351,00,888C000351,1,Y,888|888C000351,01,888C000351,2,Y,888|888C000351,02,888C000351,2,Y,888|888C000351,03,888C000351,2,Y,888'
        if (!accountInfo) {
            setAuth(true)
            glb_sv.authFlag = true
            return
        }
        const array_acnt = accountInfo.split('|').filter((e) => e !== '')
        const js_acntInfo = []
        const js_acntList = []
        // const js_acntListAll = []
        let acntMain = '',
            acntMainName = ''
        for (let i = 0; i < array_acnt.length; i++) {
            const acntInfo = array_acnt[i]
            const arr_info = acntInfo.split(',')
            // if (i === 0) {
            //     const ls_acntObjsAll = {
            //         id: arr_info[0] + '.%',
            //         name: arr_info[0] + '.% - ' + arr_info[2],
            //     }
            //     js_acntListAll.push(ls_acntObjsAll)
            // }
            if (arr_info[4] === 'Y') {
                acntMain = arr_info[0]
                acntMainName = arr_info[2]
            }
            const js_acnt = {
                AcntNo: arr_info[0],
                SubNo: arr_info[1],
                AcntNm: arr_info[2],
                IsOwnAcnt: arr_info[4],
                MarginYN: arr_info[3] === '2',
                ActType: arr_info[3],
                // Broker: arr_info[20],
                // BrokerBranch: arr_info[21],
                // BrokerAgent: arr_info[22],
                // TradingFee: arr_info[23],
            }
            const js_acntObj = {
                value: arr_info[0] + '.' + arr_info[1],
                label: arr_info[0] + '.' + arr_info[1] + ' - ' + arr_info[2],
            }
            js_acntInfo.push(js_acnt)
            js_acntList.push(js_acntObj)
            // js_acntListAll.push(js_acntObj)
        }

        glb_sv.objShareGlb['acntNoInfo'] = js_acntInfo
        glb_sv.objShareGlb['acntNoList'] = js_acntList
        // glb_sv.objShareGlb['acntNoListAll'] = js_acntListAll
        // --------------------
        glb_sv.objShareGlb['AcntMain'] = acntMain // -- chủ tài khoản lưu ký
        glb_sv.objShareGlb['AcntMainNm'] = acntMainName // -- Tên chủ tài khoản
        glb_sv.objShareGlb['workDate'] = userInfo.c16 // -- Ngày làm việc
        // glb_sv.objShareGlb['brokerId'] = userInfo['c24'] // -- ID nhân viên môi giới
        // glb_sv.objShareGlb['brokerNm'] = userInfo['c25'] // -- Tên nhân viên môi giới
        // glb_sv.objShareGlb['brokerEmail'] = userInfo['c26'] // -- Email nhân viên môi giới
        // glb_sv.objShareGlb['brokerMobile'] = userInfo['c27'] // -- Mobile nhân viên môi giới
        // glb_sv.objShareGlb['secCode'] = userInfo['c9'] // -- Tên nhân viên môi giới
        // glb_sv.objShareGlb['prdYN'] = userInfo['c31'] // -- User trình chiếu bảng điện hay không
        // glb_sv.objShareGlb['verify'] = Number(userInfo['c37']) || 0 // -- verify > 0 => Phải xác thực chứng chỉ số
        glb_sv.objShareGlb['verify'] = Number(userInfo['c21']) || 0 // -- verify > 0 => Phải xác thực chứng chỉ số
        glb_sv.objShareGlb['serialnum'] = userInfo['c38'] // -- serialNumber

        // const sub_list = []
        // const actn_list = []
        // js_acntInfo.forEach((e) => {
        //     if (e.AcntNo === acntMain) sub_list.push(e.SubNo)
        //     if (!actn_list.find((temp) => temp.AcntNo === e.AcntNo))
        //         actn_list.push({
        //             AcntNo: e.AcntNo,
        //             actn_name: e.AcntNm,
        //             key: e.AcntNo,
        //             label: e.AcntNo + ' (' + e.AcntNm + ')',
        //         })
        // })
        // const isMargin = glb_sv.objShareGlb['acntNoInfo'].find((e) => e.AcntNo === acntMain && e.SubNo === sub_list[0])
        //     ?.MarginYN
        // setUserInfo({
        //     actn_curr: acntMain,
        //     sub_curr: sub_list[0],
        //     actn_name: acntMainName,
        //     sub_list,
        //     // sub_list: ['00','01','02','03','04','05','06','07','08'],
        //     actn_list,
        //     actn_full: acntMain + '.00',
        //     isMargin: isMargin || false,
        // })
        setAuth(true)
        glb_sv.authFlag = true
    }

    const getFavInfo = () => {
        const InputParams = ['3', '', '0']
        sendRequest(ServiceInfo.getFav, InputParams, getFavInfoProc)
    }

    const getFavInfoProc = (reqInfoMap, message) => {
        let jsonArr = []
        const strdata = message['Data']
        try {
            jsonArr = strdata ? JSON.parse(strdata) : []
        } catch (error) {
            console.log(error)
            return
        }
        if (Number(message['Result']) === 1 && jsonArr.length > 0) {
            try {
                const newallListFav = []
                for (let i = 0; i < jsonArr.length; i++) {
                    const isExistFav = glb_sv.allListFav.find(item => item.key === jsonArr[i]['c1']);
                    newallListFav.push({
                        key: jsonArr[i]['c1'],
                        name: jsonArr[i]['c2'],
                        stkList: isExistFav ? isExistFav.stkList : [],
                    })
                }
                glb_sv.allListFav = newallListFav
                jsonArr.forEach((e) => getDetailFavList(e))
            } catch (error) { }
        }
    }

    const getDetailFavList = (element) => {
        const groupId = element.c1
        const InputParams = ['4', String(groupId)]
        sendRequest(ServiceInfo.getDetailList, InputParams, handleGetDetailFavList)
    }

    const handleGetDetailFavList = (reqInfoMap, message) => {
        if (Number(message['Result']) === 0) {
            return
        } else {
            reqInfoMap.procStat = 1
            let dataArr = []
            try {
                let strdata = message['Data']
                dataArr = JSON.parse(strdata)
                const findIndex = glb_sv.allListFav.findIndex((x) => x.key == reqInfoMap.inputParam[1])
                if (findIndex >= 0) {
                    glb_sv.allListFav[findIndex].stkList = dataArr.map((e) => e.c3)
                    glb_sv.commonEvent.next({ type: eventList.CHANGE_LIST_FAV })
                }
            } catch (error) {
                return
            }
        }
    }

    const getListIndex = () => {
        flagListIndex.current = true
        const InputParams = ['idx_list', '%']
        sendRequest(ServiceInfo.GET_INDEX_LIST, InputParams, hanldeGetListIndex, false)
    }

    const hanldeGetListIndex = (reqInfoMap, message) => {
        // -- process after get result --
        if (Number(message['Result']) === 0) {
            return
        } else {
            let jsondata
            try {
                jsondata = message['Data'] ? JSON.parse(message['Data']) : []
            } catch (err) {
                console.log('hanldeGetListIndex -> err', err)
                jsondata = []
            }
            jsondata.forEach((item) => {
                if (glb_sv.IndexMarket[item.c1]) {
                    glb_sv.IndexMarket[item.c1].indexName = item.c3
                } else {
                    glb_sv.IndexMarket[item.c1] = new InfoIndex()
                    glb_sv.IndexMarket[item.c1].indexName = item.c3
                }
            })
            glb_sv.jsonIndexInfo = jsondata
            // sort lại data server trả về
            const listIndexSorted = glb_sv.listIndex.config
            jsondata.map((idxInfo) => {
                if (!listIndexSorted.includes(idxInfo.c1)) {
                    listIndexSorted.push(idxInfo.c1)
                }
            })
            glb_sv.listIndex.server = listIndexSorted //  jsondata.map(e => e.c1); // TODO {Dung} Tách lại listIndex.server thành listIndex.active
            // Lưu lại info theo key là index
            jsondata.map((item) => {
                // console.log("LLLLLLLL", item);
                glb_sv.listIndex.InfoIndexServerByKey[item.c1] = item
            })
            glb_sv.eventMarket.next({ type: eventList.GET_LIST_INDEX })
            glb_sv.eventMarket.next({ type: eventList.SUB_INDEX })

            jsondata.forEach((e) => {
                const dextObj = {}
                let ls_sanGd = 'HNX'
                if (e.c1 === 'HSXIndex' || e.c2 === 'HSXIndex') {
                    ls_sanGd = 'HOSE'
                }
                const symbol = e.c1
                dextObj['symbol'] = symbol
                dextObj['full_name'] = symbol
                dextObj['description'] = e.c3
                dextObj['exchange'] = ls_sanGd
                dextObj['type'] = 'index'
                if (glb_sv.tradview_StkList.length === 0 || glb_sv.tradview_StkList.findIndex((x) => x.symbol === symbol) < 0) {
                    glb_sv.tradview_StkList.push(dextObj)
                    const stkTradInfo = {}
                    stkTradInfo['name'] = symbol
                    stkTradInfo['full_name'] = symbol
                    stkTradInfo['exchange'] = ls_sanGd
                    stkTradInfo['exchange-traded'] = ls_sanGd
                    stkTradInfo['exchange-listed'] = ls_sanGd
                    stkTradInfo['timezone'] = 'Asia/Bangkok'
                    stkTradInfo['minmov'] = 1
                    // stkTradInfo['minmov2'] = 0
                    // stkTradInfo['pointvalue'] = 1
                    stkTradInfo['session'] = '0900-1500'
                    stkTradInfo['has_intraday'] = true
                    stkTradInfo['has_seconds'] = true
                    stkTradInfo['intraday_multipliers'] = ['1']
                    stkTradInfo['seconds_multipliers'] = [1]
                    // stkTradInfo['has_no_volume'] = false
                    stkTradInfo['pricescale'] = 100
                    stkTradInfo['description'] = e.c3
                    stkTradInfo['type'] = 'index'
                    stkTradInfo['currency_code'] = 'VND'
                    stkTradInfo['supported_resolutions'] = ['1S', '15S', '30S', '1', '5', '15', '30', '60', 'D', 'W', 'M', '3M']
                    // stkTradInfo['supported_resolutions'] = ['1', '5', '15', '30', '60', 'D', 'W', 'M', '3M'];
                    stkTradInfo['ticker'] = symbol
                    glb_sv.stkInfoTradviewMap.set(symbol, stkTradInfo)
                    if (glb_sv.tradingViewFlag) {
                        const mapArr = []
                        glb_sv.stkInfoTradviewMap.forEach((value, key) => {
                            const mapObj = {}
                            mapObj[0] = key
                            mapObj[1] = value
                            mapArr.push(mapObj)
                        })
                        sessionStorage.setItem('tradinglist', JSON.stringify(glb_sv.tradview_StkList))
                        sessionStorage.setItem('tradingmap', JSON.stringify(mapArr))
                    }
                }
            })
            glb_sv.eventMarket.next({ type: eventList.GET_INDEXS_DONE })
        }
    }

    const getStockListOwn = () => {
        if (!glb_sv.objShareGlb['AcntMain']) return
        const InputParams = [glb_sv.objShareGlb['AcntMain']]
        sendRequest(
            ServiceInfo.GET_LIST_OWN,
            InputParams,
            getStockListOwnResult,
            true,
            getStockListOwnTimeout,
            '',
            0,
            'equal_input'
        )
    }

    const getStockListOwnTimeout = () => null

    const getStockListOwnResult = (reqInfoMap, message) => {
        if (Number(message['Result']) === 0) {
        } else {
            let jsondata = []
            try {
                jsondata = message.Data ? JSON.parse(message.Data) : []
            } catch (err) {
                return
            }
            const newWatchlist = {
                c1: 'watchlist_owner',
                c2: 'own_stock_list',
                type: 'own',
                stkList: jsondata.map((e) => e.c0),
            }
            glb_sv.allListFav.push(newWatchlist)
            flagGetWatchlistOwner.current += 1
            if (reLoginFlag.current) {
                if (flagGetWatchlistOwner.current === 2) {
                    if (glb_sv.activeList && glb_sv.allListFav.find((e) => e.c1 === glb_sv.activeList.c1)) {
                        glb_sv.commonEvent.next({ type: eventList.CHANGE_LIST_ACTIVE })
                    } else {
                        glb_sv.activeList = glb_sv.allListFav[0] ? { ...glb_sv.allListFav[0] } : { stkList: [] }
                        glb_sv.commonEvent.next({ type: eventList.CHANGE_LIST_ACTIVE })
                    }
                }
            } else {
                glb_sv.activeList = newWatchlist
                glb_sv.commonEvent.next({ type: eventList.CHANGE_LIST_ACTIVE })
            }
        }
    }

    const checkEkyc = () => {
        const inval = ['info']
        sendRequest(ServiceInfo.EKYC_INFO, inval, checkEkycResult, true, checkEkycTimeout, '', 5000)
    }

    const checkEkycTimeout = ({ type }) => { }

    const checkEkycResult = (reqInfoMap, message) => {
        if (Number(message['Result']) === 0) {
        } else {
            let datajson
            try {
                datajson = message.Data ? JSON.parse(glb_sv.filterStrBfParse(message.Data))[0] : {}
            } catch (err) {
                console.log('checkEkycResult', err)
                checkEkycTimeout({ type: 'timeout' })
                return
            }
            glb_sv.dataEkycInfo = datajson
        }
    }

    return (
        <>
            {['081'].includes(glb_sv.activeCode) ?
                <OpenAccountSSV />
                : <EkycModal />}
            <AlertModal />
            <OtpModal />
            {/* Modal xác nhận Chính sách xử lý dữ liệu cá nhân */}
            {allowCompanyRender(['075']) && <PersonalDataPolicyModal/>} 
            <OtpAuthenRequestModal />
            {eventMgt.length > 0 && (
                <Modal
                    className="modal-major"
                    visible={isShowEventMgtModal}
                    footer={isDontShowAgain && dataEvent.eventType === 'ADV' && (
                        <>
                            <Button
                                type='primary'
                                style={{
                                    maxWidth: 300,
                                }}
                                onClick={() => confirmEvent('D')}
                            >
                                {t('common_dont_show_again')} 
                            </Button>
                        </>
                    )}
                    closable={false} closeIcon={<IC_CLOSE fill="var(--TEXT__1)" />} onCancel={() => setIsShowEventMgtModal(false)}
                    title={<>
                        <div className="close-icon-modal" onClick={() => setIsShowEventMgtModal(false)}>
                            <IC_CLOSE fill="var(--TEXT__1)" /> 
                        </div>
                    </>}
                >
                    {dataEvent?.contentType === 'TEXT' ? (
                        <>
                            <p id='text_html_event'></p>
                        </>
                    ) : (
                        <iframe
                            src={dataEvent.content}
                            title='Web Trading Update'
                            style={{ border: 0, height: '75vh', width: '100%' }}
                        />
                    )}
                </Modal>
            )}
            {shouldShowBanner && (
                <div
                    style={{
                        minWidth: 'min-content',
                        position: 'absolute',
                        zIndex: 9,
                        bottom: 63,
                        right: 20
                    }}
                >
                    <span className='cursor-pointer' style={{ position: 'absolute', right: 0 }} onClick={() => {
                        setShouldShowBanner(false)
                        // setShouldShowBannerAgain(false)
                        bannerShowAgain.current = false
                    }}>
                        <IC_CLOSE width={32} height={32} fill="var(--RED__COLOR)" />
                    </span>
                    <img className='cursor-pointer' onClick={() => window.open(bannerOutLink, '_blank')} src={bannerImage} width={320} height={200} />
                </div>
            )}

            <EventModal />

            <IndividualBondDetail />
        </>
    )
}
const BackgroundService = memo(BackgroundServiceMemo)
export default BackgroundService
