import { Rate } from 'antd'
import React from 'react'

interface IRaTingProps {
	className?: string;
	defaultValue?: number;
	value?: number;
	ratingStyle?: React.CSSProperties;
	allowHalf?: boolean;
	onChange?: (value: number) => void;
}

export const RaTing = ({
	className = '',
	defaultValue = 0,
	value = 0,
	ratingStyle = {},
	allowHalf = true,
	onChange = e => null,
	...props
}: IRaTingProps) => {
	return (
		<div className={className}>
			<Rate onChange={onChange} style={ratingStyle} allowHalf={allowHalf} defaultValue={defaultValue} value={value} {...props} />
		</div>
	);
};
