import { Modal } from 'antd';
import { head } from 'lodash';
import React, { memo, useEffect, useState } from 'react';
import { FormatNumber, RealTimeHandler, Screens, eventList, glb_sv } from 'utils';
import { useTranslation } from 'react-i18next';
import './individual_bond_detail.scss';
import moment from 'moment';

const IndividualBondDetail = () => {
	const { t } = useTranslation();
	const [isOpenModal, setIsOpenModal] = useState(false);
	const [stkCode, setStkCode] = useState('');
	const [stockData, setStockData] = useState({});

	useEffect(() => {
		const commonEvent = glb_sv.commonEvent.subscribe(msg => {
			if (msg.type == eventList.OPEN_INDIVIDUAL_BOND_INFO_DETAIL && msg.value) {
				setIsOpenModal(true);
				setStkCode(msg.value);
			}
		});

		return () => {
			commonEvent.unsubscribe();
		};
	}, []);

	useEffect(() => {
		if (glb_sv.StockMarket[stkCode]) {
			setStockData({ ...glb_sv.StockMarket[stkCode] });
		}

		const StockInfoEvent = new RealTimeHandler(Screens.INDIVIDUAL_BOND_INFO_DETAIL);

		StockInfoEvent.listen(msg => {
			if (msg.type === eventList.REQ_AFTER_SUB_INFO && head(msg.value) === stkCode) {
				setStockData({ ...glb_sv.StockMarket[stkCode] });
			}
			if (msg.type === eventList.SUB_STOCK && msg.msgKey === stkCode && msg.message !== 'PO') {
				setStockData({ ...glb_sv.StockMarket[stkCode] });
			}
		});

		return () => {
			StockInfoEvent.clearRealTimeListener({ unsubscribe: true });
		};
	}, [stkCode]);

	const closeStockInfo = () => {
		setIsOpenModal(false);
	};

	const dictionaryBond = {
		optionsBondStatus: val => {
			// 0: Bình thường
			// 1: Tạm ngừng giao dịch do ngày nghỉ
			// 10: Tạm ngừng giao dịch
			// 2: Ngừng giao dịch
			// 11: Hạn chế giao dịch
			// 25: Giao dịch đặc biệt
			if ('0' === val) return t('common_normal');
            if ('1' === val) return t('suspend_due_holiday');
            if ('10' === val) return t('suspend_trading');
            if ('2' === val) return t('stop_trading');
            if ('11' === val) return t('transaction_restrictions');
            if ('25' === val) return t('special_deals');
            return val
		},
        optionsBondTermUnit: val => {
            // "Đơn vị kỳ hạn TP
            // 1: Ngày
            // 2: Tuần
            // 3: Tháng
            // 4: Năm
            // 6: Khác"
            if (1 === val) return t('day');
            if (2 === val) return t('week');
            if (3 === val) return t('month').charAt(0).toUpperCase() + t('month').slice(1);
            if (4 === val) return t('year').charAt(0).toUpperCase() + t('year').slice(1);
            if (6 === val) return t('common_other');
            return val
        },
        optionsInterestPaymentTermUnit: val => {
            // "Đơn vi kỳ hạn trả lãi trái phiếu
            // 1: Ngày
            // 2: Tuần
            // 3: Tháng
            // 4: Năm
            // 6: Khác"
            if (1 === val) return t('day');
            if (2 === val) return t('week');
            if (3 === val) return t('month').charAt(0).toUpperCase() + t('month').slice(1);
            if (4 === val) return t('year').charAt(0).toUpperCase() + t('year').slice(1);
            if (6 === val) return t('common_other');
            return val
        },
        optionsTypeInterestPayment: val => {
            // "Loại hình trả lãi:
            // 1: Coupon
            // 2: Zero Coupon
            // 3: Không xác định"
            if (1 === val) return 'Coupon';
            if (2 === val) return 'Zero Coupon';
            if (3 === val) return t('common_unknown');
            return val
        },
        optionsTypeInterestRate: val => {
            console.log('optionsTypeInterestRate', val)
            // "Loại lãi suất:
            // 1: Cố định
            // 2: Thả nổi
            // 3: Kết hợp"
            if (1 === val) return t('common_fixed');
            if (2 === val) return t('common_floating');
            if (3 === val) return t('common_combined');
            return val
        },
        optionsPaymentMethod: val => {
            console.log('optionsPaymentMethod', val)
            // "Phương thức trả lãi:
            // 1: Định kỳ - Cuối kỳ
            // 2: Định kỳ - Đầu kỳ
            // 3: Một lần khi đến hạn
            // 4: Khác"
            if (1 === val) return t('bond_periodic_end_period');
            if (2 === val) return t('bond_periodic_begin_period');
            if (3 === val) return t('bond_once_at_maturity');
            if (4 === val) return t('common_other');
            return val
        },
        optionsFeaturesBond: val => {
            // "Bao gồm các đặc điểm của trái phiếu:
            // - 1 = TP thường/ 2 = TP xanh
            // - 1 = TP chuyển đổi/ 0 = Không chuyển đổi
            // - 1 = TP kèm chứng quyền/ 0 = TP không kèm chứng quyền
            // - 1 = TP đảm bảo/ 0 = TP  không có đảm bảo
            // Các giá trị theo đúng thứ tự và cách nhau bởi dấu ; Ví dụ: 1;0;0;1"
            if (!val) return ''
            const splitVal = val.split(';')
            const featuresFirst = splitVal[0] == '1' ? t('bond_common') : t('bond_green')
            const featuresSecond = splitVal[0] == '1' ? t('bond_convertible') : t('bond_non_convertible')
            const featuresThird = splitVal[0] == '1' ? t('covered_warrant_bond') : t('uncovered_warrant_bond')
            const featuresFour = splitVal[0] == '1' ? t('secured_bond') : t('unsecured_bond')

            return `${t('bonds')} ${featuresFirst}, ${featuresSecond}, ${featuresThird}, ${featuresFour}.`
        }
	};

	return (
		<>
			{isOpenModal && (
				<Modal
					className='bond-info-detail'
					visible={isOpenModal}
					footer={null}
					onCancel={closeStockInfo}
					title={t('info_trading_bond').toUpperCase() + ` ${stkCode}`}>
					<div className='flex flex-direction-column'>
						<div className='price-up'>
							{t('info_detail')}
						</div>
						<div className='flex'>
							<div className='flex flex-1 flex-direction-column'>
								<div>
									{t('symbol_bond')}: {stockData.t55}
								</div>
								<div>
									{t('symbol_issuer')}: {stockData.symbol_issuer_bond}
								</div>
								<div>
									{t('date_trading')}:{' '}
									{!stockData.date_trading_bond ? null : moment(stockData.date_trading_bond, 'YYYYMMDD').format('DD/MM/YYYY')}
								</div>
								<div>
									{t('reference_price')}: {FormatNumber(stockData.ref_bond, 0, 1)}
								</div>
								<div>
									{t('listing_volume')}: {FormatNumber(stockData.listing_volume_bond)}
								</div>
								<div>
									{t('due_date_other')}:{' '}
									{!stockData.due_date_bond ? null : moment(stockData.due_date_bond, 'YYYYMMDD').format('DD/MM/YYYY')}
								</div>
								<div>
									{t('bond_term')}: {stockData.bond_term}
								</div>
								<div>
									{t('remaining_term')}: {stockData.remaining_term_bond} {t('day').toLowerCase()}
								</div>
								<div>
									{t('type_interest_payment')}: {dictionaryBond.optionsTypeInterestPayment(stockData.type_interest_payment_bond)}
								</div>
								<div>
									{t('nominal_interest_rate')}: {stockData.nominal_interest_rate_bond}%
								</div>
								<div>
									{t('interest_period')}: {stockData.interest_period_bond}
								</div>
								<div>
									{t('type_interest_rate')}: {dictionaryBond.optionsTypeInterestRate(stockData.type_interest_rate_bond)}
								</div>
								<div>
									{t('payment_method')}: {dictionaryBond.optionsPaymentMethod(stockData.payment_method_bond)}
								</div>
								<div>
									{t('features_bond')}: {dictionaryBond.optionsFeaturesBond(stockData.features_bond)}
								</div>
							</div>
							<div className='flex flex-1 flex-direction-column'>
								<div>
									{t('code_isin')}: {stockData.code_isin_bond}
								</div>
								<div>&ensp;</div>
								<div>
									{t('bond_status')}: {dictionaryBond.optionsBondStatus(stockData.bond_status)}
								</div>
								<div className='flex'>
									{t('open_price')}: {FormatNumber(stockData.open_price_bond, 0, 1)}
									<div className='ml-3'>
										{t('close_price_full')}: {FormatNumber(stockData.close_price_bond, 0, 1)}
									</div>
								</div>
								<div>
									{t('denominations_bond')}: {FormatNumber(stockData.denominations_bond, 0, 1)} {t('vnd_currency_unit')}
								</div>
								<div>
									{t('release_date')}:{' '}
									{!stockData.release_date_bond ? null : moment(stockData.release_date_bond, 'YYYYMMDD').format('DD/MM/YYYY')}
								</div>
								<div>
									{t('bond_term_unit')}: {dictionaryBond.optionsBondTermUnit(stockData.bond_term_unit)}
								</div>
								<div>&ensp;</div>
								<div>&ensp;</div>
								<div>&ensp;</div>
								<div>
									{t('interest_payment_term_unit')}: {dictionaryBond.optionsInterestPaymentTermUnit(stockData.interest_payment_term_unit_bond)}
								</div>
							</div>
						</div>
					</div>
				</Modal>
			)}
		</>
	);
};

export default memo(IndividualBondDetail);
