export default {
    LOGIN_FUNCTION: 'LOGIN_FUNCTION', // login
    REGISTER_FUNCTION: 'REGISTER_FUNCTION', // register
    FORGOT_FUNCTION: 'FORGOT_FUNCTION',
    GET_OTP_FUNCTION: 'GET_OTP_FUNCTION',
    SEND_OTP_FUNCTION: 'SEND_OTP_FUNCTION',
    SUBSCRIBE_INFO: 'SUBSCRIBE_INFO',
    UNSUBSCRIBE_INFO: 'UNSUBSCRIBE_INFO',
    GET_INDEX_HIST: 'GET_INDEX_HIST',
    LAST_MSG: 'LAST_MSG',
    GET_INDEX_MISS: 'GET_INDEX_MISS',
    GET_DATA_CHART_INDEX: 'GET_DATA_CHART_INDEX',
    GET_FAV_INFO: 'GET_FAV_INFO',
    GET_DETAIL_FAV_LIST: 'GET_DETAIL_FAV_LIST',
    ADD_NEW_FAV_LIST: 'ADD_NEW_FAV_LIST',
    REMOVE_FAV_LIST: 'REMOVE_FAV_LIST',
    ADD_STOCK_TO_FAV_LIST: 'ADD_STOCK_TO_FAV_LIST',
    REMOVE_STOCK_IN_FAV_LIST: 'REMOVE_STOCK_IN_FAV_LIST',
    EDIT_NAME_FAV_LIST: 'EDIT_NAME_FAV_LIST',
    GET_DATA_EP: 'GET_DATA_EP',
    GET_NEWS_INFO: 'GET_NEWS_INFO',
    GET_HOT_NEWS: 'GET_HOT_NEWS',
    GET_DEEP_NEWS: 'GET_DEEP_NEWS',
    GET_BUY_ABLE: 'GET_BUY_ABLE',
    GET_SELL_ABLE_STOCK: 'GET_SELL_ABLE_STOCK',
    GET_SELL_STOCK_LIST: 'GET_SELL_STOCK_LIST',
    GET_SUMARY_INFO_MARKET: 'GET_SUMARY_INFO_MARKET',
    GET_LIST_EVENT: 'GET_LIST_EVENT',
    GET_LIST_NOTIFY: 'GET_LIST_NOTIFY',
    READ_NOTIFY: 'READ_NOTIFY',
    GET_USER_INFO: 'GET_USER_INFO',
    GET_VERSION_SERVER: 'GET_VERSION_SERVER',
    GET_OTP_NUMBER: 'GET_OTP_NUMBER',
    GET_MARKET_TOP: 'GET_MARKET_TOP',
    GET_LIST_CW: 'GET_LIST_CW',
    GET_LIST_STOCK_INTO_INDEX: 'GET_LIST_STOCK_INTO_INDEX',
    GET_LIST_BONDS: 'GET_LIST_BONDS',
    GET_LIST_ETF: 'GET_LIST_ETF',
    GET_HIST: 'GET_HIST',
    GET_HIST_INDEX: 'GET_HIST_INDEX',
    GET_HIST_STOCK: 'GET_HIST_STOCK',
    GET_NEWS_INTO_MARKET: 'GET_NEWS_INTO_MARKET',
    RESET_PASSWORD: 'RESET_PASSWORD',
    GET_NEW_OTP: 'GET_NEW_OTP',
    EKYC_UPDATE: 'EKYC_UPDATE',
    LOGOUT: 'LOGOUT',
    GET_INDEX_LIST: 'GET_INDEX_LIST',
    CHANGE_PASSWORD_LOGIN: 'CHANGE_PASSWORD_LOGIN',
    CHANGE_PASSWORD_ORDER: 'CHANGE_PASSWORD_ORDER',
    EKYC_INFO: 'EKYC_INFO',
    API_UPLOAD: 'API_UPLOAD',
    GET_LIST_OWN: 'GET_LIST_OWN',
    CONFIG_NOTIFY: 'CONFIG_NOTIFY',

    //---------------------- Đặt lệnh
    ORDER_VIP: 'ORDER_VIP',
    ORDER_BUY: 'ORDER_BUY',
    ORDER_SELL: 'ORDER_SELL',
    CANCEL_ORDER: 'CANCEL_ORDER',
    MODIFY_ORDER: 'MODIFY_ORDER',
    GET_ORDER_LIST: 'GET_ORDER_LIST',
    GET_HIS_ORDER_LIST: 'GET_HIS_ORDER_LIST',

    //---------------------- Đặt lệnh trước
    GET_ADV_ORDER_LIST: 'GET_ADV_ORDER_LIST',
    CANCEL_ADV_ORDER: 'CANCEL_ADV_ORDER',
    GET_NEXT_TRADING_DAY: 'GET_NEXT_TRADING_DAY',

    // ---------------------- Tài sản
    GET_ASSETS_TOTAL_INFO: 'GET_ASSETS_TOTAL_INFO',
    GET_ASSETS_GUAR_INFO: 'GET_ASSETS_GUAR_INFO',
    GET_ASSETS_STOCK_LIST: 'GET_ASSETS_STOCK_LIST',

    //---------------------- Nghiệp vụ
    GET_HIS_PAY_ON_ACCOUT_BY_SUB: 'GET_HIS_PAY_ON_ACCOUT_BY_SUB', // Lấy DS giao dịch gần nhất theo theo sub
    GET_HIS_PAY_ON_ACCOUT_BY_ACT: 'GET_HIS_PAY_ON_ACCOUT_BY_ACT', // Lịch sử giao dịch nộp tiền theo tài khoản
    CANCEL_TRAN_MONEY: 'CANCEL_TRAN_MONEY', // Hủy giao dịch thông báo nộp tiền
    GET_CURRENT_CASH_BY_SUB: 'GET_CURRENT_CASH_BY_SUB', // Lấy "Số dư hiện tại"
    GET_LIST_BANK_TRANS: 'GET_LIST_BANK_TRANS', // Lấy DS ngân hàng chuyển
    GET_LIST_ACT_RECEIVE: 'GET_LIST_ACT_RECEIVE', // Lấy DS số tài khoản hưởng (CTCK)
    ADD_NEW_TRAN_MONEY_NOTIFY: 'ADD_NEW_TRAN_MONEY_NOTIFY', // Thêm giao dịch thông báo nộp tiền
    QUERY_ADVANCE_INFO_FEE: 'QUERY_ADVANCE_INFO_FEE', // Thông tin phí của Ứng trước tiền bán

    //----------------------- Các request cho màn hình login mới
    CREATE_ACCOUNT: 'CREATE_ACCOUNT',
    CHECK_ACCOUNT: 'CHECK_ACCOUNT',
    AUTHEN_OTP: 'AUTHEN_OTP',
    //------
    GET_CASH_CAN_LENDING: 'GET_CASH_CAN_LENDING', // Lấy số tiền có thể ứng
    GET_LENDING_FEE: 'GET_LENDING_FEE', // Lấy số phí ứng
    REQ_NEW_LENDING: 'REQ_NEW_LENDING', // Yêu cầu ứng tiền
    GET_HIS_ADVANCE_LENDING: 'GET_HIS_ADVANCE_LENDING', // Lấy lịch sử ứng trước tiền bán CK
    GET_LIST_CONTRACT_RENEW: 'GET_LIST_CONTRACT_RENEW', // Lấy danh sách hợp đồng gia hạn
    CONFIRM_RENEW_MARGIN_CONTRACT: 'CONFIRM_RENEW_MARGIN_CONTRACT', // Xác nhận gia hạn hợp đồng margin
    GET_LIST_ANNOUNCEMENT_ORDER: 'GET_LIST_ANNOUNCEMENT_ORDER', //Lấy danh sách lệnh quảng cáo
    GET_LIST_RIGHT_TYPE: 'GET_LIST_RIGHT_TYPE',
    GET_LIST_RIGHT_TYPE_REGISTER: 'GET_LIST_RIGHT_TYPE_REGISTER',
    GET_WITHDRAW_HISTORY: 'GET_WITHDRAW_HISTORY',
    ADD_NEW_WITH_DRAWAL: 'ADD_NEW_WITH_DRAWAL',
    CANCEL_WITH_DRAWAL: 'CANCEL_WITH_DRAWAL',
    //---------------
    GET_CUR_MARG_CONT_LIST: 'GET_CUR_MARG_CONT_LIST', // Lấy danh sách hợp đồng kỹ quỹ hiện tại
    SEND_REFUND_MARG: 'SEND_REFUND_MARG', // Send repay margin
    GET_REF_INFO_DETAIL: 'GET_REF_INFO_DETAIL', // get contract info detail
    CANCEL_MARGIN_CONTRACT: 'CANCEL_MARGIN_CONTRACT', // cancel a margin contract
    GET_HIS_MARG_LIST: 'GET_HIS_MARG_LIST', // get history of margin list
    //-----------------------------------
    GET_STK_ODD_LIST: 'GET_STK_ODD_LIST', // Danh sách chứng khoán lô lẻ
    SELL_ODD_LOT: 'SELL_ODD_LOT', // Xác nhận bán chứng khoán lô lẻ
    GET_HIS_ODD_LOT_ORDER: 'GET_HIS_ODD_LOT_ORDER', // Danh sách bán chứng khoán lô lẻ
    //--------------------------------
    GET_CONFIRM_ORDER_LIST: 'GET_CONFIRM_ORDER_LIST',
    CONFIRM_VERIFY_ALL_ORDER: 'CONFIRM_VERIFY_ALL_ORDER',
    KEY_EXCHANGE_STATEMENT_QUERY: 'KEY_EXCHANGE_STATEMENT_QUERY', // Lấy danh sách sao kê giao dịch (công)
    CONFIRM_ORDER: 'CONFIRM_ORDER',
    GET_RIGHTS_LIST_ADDITIONAL_STOCK_RELEASE: 'GET_RIGHTS_LIST_ADDITIONAL_STOCK_RELEASE',
    GET_CURRENT_CASH_AVAILABLE: 'GET_CURRENT_CASH_AVAILABLE', // Lấy số tiền hiện tại có thể chuyển
    GET_HIS_INTRERNAL_TRANSFER: 'GET_HIS_INTRERNAL_TRANSFER', // Lấy DS chuyển nội bộ gần nhất
    GET_LIST_AVAILABLE_TRAN: 'GET_LIST_AVAILABLE_TRAN', // Lấy danh sách có thể chuyển
    CONFIRM_BUY_STOCK_ADDITIONAL_RELEASE: 'CONFIRM_BUY_STOCK_ADDITIONAL_RELEASE',
    GET_LIST_HIST_STOCK_RELEASE_TRANSACTION: 'GET_LIST_HIST_STOCK_RELEASE_TRANSACTION',
    CANCEL_STOCK_RELEASE_REGISTER: 'CANCEL_STOCK_RELEASE_REGISTER',
    KEY_GET_STOCK_LIST: 'KEY_GET_STOCK_LIST', // Lấy danh sách mã chứng khoán có thể chuyển (công)
    KEY_GET_HISTORY: 'KEY_GET_HISTORY',
    KEY_ADD_STOCK_TRANSFER: 'KEY_ADD_STOCK_TRANSFER', // chuyển chứng khoán
    KEY_ABORT: 'KEY_ABORT', // Hủy chuyển chứng khoán,
    CANCEL_RG_ODD_ORDER: 'CANCEL_RG_ODD_ORDER',
    GET_CURRENT_CASH_COM_STOCK: 'GET_CURRENT_CASH_COM_STOCK',
    GET_BANK_LINKED_ACCOUNT_LIST: 'GET_BANK_LINKED_ACCOUNT_LIST',
    GET_MONEY_IN_BANK: 'GET_MONEY_IN_BANK',
    GET_HISTORY_ONLINE_BANKING: 'GET_HISTORY_ONLINE_BANKING',
    CONFIRM_TRAN_SAME_ACT:'CONFIRM_TRAN_SAME_ACT',
    CONFIRM_TRAN_DIFF_ACT:'CONFIRM_TRAN_DIFF_ACT',

    QUERY_USER_INFORMATION: 'QUERY_USER_INFORMATION',
    QUERY_HISTORY_ADVANCE_CASH: 'QUERY_HISTORY_ADVANCE_CASH',
    QUERY_ADVANCE_CASH_ABLE: 'QUERY_ADVANCE_CASH_ABLE',
    QUERY_ADVANCE_CASH_FEE: 'QUERY_ADVANCE_CASH_FEE',
    ADVANCE_CASH_REQUEST: 'ADVANCE_CASH_REQUEST',
    QUERY_CURRENT_CASH_IN_COMP_STOCK: 'QUERY_CURRENT_CASH_IN_COMP_STOCK',
    QUERY_BANK_LINKED_ACCOUNT_LIST: 'QUERY_BANK_LINKED_ACCOUNT_LIST',
    QUERY_MONEY_IN_BANK: 'QUERY_MONEY_IN_BANK',
    QUERY_HISTORY_ONLINE_BANKING: 'QUERY_HISTORY_ONLINE_BANKING',
    QUERY_FINANCE_INFO: 'QUERY_FINANCE_INFO',
    POST_NEW_FEEDBACK: 'POST_NEW_FEEDBACK',
    QUERY_FEEDBACK_COMMENT_LIST: 'QUERY_FEEDBACK_COMMENT_LIST',
    POST_NEW_COMMENT: 'POST_NEW_COMMENT',
    GET_VERSION_STKLIST: 'GET_VERSION_STKLIST',
    GET_STKLIST: 'GET_STKLIST',
    QUERY_LIST_NOTIFICATION: 'QUERY_LIST_NOTIFICATION',
    QUERY_LIST_RIGHT_FOR_BUY: 'QUERY_LIST_RIGHT_FOR_BUY',
    QUERY_HIST_RIGHT_FOR_BUY: 'QUERY_HIST_RIGHT_FOR_BUY',
    REGISTER_RIGHT_FOR_BUY: 'REGISTER_RIGHT_FOR_BUY',
    UPDATE_NOTIFY: 'UPDATE_NOTIFY',
    REGISTER_DEVICE_ONESIGNAL: 'REGISTER_DEVICE_ONESIGNAL',
    QUERY_LIST_UNREAD_NOTIFICATION: 'QUERY_LIST_UNREAD_NOTIFICATION',
    QUERY_LIST_NOTIFICATION_READ: 'QUERY_LIST_NOTIFICATION_READ',

    UPDATE_NOTIFICATION: 'UPDATE_NOTIFICATION',
    QUERY_ORDER_ADVERTISE: 'QUERY_ORDER_ADVERTISE',
    DELETE_NOTIFICATION: 'DELETE_NOTIFICATION',
    GET_DATA_TRADINGVIEW: 'GET_DATA_TRADINGVIEW',
    GET_RIGHTS_TRADINGVIEW: 'GET_RIGHTS_TRADINGVIEW',

    CANCEL_MARGIN_PAYBACK:'CANCEL_MARGIN_PAYBACK',
    CANCEL_ADVANCE_CASH: 'CANCEL_ADVANCE_CASH',
    QUERY_FINANCE_DATA: 'QUERY_FINANCE_DATA',
    QUERY_NEW_DATA: 'QUERY_NEW_DATA',

    GET_LIST_OVERVIEW:'GET_LIST_OVERVIEW',
    GET_STK_LIST_RECM: 'GET_STK_LIST_RECM',
    GET_STOCK_CODE: 'GET_STOCK_CODE',
    GET_HISTORY_ASSETS: 'GET_HISTORY_ASSETS',
    EVENT_FOOTER_LOGIN: 'EVENT_FOOTER_LOGIN',
    GET_LIST_BANK: 'GET_LIST_BANK',
    REGISTER_ONLINE_BANK: 'REGISTER_ONLINE_BANK',

    GET_HIS_PRICES:'GET_HIS_PRICES', // Công mới thêm,
    GET_LIST_INDUSTRY: 'GET_LIST_INDUSTRY',
    GET_LIST_STOCK_INDUSTRY: 'GET_LIST_STOCK_INDUSTRY',
    GET_LIST_RIGHT: 'GET_LIST_RIGHT',
    GET_INSTITUATION: 'GET_INSTITUATION',
    GET_LIST_RECOMMEND: 'GET_LIST_RECOMMEND',
    GET_LIST_STOCK_RECOMMEND: 'GET_LIST_STOCK_RECOMMEND',
    GET_HISTORY_CASH_FLOW: 'GET_HISTORY_CASH_FLOW',
    GET_CASH_INFOMATION: 'GET_CASH_INFOMATION',
    GET_BANK_ACCOUNT_LIST: 'GET_BANK_ACCOUNT_LIST',
    GET_BANK_SECURITY_LIST: 'GET_BANK_SECURITY_LIST',
    SEND_DEPOSIT_MONEY: 'SEND_DEPOSIT_MONEY',
    GET_HISTORY_TRANSACTION: 'GET_HISTORY_TRANSACTION',
    CANCEL_TRANSACTION: 'CANCEL_TRANSACTION',
    PORTFOLIO_MANAGEMENT: 'PORTFOLIO_MANAGEMENT',
    PORTFOLIO_MANAGEMENT_DETAIL: 'PORTFOLIO_MANAGEMENT_DETAIL',
    FORGET_PASSWORD: 'FORGET_PASSWORD',
    ORDER_GTC: 'ORDER_GTC',
    GET_DETAILS_NEWS: 'GET_DETAILS_NEWS',
    GET_REFER_NAME: 'GET_REFER_NAME',
    CHECK_EVENT_MGT: 'CHECK_EVENT_MGT',
    GET_LIST_BONDS_SECURITIES: 'GET_LIST_BONDS_SECURITIES',
    GET_INFO_BOND_SECURITIES: 'GET_INFO_BOND_SECURITIES',
    GET_BUY_ABLE_BONDS: 'GET_BUY_ABLE_BONDS',
    ORDER_BONDS_BUY: 'ORDER_BONDS_BUY',
    ORDER_BONDS_SELL: 'ORDER_BONDS_SELL',
    GET_ORDER_BOND_LIST: 'GET_ORDER_BOND_LIST',
    GET_SELL_BONDS_LIST: 'GET_SELL_BONDS_LIST',
    CANCEL_ORDER_BONDS: 'CANCEL_ORDER_BONDS',
    CANCEL_SELL_ORDER_BONDS: 'CANCEL_SELL_ORDER_BONDS',
    GET_NEWS_BY_STOCK: 'GET_NEWS_BY_STOCK',
    GET_LOGIN_SESSION: 'GET_LOGIN_SESSION',
    QUERY_STOCK_MARGIN_LIST: 'QUERY_STOCK_MARGIN_LIST',
    GET_JOB_LIST: 'GET_JOB_LIST',
    CHECK_PROMO_CODE: 'CHECK_PROMO_CODE',
    CHECK_DUPLICATE_INFO: 'CHECK_DUPLICATE_INFO',
    GET_ACCOUNT_NUMBER_OPEN_VIA_EKYC : 'GET_ACCOUNT_NUMBER_OPEN_VIA_EKYC',

    GET_INFO_BOND_USER: 'GET_INFO_BOND_USER',
    GET_ID_USER_BOND: 'GET_ID_USER_BOND',
    GET_HISTORY_ORDER: 'GET_HISTORY_ORDER',
    ADD_TAKE_PROFIT_STOP_LOSS: 'ADD_TAKE_PROFIT_STOP_LOSS',
    UPDATE_TAKE_PROFIT_STOP_LOSS: 'UPDATE_TAKE_PROFIT_STOP_LOSS',
    DELETE_TAKE_PROFIT_STOP_LOSS: 'DELETE_TAKE_PROFIT_STOP_LOSS',
    QUERY_USER_INFORMATION_IVS: 'QUERY_USER_INFORMATION_IVS',
    RECORD_STATISTICS: 'RECORD_STATISTICS',
    GET_DAY_TRADING: 'GET_DAY_TRADING',
    GET_PRICE_BUY_SELL_BONDS: 'GET_PRICE_BUY_SELL_BONDS',
    GET_PRO_INVESTER_SECURITIES: 'GET_PRO_INVESTER_SECURITIES',
    MANUAL_OTP: 'MANUAL_OTP',
    REQUEST_OTP_AUTHEN: 'REQUEST_OTP_AUTHEN',
    PORTFOLIO_INFO: 'PORTFOLIO_INFO',
    GET_STOCK_INFO_LIST: 'GET_STOCK_INFO_LIST',
    LIST_TOKEN_OAUTH: 'LIST_TOKEN_OAUTH',
    REMOVE_TOKEN_OAUTH: 'REMOVE_TOKEN_OAUTH',
    UPDATE_GRANT_PERMISSION: 'UPDATE_GRANT_PERMISSION',
    EXPECTED_PROFIT_AMOUNT: 'EXPECTED_PROFIT_AMOUNT',
    EXPECTED_PROFIT_RATIO: 'EXPECTED_PROFIT_RATIO',
    EXPECTED_PROFIT_FULL: 'EXPECTED_PROFIT_FULL',
    CHECK_EVENT: 'CHECK_EVENT', // Hiện tại dùng cho SSV
    ADD_BANK_INFO: 'ADD_BANK_INFO',
    QUERY_LIST_BANK_ACCOUNT: 'QUERY_LIST_BANK_ACCOUNT',
    DELETE_BANK_INFO: 'DELETE_BANK_INFO',
    CHECK_SHD: 'CHECK_SHD',
    UPDATE_USER_INFO: 'UPDATE_USER_INFO',
    CHECK_WITHDRAWAL: 'CHECK_WITHDRAWAL',

    GET_COMMON_BOND_LIST_INFO: 'GET_COMMON_BOND_LIST_INFO',
    GET_COMMON_BOND: 'GET_COMMON_BOND',
    GET_BOND_CAST_FLOW: 'GET_BOND_CAST_FLOW',
    GET_BOND_CAST_FLOW_FOOTER: 'GET_BOND_CAST_FLOW_FOOTER',
    REQUEST_PLACE_ORDER_BOND: 'REQUEST_PLACE_ORDER_BOND',
    PLACE_ORDER_BONDS_TRADE: 'PLACE_ORDER_BONDS_TRADE',
    GET_BONDS_ORDER_NORMAL_HIST: 'GET_BONDS_ORDER_HIST',
    GET_BONDS_ORDER_NORMAL_HIST_2: 'GET_BONDS_ORDER_HIST_2',
    GET_BONDS_ORDER_ADVANCE_HIST: 'GET_BONDS_ORDER_ADVANCE_HIST',
    GET_BONDS_CONTRACT_HIST: 'GET_BONDS_CONTRACT_HIST',
    GET_BONDS_CATEGORY_LIST: 'GET_BONDS_CATEGORY_LIST',
    UPDATE_CONTRACT_PERIOD: 'UPDATE_CONTRACT_PERIOD',
    GET_BONDS_ORDER_ADVANCE_CONTRACT_CODE: 'GET_BONDS_ORDER_ADVANCE_CONTRACT_CODE',
    REQUEST_CANCEL_PLACE_ORDER_BOND: 'REQUEST_CANCEL_PLACE_ORDER_BOND',
    REQUEST_DEAL_TRADE_BOND: 'REQUEST_DEAL_TRADE_BOND',
    REGISTER_INVESTOR: 'REGISTER_INVESTOR',
    STATEMENT_STOCK: 'STATEMENT_STOCK',
    GET_FINANCE_INFO: 'GET_FINANCE_INFO',
    
    GET_BROKER_REMISIER_LIST:"GET_BROKER_REMISIER_LIST",
    IS_FIRST_IN_MONTH: "IS_FIRST_IN_MONTH",
    UPDATE_IS_FIRST_IN_MONTH:"UPDATE_IS_FIRST_IN_MONTH",
	CHANGE_BROKER: "CHANGE_BROKER", // thay đổi nhân viên tư vấn
    GET_CURRENT_BROKER: "GET_CURRENT_BROKER", // Lấy thông tin nhân viên tư vấn hiện tại
    DELETE_REQUEST_CHANGE_BROKER: "DELETE_REQUEST_CHANGE_BROKER", // Xoá yêu cầu thay đổi nhân viên tư vấn
    SEND_CUSTOMER_EVALUATE: "SEND_CUSTOMER_EVALUATE",
    GET_BROKER_HISTORY:"GET_BROKER_HISTORY", // lấy danh sách nhân viên tư vấn
    REGISTER_NEW_BROKER: "REGISTER_NEW_BROKER", // đăng ký mới nhân viên tư vấn
    CANCEL_REGISTER_BROKER: "CANCEL_REGISTER_BROKER",
    GET_QR_CODE_CASH_DEPOSIT: 'GET_QR_CODE_CASH_DEPOSIT',
    GET_LIST_BANK_DEPOSIT: 'GET_LIST_BANK_DEPOSIT',
    GET_LIST_ECONTRACT: 'GET_LIST_ECONTRACT',
    CHECK_OTHER_BANK_ACCOUNT: "CHECK_OTHER_BANK_ACCOUNT",
    GET_LIST_INDIVIDUAL_BONDS_SECURITIES: 'GET_LIST_INDIVIDUAL_BONDS_SECURITIES',
    GET_BOND_PRICE: 'GET_BOND_PRICE',
    REGISTER_SUB_ACCOUNT_FOR_BOND_TRADING: 'REGISTER_SUB_ACCOUNT_FOR_BOND_TRADING',
    UPLOAD_BOND_CONTRACT: 'UPLOAD_BOND_CONTRACT',
    GET_BOND_DETAIL_INFO: 'GET_BOND_DETAIL_INFO',
    REQUEST_EXPORT_REPORT: 'REQUEST_EXPORT_REPORT',
    QUERRY_EXPORT_REPORT: 'QUERRY_EXPORT_REPORT',
    GET_COMMON_BOND_CUST_TP: 'GET_COMMON_BOND_CUST_TP',
    GET_COMMON_BOND_PERIOD: 'GET_COMMON_BOND_PERIOD',
    GET_MARGIN_INFO: 'GET_MARGIN_INFO',
    UPDATE_MARGIN_ORDER: 'UPDATE_MARGIN_ORDER',
    GET_MARGIN_INFO_DETAIL: 'GET_MARGIN_INFO_DETAIL',
    GET_PROFIT_LOST_HISTORY: 'GET_PROFIT_LOST_HISTORY',
}
