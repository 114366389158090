const width = window.innerWidth, height = window.innerHeight
const [shortDimension, longDimension] = width < height ? [width, height] : [height, width];
//-- base on device ~5"
const guidelineBaseWidth = 1920;
const guidelineBaseHeight = 1080;
//-- tính tỷ lệ theo chiều rộng
const scale = size => Math.round((shortDimension / guidelineBaseWidth) * size);
//-- tính tỷ lệ theo chiều cao
const scaleHeight = size => Math.round((longDimension / guidelineBaseHeight) * size);
// const verticalScale = size => Math.round((height / guidelineBaseHeight) * size);
const verticalScale = (size, factor = 0.4) =>
    Math.round(size + (scaleHeight(size) - size) * factor);
//-- size (tỷ lệ) theo chiều rộng với tỷ lệ sai số nhất định (factor)
const moderateScale = (size, factor = 0.4) =>
  Math.round(size + (scale(size) - size) * factor);
export {scale, verticalScale, moderateScale};


// !!! {Dung} Xóa nếu không cần thiết
// !!! {Tuan} Xóa nếu không cần thiết