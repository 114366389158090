import { Subject } from 'rxjs'
import { eventList, InfoIndex, InfoStock, glb_sv, reqFunct, channels, socket_sv, requestInfo } from '../index'
import moment from 'moment'
import InfoStatistic from '../models/infoStatistic'
import allowCompanyRender from '../allowCompanyApplyFunct'
import sendRequest from './sendRequest'

class globalService {
    eventMarket: any
    isInternetReachable: any
    reqInfoMap: Map<any, any>
    authFlag: boolean
    wait_reAuthFlag: boolean
    permissNotify: boolean
    publicFlag: boolean
    activeAcnt: string
    expTimeout: number
    discexpire: number
    reqTimeout: number
    versionChange: string
    configInfo: {
        fraction_qty?: string
        sub_path_trading?: string
        sub_path_stream?: string
    }
    objShareGlb: {
        userInfo: {
			c53?: any
            c13?: 'Y' | 'N',
            c6?: any
        }
        workDate: string // Ngày giao dịch/ Trading date
        sessionInfo: { sessionId: string; userID: string; remOrdPass: boolean; orderPass: string; Otp: string, firstLoginInMonth: string }
    }
    svTime: {}
    gapSvTime: number
    toastYN: any[]
    otpCases: string[]
    timeServer: string
    mrk_stklist: any[]
    mrk_stklist_select: any[]
    mrk_stklist_lang: string
    stk_VN30: any[]
    language: string
    timeoutConnect: string
    stockOwner: any[]
    allListFav: any[]
    activeList: { stkList: any[] }
    stkListPrb: any[]
    hotKeys: {}
    currentAppState: string
    networkState: {}
    waitingReconnectSocketFlag: boolean
    waitingReLoginFlag: boolean
    isWaitSendResultLogin: boolean
    mapDataEP: Map<any, any>
    mapSMLDataEP: Map<any, any>
    subListCur: any[]
    isConnectApp: boolean
    listIntradayTopMrk: any[]
    mrkIndex_MsgMap: Map<any, any>
    LIST_HEADER_PRICEBOARD: { name: string; type: string; isShow: boolean }[]
    StatisticMarket: { HSX: InfoStatistic; HNX: InfoStatistic; UPC: InfoStatistic; getStatisticData: ({ key, time, topic }: { key: any; time: any; topic: any }) => any; updateStatisticData: ({ key, time, topic, data }: { key: any; time: any; topic: any; data?: any[] }) => any[] }
    listIndex: { config: any[]; server: any[]; InfoIndexServerByKey: {}; active: any[] }
    jsonIndexInfo: any[]
    dataHisMrktop: {
        '1D': {} // !!! {Dung} xem lại nhớ bỏ nếu không cần thiết
        '1W': {}; '1M': {}; '2M': {}; '3M': {}; '6M': {}; '1Y': {}; '2Y': {}; '3Y': {}; '5Y': {}
    }
    LIST_GET_INDEX_MSG: any[]
    HISTORY_CHART: {}
    STOCK_INFO_HSX: any[]
    STOCK_INFO_HNX: any[]
    STOCK_INFO_UPC: any[]
    warrant_list: any[]
    bonds_list: any[]
    etf_list: any[]
    TYPE_TOP_MARKET: string
    theme: string
    isVisibleMarket: boolean
    notifyOnesignal: { userId: string; pushToken: string }
    imageUrl: string
    userInfo: {}
    credentials: { username: string; password: string; user_id: String }
    dataEkycInfo: {}
    userInfoServer: any
    timeAuthenOtp: {}
    VERSION_STKLIST: { HSX: string; HNX: string; UPC: string }
    isAutoLogin: boolean
    ListBankTrans: any[]
    ListBankReceive: any[]
    ListRightType: any[]
    ListRightTypeReceive: any[]
    IS_OPEN_STOCK_INFO: boolean
    listBondsSecurities: any[]
    notify: any[]
    lastAdvOrdInfo: { acntNo: string; sb_tp: string; stkCode: string; orderTp: string; seasonTp: string; price: string; qty: string; startDt: string; endDt: string }
    last24OrdInfo: { acntNo: string; sb_tp: string; stkCode: string; orderTp: string; seasonTp: string; price: string; qty: string; startDt: string; endDt: string }
    lastGtcOrdInfo: { acntNo: string; sb_tp: string; stkCode: string; orderTp: string; seasonTp: string; price: string; qty: string; startDt: string; endDt: string }
    lastOrdInfo: { acntNo: string; sb_tp: string; stkCode: string; orderTp: string; price: string; qty: string }
    lastOrdBondInfo: {}
    tradingViewFirstRq: boolean
    tradingViewDoneGetData: boolean
    tradingViewFlag: boolean
    tradingView: any
    tradingViewData: {}
    tradingViewRightInfo: {}
    tradingViewDataFull: {}
    tradingViewBarData: {}
    TradingviewDatafeed: any
    stkInfoTradviewMap: Map<any, any>
    tradview_StkList: any[]
    price_basic_color: string
    price_floor_color: string
    price_ceil_color: string
    price_basic_less: string
    price_basic_over: string
    defaultColor: string
    openAccountEkyc: any
    tradingViewWidget: { resolution: string; isExistData: boolean }
    company_info: {}
    isProInvester: boolean
    proc_ServerPushMRKRcv: (message: any) => void
    getDataMsgTP: (msgObj: any, index: any, key: any) => any
    setColorPriceTP: (stkMsgObj: any, key: any) => void
    updI_MsgHistory: (msgObj: any, isDone: any) => void
    timeoutINDEXMSG: any
    setReqInfoMapValue: (key: number, valObj: IReqInfoMap | {} | null) => void
    convUnixTime2StrDt: (unixTm: any, DateTp: any) => string
    convStrDt2UnixTime: (strTime: any) => number
    convStrDtime2UnixTime: (strTime: any) => number
    searchStk2Scroll: (div_table_prcb_id: any, table_priceP_id: any, table_priceP_head_id: any, value: any) => void
    convDate2StrDt: (Datt: any) => string
    replaceStrToHTML: (str: string, isTransformBr?: boolean ) => string
    filterStrBfParse: (str: any) => string
    logMessage: (flag?: boolean, ...message: any[]) => void
    filterNumber: (numberstr: any) => number
    setItervClientTimeFunct: () => void
    orgTime: any
    mrkInfo: any
    convDate2StrTime: (Datt: any) => string
    convData2TradFormat: (dataArrS: any) => { t: any[]; o: any[]; h: any[]; l: any[]; c: any[]; v: any[]; s: string }
    dateToChartTimeMinute: (date: any) => number
    convertUTCDateToLocalDate: (date: any) => Date
    versionCompare: (v1: any, v2: any, options: any) => number
    convertNameIndex: (name: any) => any
    chartConvertIntraday: (data: any) => any[]
    chartConvert15Second: (data: any) => any[]
    chartConvert30Second: (data: any) => any[]
    chartConvert2Minutes: (data?: any[]) => any[]
    chartConvert2Month: (data: any) => any[]
    chartConvert2MonthServer: (data: any) => any[]
    chartConvert2Year: (data: any) => any[]
    convertDataChart5Days: (data?: any[]) => void
    chartConvertIndexWeek: (data: any) => any[]
    chartConvertIndexMonth: (data: any) => any[]
    chartConvertIndexYear: (data: any) => any[]
    createIndex: (index: any) => any
    getUnique: (arr: any, comp: any) => any
    SMA: (data: any, period: any) => any[]
    EMA: (data: any, period: any, smoothing: any) => any[]
    getColor: (value: any, item: any, styles: any, key: any) => any
    arr_diff: (a1: any, a2: any) => any[]
    intersect: (arr1: any, arr2: any) => any[]
    checkOtp: (functionCallback: any) => boolean
    cvStringToDate: (time: any) => string
    convertTimeT52toDate: (time: any) => string
    convertTimeT52toTimetb: (time: any) => any
    sendAuthReqSocketStream: ({ LoginID, MdmTp, Token }: { LoginID: any; MdmTp: any; Token: any }) => void
    dataAuthStream: { LoginID: any; MdmTp: any; Token: any }
    showAlert: (params: any) => void
    focusInput: (id: any, sleep: any) => void
    stringToSlug: (str: any) => any
    setColorFlashPriceboard: () => boolean
    removeTrashObjEkyc: (object: any) => any
    sendEKYCLog: (sEKYCLog: any, eKYCType?: string) => void
    deviceName: string
    checkDuplicatedObjFieldInArr: (array?: any[], field?: string) => boolean
	tradingViewPage: boolean = false
    getReqInfoMapValue(ClientSeq: number): IReqInfoMap {
        throw new Error('Method not implemented.')
    }
    IndexMarket: { [key: string]: any};
    LastConnectStatus: string
    commonEvent: any
    eventConnect: any
    activeCode: ISecCode
    StockMarket: any

    constructor() {
        this.reqInfoMap = new Map()
        this.eventMarket = new Subject()
        this.commonEvent = new Subject()
        this.eventConnect = new Subject()
        this.authFlag = false
        this.wait_reAuthFlag = false
        this.permissNotify = false
        this.publicFlag = false
        // @ts-expect-error
        this.showAlertRedirectTableIpad = false
        // this.activeCode = ''
        this.activeAcnt = ''
        this.expTimeout = 900 // -- time idle
        this.discexpire = 30 // -- time expire tính từ thời điểm mất kết nối server
        this.reqTimeout = 15000 // 25 seconds
        this.versionChange = '1425'
        this.configInfo = {}
        this.objShareGlb = {
            workDate: '', // Ngày giao dịch/ Trading date
            sessionInfo: {
                sessionId: '',
                userID: '',
                remOrdPass: false,
                orderPass: '',
                Otp: '',
                firstLoginInMonth: 'Y',
            },
            userInfo: {}
        }
        this.svTime = {}
        this.gapSvTime = 0
        this.toastYN = []
        this.otpCases = ['010034', '010035', '010037', '010038']

        this.timeServer = moment().format('YYYYMMDD')

        this.mrk_stklist = [] // market stock list
        this.mrk_stklist_select = [] // autocomplete
        this.mrk_stklist_lang = 'VI' // language stock list

        this.stk_VN30 = []
        this.language = 'VI'
        this.timeoutConnect = 'timeout_30m'

        this.stockOwner = [] // Danh sách chứa mã chứng khoán sở hữu
        this.allListFav = []
        this.activeList = {
            stkList: [],
        }
        this.stkListPrb = []
        this.hotKeys = {}
        //---------------------------------------------
        /**
         * @param {String} LastConnectStatus      Value in ['non_authen', 'authen_success']
         */
        this.LastConnectStatus = ''
        this.currentAppState = '' // Trạng thái app hiện tại: background, active, ...
        this.isInternetReachable = true
        this.networkState = {} // trạng thái mạng (không realtime)
        this.waitingReconnectSocketFlag = false
        this.waitingReLoginFlag = false

        /**
        * @param {String} isWaitSendResultLogin flag lưu trạng thái đang reLogin, thì return socket service
        */
        this.isWaitSendResultLogin = false;

        //----------------------

        // map stock data ep market
        this.mapDataEP = new Map()
        this.mapSMLDataEP = new Map()

        this.subListCur = [] // list stock is view for setState

        this.isConnectApp = true
        /**
         * @param {String} listIntradayTopMrk      chứa dữ liệu reatime của market top
         */
        this.listIntradayTopMrk = []

        /**
         * @param {String} mrkIndex_MsgMap      hash map chứa dữ liệu index cho tradingview
         */
        this.mrkIndex_MsgMap = new Map()

        this.LIST_HEADER_PRICEBOARD = [
            { name: 'short_symbol', type: 't55', isShow: true },

            { name: 'priceboard_price3', type: 't132_3', isShow: false },
            { name: 'priceboard_vol3', type: 't1321_3', isShow: false },
            { name: 'priceboard_price2', type: 't132_2', isShow: false },
            { name: 'priceboard_vol2', type: 't1321_2', isShow: false },
            { name: 'priceboard_price1', type: 't132_1', isShow: true },
            { name: 'priceboard_vol1', type: 't1321_1', isShow: true },
            { name: '+/-', type: 't31_incr', isShow: true },
            { name: '+/-(%)', type: 't31_incr_per', isShow: false },
            { name: 'priceboard_price', type: 't31', isShow: true },
            { name: 'volume_evaluation', type: 't32', isShow: true },
            { name: 'priceboard_price1', type: 't133_1', isShow: true },
            { name: 'priceboard_vol1', type: 't1331_1', isShow: true },
            { name: 'priceboard_price2', type: 't133_2', isShow: false },
            { name: 'priceboard_vol2', type: 't1331_2', isShow: false },
            { name: 'priceboard_price3', type: 't133_3', isShow: false },
            { name: 'priceboard_vol3', type: 't1331_3', isShow: false },
            { name: 'sum_trading_qty_short', type: 't391', isShow: true },
            { name: 'priceboard_buy', type: 't397', isShow: true },
            { name: 'priceboard_sell', type: 't398', isShow: true },
            { name: 'priceboard_short_room', type: 't3301', isShow: true },
            { name: 'floor', type: 't333', isShow: true },
            { name: 'reference', type: 't260', isShow: true },
            { name: 'ceiling', type: 't332', isShow: true },
            { name: 'priceboard_open', type: 't137', isShow: true },
            { name: 'priceboard_avg', type: 't631', isShow: true },
            { name: 'priceboard_low', type: 't2661', isShow: true },
            { name: 'priceboard_hight', type: 't266', isShow: true },
        ]

        this.IndexMarket = {
            // Các method IndexMarket ---------------------------------
            getIndexData: function ({ key, time, topic }) {
                // console.log('getIndexData', key, time, topic, this)
                if (!key || !time || !topic) {
                    console.warn('Lỗi tại getIndexData [Thiếu tham số cần thiết]: ', key, time, topic)
                    return []
                }
                // Nếu chưa có key thì khởi tạo
                if (!this[key]) {
                    console.warn(
                        'Hãy khai báo key trong IndexMarket để get dữ liệu mới trong IndexMarket [key]: ',
                        key
                    )
                    return
                }
                if (!this[key][time]) {
                    console.warn('Hãy khai báo time trong IndexMarket để get dữ liệu [time]: ', time)
                    return
                }
                if (!this[key][time][topic]) {
                    console.warn('Hãy khai báo topic trong IndexMarket để get dữ liệu [topic]: ', topic)
                    return
                }
                return this[key][time][topic] || []
            },
            setIndexData: function ({ key, time, topic, data = [], mode = 'override' }) {
                // console.log('>>>>>>>>>>: ', key, time, topic, data, mode, this[key])

                if (!key || !time || !topic || !data || !mode) {
                    console.warn('Lỗi tại setIndexData [Thiếu tham số cần thiết]: ', key, time, topic, data, mode)
                    return []
                }
                // Nếu chưa có key thì khởi tạo
                if (!this[key]) {
                    this[key] = new InfoIndex()
                    console.log(
                        'Hãy khai báo key trong IndexMarket để update dữ liệu mới trong IndexMarket [key]: ',
                        key
                    )
                }
                // Set dữ liệu
                this[key].key = key
                // Kiểm tra topic tồn tại chưa. Nếu chưa thì warning để developer khai báo trong IndexMarket
                if (!this[key][time]) {
                    console.warn('Hãy khai báo time trong IndexMarket để update dữ liệu [time]: ', time)
                    return
                }
                if (!this[key][time][topic]) {
                    console.warn('Hãy khai báo topic trong IndexMarket để update dữ liệu [topic]: ', topic)
                    return
                }
                if (mode === 'override') this[key][time][topic] = data
                if (mode === 'append') {
                    this[key][time][topic].push(data)
                }
                // if (mode === 'concat') {
                //     this[key][time][topic] = [...this[key][time][topic], ...data].sort(function compareNumbers(a, b) {
                //         return a.seq - b.seq
                //     })
                //     // Ở đây là trường hợp get HIST nhiều lần. cần bắn event để lấy nhiều lần
                //     if (data.length >= 2) {
                //         // Nếu data cuối cùng có độ dài lớn hơn 2 thì getHist tiếp
                //         const min = data?.reduce(function (prev, curr) {
                //             return prev.seq < curr.seq ? prev : curr
                //         })
                //         const max = data?.reduce(function (prev, curr) {
                //             return prev.seq > curr.seq ? prev : curr
                //         })
                //         setTimeout(() => {
                //             // console.log('seq min, seq max khi get hist', min.seq, max.seq)
                //             glb_sv.eventMarket.next({
                //                 type: eventList.REQ_GET_ANOTHER_HIST_VAL,
                //                 key,
                //                 time,
                //                 topic,
                //                 nextSeq: max.seq,
                //                 length: data.length,
                //             })
                //         }, 10)
                //     }
                //     // console.log('push data', data);
                // }
                // Bắn event sau khi set dữ liệu xong
                setTimeout(() => {
                    glb_sv.eventMarket.next({ type: eventList.UPDATE_INDEX_DATA, key, time, topic, mode })
                }, 10)
                // console.log('>>>>>>>>>>> Bắn event update IndexMarket [mode]: ', mode, this)
            },
            resetAndClearAllData: function () {
                // TODO {Dung} thêm event clear data
            },
        }
        this.StockMarket = {
            // Các method StockMarket ---------------------------------
            getStockData: function ({ key, time, topic }) {
                // console.log('getStockData', key, time, topic, this)
                if (!key || !time || !topic) {
                    console.warn('Lỗi tại getStockData [Thiếu tham số cần thiết]: ', key, time, topic)
                    return []
                }
                // Nếu chưa có key thì khởi tạo
                if (!this[key]) {
                    console.warn(
                        'Hãy khai báo key trong StockMarket để get dữ liệu mới trong StockMarket [key]: ',
                        key
                    )
                    return
                }
                if (!this[key][time]) {
                    console.warn('Hãy khai báo time trong StockMarket để get dữ liệu [time]: ', time)
                    return
                }
                if (!this[key][time][topic]) {
                    console.warn('Hãy khai báo topic trong StockMarket để get dữ liệu [topic]: ', topic)
                    return
                }
                return this[key][time][topic] || []
            },
            setStockData: function ({ key, time, topic, data = [], mode = 'override' }) {
                // console.log('>>>>>>>>>>: ', key, time, topic, data, mode, this[key])

                if (!key || !time || !topic || !data || !mode) {
                    console.warn('Lỗi tại setStockData [Thiếu tham số cần thiết]: ', key, time, topic, data, mode)
                    return []
                }
                // Nếu chưa có key thì khởi tạo
                if (!this[key]) {
                    this[key] = new InfoStock()
                    console.warn(
                        'Hãy khai báo key trong StockMarket để update dữ liệu mới trong StockMarket [key]: ',
                        key
                    )
                }
                // Set dữ liệu
                this[key].key = key
                // Kiểm tra topic tồn tại chưa. Nếu chưa thì warning để developer khai báo trong StockMarket
                if (!this[key][time]) {
                    console.warn('Hãy khai báo time trong StockMarket để update dữ liệu [time]: ', time)
                    return
                }
                if (!this[key][time][topic]) {
                    console.warn('Hãy khai báo topic trong StockMarket để update dữ liệu [topic]: ', topic)
                    return
                }
                if (mode === 'override') this[key][time][topic] = data
                if (mode === 'append') {
                    this[key][time][topic].push(data)
                    // console.log('push data', data);
                }
                if (mode === 'concat') {
                    this[key][time][topic] = [...this[key][time][topic], ...data]
                    // console.log('push data', data);
                }
                // Bắn event sau khi set dữ liệu xong
                setTimeout(() => {
                    glb_sv.eventMarket.next({ type: eventList.UPDATE_STOCK_DATA, key, time, topic, mode })
                }, 10)
                // console.log('>>>>>>>>>>> Bắn event update StockMarket [mode]: ', mode, this)
            },
            resetAndClearAllData: function () {
                // TODO {Dung} thêm event clear data
            },
            resetStockData: function (key) {
                if (!key) {
                    console.warn('Lỗi tại resetStockData [Thiếu tham số cần thiết]: ', key)
                    return []
                }
                // reset data về null
                this[key] = {
                    ...new InfoStock(), ...{
                        t55: this[key]?.t55,
                        U9: this[key]?.U9,
                        U10: this[key]?.U10,
                    }
                }
            }
        }
        this.StatisticMarket = {
            HSX: new InfoStatistic(),
            HNX: new InfoStatistic(),
            UPC: new InfoStatistic(),
            getStatisticData: function ({ key, time, topic }) {
                if (!key || !time || !topic) {
                    console.warn('Lỗi tại getStatisticData [Thiếu tham số cần thiết]: ', key, time, topic)
                    return []
                }
                return this[key][time][topic] || []
            },
            updateStatisticData: function ({ key, time, topic, data = [] }) {
                if (!key || !time || !topic || !data) {
                    console.warn('Lỗi tại getStatisticData [Thiếu tham số cần thiết]: ', key, time, topic)
                    return []
                }
                // Nếu chưa có key thì khởi tạo
                if (!this[key]) {
                    this[key] = new InfoStatistic()
                    console.warn(
                        'Hãy khai báo key trong InfoStatistic để update dữ liệu mới trong StatisticMarket [key]: ',
                        key
                    )
                }
                // Set dữ liệu
                this[key].key = key
                // Kiểm tra topic tồn tại chưa. Nếu chưa thì warning để developer khai báo trong InfoStatistic
                if (!this[key][time]) {
                    console.warn('Hãy khai báo time trong InfoStatistic để update dữ liệu [time]: ', time)
                    return
                }
                if (!this[key][time][topic]) {
                    console.warn('Hãy khai báo topic trong InfoStatistic để update dữ liệu [topic]: ', topic)
                    return
                }
                this[key][time][topic] = data
                // Bắn event sau khi set dữ liệu xong
                setTimeout(() => {
                    glb_sv.eventMarket.next({ type: eventList.UPDATE_STATISTIC, key, time, topic })
                }, 10)
            },
        }
        this.listIndex = {
            config: [],
            server: [],
            InfoIndexServerByKey: {},
            active: [],
        }
        this.jsonIndexInfo = []
        /**
         * @param {String} dataHisMrktop: lưu dữ liệu lịch sử của market top
         */
        this.dataHisMrktop = {
            '1D': {}, // !!! {Dung} xem lại nhớ bỏ nếu không cần thiết
            '1W': {},
            '1M': {},
            '2M': {},
            '3M': {},
            '6M': {},
            '1Y': {},
            '2Y': {},
            '3Y': {},
            '5Y': {},
        }

        this.LIST_GET_INDEX_MSG = []
        this.HISTORY_CHART = {}
        this.STOCK_INFO_HSX = []
        this.STOCK_INFO_HNX = []
        this.STOCK_INFO_UPC = []

        /**
         * @param {String} warrant_list: danh sách mã chứng quyền
         */
        this.warrant_list = []
        /**
         * @param {String} bonds_list danh sách mã trái phiếu
         */
        this.bonds_list = []
        /**
         * @param {String} etf_list: danh sách chứng chỉ quỹ và etf
         */
        this.etf_list = []
        /**
         * @param {String} TYPE_TOP_MARKET: Loại top đang active TOP_PRI_UP, TOP_PRI_DOWN, TOP_QTY_UP, TOP_VAL_UP
         */
        this.TYPE_TOP_MARKET = 'TOP_VAL_UP'
        /**
         * @param {String} theme: theme app LIGHT || CN_LIGHT || DARK || CN_DARK
         */
        this.theme = 'LIGHT'
        /**
         * @param {String} isVisibleMarket: màn hình thị trường có đang visible hay không
         */
        this.isVisibleMarket = false
        /**
         * @param {String} notifyOnesignal chứa userId và pushToken của onesignal
         */
        this.notifyOnesignal = {
            userId: '',
            pushToken: '',
        }

        this.imageUrl = '';
        /**
         * @param {String} userInfo chứa email, phone, name của user từ social
         */
        this.userInfo = {}
        /**
         * @param {String} credentials chứa thông tin khi tạo tài khoản
         */
        this.credentials = {
            username: '',
            password: '',
            user_id: ''
        }
        /**
         * @param {String} dataEkycInfo chứa thông tin ekyc từ server
         */
        this.dataEkycInfo = {}
        /**
         * @param {String} userInfoServer chứa thông tin tài khoản từ server
         */
        this.userInfoServer = null
        /**
         * @param {String} timeAuthenOtp chứa thông tin thời gian nhận otp và thời gian còn lại (giây)
         */
        this.timeAuthenOtp = {}
        /**
         * @param {String} VERSION_STKLIST chứa thông tin version stklist của từng sàn
         */
        this.VERSION_STKLIST = {
            HSX: 'none',
            HNX: 'none',
            UPC: 'none',
        }
        /**
         * @param {String} isAutoLogin flag check có phải auto login hay không
         */
        this.isAutoLogin = false
        // -------------------------------------------
        this.ListBankTrans = []
        this.ListBankReceive = []
        //
        this.ListRightType = []
        this.ListRightTypeReceive = []

        /**
         * @param {String} IS_OPEN_STOCK_INFO flag check trạng thái Stock info có mở hay không
         */
        this.IS_OPEN_STOCK_INFO = false
        /**
         * @param {String} listBondsSecurities danh sách trái phiếu từ CTCK
         */
        this.listBondsSecurities = []

        this.notify = []
        this.lastAdvOrdInfo = {
            acntNo: '',
            sb_tp: '',
            stkCode: '',
            orderTp: '',
            seasonTp: '',
            price: '',
            qty: '',
            startDt: '',
            endDt: '',
        }
        this.last24OrdInfo = {
            acntNo: '',
            sb_tp: '',
            stkCode: '',
            orderTp: '',
            seasonTp: '',
            price: '',
            qty: '',
            startDt: '',
            endDt: '',
        }
        this.lastGtcOrdInfo = {
            acntNo: '',
            sb_tp: '',
            stkCode: '',
            orderTp: '',
            seasonTp: '',
            price: '',
            qty: '',
            startDt: '',
            endDt: '',
        }
        this.lastOrdInfo = { acntNo: '', sb_tp: '', stkCode: '', orderTp: '', price: '', qty: '' }
        this.lastOrdBondInfo = {}
        /**
         * Các giá trị global cho TradingView chart
         */
        this.tradingViewFirstRq = false
        this.tradingViewDoneGetData = false
        this.tradingViewFlag = false
        this.tradingView = undefined
        this.tradingViewData = {}
        this.tradingViewRightInfo = {}
        this.tradingViewDataFull = {}
        this.tradingViewBarData = {}
        this.TradingviewDatafeed = undefined

        this.stkInfoTradviewMap = new Map()
        this.tradview_StkList = []

        // color priceboard
        this.price_basic_color = 'price-ref'
        this.price_floor_color = 'price-floor'
        this.price_ceil_color = 'price-ceil'
        this.price_basic_less = 'price-down'
        this.price_basic_over = 'price-up'
        this.defaultColor = 'text-primary'

        // Object lưu thông brokerid, remiserid, code qua link mở ekyc
        this.openAccountEkyc = null;

        /// obj tradingview widget lưu thông tin khi resolution thay đổi
        this.tradingViewWidget = {
            resolution: '',
            isExistData: false
        }
        /**
         * @param {String} company_info thông tin công ty chứng khoán
         */
        this.company_info = {}
        /**
         * @param {String} isProInvester cờ check nhà đầu tư chuyên nghiệp
         */
        this.isProInvester = false

        //-- process all info from MKT_INFO chanel - server push realtime
        this.proc_ServerPushMRKRcv = (message) => {
            // -- receive info that push from server real time
            let jsonArr = []
            const strdata = message['Data']
            try {
                jsonArr = JSON.parse(strdata)
            } catch (error) {
                jsonArr = []
                return
            }
            this.process_MrkInfoMsgMuiltData(jsonArr, 0)
        }

        this.process_MrkInfoMsgMuiltData = (msgInfo = [], clientSeq = 0) => {
            // console.log('process_MrkInfoMsgMuiltData', msgInfo)
            for (let i = 0; i < msgInfo.length; i++) {
                this.sprocess_ForOneMsg(msgInfo[i], clientSeq)
            }
        }

        this.sprocess_ForOneMsg = (msg = {}, clientSeq = 0) => {
            const msgTp = msg.U7
            switch (msgTp) {
                case 'SI':
                    // console.log("sprocess_ForOneMsg -> SI", msg)
                    if (msg['t167'] === 'BO' && msg['U10'] === '01') {
                        break
                    }
                    if (this.StockMarket[msg.t55] && this.StockMarket[msg.t55].t260) {
                        setTimeout(() => this.updSI_Msg2MrkInfoMap(msg), 0)
                    } else {
                        this.updSI_Msg2MrkInfoMap(msg)
                    }
                    break
                case 'TP': {
                    const msgKey = msg.t55
                    if (!msgKey) return
                    setTimeout(() => {
                        this.updTP_Msg2MrkInfoMap(msg, msgKey)
                    }, 0)
                    break
                }
                case 'PO': {
                    const msgKey = msg.t55
                    if (!msgKey) return
                    // if (msg.U8 && msg.U8.includes('HSX')) return
                    setTimeout(() => {
                        this.updPO_Msg2MrkInfoMap(msg, msgKey)
                    }, 0)
                    break
                }
                case 'TO': {
                    const msgKey = msg.t55
                    if (!msgKey) return
                    // if (msg.U8 && msg.U8.includes('HSX')) {
                    //     setTimeout(() => {
                    //         this.updPO_Msg2MrkInfoMap(msg, msgKey)
                    //     }, 0)
                    // }
                    setTimeout(() => {
                        this.updPO_Msg2MrkInfoMap(msg, msgKey)
                    }, 0)
                    break
                }
                case 'EP':
                    setTimeout(() => this.updEP_Msg2MrkInfoMap(msg), 0)
                    break
                case 'I':
                    setTimeout(() => this.updI_Msg2MrkInfoMap(msg), 0)
                    break
                case 'BI':
                    if (!msg['U8']) {
                        break
                    }
                    setTimeout(() => this.updBI_Msg2MrkInfoMap(msg), 0)
                    break
                case 'TS':
                    if (msg == null || msg === undefined || clientSeq > 0) {
                        break
                    }
                    this.updTS_Msg2MrkInfoMap(msg)
                    break
                case 'AA':
                    if (msg == null || msg === undefined) {
                        break
                    }
                    setTimeout(() => {
                        this.updAA_Msg2MrkInfoMap(msg)
                    }, 0)
                    break
                case 'S':
                    break
                default:
                    break
            }
            return
        }

        //-- Start process a message from MKT_INFO chanel - auto pushed by server
        // TODO: Refactor use TypeScript
        this.updSI_Msg2MrkInfoMap = (msgObj: I_MsgSIData) => {
            if (!msgObj.t55) return
            const msgKey = msgObj.t55
            let stkMsgObj = this.StockMarket[msgKey]
            if (!stkMsgObj) {
                stkMsgObj = new InfoStock()
                this.StockMarket[msgKey] = stkMsgObj
            }
            stkMsgObj.t55 = msgObj.t55
            stkMsgObj.U10 = msgObj.U10
            // stkMsgObj.U34 = msgObj.U34
            stkMsgObj.timeStock = msgObj.t52
            if (msgObj.seq >= stkMsgObj.lastSeq) {
                stkMsgObj.lastSeq = msgObj.seq
                let isUpdateWatchlistMobile = false,
                    isUpdateWatchlistTablet = false,
                    isUpdatePriceboard = false
                if (stkMsgObj.t31 !== msgObj.t31) {
                    // -- Giá khớp hiện tại
                    stkMsgObj.t31 = msgObj.t31
                    stkMsgObj.t31_incr = !msgObj.t31 ? null : msgObj.t31 - msgObj.t260
                    stkMsgObj.t31_incr_per = !msgObj.t31 ? null : ((msgObj.t31 - msgObj.t260) * 100) / msgObj.t260
                    isUpdateWatchlistMobile = true
                    isUpdateWatchlistTablet = true
                    isUpdatePriceboard = true
                }

                if (stkMsgObj.t32 !== msgObj.t32) {
                    // -- Khối lượng khớp
                    stkMsgObj.t32 = msgObj.t32
                    isUpdatePriceboard = true
                }
                if (stkMsgObj.t137 !== msgObj.t137) {
                    // -- Giá mở cửa
                    stkMsgObj.t137 = msgObj.t137
                    // isUpdatePriceboard = true
                }
                if (stkMsgObj.t631 !== msgObj.t631) {
                    // -- Giá trung bình
                    stkMsgObj.t631 = msgObj.t631
                    isUpdatePriceboard = true
                }
                if (stkMsgObj.t266 !== msgObj.t266) {
                    // -- Giá cao
                    stkMsgObj.t266 = msgObj.t266
                    isUpdatePriceboard = true
                }
                if (stkMsgObj.t2661 !== msgObj.t2661) {
                    // -- Giá thấp
                    stkMsgObj.t2661 = msgObj.t2661
                    isUpdatePriceboard = true
                }
                if (stkMsgObj.t397 !== msgObj.t397) {
                    // -- NDTNN mua
                    stkMsgObj.t397 = msgObj.t397
                    isUpdatePriceboard = true
                }
                if (stkMsgObj.t398 !== msgObj.t398) {
                    // -- NDTNN bán
                    stkMsgObj.t398 = msgObj.t398
                    isUpdatePriceboard = true
                }
                if (stkMsgObj.t3301 !== msgObj.t3301) {
                    // -- NDTNN Room
                    isUpdateWatchlistTablet = true
                    isUpdatePriceboard = true
                    stkMsgObj.t3301 = msgObj.t3301
                }
                if (stkMsgObj.t139 !== msgObj.t139) {
                    stkMsgObj.t139 = msgObj.t139
                }
                if (stkMsgObj.t387 !== msgObj.t387) {
                    isUpdateWatchlistTablet = true
                    stkMsgObj.t387 = msgObj.t387
                }
                if (stkMsgObj.t3871 !== msgObj.t3871) {
                    isUpdateWatchlistTablet = true
                    isUpdatePriceboard = true
                    stkMsgObj.t3871 = msgObj.t3871
                }
                if (stkMsgObj.t3981 !== msgObj.t3981) {
                    stkMsgObj.t3981 = msgObj.t3981
                }
                if (stkMsgObj.t3971 !== msgObj.t3971) {
                    stkMsgObj.t3971 = msgObj.t3971
                }
                if (stkMsgObj.t332 !== msgObj.t332) {
                    // giá trần
                    stkMsgObj.t332 = msgObj.t332
                    isUpdatePriceboard = true
                }
                if (stkMsgObj.t333 !== msgObj.t333) {
                    // giá sàn
                    stkMsgObj.t333 = msgObj.t333
                    isUpdatePriceboard = true
                }
                if (stkMsgObj.t134 !== msgObj.t134) {
                    // Tổng KL lệnh đã đặt mua tại thời điểm hiện tại
                    stkMsgObj.t134 = msgObj.t134
                    // Tổng KL lệnh mua đang trên sàn (Tổng dư mua)
                    stkMsgObj.t134_sub_t391 = msgObj.t134 - msgObj.t391
                }
                if (stkMsgObj.t135 !== msgObj.t135) {
                    // Tổng KL lệnh đã đặt bán tại thời điểm hiện tại
                    stkMsgObj.t135 = msgObj.t135
                    // Tổng KL lệnh bán đang trên sàn (Tổng dư bán)
                    stkMsgObj.t135_sub_t391 = msgObj.t135 - msgObj.t391
                }
                if (stkMsgObj.t260 !== msgObj.t260) {
                    // giá tham chiếu
                    isUpdateWatchlistMobile = true
                    isUpdateWatchlistTablet = true
                    isUpdatePriceboard = true
                    stkMsgObj.t260 = msgObj.t260
                    stkMsgObj.ref = msgObj.t260
                }
                if (stkMsgObj.t391 !== msgObj.t391) {
                    isUpdateWatchlistTablet = true
                    isUpdatePriceboard = true
                    stkMsgObj.t391 = msgObj.t391
                }
                if (stkMsgObj.t392 !== msgObj.t392) {
                    stkMsgObj.t392 = msgObj.t392
                }

                //-- Chứng quyền
                if (stkMsgObj.t106 !== msgObj.t106) {
                    //-- TC phát hành
                    stkMsgObj.t106 = msgObj.t106
                    isUpdatePriceboard = true
                }
                //-- CK Cơ sở --
                const U24 = msgObj.U24 ? msgObj.U24.trim() : ''
                if (stkMsgObj.U24 !== U24) {
                    //-- TC phát hành
                    stkMsgObj.U24 = U24
                    isUpdatePriceboard = true
                }
                if (stkMsgObj.U22 !== msgObj.U22) {
                    //-- Giá thực hiện
                    stkMsgObj.U22 = msgObj.U22
                    isUpdatePriceboard = true
                }
                if (!stkMsgObj.U23) {
                    //-- Tỷ lệ chuyển đổi
                    const split = msgObj.U23 ? msgObj.U23.split(':') : [0, 0]
                    stkMsgObj.U23 = Math.round(split[0] * 10) / 10 + ':' + split[1]
                }
                if (stkMsgObj.t109 !== msgObj.t109) {
                    stkMsgObj.t109 = msgObj.t109
                }
                // if (stkMsgObj.U20 !== msgObj.U20) {
                //-- ngày đáo hạn
                stkMsgObj.U20 = msgObj.U20
                // }
                if (stkMsgObj.U21 !== msgObj.U21) {
                    //-- Ngày GDCC
                    stkMsgObj.U21 = msgObj.U21
                    isUpdatePriceboard = true
                }
                if (stkMsgObj.U19 !== msgObj.U19) {
                    stkMsgObj.U19 = msgObj.U19
                }
                if (stkMsgObj.U29 !== msgObj.U29) {
                    stkMsgObj.U29 = msgObj.U29
                }
                if (stkMsgObj.U30 !== msgObj.U30) {
                    stkMsgObj.U30 = msgObj.U30
                }
                if (stkMsgObj.U31 !== msgObj.U31) {
                    stkMsgObj.U31 = msgObj.U31
                }
                if (stkMsgObj.U33 !== msgObj.U33) {
                    //-- Điểm hoà vốn
                    stkMsgObj.U33 = msgObj.U33
                    isUpdatePriceboard = true
                }

                // -- Xét màu t31 - giá khớp hiện tại
                if (
                    msgObj.t31 === 0 ||
                    msgObj.t31 === undefined ||
                    Math.round(msgObj.t31 * 1000) / 1000 === Math.round(msgObj.t260 * 1000) / 1000
                ) {
                    stkMsgObj.t31_color = this.price_basic_color
                } else if (Math.round(msgObj.t31 * 1000) / 1000 > Math.round(msgObj.t260 * 1000) / 1000) {
                    if (Math.round(msgObj.t31 * 1000) / 1000 === Math.round(msgObj.t332 * 1000) / 1000) {
                        stkMsgObj.t31_color = this.price_ceil_color
                    } else {
                        stkMsgObj.t31_color = this.price_basic_over
                    }
                } else if (Math.round(msgObj.t31 * 1000) / 1000 < Math.round(msgObj.t260 * 1000) / 1000) {
                    if (Math.round(msgObj.t31 * 1000) / 1000 === Math.round(msgObj.t333 * 1000) / 1000) {
                        stkMsgObj.t31_color = this.price_floor_color
                    } else {
                        stkMsgObj.t31_color = this.price_basic_less
                    }
                }

                // -- Xét màu t137 -- Giá mở cửa
                if (msgObj.t137 === 0 || msgObj.t137 === msgObj.t260) {
                    stkMsgObj.t137_color = this.price_basic_color
                } else if (msgObj.t137 > msgObj.t260) {
                    if (msgObj.t137 === msgObj.t332) {
                        stkMsgObj.t137_color = this.price_ceil_color
                    } else {
                        stkMsgObj.t137_color = this.price_basic_over
                    }
                } else if (msgObj.t137 < msgObj.t260) {
                    if (msgObj.t137 === msgObj.t333) {
                        stkMsgObj.t137_color = this.price_floor_color
                    } else {
                        stkMsgObj.t137_color = this.price_basic_less
                    }
                }
                // -- Xét màu t631 - Giá trung bình
                if (msgObj.t631 === 0 || msgObj.t631 === msgObj.t260) {
                    stkMsgObj.t631_color = this.price_basic_color
                } else if (msgObj.t631 > msgObj.t260) {
                    if (msgObj.t631 === msgObj.t332) {
                        stkMsgObj.t631_color = this.price_ceil_color
                    } else {
                        stkMsgObj.t631_color = this.price_basic_over
                    }
                } else if (msgObj.t631 < msgObj.t260) {
                    if (msgObj.t631 === msgObj.t333) {
                        stkMsgObj.t631_color = this.price_floor_color
                    } else {
                        stkMsgObj.t631_color = this.price_basic_less
                    }
                }
                // -- Xét màu t266 - Gía cao
                if (msgObj.t266 === 0 || msgObj.t266 === msgObj.t260) {
                    stkMsgObj.t266_color = this.price_basic_color
                } else if (msgObj.t266 > msgObj.t260) {
                    if (msgObj.t266 === msgObj.t332) {
                        stkMsgObj.t266_color = this.price_ceil_color
                    } else {
                        stkMsgObj.t266_color = this.price_basic_over
                    }
                } else if (msgObj.t266 < msgObj.t260) {
                    if (msgObj.t266 === msgObj.t333) {
                        stkMsgObj.t266_color = this.price_floor_color
                    } else {
                        stkMsgObj.t266_color = this.price_basic_less
                    }
                }
                // -- Xét màu t2661 - Gía thấp
                if (msgObj.t2661 === 0 || msgObj.t2661 === msgObj.t260) {
                    stkMsgObj.t2661_color = this.price_basic_color
                } else if (msgObj.t2661 > msgObj.t260) {
                    if (msgObj.t2661 === msgObj.t332) {
                        stkMsgObj.t2661_color = this.price_ceil_color
                    } else {
                        stkMsgObj.t2661_color = this.price_basic_over
                    }
                } else if (msgObj.t2661 < msgObj.t260) {
                    if (msgObj.t2661 === msgObj.t333) {
                        stkMsgObj.t2661_color = this.price_floor_color
                    } else {
                        stkMsgObj.t2661_color = this.price_basic_less
                    }
                }
                // -- Xét màu t139 - Giá đóng cửa
                if (msgObj.t139 === 0 || msgObj.t139 === msgObj.t260) {
                    stkMsgObj.t139_color = this.price_basic_color
                } else if (msgObj.t139 > msgObj.t260) {
                    if (msgObj.t139 === msgObj.t332) {
                        stkMsgObj.t139_color = this.price_ceil_color
                    } else {
                        stkMsgObj.t139_color = this.price_basic_over
                    }
                } else if (msgObj.t139 < msgObj.t260) {
                    if (msgObj.t139 === msgObj.t333) {
                        stkMsgObj.t139_color = this.price_floor_color
                    } else {
                        stkMsgObj.t139_color = this.price_basic_less
                    }
                }

                const t340 = msgObj['t340'],
                    t336 = msgObj['t336']
                let t340_code = '',
                    t340_nm = ''
                if (t340 === '0' || t340 === '90') {
                    t340_code = 'BEFORE_OPEN'
                } else if (t340 === '2' || t340 === '3' || t340 === '4' || t340 === '6') {
                    t340_code = 'PENDING'
                } else if (t340 === '6' || t340 === '97') {
                    t340_code = 'RELEASE'
                } else if (t340 === '1' || t340 === '5') {
                    if (!t336 || t336.length < 4) {
                        t340_code = 'NONE'
                    } else {
                        const t = t336.substring(4, t336.length)
                        if (t === 'ATO_NML') {
                            t340_code = 'OPEN_ATO'
                        } else if (t === 'CON_NML' || t === 'CON_NEW' || t === 'CON_SPC') {
                            t340_code = 'OPEN_LO'
                        } else if (
                            t === 'AUC_C_NML' ||
                            t === 'AUC_C_NEW' ||
                            t === 'AUC_C_NML_LOC' ||
                            t === 'AUC_C_NEW_LOC'
                        ) {
                            t340_code = 'OPEN_ATC'
                        } else if (t === 'PTH_P_NML') {
                            t340_code = 'CLOSE'
                        } else if (t === 'LIS_BRK_NML') {
                            t340_code = 'BREAK_TIME'
                        } else {
                            t340_code = 'RELEASE'
                        }
                    }
                }

                if (t340_code === 'OPEN_ATO') {
                    t340_nm = 'priceboard_ATO_session' // -- Phiên ATO;
                } else if (t340_code === 'OPEN_ATC') {
                    t340_nm = 'priceboard_ATC_session' // -- Phiên ATC
                } else if (t340_code === 'OPEN_LO') {
                    t340_nm = 'priceboard_Continuous' // -- Phiên liên tục
                } else if (t340_code === 'BREAK_TIME') {
                    t340_nm = 'priceboard_Break_time' // Nghỉ trưa
                } else if (t340_code === 'PENDING') {
                    t340_nm = 'priceboard_Pending' // -- Nghỉ trưa
                } else if (t340_code === 'CLOSE') {
                    t340_nm = 'priceboard_Close' // -- Phiên GDTT
                } else if (t340_code === 'RELEASE') {
                    t340_nm = 'priceboard_Release' // --Hết giờ GD
                } else {
                    t340_nm = 'priceboard_Not_traded' // -- Chưa đến giờ GD
                }
                stkMsgObj.t340 = t340
                stkMsgObj.t336 = t336
                stkMsgObj.t340_nm = t340_nm
                stkMsgObj.t340_code = t340_code

                if (stkMsgObj.U10 === '01') {
                    stkMsgObj.t31_PO = msgObj.U35
                    stkMsgObj.t31_incr_PO = !msgObj.U35 ? null : msgObj.U35 - msgObj.t260
                    stkMsgObj.t31_incr_per_PO = !msgObj.U35 ? null : ((msgObj.U35 - msgObj.t260) * 100) / msgObj.t260
                    stkMsgObj.t32_PO = msgObj.U36
                    stkMsgObj.t631_PO = msgObj.U41
                    stkMsgObj.t2661_PO = msgObj.U40
                    stkMsgObj.t266_PO = msgObj.U39
                    stkMsgObj.t391_PO = msgObj.U37
                    stkMsgObj.t3871_PO = msgObj.U38

                    stkMsgObj.t31_color_PO = !stkMsgObj.t31_PO ? this.price_basic_color : this.getColorTP(stkMsgObj.t31_PO, stkMsgObj)
                    stkMsgObj.t631_color_PO = this.getColorTP(stkMsgObj.t631_PO, stkMsgObj)
                    stkMsgObj.t2661_color_PO = this.getColorTP(stkMsgObj.t2661_PO, stkMsgObj)
                    stkMsgObj.t266_color_PO = this.getColorTP(stkMsgObj.t266_PO, stkMsgObj)
                } else {
                    stkMsgObj.t31_PO = stkMsgObj.t31
                    stkMsgObj.t31_incr_PO = stkMsgObj.t31_incr
                    stkMsgObj.t31_incr_per_PO = stkMsgObj.t31_incr_per
                    stkMsgObj.t32_PO = msgObj.t32
                    stkMsgObj.t631_PO = msgObj.t631
                    stkMsgObj.t2661_PO = msgObj.t2661
                    stkMsgObj.t266_PO = msgObj.t266
                    stkMsgObj.t391_PO = msgObj.t391
                    stkMsgObj.t3871_PO = msgObj.t3871

                    stkMsgObj.t31_color_PO = stkMsgObj.t31_color
                    stkMsgObj.t631_color_PO = stkMsgObj.t631_color
                    stkMsgObj.t2661_color_PO = stkMsgObj.t2661_color
                    stkMsgObj.t266_color_PO = stkMsgObj.t266_color
                }

                this.eventMarket.next({
                    type: eventList.SUB_STOCK,
                    msgKey: msgObj.t55,
                    message: 'SI',
                    isUpdatePriceboard: true
                })
            }
            return
        }

        this.updTP_Msg2MrkInfoMap = (msgObj: I_MsgTPData, msgKey) => {
            // TP,
            // t132_1,
            // t1321_1,
            // t133_1,
            // t1331_1,
            // t132_2,
            // t1321_2,
            // t133_2,
            // t1331_2,
            // t132_3,
            // t1321_3,
            // t133_3,
            // t1331_3
            let stkMsgObj = this.StockMarket[msgKey]
            if (!stkMsgObj) {
                stkMsgObj = new InfoStock()
                stkMsgObj.t55 = msgObj.t55
                stkMsgObj.U10 = msgObj.U10
                this.StockMarket[msgKey] = stkMsgObj
            }

            const obj = this.StockMarket[msgKey]
            obj.TP = msgObj.TP

            // reset giá TP trước set giá mới
            obj['t132_' + 3] = 0
            obj['t132_' + 3 + '_color'] = this.defaultColor
            obj['t1321_' + 3] = 0
            obj['t1321_' + 3 + '_color'] = this.defaultColor
            obj['t133_' + 3] = 0
            obj['t133_' + 3 + '_color'] = this.defaultColor
            obj['t1331_' + 3] = 0
            obj['t1331_' + 3 + '_color'] = this.defaultColor

            obj['t132_' + 2] = 0
            obj['t132_' + 2 + '_color'] = this.defaultColor
            obj['t1321_' + 2] = 0
            obj['t1321_' + 2 + '_color'] = this.defaultColor
            obj['t133_' + 2] = 0
            obj['t133_' + 2 + '_color'] = this.defaultColor
            obj['t1331_' + 2] = 0
            obj['t1331_' + 2 + '_color'] = this.defaultColor

            obj['t132_' + 1] = 0
            obj['t132_' + 1 + '_color'] = this.defaultColor
            obj['t1321_' + 1] = 0
            obj['t1321_' + 1 + '_color'] = this.defaultColor
            obj['t133_' + 1] = 0
            obj['t133_' + 1 + '_color'] = this.defaultColor
            obj['t1331_' + 1] = 0
            obj['t1331_' + 1 + '_color'] = this.defaultColor
            msgObj.TP.forEach((element) => {
                obj['t132_' + element.t556] = element.t132
                obj['t132_' + element.t556 + '_color'] = this.getColorTP(element.t132, obj)
                obj['t1321_' + element.t556] = element.t1321
                obj['t1321_' + element.t556 + '_color'] = obj['t132_' + element.t556 + '_color']
                obj['t133_' + element.t556] = element.t133
                obj['t133_' + element.t556 + '_color'] = this.getColorTP(element.t133, obj)
                obj['t1331_' + element.t556] = element.t1331
                obj['t1331_' + element.t556 + '_color'] = obj['t133_' + element.t556 + '_color']
            })
            this.eventMarket.next({ type: eventList.SUB_STOCK, msgKey, message: 'TP', isUpdateWatchlistTablet: true, isUpdatePriceboard: true })
            return
        }

        this.updPO_Msg2MrkInfoMap = (msgObj, msgKey) => {
            // TP,
            // t132_1,
            // t1321_1,
            // t133_1,
            // t1331_1,
            // t132_2,
            // t1321_2,
            // t133_2,
            // t1331_2,
            // t132_3,
            // t1321_3,
            // t133_3,
            // t1331_3
            let stkMsgObj = this.StockMarket[msgKey]
            if (!stkMsgObj) {
                stkMsgObj = new InfoStock()
                stkMsgObj.t55 = msgObj.t55
                stkMsgObj.U10 = msgObj.U10
                this.StockMarket[msgKey] = stkMsgObj
            }

            const obj = this.StockMarket[msgKey]
            // reset giá PO trước set giá mới
            obj['t132_PO_' + 3] = 0
            obj['t132_PO_' + 3 + '_color'] = this.defaultColor
            obj['t1321_PO_' + 3] = 0
            obj['t1321_PO_' + 3 + '_color'] = this.defaultColor
            obj['t133_PO_' + 3] = 0
            obj['t133_PO_' + 3 + '_color'] = this.defaultColor
            obj['t1331_PO_' + 3] = 0
            obj['t1331_PO_' + 3 + '_color'] = this.defaultColor
            
            obj['t132_PO_' + 2] = 0
            obj['t132_PO_' + 2 + '_color'] = this.defaultColor
            obj['t1321_PO_' + 2] = 0
            obj['t1321_PO_' + 2 + '_color'] = this.defaultColor
            obj['t133_PO_' + 2] = 0
            obj['t133_PO_' + 2 + '_color'] = this.defaultColor
            obj['t1331_PO_' + 2] = 0
            obj['t1331_PO_' + 2 + '_color'] = this.defaultColor
            
            obj['t132_PO_' + 1] = 0
            obj['t132_PO_' + 1 + '_color'] = this.defaultColor
            obj['t1321_PO_' + 1] = 0
            obj['t1321_PO_' + 1 + '_color'] = this.defaultColor
            obj['t133_PO_' + 1] = 0
            obj['t133_PO_' + 1 + '_color'] = this.defaultColor
            obj['t1331_PO_' + 1] = 0
            obj['t1331_PO_' + 1 + '_color'] = this.defaultColor
            if (stkMsgObj.U10 === '01') {
                obj.PO = msgObj.TO
                msgObj.TO.forEach((element) => {
                    obj['t132_PO_' + element.t556] = element.t132
                    obj['t132_PO_' + element.t556 + '_color'] = this.getColorTP(element.t132, obj)
                    obj['t1321_PO_' + element.t556] = element.t1321
                    obj['t1321_PO_' + element.t556 + '_color'] = obj['t132_PO_' + element.t556 + '_color']
                    obj['t133_PO_' + element.t556] = element.t133
                    obj['t133_PO_' + element.t556 + '_color'] = this.getColorTP(element.t133, obj)
                    obj['t1331_PO_' + element.t556] = element.t1331
                    obj['t1331_PO_' + element.t556 + '_color'] = obj['t133_PO_' + element.t556 + '_color']
                })
            } else {
                obj.PO = msgObj.TP
                msgObj.TP.forEach((element) => {
                    obj['t132_PO_' + element.t556] = element.t132
                    obj['t132_PO_' + element.t556 + '_color'] = this.getColorTP(element.t132, obj)
                    obj['t1321_PO_' + element.t556] = element.t1321
                    obj['t1321_PO_' + element.t556 + '_color'] = obj['t132_PO_' + element.t556 + '_color']
                    obj['t133_PO_' + element.t556] = element.t133
                    obj['t133_PO_' + element.t556 + '_color'] = this.getColorTP(element.t133, obj)
                    obj['t1331_PO_' + element.t556] = element.t1331
                    obj['t1331_PO_' + element.t556 + '_color'] = obj['t133_PO_' + element.t556 + '_color']
                })
            }

            this.eventMarket.next({ type: eventList.SUB_STOCK, msgKey, message: 'PO', isUpdateWatchlistTablet: true, isUpdatePriceboard: true })
            return
        }

        this.getColorTP = (value, item) => {
            // REF__COLOR: '#f1c40f',
            // UP__COLOR: '#2ECC71',
            // DOWN__COLOR: '#EB5C55',
            // CEIL__COLOR: '#f618fb',
            // FLOOR__COLOR: '#00d3b8',
            if (!value) return this.defaultColor
            if (!item.t333) return this.defaultColor
            if (value === 777777710000 || value === 777777720000) return this.defaultColor
            if (value > 0 && value > item.t333 && value < item.t260) return this.price_basic_less
            else if (value > 0 && value < item.t332 && value > item.t260) return this.price_basic_over
            else if (value === item.t260) return this.price_basic_color
            else if (value >= item.t332) return this.price_ceil_color
            else if (value <= item.t333) return this.price_floor_color
        }
        this.getDataMsgTP = (msgObj, index, key) => {
            // !!! {Dung} Check lại xem có nên xóa hay không
            let ls_currVal
            if (!msgObj['TP'][index] || !msgObj['TP'][index][key]) {
                ls_currVal = 0
            } else {
                ls_currVal = msgObj['TP'][0][key]
            }
            return ls_currVal
        }

        this.setColorPriceTP = (stkMsgObj, key) => {
            // !!! {Dung} Check lại xem có nên xóa hay không
            if (stkMsgObj[key] === stkMsgObj.t260) {
                stkMsgObj[key + '_color'] = this.price_basic_color
            } else if (stkMsgObj[key] > stkMsgObj.t260) {
                if (stkMsgObj[key] === stkMsgObj.t332) {
                    stkMsgObj[key + '_color'] = this.price_ceil_color
                } else {
                    stkMsgObj[key + '_color'] = this.price_basic_over
                }
            } else if (stkMsgObj[key] < stkMsgObj.t260) {
                if (stkMsgObj[key] === stkMsgObj.t333) {
                    stkMsgObj[key + '_color'] = this.price_floor_color
                } else {
                    stkMsgObj[key + '_color'] = this.price_basic_less
                }
            }
        }
        // TODO: Refactor updI_Msg2MrkInfoMap
        this.updI_Msg2MrkInfoMap = (msgObj: I_MsgIData = {
            t2: undefined,
            seq: undefined
        }) => {
            // const timeServer = msgObj.t52.substr(0, 8)
            const msgKey = msgObj.t2
            const calTime = msgObj['t4']
            const indexValue = Math.round(msgObj['t3'] * 100) / 100
            const indexValueChang = Number(msgObj['t5'])
            const ref = msgObj['t3'] - msgObj['t5']
            const indexRatioChang = Math.round(msgObj['t6'] * 100) / 100
            const indexTotalQty = Number(msgObj['t7'])
            const indexTotalValue = Number(msgObj['t14'])
            const indexVolume = Number(msgObj['U12'])
            const seq = msgObj.seq

            this.mrkIndex_MsgMap.set(msgKey.toUpperCase(), msgObj)

            if (!this.IndexMarket[msgKey]) this.IndexMarket[msgKey] = new InfoIndex()
            if (!calTime) {
                this.IndexMarket[msgKey].ref = ref
                this.IndexMarket[msgKey].indexValue = indexValue
                this.IndexMarket[msgKey].indexCode = msgKey
                // this.IndexMarket[msgKey].indexName = msgObj.t18;
                // this.IndexMarket[msgKey].t18 = msgObj.t18;
                this.IndexMarket[msgKey].totalStock = msgObj['t22']
                this.IndexMarket[msgKey].indexOpen = msgObj['U27']
                this.eventMarket.next({ type: eventList.SUB_INDEX, msgKey })
                return
            }

            let newIndexNode
            if (calTime && calTime.length >= 8) {
                // const time = (new Date(this.cvStringToDate(timeServer) + 'T' + calTime.slice(0, 8))).getTime();
                const time = moment(glb_sv.timeServer + '-' + calTime, 'YYYYMMDD-HH:mm:ss:SSSSSS').valueOf()
                if (indexVolume > 0) {
                    newIndexNode = {
                        // ...msgObj,
                        // seq: seq
                        time,
                        C: indexValue,
                        ratio: indexRatioChang,
                        V: indexVolume,
                        S: msgKey,
                        T: glb_sv.timeServer + '-' + calTime.slice(0, 8),
                    }
                    // console.log('glb_sv.timeServer', glb_sv.timeServer)
                }
            }

            this.IndexMarket[msgKey].timeI = calTime
            if (!this.IndexMarket[msgKey].ref) {
                this.IndexMarket[msgKey].ref = ref
            }
            this.IndexMarket[msgKey].indexValue = indexValue
            this.IndexMarket[msgKey].indexValueChang = indexValueChang
            this.IndexMarket[msgKey].indexRatioChang = indexRatioChang
            // * Việc check > Để đề phòng 1 số trường hợp server trả sai, dẫn đến dữ liệu cuối ngày hiển thị sai.
            // * (Hiếm gặp nhưng đã từng xảy ra trong quá khứ)
            this.IndexMarket[msgKey].indexTotalQty =
                this.IndexMarket[msgKey].indexTotalQty > indexTotalQty
                    ? this.IndexMarket[msgKey].indexTotalQty
                    : indexTotalQty // Nếu lớn hơn mới set lại
            this.IndexMarket[msgKey].indexTotalValue =
                this.IndexMarket[msgKey].indexTotalValue > indexTotalValue
                    ? this.IndexMarket[msgKey].indexTotalValue
                    : indexTotalValue // !!! {Dung} Tìm cách hay hơn
            this.IndexMarket[msgKey].indexCode = msgKey
            // this.IndexMarket[msgKey].indexName = msgObj.t18;
            this.IndexMarket[msgKey].indexHighest = msgObj['t24']
            this.IndexMarket[msgKey].indexLowest = msgObj['t25']
            this.IndexMarket[msgKey].totalStock = msgObj['t22']
            this.IndexMarket[msgKey].indexOpen = msgObj['U27']
            this.IndexMarket[msgKey].timeServer = glb_sv.timeServer
            this.IndexMarket[msgKey].lastSeq = seq

            if (newIndexNode) {
                // const objDuplicate = this.IndexMarket[msgKey].indexArr.some(e => e.seq === newIndexNode.seq && e.subseq === newIndexNode.subseq);
                // console.log("globalService -> this.updI_Msg2MrkInfoMap -> objDuplicate", objDuplicate)
                // if (objDuplicate) {
                //   return;
                // }; // nếu trùng seq thì bỏ qua
                this.IndexMarket[msgKey].indexArr?.push(newIndexNode)
                // console.log('indexArr', glb_sv.timeServer ,newIndexNode)
                this.IndexMarket[msgKey].arrMinutes?.push(newIndexNode)
                this.IndexMarket.setIndexData({
                    key: msgKey,
                    time: '1D',
                    topic: 'INTRADAY_1m',
                    data: newIndexNode,
                    mode: 'append',
                })
            }

            this.eventMarket.next({ type: eventList.SUB_INDEX, msgKey, newIndexNode })
            return
        }
        // TODO: Refactor updI_MsgHistory
        this.updI_MsgHistory = (msgObj: { t2: any } = {
            t2: undefined
        }, isDone) => {
            const msgKey = msgObj.t2
            if (!msgKey) return
            const calTime = msgObj['t4']
            const timeServer = this.IndexMarket[msgKey].timeServer
            const indexValue = Math.round(msgObj['t3'] * 100) / 100
            const ref = this.IndexMarket[msgKey].ref
            const indexRatioChang = Math.round(((indexValue - ref) * 10000) / ref) / 100
            const indexVolume = Number(msgObj['U12'])

            let newIndexNode
            if (calTime && calTime.length >= 8) {
                const time = moment(timeServer + '-' + calTime, 'YYYYMMDD-HH:mm:ss:SSSSSS').valueOf()
                // const time = (new Date(this.cvStringToDate(timeServer) + 'T' + calTime.slice(0, 8))).getTime();
                if (indexVolume <= 0) {
                    if (this.timeoutINDEXMSG) clearTimeout(this.timeoutINDEXMSG)
                    this.timeoutINDEXMSG = setTimeout(() => {
                        this.eventMarket.next({ type: eventList.SUB_INDEX, msgKey, isHistory: true })
                    }, 100)
                    return
                } else {
                    newIndexNode = {
                        ...msgObj,
                        time,
                        t31: indexValue,
                        indexRatioChang,
                        t32: indexVolume,
                        ref,
                        type: msgKey,
                        t52: timeServer + '-' + calTime.slice(0, 8),
                    }
                }
            } else if (indexVolume === 0 && msgObj['seq'] === 0 && msgObj['subseq'] === 0) {
                if (isDone) {
                    this.IndexMarket[msgKey].indexArr.sort(function (a, b) {
                        if (a.seq > b.seq) return 1
                        if (a.seq < b.seq) return -1
                        if (a.seq === b.seq) {
                            if (a.subseq > b.subseq) return 1
                            if (a.subseq < b.subseq) return -1
                        }
                        return 0
                    })

                    this.eventMarket.next({ type: eventList.SUB_INDEX, msgKey, isHistory: true })
                    return
                }
            }
            if (newIndexNode) {
                this.IndexMarket[msgKey].indexArr.push(newIndexNode)
            }
            if (isDone) {
                this.IndexMarket[msgKey].indexArr.sort(function (a, b) {
                    if (a.seq > b.seq) return 1
                    if (a.seq < b.seq) return -1
                    if (a.seq === b.seq) {
                        if (a.subseq > b.subseq) return 1
                        if (a.subseq < b.subseq) return -1
                    }
                    return 0
                })

                this.eventMarket.next({ type: eventList.SUB_INDEX, msgKey, isHistory: true })
            }
            return
        }

        this.updEP_Msg2MrkInfoMap = (msgObj: I_MsgEPData = {
            t55: undefined,
            seq: undefined,
            subseq: undefined
        }) => {
            if (msgObj['t33'] !== 'M1' && msgObj['t33'] !== 'M') return
            if (!msgObj.t55) return
            const autionTimePrcObjChart = this.StockMarket[msgObj.t55].EP
            // if (!msgObj.subseq) msgObj.subseq = 0 // lỗi trùng do HNX, UPCOM k trả subseq
            const objFind = autionTimePrcObjChart.find((o) => o.seq === msgObj.seq && o.subseq === msgObj.subseq)
            if (objFind) {
                return
            }
            autionTimePrcObjChart.push(msgObj)
            this.eventMarket.next({
                type: eventList.SUB_STOCK,
                newIndexNode: msgObj,
                msgKey: msgObj.t55,
                message: 'EP',
            })
            return
        }
        // TODO: Refactor updBI_Msg2MrkInfoMap
        this.updBI_Msg2MrkInfoMap = (msgObj: I_MsgBIData = {
            t240: undefined,
            t241: undefined,
            t210: undefined,
            t211: undefined,
            t220: undefined,
            t221: undefined,
            t52: undefined
        }) => {
            const t340 = msgObj['t340'],
                t336 = msgObj['t336']
            let t340_code = '',
                t340_nm = ''
            if (t340 === '0' || t340 === '90') {
                t340_code = 'BEFORE_OPEN'
            } else if (t340 === '2' || t340 === '3' || t340 === '4' || t340 === '6') {
                t340_code = 'PENDING'
            } else if (t340 === '6' || t340 === '97') {
                t340_code = 'RELEASE'
            } else if (t340 === '1' || t340 === '5') {
                if (!t336 || t336.length < 4) {
                    t340_code = 'NONE'
                } else {
                    const t = t336.substring(4, t336.length)
                    if (t === 'ATO_NML') {
                        t340_code = 'OPEN_ATO'
                    } else if (t === 'CON_NML' || t === 'CON_NEW' || t === 'CON_SPC') {
                        t340_code = 'OPEN_LO'
                    } else if (
                        t === 'AUC_C_NML' ||
                        t === 'AUC_C_NEW' ||
                        t === 'AUC_C_NML_LOC' ||
                        t === 'AUC_C_NEW_LOC'
                    ) {
                        t340_code = 'OPEN_ATC'
                    } else if (t === 'PTH_P_NML') {
                        t340_code = 'CLOSE'
                    } else if (t === 'LIS_BRK_NML') {
                        t340_code = 'BREAK_TIME'
                    } else {
                        t340_code = 'RELEASE'
                    }
                }
            }
            if (t340_code === 'OPEN_ATO') {
                t340_nm = 'priceboard_ATO_session' // -- Phiên ATO;
            } else if (t340_code === 'OPEN_ATC') {
                t340_nm = 'priceboard_ATC_session' // -- Phiên ATC
            } else if (t340_code === 'OPEN_LO') {
                t340_nm = 'priceboard_Continuous' // -- Phiên liên tục
            } else if (t340_code === 'BREAK_TIME') {
                t340_nm = 'priceboard_Break_time' // Nghỉ trưa
            } else if (t340_code === 'PENDING') {
                t340_nm = 'priceboard_Pending' // -- Nghỉ trưa
            } else if (t340_code === 'CLOSE') {
                t340_nm = 'priceboard_Close' // -- Phiên GDTT
            } else if (t340_code === 'RELEASE') {
                t340_nm = 'priceboard_Release' // --Hết giờ GD
            } else {
                t340_nm = 'priceboard_Not_traded' // -- Chưa đến giờ GD
            }

            // TODO {Dung} Refactor lại đoạn này trở xuống
            // -----------------------------------------------------
            if (msgObj['U10'] === '03' || msgObj['U10'] === '05') {
                if (msgObj['t341'].toUpperCase() === 'LIS') {
                    if (!this.IndexMarket['HNXIndex']) this.IndexMarket['HNXIndex'] = new InfoIndex()
                    if (!this.IndexMarket['LIS_BRD_BOND']) this.IndexMarket['LIS_BRD_BOND'] = new InfoIndex()
                    if (!this.IndexMarket['HNX30']) this.IndexMarket['HNX30'] = new InfoIndex()
                    const oldStatus = this.IndexMarket['HNXIndex'].t340_code
                    this.IndexMarket['HNXIndex'].t340 = t340
                    this.IndexMarket['HNXIndex'].t340_code = t340_code
                    this.IndexMarket['HNXIndex'].t340_nm = t340_nm
                    this.IndexMarket['HNX30'].t340 = t340
                    this.IndexMarket['HNX30'].t340_code = t340_code
                    this.IndexMarket['HNX30'].t340_nm = t340_nm
                    if (msgObj['t341'] === 'LIS') {
                        if (msgObj['U8'] === 'HNX|BI|LIS_BRD_01') {
                            this.IndexMarket['HNXIndex']['increase'] = msgObj['t251']
                            this.IndexMarket['HNXIndex']['equaCeil'] = msgObj['U35'] // tăng trần
                            // this.IndexMarket['HNXIndex']['noChange'] = (this.HNX_PRC_LIST.length === 0 ? 0 : (this.HNX_PRC_LIST.length - (msgObj['t251'] + msgObj['t253'])));
                            this.IndexMarket['HNXIndex']['decrease'] = msgObj['t253']
                            this.IndexMarket['HNXIndex']['equaFloor'] = msgObj['U36'] // giảm sàn
                            this.IndexMarket['HNXIndex'].qtyBiglot = msgObj.t240 // t240 KLGD Thỏa thuận
                            this.IndexMarket['HNXIndex'].valueBiglot = msgObj.t241 // t241 GTGD Thỏa thuận
                            this.IndexMarket['HNXIndex'].qtyOddlot = msgObj.t210 // t210 KLGD Lô lẻ
                            this.IndexMarket['HNXIndex'].valueOddlot = msgObj.t211 // t211 GTGD Lô lẻ
                            this.IndexMarket['HNXIndex'].indexTotalQty = msgObj.t220 // t220 KLGD Thông thường
                            this.IndexMarket['HNXIndex'].indexTotalValue = msgObj.t221 // t221 GTGD Thông thường
                            // console.log('t341 == LIS -> HNXindex', msgObj)
                            // TODO {Dung} đoạn này xem lại
                            this.IndexMarket.setIndexData({
                                key: 'HNXIndex',
                                time: '1D',
                                topic: 'MDDS|BI',
                                data: msgObj,
                                mode: 'append',
                            })
                            this.IndexMarket.setIndexData({
                                key: 'LIS_BRD_01',
                                time: '1D',
                                topic: 'MDDS|BI',
                                data: msgObj,
                                mode: 'append',
                            })
                            // console.log(msgObj);
                        } else if (msgObj['U8'] === 'HNX|BI|LIS_BRD_BOND') {
                            this.IndexMarket['LIS_BRD_BOND'].qtyBiglot = msgObj.t240 // t240 KLGD Thỏa thuận
                            this.IndexMarket['LIS_BRD_BOND'].valueBiglot = msgObj.t241 // t241 GTGD Thỏa thuận
                            this.IndexMarket['LIS_BRD_BOND'].qtyOddlot = msgObj.t210 // t210 KLGD Lô lẻ
                            this.IndexMarket['LIS_BRD_BOND'].valueOddlot = msgObj.t211 // t211 GTGD Lô lẻ
                            this.IndexMarket['LIS_BRD_BOND'].indexTotalQty = msgObj.t220 // t220 KLGD Thông thường
                            this.IndexMarket['LIS_BRD_BOND'].indexTotalValue = msgObj.t221 // t221 GTGD Thông thường
                        }
                    } else {
                        // -- cac chi so khac
                        // this.IndexMarket[msgKey].timeIndex = msgObj.t52;
                        // this.IndexMarket[msgKey].tradStatus = t340_nm;
                        // this.eventMarket.next({ type: eventList.SUB_INDEX, msgKey, isTradStatus: true })
                    }
                    this.IndexMarket['HNXIndex'].timeIndex = msgObj.t52
                    this.IndexMarket['HNXIndex']['tradStatus'] = t340_nm
                    this.eventMarket.next({ type: eventList.SUB_INDEX, msgKey: 'HNXIndex', isTradStatus: true, message: 'BI' })
                    this.IndexMarket['HNX30'].timeIndex = msgObj.t52
                    this.IndexMarket['HNX30']['tradStatus'] = t340_nm
                    this.eventMarket.next({ type: eventList.SUB_INDEX, msgKey: 'HNX30', isTradStatus: true, message: 'BI' })
                    if (oldStatus && oldStatus !== t340_code) {
                        this.commonEvent.next({
                            type: eventList.PRC_BOARD_STATUS,
                            data: t340_nm,
                            data1: 'HNX',
                        })
                    }
                } else if (msgObj['t341'].toUpperCase() === 'UPC') {
                    // console.log('t341 == UPC -> HNXUpcomIndex', msgObj)
                    const oldStatus = this.IndexMarket['HNXUpcomIndex'].t340_code
                    this.IndexMarket['HNXUpcomIndex'].t340 = t340
                    this.IndexMarket['HNXUpcomIndex'].t340_code = t340_code
                    this.IndexMarket['HNXUpcomIndex'].t340_nm = t340_nm

                    if (msgObj['t341'] === 'UPC') {
                        this.IndexMarket['HNXUpcomIndex']['increase'] = msgObj['t251']
                        this.IndexMarket['HNXUpcomIndex']['equaCeil'] = msgObj['U35'] // tăng trần
                        // this.IndexMarket['HNXUpcomIndex']['noChange'] = (this.UPC_PRC_LIST.length === 0 ? 0 : (this.UPC_PRC_LIST.length - (msgObj['t251'] + msgObj['t253'])));
                        this.IndexMarket['HNXUpcomIndex']['decrease'] = msgObj['t253']
                        this.IndexMarket['HNXUpcomIndex']['equaFloor'] = msgObj['U36'] // giảm sàn
                        this.IndexMarket['HNXUpcomIndex'].qtyBiglot = msgObj.t240 // t240 KLGD Thỏa thuận
                        this.IndexMarket['HNXUpcomIndex'].valueBiglot = msgObj.t241 // t241 GTGD Thỏa thuận
                        this.IndexMarket['HNXUpcomIndex'].qtyOddlot = msgObj.t210 // t240 KLGD Lô lẻ
                        this.IndexMarket['HNXUpcomIndex'].valueOddlot = msgObj.t211 // t241 GTGD Lô lẻ
                        this.IndexMarket['HNXUpcomIndex'].indexTotalQty = msgObj.t220 // t220 KLGD Thông thường
                        this.IndexMarket['HNXUpcomIndex'].indexTotalValue = msgObj.t221 // t221 GTGD Thông thường
                        // TODO {Dung} đoạn này xem lại
                        // console.log('MDDS|BI', msgObj)
                        this.IndexMarket.setIndexData({
                            key: 'HNXUpcomIndex',
                            time: '1D',
                            topic: 'MDDS|BI',
                            data: msgObj,
                            mode: 'append',
                        })
                        // UPC_BRD_01 nếu BI UPC thì set vào mảng của UPC_BRD_01 luôn
                        this.IndexMarket.setIndexData({
                            key: 'UPC_BRD_01',
                            time: '1D',
                            topic: 'MDDS|BI',
                            data: msgObj,
                            mode: 'append',
                        })
                    } else {
                        // -- cacs chi so khac
                    }
                    this.IndexMarket['HNXUpcomIndex'].timeIndex = msgObj.t52
                    this.IndexMarket['HNXUpcomIndex']['tradStatus'] = t340_nm
                    this.eventMarket.next({ type: eventList.SUB_INDEX, msgKey: 'HNXUpcomIndex', isTradStatus: true, message: 'BI' })
                    if (oldStatus && oldStatus !== t340_code) {
                        this.commonEvent.next({
                            type: eventList.PRC_BOARD_STATUS,
                            data: t340_nm,
                            data1: 'UPCOM',
                        })
                    }
                } else if (msgObj['t341'].toUpperCase() === 'HNX') {
                    if (msgObj['U8'].toUpperCase() === 'HNX|BI|HNX30') {
                        this.IndexMarket.HNX30['increase'] = msgObj['t251']
                        this.IndexMarket.HNX30['equaCeil'] = msgObj['U35'] // tăng trần
                        this.IndexMarket.HNX30['noChange'] = 30 - (msgObj['t251'] + msgObj['t253'])
                        this.IndexMarket.HNX30['totalStock'] = 30
                        // this.IndexMarket.HNX30['indexName'] = msgObj['t421']
                        this.IndexMarket.HNX30['decrease'] = msgObj['t253']
                        this.IndexMarket.HNX30['equaFloor'] = msgObj['U36'] // giảm sàn
                        this.eventMarket.next({ type: eventList.SUB_INDEX, msgKey: 'HNX30', isTradStatus: true, message: 'BI' })
                    }
                }
            } else if (msgObj['U10'] === '01' && msgObj['U8']) {
                if (msgObj['U8'].toUpperCase() === 'HSX|BI|HSXINDEX') {
                    // check xem msgKey co ton tai trong IndexMarket
                    const msgKey = 'HSXIndex'
                    if (!this.IndexMarket[msgKey]) this.IndexMarket[msgKey] = new InfoIndex()
                    if (!this.IndexMarket['VN30']) this.IndexMarket['VN30'] = new InfoIndex()
                    const oldStatus = this.IndexMarket[msgKey].t340_code
                    this.IndexMarket[msgKey].t340 = t340
                    this.IndexMarket[msgKey].t340_code = t340_code
                    this.IndexMarket[msgKey].t340_nm = t340_nm

                    this.IndexMarket[msgKey]['increase'] = msgObj['t251']
                    this.IndexMarket[msgKey]['equaCeil'] = msgObj['U35'] // tăng trần
                    this.IndexMarket[msgKey]['decrease'] = msgObj['t253']
                    this.IndexMarket[msgKey]['equaFloor'] = msgObj['U36'] // giảm sàn
                    this.IndexMarket[msgKey]['tradStatus'] = t340_nm
                    this.IndexMarket['VN30']['tradStatus'] = t340_nm
                    this.IndexMarket[msgKey].timeIndex = msgObj.t52

                    if (msgKey === 'HSXIndex') {
                        this.IndexMarket['HSXIndex'].qtyBiglot = msgObj.t240 // t240 KLGD Thỏa thuận
                        this.IndexMarket['HSXIndex'].valueBiglot = msgObj.t241 // t241 GTGD Thỏa thuận
                        this.IndexMarket['HSXIndex'].qtyOddlot = msgObj.t210 || 0 // t240 KLGD Lô lẻ
                        this.IndexMarket['HSXIndex'].valueOddlot = msgObj.t211 || 0 // t241 GTGD Lô lẻ
                        this.IndexMarket['HSXIndex'].indexTotalQty = msgObj.t220 // t220 KLGD Thông thường
                        this.IndexMarket['HSXIndex'].indexTotalValue = msgObj.t221 // t221 GTGD Thông thường

                        // TODO {Dung} đoạn này xem lại
                        this.IndexMarket.setIndexData({
                            key: 'HSXIndex',
                            time: '1D',
                            topic: 'MDDS|BI',
                            data: msgObj,
                            mode: 'append',
                        })
                    }

                    this.eventMarket.next({ type: eventList.SUB_INDEX, msgKey, isTradStatus: true, message: 'BI' })
                    if (oldStatus && oldStatus !== t340_code) {
                        this.commonEvent.next({
                            type: eventList.PRC_BOARD_STATUS,
                            data: t340_nm,
                            data1: 'HOSE',
                        })
                    }
                } else if (msgObj['U8'].toUpperCase() === 'HSX|BI|VN30') {
                    if (!this.IndexMarket.VN30) this.IndexMarket.VN30 = new InfoIndex()
                    this.IndexMarket.VN30['increase'] = msgObj['t251']
                    this.IndexMarket.VN30['equaCeil'] = msgObj['U35'] // tăng trần
                    this.IndexMarket.VN30['noChange'] = 30 - (msgObj['t251'] + msgObj['t253'])
                    this.IndexMarket.VN30['decrease'] = msgObj['t253']
                    this.IndexMarket.VN30['equaFloor'] = msgObj['U36'] // giảm sàn
                    this.eventMarket.next({ type: eventList.SUB_INDEX, msgKey: 'VN30', isTradStatus: true, message: 'BI' })
                } else if (msgObj['U8'].toUpperCase() === 'HSX|BI|VNXALL') {
                    if (!this.IndexMarket.VNXALL) this.IndexMarket.VNXALL = new InfoIndex()
                    this.IndexMarket.VNXALL['increase'] = msgObj['t251']
                    this.IndexMarket.VNXALL['equaCeil'] = msgObj['U35'] // tăng trần
                    this.IndexMarket.VNXALL['noChange'] = 30 - (msgObj['t251'] + msgObj['t253'])
                    this.IndexMarket.VNXALL['decrease'] = msgObj['t253']
                    this.IndexMarket.VNXALL['equaFloor'] = msgObj['U36'] // giảm sàn
                    this.eventMarket.next({ type: eventList.SUB_INDEX, msgKey: 'VNXALL', isTradStatus: true, message: 'BI' })
                }
            } else {
            }
            // ------------------------------------------------------
        }
        // TODO: Refactor updAA_Msg2MrkInfoMap
        this.updAA_Msg2MrkInfoMap = (msgObj = {}) => {
            // if (
            //   msgObj['ClientSeq'] === 0 ||
            //   msgObj['ClientSeq'] == null ||
            //   msgObj['ClientSeq'] === undefined
            // ) {
            //   let msgInfo = '', AAFlag = '', sbTp = '';
            //   if (msgObj['U19'] === 'C') {
            //     AAFlag = '[Hủy]';
            //   } else {
            //     AAFlag = '[Mới]';
            //   }
            //   if (msgObj['U14'] === 'S') {
            //     sbTp = 'Quảng cáo Bán';
            //   } else {
            //     sbTp = 'Quảng cáo Mua';
            //   }
            //   if (msgObj['U10'] === '01') {
            //     msgInfo = 'Thông báo ' + AAFlag + ' lệnh quảng cáo sở GDCK HOSE';
            //   } else {
            //     msgInfo = 'Thông báo ' + AAFlag + ' lệnh quảng cáo sở GDCK HNX';
            //   }
            //   msgInfo = msgInfo + '. ' + sbTp + ', từ thành viên: ' + msgObj['U18'];
            //   const splitted = msgObj['U8'].split('|', 3);
            //   const stkCd = splitted[2];
            //   msgInfo = msgInfo + '. ' + 'Mã CK: ' + stkCd + ', ' + 'KL: ' +
            //     FormatNumber(Number(msgObj['U16'])) +
            //     ', ' + 'giá: ' +
            //     FormatNumber(Number(msgObj['U17'])) +
            //     '. ' + 'Vui lòng liên hệ: ' + msgObj['U15'];
            //   const toastObj = { key: 'ADVERT_ORDER', msg: msgInfo };
            //   this.event_ToastCommonInfo.next(toastObj);
            // }
            // if (msgObj != null && msgObj.length > 0) {
            //   this.mrkInfo['advert_order'].push(msgObj);
            // }
            // return;
            this.commonEvent.next({
                type: eventList.ADVERT_ORDER,
                data: msgObj,
            })
            return
        }
        // TODO: Refactor updTS_Msg2MrkInfoMap: Hoặc xóa
        this.updTS_Msg2MrkInfoMap = (msgObj = {}) => {
            // this.mrkInfo['trad_dt'] = msgObj['t388'];
            // this.mrkInfo['trad_tm'] = msgObj['t399'];
            // const workDt = msgObj['t388'], timee = msgObj['t399']; // 11:20:15
            // Tạm thời chỉ lấy time theo client
            // if (workDt && timee) {
            //   this.orgTime = new Date(Number(workDt.substr(0, 4)), Number(workDt.substr(4, 2)) - 1, Number(workDt.substr(6, 2)),
            //     Number(timee.substr(0, 2)), Number(timee.substr(3, 2)), Number(timee.substr(6, 2)));
            // }
            // return;
        }
        //-- End process a message from MKT_INFO chanel - auto pushed by server

        this.setReqInfoMapValue = (key = 0, valObj= {}): void => {
            this.reqInfoMap.set(key, valObj)
        }

        this.getReqInfoMapValue = (key) => {
            return this.reqInfoMap.get(key)
        }

        this.convUnixTime2StrDt = (unixTm, DateTp) => {
            const x = new Date(unixTm * 1000)
            const y = x.getFullYear()
            const m = x.getMonth() + 1
            const sm = ('0' + m).slice(-2)
            const d = x.getDate()
            const sd = ('0' + d).slice(-2)
            let result = y + '' + sm + '' + sd
            if (DateTp === 2) {
                const hours = '0' + x.getHours()
                const minutes = '0' + x.getMinutes()
                const seconds = '0' + x.getSeconds()
                result = result + '' + hours.substr(-2) + '' + minutes.substr(-2) + '' + seconds.substr(-2)
            }
            return result
        }

        this.convStrDt2UnixTime = (strTime) => {
            try {
                const y = Number(strTime.substr(0, 4))
                const m = Number(strTime.substr(4, 2)) - 1
                const d = Number(strTime.substr(6, 2))
                const dates = new Date(y, m, d, 15, 0, 0)
                const unixtime = dates.getTime() / 1000
                // const unixtime = Number(dates.toISOString())
                return unixtime
            } catch (error) {
                // this.logMessage('Error at convStrDt2UnixTime: ' + error);
                return -1
            }
        }

        this.convStrDtime2UnixTime = (strTime) => {
            try {
                //example: strTime = 2020 05 22 10 01 02
                const y = Number(strTime.substr(0, 4))
                const m = Number(strTime.substr(4, 2)) - 1
                const d = Number(strTime.substr(6, 2))
                const h = Number(strTime.substr(8, 2))
                const mi = Number(strTime.substr(10, 2))
                const s = Number(strTime.substr(12, 2))
                const dates = new Date(y, m, d, h, mi, s)
                const unixtime = dates.getTime() / 1000
                return unixtime
            } catch (error) {
                // this.logMessage('Error at convStrDt2UnixTime: ' + error);
                return -1
            }
        }

        this.searchStk2Scroll = (div_table_prcb_id, table_priceP_id, table_priceP_head_id, value) => {
            if (value && value.length > 2) {
                const searchNb = this.stkListPrb.findIndex((o) => o.t55.substr(0, value.length) === value)
                if (searchNb >= 0) {
                    const divTable = document.getElementById(table_priceP_id)
                    const table_head = document.getElementById(table_priceP_head_id)
                    const div_parent_table_prcb = document.getElementById(div_table_prcb_id)
                    if (!divTable || !table_head || !div_parent_table_prcb) return
                    const elvTableH = divTable.offsetHeight
                    const elvTableHeadH = table_head.offsetHeight
                    let rowHeight = (elvTableH - elvTableHeadH) / this.stkListPrb.length
                    if (rowHeight < 26.88) {
                        rowHeight = 26.88
                    }
                    div_parent_table_prcb.scrollTop = searchNb * rowHeight
                    console.log('divTable', elvTableH, 'table_head', elvTableHeadH, searchNb, rowHeight)
                }
            }
        }

        this.convDate2StrDt = (Datt) => {
            const y2 = Datt.getFullYear()
            const m2 = Datt.getMonth() + 1
            const sm = ('0' + m2).slice(-2)
            const d2 = Datt.getDate()
            const sd = ('0' + d2).slice(-2)
            const result = y2 + '' + sm + '' + sd
            return result
        }

        this.replaceStrToHTML = (str: string, isTransformBr = true) => {
            if (!str || str === undefined) return ''
            let result = '',
                i = 0,
                stringSlug = str
            stringSlug = stringSlug.replaceAll(/\r?\n|\r|\n/g, isTransformBr ? '</br>' : '')
            stringSlug = stringSlug.replaceAll(/\\\\r?\\\\n|\\\\r|\\\\n|\\\\t/g, isTransformBr ? '</br>' : '')
            for (i = 0; i < stringSlug.length; i++) {
                let tt = stringSlug.substr(i, 1)
                const ascII = tt.charCodeAt(0)
                if (ascII <= 31) {
                    tt = ''
                }
                if (ascII === 4) {
                    tt = "'|'"
                } // -- EOT
                result = result + tt
            }
            return result
        }

        this.filterStrBfParse = (str) => {
            if (!str || str === undefined) return ''
            let result = '',
                i = 0
            for (i = 0; i < str.length; i++) {
                let tt = str.substr(i, 1)
                const ascII = tt.charCodeAt(0)
                if (ascII <= 31) {
                    tt = ''
                }
                if (ascII === 4) {
                    tt = "'|'"
                } // -- EOT
                result = result + tt
            }
            return result
        }

        this.logMessage = (flag = false, ...message) => {
            if (flag) {
                const now = new Date()
                console.log(`Time: ${now.toLocaleTimeString()}.${now.getMilliseconds()}>`, ...message)
            }
            return
        }

        this.filterNumber = (numberstr) => {
            if (typeof numberstr === 'number') return numberstr
            else if (numberstr != null && numberstr.length > 0) {
                return Number(numberstr.replace(/\D/g, ''))
            }
        }

        // -- thời gian bảng điện
        this.setItervClientTimeFunct = () => {
            if (this.orgTime == null || this.orgTime === undefined) {
                this.orgTime = new Date()
            } else {
                this.orgTime = new Date(this.orgTime.getTime() + 30000)
            }
            const h = this.addZero(this.orgTime.getHours(), 2) + ''
            const m = this.addZero(this.orgTime.getMinutes(), 2) + ''
            this.mrkInfo['trad_time'] = h + ':' + m
        }

        this.addZero = (x, n) => {
            while (x.toString().length < n) {
                x = '0' + x
            }
            return x
        }

        this.convDate2StrTime = (Datt) => {
            const y2 = Datt.getFullYear()
            const m2 = Datt.getMonth() + 1
            const sm = ('0' + m2).slice(-2)
            const d2 = Datt.getDate()
            const sd = ('0' + d2).slice(-2)
            const hr = Datt.getHours() + 1
            const hrs = ('0' + hr).slice(-2)
            const mi = Datt.getMinutes() + 1
            const mis = ('0' + mi).slice(-2)
            const ss = Datt.getSeconds() + 1
            const sss = ('0' + ss).slice(-2)
            const result = y2 + '' + sm + '' + sd + '' + hrs + '' + mis + '' + sss
            return result
        }

        this.convData2TradFormat = (dataArrS) => {
            // -- sort lại time
            const dataArr = dataArrS.sort(function (a, b) {
                return a['c0'] > b['c0'] ? 1 : b['c0'] > a['c0'] ? -1 : 0
            })
            const objData = { t: [], o: [], h: [], l: [], c: [], v: [], s: 'no_data' }
            if (dataArr != null && dataArr !== undefined) {
                let i = 0,
                    uTc = 0
                for (i = 0; i < dataArr.length; i++) {
                    uTc = Number(new Date()) / 1000
                    if (objData['t'].indexOf(uTc) < 0) {
                        // console.log(uTc);
                        objData['t'].push(uTc)
                        objData['o'].push(Number(dataArr[i]['c6']))
                        objData['h'].push(Number(dataArr[i]['c5']))
                        objData['l'].push(Number(dataArr[i]['c4']))
                        objData['c'].push(Number(dataArr[i]['c7']))
                        objData['v'].push(Number(dataArr[i]['c8']))
                    }
                }
                objData['s'] = 'ok'
            }
            // console.log('obj se them vao tradview', objData);
            return objData
        }

        this.dateToChartTimeMinute = (date) => {
            return (
                Date.UTC(
                    date.getFullYear(),
                    date.getMonth(),
                    date.getDate(),
                    date.getHours(),
                    date.getMinutes(),
                    date.getSeconds(),
                    0
                ) / 1000
            )
        }
        this.convertUTCDateToLocalDate = (date) => {
            var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000)

            var offset = date.getTimezoneOffset() / 60
            var hours = date.getHours()

            newDate.setHours(hours + offset)

            return newDate
        }
        this.versionCompare = (v1, v2, options) => {
            var lexicographical = options && options.lexicographical,
                zeroExtend = options && options.zeroExtend,
                v1parts = v1.split('.'),
                v2parts = v2.split('.')

            function isValidPart(x) {
                return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x)
            }

            if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
                return NaN
            }

            if (zeroExtend) {
                while (v1parts.length < v2parts.length) v1parts.push('0')
                while (v2parts.length < v1parts.length) v2parts.push('0')
            }

            if (!lexicographical) {
                v1parts = v1parts.map(Number)
                v2parts = v2parts.map(Number)
            }

            for (var i = 0; i < v1parts.length; ++i) {
                if (v2parts.length == i) {
                    return 1
                }

                if (v1parts[i] == v2parts[i]) {
                    continue
                } else if (v1parts[i] > v2parts[i]) {
                    return 1
                } else {
                    return -1
                }
            }

            if (v1parts.length != v2parts.length) {
                return -1
            }

            return 0
        }

        this.convertNameIndex = (name) => {
            const arr = name.split('|')
            if (arr[2] === 'HSXIndex') return 'HOSE'
            if (arr[2] === 'HNXIndex') return 'HNX'
            if (arr[2] === 'HNXUpcomIndex') return 'UPCOM'
            else return arr[2]
        }

        this.chartConvertIntraday = (data) => {
            const arr = []
            for (let index = 0; index < data.length; index++) {
                const curItem = data[index]
                const prevItem = arr[arr.length - 1]
                const obj = { ...curItem }
                if (!arr.length) {
                    arr.push(obj)
                } else {
                    if (prevItem) {
                        const momentCur = moment(curItem.t52, 'YYYYMMDD-HH:mm:ss')
                        const momentPrev = moment(prevItem.t52, 'YYYYMMDD-HH:mm:ss')
                        if (momentCur.second() === momentPrev.second() && momentCur.minute() === momentPrev.minute()) {
                            if (index === data.length - 1) {
                                const time = moment(obj.t52, 'YYYYMMDD-HH:mm:ss').add(1, 'seconds')
                                obj.t52 = time.format('YYYYMMDD-HH:mm:ss')
                                arr.push(obj)
                            } else prevItem.t32 = prevItem.t32 + curItem.t32
                            continue
                        } else {
                            arr.push(obj)
                        }
                    }
                }
            }
            return arr
        }

        this.chartConvert15Second = (data) => {
            const arr = []
            for (let index = 0; index < data.length; index++) {
                const curItem = data[index]
                const prevItem = arr[arr.length - 1]
                const obj = { ...curItem }
                if (!arr.length) {
                    const second1 = moment(curItem.time).second() >= 0 && moment(curItem.time).second() < 15
                    const second2 = moment(curItem.time).second() >= 15 && moment(curItem.time).second() < 30
                    const second3 = moment(curItem.time).second() >= 30 && moment(curItem.time).second() < 45
                    const time = second1
                        ? moment(obj.time).format('YYYYMMDD HH:mm') + ':00'
                        : second2
                            ? moment(obj.time).format('YYYYMMDD HH:mm') + ':15'
                            : second3
                                ? moment(obj.time).format('YYYYMMDD HH:mm') + ':30'
                                : moment(obj.time).format('YYYYMMDD HH:mm') + ':45'
                    obj.time = moment(time, 'YYYYMMDD HH:mm:ss').valueOf()
                    arr.push(obj)
                } else {
                    if (prevItem) {
                        if (moment(curItem.time).minute() === moment(prevItem.time).minute()) {
                            const secondCur1 = moment(curItem.time).second() >= 0 && moment(curItem.time).second() < 15
                            const secondCur2 = moment(curItem.time).second() >= 15 && moment(curItem.time).second() < 30
                            const secondCur3 = moment(curItem.time).second() >= 30 && moment(curItem.time).second() < 45
                            const secondCur4 = moment(curItem.time).second() >= 45 && moment(curItem.time).second() < 60
                            const secondPrev1 =
                                moment(prevItem.time).second() >= 0 && moment(prevItem.time).second() < 15
                            const secondPrev2 =
                                moment(prevItem.time).second() >= 15 && moment(prevItem.time).second() < 30
                            const secondPrev3 =
                                moment(prevItem.time).second() >= 30 && moment(prevItem.time).second() < 45
                            const secondPrev4 =
                                moment(prevItem.time).second() >= 45 && moment(prevItem.time).second() < 60
                            if (
                                secondCur1 === secondPrev1 ||
                                secondCur2 === secondPrev2 ||
                                secondCur3 === secondPrev3 ||
                                secondCur4 === secondPrev4
                            ) {
                                prevItem.volume = prevItem.volume + curItem.volume
                                prevItem.indexValue = curItem.indexValue
                                continue
                            } else {
                                const second1 = moment(curItem.time).second() >= 0 && moment(curItem.time).second() < 15
                                const second2 =
                                    moment(curItem.time).second() >= 15 && moment(curItem.time).second() < 30
                                const second3 =
                                    moment(curItem.time).second() >= 30 && moment(curItem.time).second() < 45
                                const time = second1
                                    ? moment(obj.time).format('YYYYMMDD HH:mm') + ':00'
                                    : second2
                                        ? moment(obj.time).format('YYYYMMDD HH:mm') + ':15'
                                        : second3
                                            ? moment(obj.time).format('YYYYMMDD HH:mm') + ':30'
                                            : moment(obj.time).format('YYYYMMDD HH:mm') + ':45'
                                obj.time = moment(time, 'YYYYMMDD HH:mm:ss').valueOf()
                                arr.push(obj)
                            }
                        } else {
                            const second1 = moment(curItem.time).second() >= 0 && moment(curItem.time).second() < 15
                            const second2 = moment(curItem.time).second() >= 15 && moment(curItem.time).second() < 30
                            const second3 = moment(curItem.time).second() >= 30 && moment(curItem.time).second() < 45
                            const time = second1
                                ? moment(obj.time).format('YYYYMMDD HH:mm') + ':00'
                                : second2
                                    ? moment(obj.time).format('YYYYMMDD HH:mm') + ':15'
                                    : second3
                                        ? moment(obj.time).format('YYYYMMDD HH:mm') + ':30'
                                        : moment(obj.time).format('YYYYMMDD HH:mm') + ':45'
                            obj.time = moment(time, 'YYYYMMDD HH:mm:ss').valueOf()
                            arr.push(obj)
                        }
                    }
                }
            }
            return arr
        }

        this.chartConvert30Second = (data) => {
            const arr = []
            for (let index = 0; index < data.length; index++) {
                const curItem = data[index]
                const prevItem = arr[arr.length - 1]
                const obj = { ...curItem }
                if (!arr.length) {
                    const second = moment(curItem.time).second() >= 0 && moment(curItem.time).second() < 30
                    const time = second
                        ? moment(obj.time).format('YYYYMMDD HH:mm') + ':00'
                        : moment(obj.time).format('YYYYMMDD HH:mm') + ':30'
                    obj.time = moment(time, 'YYYYMMDD HH:mm:ss').valueOf()
                    arr.push(obj)
                } else {
                    if (prevItem) {
                        if (moment(curItem.time).minute() === moment(prevItem.time).minute()) {
                            const secondCur = moment(curItem.time).second() >= 0 && moment(curItem.time).second() < 30
                            const secondPrev =
                                moment(prevItem.time).second() >= 0 && moment(prevItem.time).second() < 30
                            if (secondCur === secondPrev) {
                                prevItem.volume = prevItem.volume + curItem.volume
                                continue
                            } else {
                                const second = moment(curItem.time).second() >= 0 && moment(curItem.time).second() < 30
                                const time = second
                                    ? moment(obj.time).format('YYYYMMDD HH:mm') + ':00'
                                    : moment(obj.time).format('YYYYMMDD HH:mm') + ':30'
                                obj.time = moment(time, 'YYYYMMDD HH:mm:ss').valueOf()
                                arr.push(obj)
                            }
                        } else {
                            const second = moment(curItem.time).second() >= 0 && moment(curItem.time).second() < 30
                            const time = second
                                ? moment(obj.time).format('YYYYMMDD HH:mm') + ':00'
                                : moment(obj.time).format('YYYYMMDD HH:mm') + ':30'
                            obj.time = moment(time, 'YYYYMMDD HH:mm:ss').valueOf()
                            arr.push(obj)
                        }
                    }
                }
            }
            return arr
        }

        this.chartConvert2Minutes = (data = []) => {
            const arr = []
            for (let index = 0; index < data.length; index++) {
                const curItem = data[index]
                const prevItem = arr[arr.length - 1]
                const obj = { ...curItem }
                if (!arr.length) {
                    arr.push(obj)
                } else {
                    if (prevItem) {
                        const momentCur = moment(curItem.time)
                        const momentPrev = moment(prevItem.time)
                        if (momentCur.minute() === momentPrev.minute() && momentCur.hour() === momentPrev.hour()) {
                            if (index === data.length - 1) {
                                arr.push(obj)
                            } else {
                                prevItem.C = curItem.C
                                prevItem.V = prevItem.V + curItem.V
                            }
                            continue
                        } else {
                            arr.push(obj)
                        }
                    }
                }
            }
            return arr
        }

        this.chartConvert2Month = (data) => {
            const arr = []
            for (let index = 0; index < data.length; index++) {
                const curItem = data[index]
                const prevItem = arr[arr.length - 1]
                const obj = { ...curItem }
                if (!arr.length) {
                    arr.push(obj)
                } else {
                    if (prevItem) {
                        const momentCur = moment(curItem.t52, 'YYYYMMDD-HH:mm:ss')
                        const momentPrev = moment(prevItem.t52, 'YYYYMMDD-HH:mm:ss')
                        if (momentCur.month() === momentPrev.month() && momentCur.year() === momentPrev.year()) {
                            prevItem.volume = prevItem.volume + curItem.volume
                            if (prevItem.high < curItem.high) prevItem.high = curItem.high
                            if (prevItem.low > curItem.low) prevItem.low = curItem.low
                            prevItem.close = curItem.close
                            continue
                        } else {
                            arr.push(obj)
                        }
                    }
                }
            }
            return arr
        }

        this.chartConvert2MonthServer = (data) => {
            const arr = []
            for (let index = 0; index < data.length; index++) {
                const curItem = data[index]
                const prevItem = arr[arr.length - 1]
                const obj = { ...curItem }
                if (!arr.length) {
                    arr.push(obj)
                } else {
                    if (prevItem) {
                        const momentCur = moment(curItem.c0, 'YYYYMMDD')
                        const momentPrev = moment(prevItem.c0, 'YYYYMMDD')
                        if (momentCur.month() === momentPrev.month() && momentCur.year() === momentPrev.year()) {
                            prevItem.c8 = Math.abs(Number(prevItem.c8)) + Math.abs(Number(curItem.c8))
                            if (Number(prevItem.c5) < Number(curItem.c5)) prevItem.c5 = Number(curItem.c5)
                            if (Number(prevItem.c4) > Number(curItem.c4)) prevItem.low = Number(curItem.c4)
                            prevItem.c7 = Number(curItem.c7)
                            continue
                        } else {
                            arr.push(obj)
                        }
                    }
                }
            }
            return arr
        }

        this.chartConvert2Year = (data) => {
            const arr = []
            for (let index = 0; index < data.length; index++) {
                const curItem = data[index]
                const prevItem = arr[arr.length - 1]
                const obj = { ...curItem }
                if (!arr.length) {
                    arr.push(obj)
                } else {
                    if (prevItem) {
                        if (moment(curItem.time).year() === moment(prevItem.time).year()) {
                            prevItem.volume = prevItem.volume + curItem.volume
                            if (prevItem.high < curItem.high) prevItem.high = curItem.high
                            if (prevItem.low > curItem.low) prevItem.low = curItem.low
                            prevItem.close = curItem.close
                            continue
                        } else {
                            arr.push(obj)
                        }
                    }
                }
            }
            return arr
        }

        this.convertDataChart5Days = (data = []) => {
            const arr = []
            if (data[0].MSGTP === 'EP') {
                for (let index = 0; index < data.length; index++) {
                    if (!data[index].t32) continue
                    const obj = {
                        type: data[index].t55,
                        indexValue: data[index].t31,
                        volume: data[index].t32,
                        time: moment(data[index].t52, 'YYYYMMDD-HH:mm:ss').valueOf(),
                    }
                    arr.push(obj)
                }
            } else if (data[0].MSGTP === 'I') {
                for (let index = 0; index < data.length; index++) {
                    if (!data[index].U12) continue
                    const obj = {
                        type: data[index].t2,
                        indexValue: data[index].t3,
                        volume: data[index].U12,
                        time: moment(data[index].t52, 'YYYYMMDD-HH:mm:ss').valueOf(),
                    }
                    arr.push(obj)
                }
            }

            this.eventMarket.next({ type: 'GET_HIS', data: arr })
        }

        this.chartConvertIndexWeek = (data) => {
            const arr = []
            for (let index = 0; index < data.length - 1; index++) {
                const curItem = data[index]
                const prevItem = arr[arr.length - 1]
                const obj = { ...curItem }
                if (!arr.length) {
                    arr.push(obj)
                } else {
                    if (prevItem) {
                        if (moment(curItem.time).week() === moment(prevItem.time).week()) {
                            prevItem.volume = prevItem.volume + curItem.volume
                            if (prevItem.high < curItem.high) prevItem.high = curItem.high
                            if (prevItem.low > curItem.low) prevItem.low = curItem.low
                            prevItem.close = curItem.close
                            continue
                        } else {
                            arr.push(obj)
                        }
                    }
                }
            }
            return arr
        }

        this.chartConvertIndexMonth = (data) => {
            const arr = []
            for (let index = 0; index < data.length; index++) {
                const curItem = data[index]
                const prevItem = arr[arr.length - 1]
                const obj = { ...curItem }
                if (!arr.length) {
                    arr.push(obj)
                } else {
                    if (prevItem) {
                        if (moment(curItem.time).month() === moment(prevItem.time).month()) {
                            prevItem.volume = prevItem.volume + curItem.volume
                            if (prevItem.high < curItem.high) prevItem.high = curItem.high
                            if (prevItem.low > curItem.low) prevItem.low = curItem.low
                            prevItem.close = curItem.close
                            continue
                        } else {
                            arr.push(obj)
                        }
                    }
                }
            }
            return arr
        }

        this.chartConvertIndexYear = (data) => {
            const arr = []
            for (let index = 0; index < data.length; index++) {
                const curItem = data[index]
                const prevItem = arr[arr.length - 1]
                const obj = { ...curItem }
                if (!arr.length) {
                    arr.push(obj)
                } else {
                    if (prevItem) {
                        if (moment(curItem.time).year() === moment(prevItem.time).year()) {
                            prevItem.volume = prevItem.volume + curItem.volume
                            if (prevItem.high < curItem.high) prevItem.high = curItem.high
                            if (prevItem.low > curItem.low) prevItem.low = curItem.low
                            prevItem.close = curItem.close
                            continue
                        } else {
                            arr.push(obj)
                        }
                    }
                }
            }
            return arr
        }

        this.createIndex = (index) => {
            this.IndexMarket[index] = new InfoIndex()
            this.IndexMarket[index].indexCode = index
            return this.IndexMarket[index]
        }

        this.getUnique = (arr, comp) => {
            //store the comparison  values in array
            const unique = arr
                .map((e) => e[comp])
                // store the indexes of the unique objects
                .map((e, i, final) => final.indexOf(e) === i && i)
                // eliminate the false indexes & return unique objects
                .filter((e) => arr[e])
                .map((e) => arr[e])

            return unique
        }

        this.SMA = (data, period) => {
            const result = []
            if (data.length < period) return result
            for (let index = period; index < data.length; index++) {
                let sum = 0,
                    periodDivide = 0
                for (let temp = index; temp > index - period; temp--) {
                    if (data[temp].value) {
                        periodDivide += 1
                        sum += data[temp].value
                    }
                }
                if (periodDivide) {
                    const ma = {
                        value: Math.round((sum / periodDivide) * 100) / 100,
                        time: data[index].time,
                    }
                    result.push(ma)
                }
            }
            return result
        }

        this.EMA = (data, period, smoothing) => {
            const result = []
            if (data.length < period) return result
            for (let index = period; index < data.length; index++) {
                const multiplier = 2 / (period + 1)
                const prevEma = result[index - 1] ? result[index - 1].value : 0
                const ema = {
                    value: data[index].value * multiplier + prevEma * (1 - multiplier),
                    time: data[index].time,
                }
                result.push(ema)
            }
            return result
        }

        this.getColor = (value, item, styles, key) => {
            // REF__COLOR: '#f1c40f',
            // UP__COLOR: '#2ECC71',
            // DOWN__COLOR: '#EB5C55',
            // CEIL__COLOR: '#f618fb',
            // FLOOR__COLOR: '#00d3b8',
            if (key) {
                if (key === 't55' || key === 't387' || key === 't391' || key === 't397' || key === 't398' || key === 't3301')
                    return styles.PRIMARY__CONTENT__COLOR
                if (key === 't31_incr_per' || key === 't31_incr' || key === 't32' || key === 't31') {
                    if (!value) return styles.REF__COLOR
                    if (item.t31 > 0 && item.t31 > item.t333 && item.t31 < item.t260) return styles.DOWN__COLOR
                    else if (item.t31 > 0 && item.t31 < item.t332 && item.t31 > item.t260) return styles.UP__COLOR
                    else if (item.t31 === item.t260) return styles.REF__COLOR
                    else if (item.t31 >= item.t332) return styles.CEIL__COLOR
                    else if (item.t31 <= item.t333) return styles.FLOOR__COLOR
                }
                if (key === 't1321_3') {
                    if (item.t132_3 > 0 && item.t132_3 > item.t333 && item.t132_3 < item.t260) return styles.DOWN__COLOR
                    else if (item.t132_3 > 0 && item.t132_3 < item.t332 && item.t132_3 > item.t260)
                        return styles.UP__COLOR
                    else if (item.t132_3 === item.t260) return styles.REF__COLOR
                    else if (item.t132_3 >= item.t332) return styles.CEIL__COLOR
                    else if (item.t132_3 <= item.t333) return styles.FLOOR__COLOR
                }
                if (key === 't1321_2') {
                    if (item.t132_3 > 0 && item.t132_2 > item.t333 && item.t132_2 < item.t260) return styles.DOWN__COLOR
                    else if (item.t132_2 > 0 && item.t132_2 < item.t332 && item.t132_2 > item.t260)
                        return styles.UP__COLOR
                    else if (item.t132_2 === item.t260) return styles.REF__COLOR
                    else if (item.t132_2 >= item.t332) return styles.CEIL__COLOR
                    else if (item.t132_2 <= item.t333) return styles.FLOOR__COLOR
                }
                if (key === 't1321_1') {
                    if (item.t133_1 === 777777710000 || item.t133_1 === 777777720000)
                        return styles.PRIMARY__CONTENT__COLOR
                    if (item.t132_1 > 0 && item.t132_1 > item.t333 && item.t132_1 < item.t260) return styles.DOWN__COLOR
                    else if (item.t132_1 > 0 && item.t132_1 < item.t332 && item.t132_1 > item.t260)
                        return styles.UP__COLOR
                    else if (item.t132_1 === item.t260) return styles.REF__COLOR
                    else if (item.t132_1 >= item.t332) return styles.CEIL__COLOR
                    else if (item.t132_1 <= item.t333) return styles.FLOOR__COLOR
                }
                if (key === 't1331_3') {
                    if (item.t133_3 > 0 && item.t133_3 > item.t333 && item.t133_3 < item.t260) return styles.DOWN__COLOR
                    else if (item.t133_3 > 0 && item.t133_3 < item.t332 && item.t133_3 > item.t260)
                        return styles.UP__COLOR
                    else if (item.t133_3 === item.t260) return styles.REF__COLOR
                    else if (item.t133_3 >= item.t332) return styles.CEIL__COLOR
                    else if (item.t133_3 <= item.t333) return styles.FLOOR__COLOR
                }
                if (key === 't1331_2') {
                    if (item.t133_2 > 0 && item.t133_2 > item.t333 && item.t133_2 < item.t260) return styles.DOWN__COLOR
                    else if (item.t133_2 > 0 && item.t133_2 < item.t332 && item.t133_2 > item.t260)
                        return styles.UP__COLOR
                    else if (item.t133_2 === item.t260) return styles.REF__COLOR
                    else if (item.t133_2 >= item.t332) return styles.CEIL__COLOR
                    else if (item.t133_2 <= item.t333) return styles.FLOOR__COLOR
                }
                if (key === 't1331_1') {
                    if (item.t133_1 === 777777710000 || item.t133_1 === 777777720000)
                        return styles.PRIMARY__CONTENT__COLOR
                    if (item.t133_1 > 0 && item.t133_1 > item.t333 && item.t133_1 < item.t260) return styles.DOWN__COLOR
                    else if (item.t133_1 > 0 && item.t133_1 < item.t332 && item.t133_1 > item.t260)
                        return styles.UP__COLOR
                    else if (item.t133_1 === item.t260) return styles.REF__COLOR
                    else if (item.t133_1 >= item.t332) return styles.CEIL__COLOR
                    else if (item.t133_1 <= item.t333) return styles.FLOOR__COLOR
                }
                if (value === 777777710000 || value === 777777720000) return styles.PRIMARY__CONTENT__COLOR
                if (value > 0 && value > item.t333 && value < item.t260) return styles.DOWN__COLOR
                else if (value > 0 && value < item.t332 && value > item.t260) return styles.UP__COLOR
                else if (value === item.t260) return styles.REF__COLOR
                else if (value >= item.t332) return styles.CEIL__COLOR
                else if (value <= item.t333) return styles.FLOOR__COLOR
                return ''
            }
            if (!value) return 'var(--PRIMARY__CONTENT__COLOR)'
            if (!item.t333) return 'var(--PRIMARY__CONTENT__COLOR)'
            if (value === 777777710000 || value === 777777720000) return 'var(--PRIMARY__CONTENT__COLOR)'
            if (value > 0 && value > item.t333 && value < item.t260) return 'var(--DOWN__COLOR)'
            else if (value > 0 && value < item.t332 && value > item.t260) return 'var(--UP__COLOR)'
            else if (value === item.t260) return 'var(--REF__COLOR)'
            else if (value >= item.t332) return 'var(--CEIL__COLOR)'
            else if (value <= item.t333) return 'var(--FLOOR__COLOR)'
        }

        this.arr_diff = (a1, a2) => {
            const a = [],
                diff = []

            for (let i = 0; i < a1.length; i++) {
                a[a1[i]] = true
            }

            for (let i = 0; i < a2.length; i++) {
                if (a[a2[i]]) {
                    delete a[a2[i]]
                } else {
                    a[a2[i]] = true
                }
            }

            for (let k in a) {
                diff.push(k)
            }

            return diff
        }

        this.intersect = (arr1, arr2) => {
            const result = []
            for (let i = 0; i < arr1.length; i++) {
                if (arr2.indexOf(arr1[i]) === -1) result.push(arr1[i])
            }
            return result
        }

        // kiểm tra xem otp đã dc nhập chưa
        this.checkOtp = (functionCallback) => {
            // nếu activeCode là 888, 081 thì để server check otp
            if (['081'].includes(glb_sv.activeCode)) return true

            if (this.objShareGlb.userInfo && this.objShareGlb.userInfo.c13 === 'N') return true
            if (!this.objShareGlb.sessionInfo.Otp) {
                this.commonEvent.next({
                    type: eventList.OTP_MODAL,
                    otp_Type: Number(this.objShareGlb.userInfo.c6),
                    functionCallback,
                })
                return false
            }
            return true
        }

        // time msgI
        this.cvStringToDate = (time) => {
            const year = time.slice(0, 4)
            const month = time.slice(4, 6)
            const day = time.slice(6, 8)
            return year + '-' + month + '-' + day
        }
        // time msg EP
        this.convertTimeT52toDate = (time) => {
            const year = time.slice(0, 4)
            const month = time.slice(4, 6)
            const day = time.slice(6, 8)
            return year + '-' + month + '-' + day + 'T' + time.slice(9, 17)
        }
        // time msg Ep to HH:mm:ss
        this.convertTimeT52toTimetb = (time) => {
            return time.slice(9, 17)
        }

        this.sendAuthReqSocketStream = ({ LoginID, MdmTp, Token }) => {
            return;
            // if (!this.objShareGlb.sessionInfo.sessionId) {
            //     console.log('authen stream error TransId null')
            //     return
            // }
            // if (socket_sv.timeoutAuthStream) clearTimeout(socket_sv.timeoutAuthStream)
            // const clientSeq = socket_sv.getRqSeq()
            // const msgObj2 = {
            //     ClientSeq: clientSeq,
            //     TransId: this.objShareGlb.sessionInfo.sessionId,
            //     LoginID,
            //     MdmTp,
            //     Token,
            // }
            // this.dataAuthStream = { LoginID, MdmTp, Token }
            // const reqInfo = new requestInfo(reqFunct.SUBSCRIBE_INFO, msgObj2)
            // this.setReqInfoMapValue(clientSeq, reqInfo)
            // socket_sv.send2Sv(channels.AUTHEN_REQ, msgObj2)
        }

        this.showAlert = (params) => {
            this.commonEvent.next({ type: eventList.ALERT_MODAL, params })
        }

        this.focusInput = (id, sleep) => {
            if (sleep) {
                setTimeout(() => {
                    const ele = document.getElementById(id)
                    if (ele) ele.focus()
                }, sleep)
            } else {
                const ele = document.getElementById(id)
                if (ele) ele.focus()
            }
        }

        this.stringToSlug = (str) => {
            if (!str) return ''
            // remove accents vietnames
            const from = "àáãảạăằắẳẵặâầấẩẫậèéẻẽẹêềếểễệđùúủũụưừứửữựòóỏõọôồốổỗộơờớởỡợìíỉĩịäëïîöüûñçýỳỹỵỷ",
                to = "aaaaaaaaaaaaaaaaaeeeeeeeeeeeduuuuuuuuuuuoooooooooooooooooiiiiiaeiiouuncyyyyy";
            let res = str;
            for (let i = 0, l = from.length; i < l; i++) {
                res = res.replace(RegExp(from[i], "gi"), to[i]);
            }

            res = res.toLowerCase()
                .trim()
                .replace(/[^a-z0-9\-]/g, '-')
                .replace(/-+/g, '-');

            return res;
        }

        this.setColorFlashPriceboard = () => {
            return allowCompanyRender(['061', '081', '102'])
        }

        this.removeTrashObjEkyc = (object) => {
            delete object['challengeCode']
            delete object['dataSign']
            delete object['dataBase64']
            delete object['server_version']
            delete object['statusCode']

            delete object['object']['name_probs']
            delete object['object']['quality_back']
            delete object['object']['quality_front']
            delete object['object']['checking_result_front']
            delete object['object']['checking_result_back']
            delete object['object']['issue_date_probs']
            delete object['object']['citizen_id_prob']
            delete object['object']['dob_fake_warning_prob']
            delete object['object']['ethnicity_prob']
            delete object['object']['features_prob']
            delete object['object']['gender_prob']
            delete object['object']['issue_date_label_prob']
            delete object['object']['issue_date_prob']
            delete object['object']['issue_place_prob']
            delete object['object']['name_fake_warning_prob']
            delete object['object']['origin_location_prob']
            delete object['object']['recent_location_prob']
            delete object['object']['religion_prob']
            delete object['object']['valid_date_prob']
            delete object['object']['id_probs']
            delete object['object']['birth_day_label']
            delete object['object']['recent_location_label']
            delete object['object']['origin_location_label']
            delete object['object']['features']
            delete object['object']['ethnicity']
            delete object['object']['post_code']
            delete object['object']['mrz']
            delete object['object']['mrz_prob']

            delete object['object']['face_swapping_prob']
            delete object['object']['fake_print_photo_prob']
            return object
        }

        /*
            Type 3 là eKYC SUCCESS
            Type 4 là EKYC ERROR
        */
        this.sendEKYCLog = (sEKYCLog, eKYCType = '3') => {
            if (!sEKYCLog) return;

            const ServiceInfo: { [key: string]: IServiceInfo } = {
                RECORD_STATISTICS: {
                    reqFunct: reqFunct.RECORD_STATISTICS,
                    WorkerName: 'FOSxID03',
                    ServiceName: 'FOSxID03_Statistics',
                    ClientSentTime: '0',
                    Operation: 'U',
                }
            }

            // Ví dụ tracking ekyc tk có id là 00008279 tại step số 6:
            // 3;00008279;6;A;eKYC_Log|
            const loginID = this.credentials.username ? this.credentials.username : this.objShareGlb['acntMain']
            const logParam = eKYCType + ';' + loginID + ';5;A;' + sEKYCLog + '|'

            const InputParams = ['update', logParam];
            const sendEKYCLogResult = (reqInfoMap, message) => {
                console.log("sendEKYCLogResult: ", message)
            }
            sendRequest(ServiceInfo.RECORD_STATISTICS, InputParams, sendEKYCLogResult, false)

        }

        // Kiểm tra một field có trùng ở tất cả các object trong array
        this.checkDuplicatedObjFieldInArr = (array = [], field = 'c0') => {
            // @ts-expect-error
            return array.some(x => array.every((currentValue, index, arr) => currentValue[[field]] === x[[field]]))
        }
    }
    updPO_Msg2MrkInfoMap(msg: any, msgKey: any) {
        throw new Error('Method not implemented.')
    }
    process_MrkInfoMsgMuiltData(jsonArr: any[], arg1: number) {
        throw new Error('Method not implemented.')
    }
    sprocess_ForOneMsg(arg0: any, clientSeq?: number) {
        throw new Error('Method not implemented.')
    }
    updSI_Msg2MrkInfoMap(msg: {}): void {
        throw new Error('Method not implemented.')
    }
    updTP_Msg2MrkInfoMap(msg: {}, msgKey: any) {
        throw new Error('Method not implemented.')
    }
    updEP_Msg2MrkInfoMap(msg: {}): void {
        throw new Error('Method not implemented.')
    }
    updI_Msg2MrkInfoMap(msg: {}): void {
        throw new Error('Method not implemented.')
    }
    updBI_Msg2MrkInfoMap(msg: {}): void {
        throw new Error('Method not implemented.')
    }
    updTS_Msg2MrkInfoMap(msg: {}) {
        throw new Error('Method not implemented.')
    }
    updAA_Msg2MrkInfoMap(msg: {}) {
        throw new Error('Method not implemented.')
    }
    getColorTP(t132: any, obj: any): any {
        throw new Error('Method not implemented.')
    }
    addZero(arg0: any, arg1: number) {
        throw new Error('Method not implemented.')
    }

    updCBI_Msg2MrkInfoMap = (msg) => {
        const msgKey = msg.t55;
        if (!msgKey) return
        if (!this.StockMarket[msgKey]) {
            this.StockMarket[msgKey] = new InfoStock();
            this.StockMarket[msgKey].t55 = msgKey;
        }
        // ceil_bond = 0 //t432
        // floor_bond = 0 //t433
        // ref_bond = 0 //t260
        // total_qty_bid_bond = 0 //t3830
        // total_value_bid_bond = 0 //t3832
        // highest_price_bid_bond = 0 //t3811
        // highest_qty_bid_bond = 0 //t381
        // total_qty_lowest_price_match_bond = 0 //t396
        // lowest_price_match_bond = 0 //t3961
        // price_match_bond = 0 //t3931
        // qty_match_bond = 0 //t393
        // highest_price_match_bond = 0 //t3951
        // total_qty_highest_price_match_bond = 0 //t395
        // total_qty_ask_bond = 0 //t3831
        // total_value_ask_bond = 0 //t3833
        // lowest_price_ask_bond = 0 //t3821
        // lowest_qty_ask_bond = 0 //t382
        // total_value_bond = 0 //t3941
        // total_qty_bond = 0 //t394
        // remain_qty_foregin = 0 //t3301
        // symbol_issuer = '' //t106
        // open_price_bond = 0 //t137
        // close_price_bond = 0 //t139
        // date_trading_bond = '' //t388
        // listing_volume_bond = 0 //t109
        // due_date_bond = '' //t541
        // bond_term = '' //t900
        // remaining_term_bond = '' //t902
        // type_interest_payment_bond = '' //t903
        // nominal_interest_rate_bond = '' //t223
        // interest_period_bond = '' //t904
        // type_interest_rate_bond = '' //t907
        // payment_method_bond = '' //t908
        // features_bond = '' // t909
        // code_isin_bond = '' //t910
        // bond_status = '' //t326
        // denominations_bond = 0 //t334
        // release_date_bond = '' //t225
        // bond_term_unit = '' //t901
        // interest_payment_term_unit_bond = '' //t905

        this.StockMarket[msgKey].t55 = msgKey;
        this.StockMarket[msgKey].ceil_bond = msg.t432;
        this.StockMarket[msgKey].floor_bond = msg.t433;
        this.StockMarket[msgKey].ref_bond = msg.t260;
        this.StockMarket[msgKey].total_qty_bid_bond = msg.t3830
        this.StockMarket[msgKey].total_value_bid_bond = msg.t3832
        this.StockMarket[msgKey].highest_price_bid_bond = msg.t3811
        this.StockMarket[msgKey].highest_qty_bid_bond = msg.t381
        this.StockMarket[msgKey].lowest_price_match_bond = msg.t3961
        this.StockMarket[msgKey].total_qty_lowest_price_match_bond = msg.t396
        this.StockMarket[msgKey].price_match_bond = msg.t3931
        this.StockMarket[msgKey].qty_match_bond = msg.t393
        this.StockMarket[msgKey].highest_price_match_bond = msg.t3951
        this.StockMarket[msgKey].total_qty_highest_price_match_bond = msg.t395
        this.StockMarket[msgKey].total_qty_ask_bond = msg.t3831
        this.StockMarket[msgKey].total_value_ask_bond = msg.t3833
        this.StockMarket[msgKey].lowest_price_ask_bond = msg.t3821
        this.StockMarket[msgKey].lowest_qty_ask_bond = msg.t382
        this.StockMarket[msgKey].total_value_bond = msg.t3941
        this.StockMarket[msgKey].total_qty_bond = msg.t394
        this.StockMarket[msgKey].remain_qty_foregin = msg.t3301
        this.StockMarket[msgKey].total_value_ask_bond = msg.t3833
        this.StockMarket[msgKey].symbol_issuer = msg.t106

        this.StockMarket[msgKey].open_price_bond = msg.t137
        this.StockMarket[msgKey].close_price_bond = msg.t139
        this.StockMarket[msgKey].date_trading_bond = msg.t388
        this.StockMarket[msgKey].listing_volume_bond = msg.t109
        this.StockMarket[msgKey].due_date_bond = msg.t541
        this.StockMarket[msgKey].bond_term = msg.t900
        this.StockMarket[msgKey].remaining_term_bond = msg.t902
        this.StockMarket[msgKey].type_interest_payment_bond = msg.t903
        this.StockMarket[msgKey].nominal_interest_rate_bond = msg.t223
        this.StockMarket[msgKey].interest_period_bond = msg.t904
        this.StockMarket[msgKey].type_interest_rate_bond = msg.t907
        this.StockMarket[msgKey].payment_method_bond = msg.t908
        this.StockMarket[msgKey].features_bond = msg.t909
        this.StockMarket[msgKey].code_isin_bond = msg.t910
        this.StockMarket[msgKey].bond_status = msg.t326
        this.StockMarket[msgKey].denominations_bond = msg.t334
        this.StockMarket[msgKey].release_date_bond = msg.t225
        this.StockMarket[msgKey].bond_term_unit = msg.t901
        this.StockMarket[msgKey].interest_payment_term_unit_bond = msg.t905
        this.StockMarket[msgKey].symbol_issuer_bond = msg.t106

        this.StockMarket[msgKey].ceil_bond_color = this.price_ceil_color
        this.StockMarket[msgKey].floor_bond_color = this.price_floor_color
        this.StockMarket[msgKey].ref_bond_color = this.price_basic_color
        this.getColorCBI('open_price_bond', msgKey)
        this.getColorCBI('close_price_bond', msgKey)
        this.getColorCBI('highest_price_bid_bond', msgKey)
        // this.getColorCBI('highest_qty_bid_bond', msgKey)
        this.getColorCBI('lowest_price_match_bond', msgKey)
        this.getColorCBI('price_match_bond', msgKey)
        // this.getColorCBI('qty_match_bond', msgKey)
        this.getColorCBI('highest_price_match_bond', msgKey)
        this.getColorCBI('lowest_price_ask_bond', msgKey)
        // this.getColorCBI('lowest_qty_ask_bond', msgKey)
        this.StockMarket[msgKey].highest_qty_bid_bond_color = this.StockMarket[msgKey].highest_price_bid_bond_color
        this.StockMarket[msgKey].qty_match_bond_color = this.StockMarket[msgKey].price_match_bond_color
        this.StockMarket[msgKey].lowest_qty_ask_bond_color = this.StockMarket[msgKey].lowest_price_ask_bond_color
        this.StockMarket[msgKey].total_qty_lowest_price_match_bond_color = this.StockMarket[msgKey].lowest_price_match_bond_color
        this.StockMarket[msgKey].total_qty_highest_price_match_bond_color = this.StockMarket[msgKey].highest_price_match_bond_color

        this.eventMarket.next({ type: eventList.SUB_STOCK, msgKey })
    }

    updBBI_Msg2MrkInfoMap = (msg) => {
        glb_sv.IndexMarket.INDIVIDUAL_BOND.total_volume_outright_bond = msg.t240
        glb_sv.IndexMarket.INDIVIDUAL_BOND.total_value_outright_bond = msg.t241
        this.eventMarket.next({ type: eventList.SUB_INDIVIDUAL_BOND, msgKey: 'INDIVIDUAL_BOND' })
    }

    getColorCBI = (key, stockCode) => {
        const stkMsgObj = this.StockMarket[stockCode];
        if (stkMsgObj[key] === stkMsgObj.ref_bond) {
            stkMsgObj[key + '_color'] = this.price_basic_color
        } else if (stkMsgObj[key] > stkMsgObj.ref_bond) {
            if (stkMsgObj[key] === stkMsgObj.ceil_bond) {
                stkMsgObj[key + '_color'] = this.price_ceil_color
            } else {
                stkMsgObj[key + '_color'] = this.price_basic_over
            }
        } else if (stkMsgObj[key] < stkMsgObj.ref_bond) {
            if (stkMsgObj[key] === stkMsgObj.floor_bond) {
                stkMsgObj[key + '_color'] = this.price_floor_color
            } else {
                stkMsgObj[key + '_color'] = this.price_basic_less
            }
        }
    }
}
const theInstance = new globalService()
// @ts-expect-error
window.glb_sv = theInstance
export default theInstance
