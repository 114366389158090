
import { glb_sv } from '../index'
import { subcribeFunctStream } from './subcribe_function'
/**
 * @summary ControlService sẽ đảm nhận chức năng kiểm soát dòng thông tin của App. Các chức năng control bao gồm:
 * 1. Control Time out Request
 * 2. Control List Sub, unsub từ server
 *
 */
class ControlService {
    clearTimeOutRequest: Function
    checkSubMapBeforeSub: Function
    addNewSub: Function
    removeSubInfoFromMap: Function
    reSubAllWhenReconnectMarket: Function
    clearReqInfoMapRequest: Function
    ControlTimeOutObj: { [key: string]: any}
    subControlMap: {}




    constructor() {
        /**
         * @param {Object} ControlTimeOutObj Object Global để control timeout request ở client
         * @function clearTimeOutRequest Clear timeout request sau khi đã Nhận phản hồi hoặc bị timeout
         * @function clearReqInfoMapRequest Clear Result handle function để tránh lỗi ngoài ý muốn khi server phản hồi sau thời gian timeout client
         */
        this.ControlTimeOutObj = {}

        this.clearTimeOutRequest = (controlTimeOutKey: string) => {
            // console.log('Các request đang đợi phản hồi', this.ControlTimeOutObj);
            if (this.ControlTimeOutObj[controlTimeOutKey]) clearTimeout(this.ControlTimeOutObj[controlTimeOutKey])
            this.ControlTimeOutObj[controlTimeOutKey] = null;
            // delete this.ControlTimeOutObj[controlTimeOutKey]
            // console.log('Clear TimeOut Request: Request đã nhận được phản hồi hoặc bị timeOut ', controlTimeOutKey);
            return
        }
        this.clearReqInfoMapRequest = (clientSeq: number) => {
            glb_sv.setReqInfoMapValue(clientSeq, null)
            return
        }
        /**
         * @param {Object} subControlMap Control all Sub in app
         *
         *
         *
         *
         */

        //-------------------------------------------------------
        // TODO: {Dung} Thêm các function check value. Case check: array, array level 1 as tring
        this.subControlMap = {}
        this.checkSubMapBeforeSub = ({ topic, value, component }) => {
            if (!Array.isArray(value) || !Array.isArray(value) || typeof component !== 'string') {
                console.warn('Kiểu dữ liệu không đúng checkSubMapBeforeSub:', topic, value, component)
                return []
            }
            // console.log('{ topic, value, component }', { topic, value, component })
            /**
             * TODO {Dung} Viết lại document
             * @returns Mảng thông tin SUB (value, topic, component)
             *
             * @var {Object} diffResultObject Object có key là Mã CK/Mã Index, value là Object valueOfValAsKey (key: topic, value: component)
             */
            const diffResultObject = {}
            value.map((val) => {
                // Mỗi value tương ứng với một key
                if (this.subControlMap.hasOwnProperty(val)) {
                    // Nếu đã tồn tại key thì xét tiếp topic
                    const valueOfValAsKey = {}
                    topic.map((t) => {
                        if (this.subControlMap[val].hasOwnProperty(t)) {
                            
                            // Nếu tại Value Sub đã tồn tại topic thì kiểm tra
                            // Nếu đã tồn tại component rồi thì bỏ qua
                            // Nếu chưa thì push component mới vào, rồi thì bỏ qua
                            const topicArrComponent = this.subControlMap[val][t] || []
                            if (!topicArrComponent.includes(component)) {
                                if (topicArrComponent === 0 ) {
                                    valueOfValAsKey[t] = [component]
                                } else {
                                    valueOfValAsKey[t] = [...topicArrComponent, component]
                                    this.subControlMap[val][t] = [...topicArrComponent, component]
                                }
                                // Nếu chưa tồn tại component push compon
                            }
                        } else {
                            // Nếu tại Value Sub chưa tồn tại topic thì tạo topic
                            // Và thêm component
                            valueOfValAsKey[t] = [component]
                        }
                    })
                    diffResultObject[val] = { ...valueOfValAsKey }
                } else {
                    // Chưa tồn tại key đang xét
                    const valueOfValAsKey = {}
                    topic.map((t) => {
                        // Push/Set value vào object mới
                        valueOfValAsKey[t] = [component]
                    })
                    diffResultObject[val] = { ...valueOfValAsKey }
                }
            })
            const objWithTopicAsKey = {}
            // console.log('arrayDiff', diffResultObject)
            for (const [key, objTopic] of Object.entries(diffResultObject)) {
                // console.log(`${key}:`, objTopic)
                const keyNewObj = JSON.stringify(Object.keys(objTopic)) // Array
                if (objWithTopicAsKey.hasOwnProperty(keyNewObj)) {
                    objWithTopicAsKey[keyNewObj].push(key)
                } else {
                    objWithTopicAsKey[keyNewObj] = [key]
                }
            }
            const arraySubInfo = []
            for (const [arrTopicAsKey, arrValue] of Object.entries(objWithTopicAsKey)) {
                // console.log(`arrTopicAsKey ${arrTopicAsKey}:`, arrValue)
                arraySubInfo.push({
                    topic: JSON.parse(arrTopicAsKey),
                    value: arrValue,
                    component: component,
                })
            }
            return arraySubInfo
        }

        this.addNewSub = ({ topic, value, component }) => {
            if (!Array.isArray(value) || !Array.isArray(value) || typeof component !== 'string') {
                console.warn('Kiểu dữ liệu không đúng addNewSub:', topic, value, component)
                return
            }
            value.map((val) => {
                if (this.subControlMap.hasOwnProperty(val)) {
                    topic.map((t) => {
                        if (this.subControlMap[val].hasOwnProperty(t)) {
                            this.subControlMap[val][t].concat([component])
                        } else {
                            this.subControlMap[val][t] = [].concat([component])
                        }
                    })
                } else {
                    // Nếu chưa tồn tại key
                    this.subControlMap[val] = {}
                    topic.map((t) => {
                        Object.defineProperty(this.subControlMap[val], t, {
                            value: [component],
                            configurable: true,
                            writable: true,
                            enumerable: true,
                        })
                    })
                }
            })
            // console.log('addNewSub [currSubMapAfterSub] <<<<<<<<<<<<<<', this.subControlMap)
        }
        this.removeSubInfoFromMap = ({ topic, value, component }) => {
            if (!Array.isArray(value) || !Array.isArray(value) || typeof (component) !== 'string') {
                console.warn('Kiểu dữ liệu không đúng removeSubInfoFromMap:', topic, value, component)
                return []
            }

            /**
             * @returns Mảng thông tin UNSUB (value, topic, component)
             *
             * @var {Object} diffResultObject Object có key là Mã CK/Mã Index, value là Object valueOfValAsKey (key: topic, value: component)
             */
            const diffResultObject = {}

            value.map((val) => {
                if (this.subControlMap.hasOwnProperty(val)) {
                    // Nếu đã tồn tại key thì xét tiếp topic
                    const valueOfValAsKey = {}
                    topic.map((t) => {
                        // console.log('hdfjkshdgfjdhs', val, t)
                        if (this.subControlMap[val]?.hasOwnProperty(t)) {
                            const arrCpnOfTopic = this.subControlMap[val][t] // !!! {Dung} Check kỹ
                            if (arrCpnOfTopic.includes(component)) {
                                // Nếu tồn tại component thì mới push vào để tí nữa UNSUB
                                // Xong thì remove component đó đi
                                const removed = arrCpnOfTopic.filter((cpn) => cpn !== component)
                                if (removed.length === 0) {
                                    // Nếu không còn component nào dùng nữa thì mới UNSUB
                                    valueOfValAsKey[t] = [component]
                                    delete this.subControlMap[val][t]
                                    if (Object.keys(this.subControlMap[val]).length === 0) {
                                        delete this.subControlMap[val]
                                        // console.log('arrCpnOfTopic UNSUB', this.subControlMap, component, 'removed', removed)
                                    }
                                } else {
                                    this.subControlMap[val][t] = removed
                                    // console.log('arrCpnOfTopic UNSUB nnnnn', this.subControlMap[val], 'removed')
                                }
                            }
                        }
                    })
                    diffResultObject[val] = { ...valueOfValAsKey }
                }
            })
            const objWithTopicAsKey: { [key: string]: any[]} = {}
            // console.log('arrayDiff', diffResultObject)
            for (const [key, objTopic] of Object.entries(diffResultObject)) {
                // console.log(`${key}:`, objTopic)
                const keyNewObj = JSON.stringify(Object.keys(objTopic)) // Array
                if (objWithTopicAsKey.hasOwnProperty(keyNewObj)) {
                    objWithTopicAsKey[keyNewObj].push(key)
                } else {
                    objWithTopicAsKey[keyNewObj] = [key]
                }
            }
            const arrayUnsubInfo = []
            for (const [arrTopicAsKey, arrValue] of Object.entries(objWithTopicAsKey)) {
                let topic = JSON.parse(arrTopicAsKey)
                if (arrValue.length === 0 || topic.length === 0) {
                    // Không làm gì
                } else {
                    arrayUnsubInfo.push({
                        topic: topic,
                        value: arrValue,
                        component: component,
                    })
                }
            }
            // console.log('UNSUB >>>>>>>>>>>>', arrayUnsubInfo)
            return arrayUnsubInfo
            // console.log('removeSubInfoFromMap [currentSubMapAfterUnsub]  >>>>>>>>>><<<<<<<<<<<<<<', arrayUnsubInfo)
        }
        this.reSubAllWhenReconnectMarket = () => {
            const currSubMap = { ...this.subControlMap }
            const objWithTopicAsKey = {}
            // console.log('arrayDiff', diffResultObject)
            for (const [key, objTopic] of Object.entries(currSubMap)) {
                // console.log(`${key}:`, objTopic)
                const keyNewObj = JSON.stringify(Object.keys(objTopic)) // Array
                if (objWithTopicAsKey.hasOwnProperty(keyNewObj)) {
                    objWithTopicAsKey[keyNewObj].push(key)
                } else {
                    objWithTopicAsKey[keyNewObj] = [key]
                }
            }
            const arrayUnsubInfo = []
            for (const [arrTopicAsKey, arrValue] of Object.entries(objWithTopicAsKey)) {
                // console.log(`arrTopicAsKey ${arrTopicAsKey}:`, arrValue)
                arrayUnsubInfo.push({
                    topic: JSON.parse(arrTopicAsKey),
                    value: arrValue,
                })
            }
            arrayUnsubInfo.map((subInfo) => {
                subcribeFunctStream({
                    Command: 'SUB',
                    topic: subInfo.topic,
                    value: subInfo.value,
                })
            })
            // return arrayUnsubInfo
        }
    }
}

const theInstance = new ControlService()
// @ts-expect-error
window.control_sv = theInstance
export default theInstance
