import { glb_sv } from "."

/**
 * 
 * @param {Array} preventSecCode 
 * @returns {Boolean}: true is allow, false is prevent
 */
const allowCompanyRender = (preventSecCode: ISecCode[] = []) => {
    return preventSecCode.includes(glb_sv.activeCode)
}

export default allowCompanyRender