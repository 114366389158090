import { glb_sv, socket_sv, control_sv, requestInfo, InputServiceBody } from '../index'
import isEqual from 'lodash/isEqual'

/**
 * @param {Object} serviceInfo                  Thông tin cơ bản của service
 * @param {Array} inputParams                   Mảng các tham số của service cần lấy
 * @param {Function} handleResultFunc           Hàm xử lý kết quả
 * @param {Boolean} isControlTimeOut            Xác định có xử lý TimeOut hay không. Mặc định luôn có timeOut (isControlTimeOut = true)
 * @param {Function} onTimeout      Để log, warn, openmodal hay xử lý lỗi cho từng request đặc biệt (optional)
 * @param {String} OTP                          Truyền OTP cho request login
 * @param {Number} time                         Thời gian time out cho request. Mặc định lấy từ timeout config
 * mặc định chỉ log "Request bị timeOut"
 * 
 */

const sendRequest = (serviceInfo: IServiceInfo, inputParams: (string | undefined)[], handleResultFunc: (reqInfoMap: IReqInfoMap, message: IServiceRespone) => void, isControlTimeOut: boolean = true, onTimeout: Function = () => null, OTP?: string, time?: number, isClearReqInfoMap?: 'equal_input' | 'equal_service') => {
    if (!socket_sv.socket_trading?.connected || glb_sv.isWaitSendResultLogin) {
        console.warn("Mạng không ổn định, vui lòng thử lại sau: [ServiceInfo]:", serviceInfo, ' [inputParams]: ', inputParams);
        // Nếu request có hàm time out thì cần phải xử lý ==> dể các state trở về trạng thái ban đầu
        onTimeout && onTimeout({ type: 'not_network', inputParams })
        return
    }

    // Nếu không có thì gọi request
    const clientSeq = serviceInfo.clientSeq || socket_sv.getRqSeq();
    const inputServiceBody = new InputServiceBody(
        clientSeq,
        serviceInfo.WorkerName,
        serviceInfo.ServiceName,
        inputParams,
        serviceInfo.Operation,
        null,
        null,
        OTP,
        serviceInfo.AprStat,
        serviceInfo.AprSeq,
        serviceInfo.MakerDt,
        serviceInfo.TimeOut
    );
    // @ts-expect-error
    const reqInfo = new requestInfo(serviceInfo.reqFunct, inputParams, handleResultFunc, serviceInfo.WorkerName, serviceInfo.ServiceName)
    glb_sv.setReqInfoMapValue(clientSeq, reqInfo)

    // nếu request không control timeOut thì warn cho developer biết
    if (!isControlTimeOut) console.warn('Không control timeOut request [ServiceInfo]: ', serviceInfo, ' [inputParams]: ', inputParams);
    // // Nếu đã request và đang đợi phản hồi thì return
    const controlTimeOutKey = serviceInfo.reqFunct + '|' + serviceInfo.WorkerName + '|' + serviceInfo.ServiceName + '|' + JSON.stringify(inputParams)
    
    if (!isClearReqInfoMap && control_sv.ControlTimeOutObj[controlTimeOutKey]) {
        console.warn('Request đang được xử lý, vui lòng đợi!', clientSeq)
        // console.warn('[sendRequest] Request đang được xử lý, vui lòng đợi! Hàm gửi [reqFunct]: ', serviceInfo, ' [inputParams]: ', inputParams)
        return
    }
    // @ts-expect-error 
    socket_sv.send2Sv(socket_sv.channels.REQ_MSG, JSON.stringify(inputServiceBody))
    if (isClearReqInfoMap === 'equal_input') {
        for (let i = 1; i < clientSeq; i++) {
            const reqInfoMap = glb_sv.getReqInfoMapValue(i);
            if (reqInfoMap && reqInfoMap.WorkerName === serviceInfo.WorkerName &&
                reqInfoMap.ServiceName === serviceInfo.ServiceName &&
                reqInfoMap.reqFunct === serviceInfo.reqFunct &&
                isEqual(reqInfoMap.inputParam, inputParams)) {
                    const controlTimeOutKeyClear = serviceInfo.reqFunct + '|' + serviceInfo.WorkerName + '|' + serviceInfo.ServiceName + '|' + JSON.stringify(reqInfoMap.inputParam)
                    control_sv.clearTimeOutRequest(controlTimeOutKeyClear)
                    glb_sv.setReqInfoMapValue(i, null);
            }
        }
    }
    if (isClearReqInfoMap === 'equal_service') {
        for (let i = 1; i < clientSeq; i++) {
            const reqInfoMap = glb_sv.getReqInfoMapValue(i);
            if (reqInfoMap && reqInfoMap.WorkerName === serviceInfo.WorkerName &&
                reqInfoMap.ServiceName === serviceInfo.ServiceName &&
                reqInfoMap.reqFunct === serviceInfo.reqFunct &&
                reqInfoMap.inputParam[0] === inputParams[0]) {
                    const controlTimeOutKeyClear = serviceInfo.reqFunct + '|' + serviceInfo.WorkerName + '|' + serviceInfo.ServiceName + '|' + JSON.stringify(reqInfoMap.inputParam)
                    control_sv.clearTimeOutRequest(controlTimeOutKeyClear)
                    glb_sv.setReqInfoMapValue(i, null);
            }
        }
    }
    // SetTimeout cho Request
    if (isControlTimeOut) control_sv.ControlTimeOutObj[controlTimeOutKey] = setTimeout(handleTimeOut, time || 15000, serviceInfo, controlTimeOutKey, onTimeout, reqInfo, clientSeq, inputParams);
}

const handleTimeOut = (serviceInfo: IServiceInfo, controlTimeOutKey: string, onTimeout: Function, reqInfo, clientSeq: number, inputParams: (string | undefined)[] ) => {
    console.log('Request bị timeOut [reqFunct]: ', serviceInfo, ' [controlTimeOutKey]: ', controlTimeOutKey);
    control_sv.clearTimeOutRequest(controlTimeOutKey)
    // Clear luôn handleResultFunc để tránh lỗi khi server gửi response về sau khi đã timeout
    control_sv.clearReqInfoMapRequest(clientSeq)
    // Xử lý time out cho từng màn hình nếu có
    onTimeout && onTimeout({ type: 'timeout', inputParams })
}

export default sendRequest
