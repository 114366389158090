// import io_1 from 'socket.io-client'
// import io_2 from 'socket.io-client'
import SocketIO from 'socket.io-client'
import { eventList, channels, glb_sv, control_sv, reqFunct, InfoStock } from '../index'
import { Subject } from 'rxjs'
import { STORE_KEY } from '../index'
import moment from 'moment'

const io_1 = SocketIO.io
const io_2 = SocketIO.io


class SocketService {
    stream_conect_first: any
    timeMarket: any
    currentTimeServer: any
    trading_conect_first: any
    getSendReqKeyMsg: (msgTp: any) => any
    send2Sv(AUTHEN_REQ: string, msgObj2: { ClientSeq: number; TransId?: any; LoginID?: any; MdmTp?: any; Token?: any }) {
        throw new Error('Method not implemented.')
    }
    RequestSeq: number
    key_MapReq: Map<any, any>
    channels: {
        SYS_MSG: string; CHAT_MSG: string; MKT_INFO_REQ: string; MKT_INFO_RES: string; MKT_INFO: string; MKT_TOP: string; PT_BOARD: string; EFF_TOP: string; FRG_TOP: string; FRG_MKT_SHARE: string; NTF_MSG: string; LIST_CHANNELS_STREAM: string[]; SUB_REQ: string; SUB_RES: string; UNSUB_REQ: string; UNSUB_RES: string; onFOSStream: string; HIST_REQ: string; HIST_RES: string; EXCHANGE_TIME: string; AUTHEN_REQ: string; AUTHEN_RES: string; LIST_CHANNELS_TRADING: string[]; REQ_MSG: string; RES_MSG: string //-------------------- Login
    }
    socket_public_flag: boolean
    socket_market_flag: boolean
    socket_stream: any
    socket_trading: any
    url_trading: any[]
    url_stream: any[]
    url_stream_index: number
    url_trading_index: number
    getRqSeq: () => number
    server_node_list: any[]
    current_server_node: number
    anotherLoginFlag: boolean
    event_ClientReqMRKRcv: Subject<unknown>
    connect_event: {
        //------------------- Connect socket
        REQ_RECONNECT_ON_CONNECT_ERROR: string
        //-------------------- Login
        //--------------------
        REQ_AUTO_LOGIN: string; REQ_RELOGIN: string; REQ_RECONNECT_ON_DISCONNECT: string; NETWORK_RECONNECT_SUCCESS: string; SERVER_LOST: string; SWITCH_NODE_SERVER: string
    }
    establishSocketConnect: (type_connect?: string, options?: {}) => void
    reConnectSocket: (type_reconnect: any, options?: {}) => void
    switchNodeServer: (nodeIndex?: number) => boolean
    switchSocketOnNode: (socket_nm: any) => boolean
    timeoutAuthStream: any
    
    constructor() {
        this.RequestSeq = 0
        this.key_MapReq = new Map()
        this.channels = channels

        this.socket_public_flag = false
        this.socket_stream = null
        this.socket_market_flag = false
        this.socket_trading = null
        this.socket_trading = false

        this.url_stream = []
        this.url_trading = []
        this.url_stream_index = 0
        this.url_trading_index = 0

        this.server_node_list = [] // Array các node server hiện tại
        this.current_server_node = 0 // Index of current node server

        // -- event to send broadcast result market data from server after client call a market request
        this.event_ClientReqMRKRcv = new Subject()
        // -- event to send broadcast market data realtime that were pushed from server

        /**
         * @param {Boolean} anotherLoginFlag  Nếu nhận message Code 080001, XXXXX6 -> flag chặn reconnect socket, relogin và các request qua send2Sv
         *
         */
        this.anotherLoginFlag = false

        this.getRqSeq = () => {
            return ++this.RequestSeq
        }
        this.connect_event = {
            //------------------- Connect socket
            REQ_RECONNECT_ON_CONNECT_ERROR: 'REQ_RECONNECT_ON_CONNECT_ERROR',
            //-------------------- Login
            //--------------------
            REQ_AUTO_LOGIN: 'REQ_AUTO_LOGIN',
            REQ_RELOGIN: 'REQ_RELOGIN',
            REQ_RECONNECT_ON_DISCONNECT: 'REQ_RECONNECT_ON_DISCONNECT',
            NETWORK_RECONNECT_SUCCESS: 'NETWORK_RECONNECT_SUCCESS',
            SERVER_LOST: 'SERVER_LOST',
            SWITCH_NODE_SERVER: 'SWITCH_NODE_SERVER',
        }
        // -----------------------------------------------------------------------------------------

        this.establishSocketConnect = (type_connect = 'non_authen', options = {}) => {
            console.log('>>>>>>>>>>>>>>>>>> establishSocketConnect', type_connect, options)
            // -------------------------------- Khởi tạo kết nối
            if (!this.socket_stream?.connected) this.setNewConnectionStream(this.url_stream_index)
            if (!this.socket_trading?.connected) this.setNewConnectionTrading(this.url_trading_index)
            // --------------------------------
            switch (type_connect) {
                // ------------------ Xử lý các yêu cầu kết kết nối phát sinh do trạng thái hiện tại của App
                case 'non_authen': // Chưa đăng nhập, user thông thường
                    glb_sv.LastConnectStatus = 'non_authen'
                    break
                case 'pre_auto_authen': // Chuẩn bị đăng nhập tự động
                    glb_sv.LastConnectStatus = 'pre_auto_authen'
                    break
                case 'pre_relogin': // Chuẩn bị đăng nhập lại
                    glb_sv.LastConnectStatus = 'pre_relogin'
                    break
                case 'authen_success': // Khi đăng nhập thành công
                    glb_sv.LastConnectStatus = 'authen_success'
                    // Event thông báo đã đăng nhập thành công
                    glb_sv.commonEvent.next({ type: eventList.LOGIN_SUCCESS, options: {} })
                    break
                default:
                    console.warn('establishSocketConnect [socket_sv]: wrong key. plase check carefully!', type_connect)
                    break
            }
        }

        this.reConnectSocket = (type_reconnect, options: {
            socket_nm?: 'socket_stream' | 'socket_trading'
        } = {}) => {
            console.log('Log target: type_reconnect', type_reconnect, options)
            if (options.socket_nm === 'socket_stream') {
                if (!this.socket_stream?.connected) this.setNewConnectionStream(this.url_stream_index)
            }
            if (options.socket_nm === 'socket_trading') {
                if (!this.socket_trading?.connected) this.setNewConnectionTrading(this.url_trading_index)
            }
            // ------------------ Xử lý các yêu cầu kết kết nối phát sinh do kết nối mạng.
            //     case 'reconnect_on_error': // Trường hợp connect_error
            // break;
            //     case 'reconnect_on_disconnect': // Trường hợp thiết lập lại connect do disconnect
            // break;
            //     case 'reconnect_on_server_lost': // Trường hợp thiết lập lại connect do server sập
            // break;
        }

        this.switchNodeServer = (nodeIndex = 0) => {
            // @ts-expect-error
            localStorage.setItem(STORE_KEY.SERVER, nodeIndex)
            // console.log("switchNodeServer", nodeIndex, this.server_node_list);
            // Set lại node server hiện tại
            this.current_server_node = nodeIndex
            const currentNode = this.server_node_list[nodeIndex]
            this.url_stream = currentNode.stream_server.ip_address
            this.url_trading = currentNode.trading_server.ip_address
            this.url_stream_index = 0
            this.url_trading_index = 0
            // Trước khi change node cần phải disconnect hết tất cả socket cũ
            if (this.socket_stream?.connected) this.socket_stream?.disconnect()
            if (this.socket_trading?.connected) this.socket_trading?.disconnect()
            // Bắn một event thông báo switch node server
            glb_sv.eventConnect.next({ type: this.connect_event.SWITCH_NODE_SERVER, options: {} })
            return true
        }

        this.switchSocketOnNode = (socket_nm) => {
            const numberUrlMarket = this.url_stream.length || 1
            const numberUrlTrading = this.url_trading.length || 1
            // Switch socket
            console.log('switchSocketOnNode socket_nm', socket_nm, numberUrlMarket)
            switch (socket_nm) {
                case 'socket_stream':
                    this.url_stream_index = (this.url_stream_index + 1) % numberUrlMarket
                    break
                case 'socket_trading':
                    this.url_trading_index = (this.url_trading_index + 1) % numberUrlTrading
                    break
                default:
                    break
            }
            return true
        }

        this.send2Sv = (key, message) => {
            console.log('send2Sv', key, message)

            //-- Nếu ở trạng thái relogin -> ko gửi các request service khác login
            if (this.anotherLoginFlag) return
            //-- Kiểm tra request là dạng market or service request
            if (channels.LIST_CHANNELS_STREAM.includes(key)) {
                this.socket_stream?.emit(key, message)
            } else {
                this.socket_trading?.emit(key, message)
            }
        }

        this.setNewConnectionStream = (index = 0) => {
            // Nếu đang kết nối rồi thì return và warning
            if (this.socket_stream?.connected) {
                console.log('Log target: this.socket_stream?.connected', this.socket_stream?.connected)
                return
            }
            // Thiết lập kết nối
            let httpType = 'http'
            httpType = this.url_stream[this.url_stream_index].substr(0, 5)
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            this.socket_stream =
                httpType === 'https'
                    ? io_1(this.url_stream[this.url_stream_index], {
                          path: glb_sv.configInfo.sub_path_stream || '',
                          timeout: 2000,
                          secure: true,
                          reconnection: false,
                      })
                    : io_1(this.url_stream[this.url_stream_index], {
                          path: glb_sv.configInfo.sub_path_stream || '',
                          timeout: 2000,
                          reconnection: false,
                      })
            this.socket_StartListener_Stream(this.socket_stream)
            // ----------------------- Log
            console.log('setNewConnectionStream', this.url_stream[this.url_stream_index])
        }

        this.setNewConnectionTrading = (index = 0) => {
            // Nếu đang kết nối rồi thì return và warning
            if (this.socket_trading?.connected) {
                console.log('Log target: this.socket_trading?.connected', this.socket_trading?.connected)
                return
            }
            // Tạo một kết nối mới
            let httpType = 'http'
            httpType = this.url_trading[this.url_trading_index].substr(0, 5)
            this.socket_trading =
                httpType === 'https'
                    ? io_2(this.url_trading[this.url_trading_index], {
                          path: glb_sv.configInfo.sub_path_trading || '',
                          timeout: 2000,
                          secure: true,
                          reconnection: false,
                      })
                    : io_2(this.url_trading[this.url_trading_index], {
                          path: glb_sv.configInfo.sub_path_trading || '',
                          timeout: 2000,
                          reconnection: false,
                      })
            this.socket_StartListener_Trading(this.socket_trading)
            // ------------------------------- Log
            console.log('setNewConnectionTrading', this.url_trading[this.url_trading_index])
        }

        this.socket_StartListener_Stream = (socket, socket_nm = 'socket_stream') => {
            socket.on('connect', (data) => {
                control_sv.reSubAllWhenReconnectMarket()

                //----------------------
                console.log('connect with transport: ' + socket.io.engine.transport.name, socket_nm, socket.id)
                // Set lại isInternetReachable đề phòng trường hợp thư viện NetInfo check sai
                glb_sv.isInternetReachable = true
                //------------------------------------
                // Gửi event yêu cầu lấy lại dữ liệu thị trường khi kết nối socket stream thành công
                // if (socket_nm === 'socket_stream') this.broadcastEventGetMktInfo(socket_nm)

                //-------------------------------------------------------
                if (!this.stream_conect_first) {
                    this.stream_conect_first = true
                    glb_sv.commonEvent.next({ type: eventList.CONNECT_MARKET })
                } else {
                    glb_sv.mapDataEP = new Map()
                    glb_sv.mapSMLDataEP = new Map()
                    glb_sv.commonEvent.next({ type: eventList.RECONNECT_MARKET })
                }
            })
            socket.on('connect_error', (data) => {
                console.log(socket_nm, ' connect_error ', data)
                socket.sendBuffer = []
                // Xử lý khi gặp lỗi connect_error
                if (glb_sv.isInternetReachable) {
                    // Trường hợp mạng vẫn đang kết nối ổn định nhưng server sập thì đổi server khác và thông báo để connect lại
                    if (this.switchSocketOnNode(socket_nm)) {
                        console.warn('Server lost', socket_nm, socket.io.uri)
                        // glb_sv.eventConnect.next({ type: this.connect_event.SERVER_LOST, data: socket_nm, uri: socket.io.uri })
                        setTimeout(() => {
                            this.reConnectSocket('reconnect_on_server_lost', { socket_nm })
                        }, 1000)
                    }
                } else {
                    // Trường hợp mất kết nối mạng (Không phải server sập)
                    socket.destroy()
                    // glb_sv.eventConnect.next({ type: this.connect_event.REQ_RECONNECT_ON_CONNECT_ERROR, data: socket_nm, uri: socket.io.uri });
                    setTimeout(() => {
                        this.reConnectSocket('reconnect_on_error', { socket_nm })
                    }, 1000)
                }
            })

            socket.on('disconnect', (data) => {
                console.log(socket_nm, ' disconnect ', data)
                if (this.anotherLoginFlag) return
                socket.sendBuffer = []
                // Gửi event reconnect socket
                glb_sv.eventConnect.next({
                    type: this.connect_event.REQ_RECONNECT_ON_DISCONNECT,
                    socket_nm: socket_nm,
                    uri: socket.io.uri,
                })
                setTimeout(() => {
                    this.reConnectSocket('reconnect_on_disconnect', { socket_nm })
                }, 1000)
            })

            // streamer server
            socket.on(channels.SUB_RES, (data) => {
                console.log('SUB_RES', data)
                const { inputParam, onSuccess, onFailed, ...reqInfoMap } = glb_sv.getReqInfoMapValue(data.ClientSeq)
                if (data.Result === 1) {
                    if (inputParam.topic.includes('MDDS|SI')) {
                        setTimeout(() => {
                            glb_sv.eventMarket.next({
                                type: eventList.REQ_AFTER_SUB_INFO,
                                value: inputParam.value,
                                topic: inputParam.topic,
                            })
                            onSuccess(data)
                        }, 100)
                    } else {
                        setTimeout(() => {
                            onSuccess(data)
                        }, 100)
                    }
                    // Clear timeoutSub khi sub thành công
                    if (inputParam.topic) {
                        control_sv.clearTimeOutRequest(reqInfoMap.key)
                    }
                } else {
                    glb_sv.eventMarket.next({
                        type: eventList.SUB_INFO_ERROR,
                        value: inputParam.value,
                        topic: inputParam.topic,
                    })
                    onFailed(data)
                }
                // Clear timeoutSub khi sub thành công
                // if (inputParam.topic) {
                //     const controlTimeOutKey =
                //         'SUB' + '|' + JSON.stringify(inputParam.topic) + '|' + JSON.stringify(inputParam.value)
                //     control_sv.clearTimeOutRequest(controlTimeOutKey)
                // }
            })

            socket.on(channels.UNSUB_RES, (data) => {
                const { inputParam, onSuccess, onFailed } = glb_sv.getReqInfoMapValue(data.ClientSeq)
                onSuccess(data)
                // Clear timeoutSub khi unsub thành công
                if (inputParam.topic) {
                    const controlTimeOutKey =
                        'UNSUB' + '|' + JSON.stringify(inputParam.topic) + '|' + JSON.stringify(inputParam.value)
                    control_sv.clearTimeOutRequest(controlTimeOutKey)
                }
            })

            socket.on(channels.onFOSStream, (data) => {
                // console.log('🚀 ~ SocketService ~ socket.on ~ data:', data)
                // dùng để reset dữ liệu đồ thị
                if (data.topic === 'CLEAR_ALL') {
                    const keyStock = Object.keys(glb_sv.StockMarket)
                    keyStock.forEach((e) => {
                        glb_sv.StockMarket[e].arrMinutes = []
                        glb_sv.StockMarket[e].EP = []
                    })
                    const keyIndex = Object.keys(glb_sv.IndexMarket)
                    keyIndex.forEach((e) => {
                        glb_sv.IndexMarket[e].arrMinutes = []
                        glb_sv.IndexMarket[e].indexArr = []
                        glb_sv.IndexMarket[e].indexValueChang = 0
                        glb_sv.IndexMarket[e].indexRatioChang = 0
                    })
                    glb_sv.eventMarket.next({ type: eventList.RESET_DATA })

                    return
                } else if (data.topic === 'EXCHANGE_TIME') {
                    let nextTimeServer
                    try {
                        if (data.data[0].W.length !== 8) return // Nếu không đúng định dạng thì return
                        nextTimeServer = data.data[0].W
                        this.timeMarket = data.data[0].T
                    } catch (err) {
                        console.error('Lỗi data EXCHANGE_TIME')
                        return
                    }
                    if (!nextTimeServer) {
                        console.error('Lỗi data EXCHANGE_TIME')
                        return
                    }
                    if (!this.currentTimeServer) {
                        this.currentTimeServer = nextTimeServer
                        glb_sv.commonEvent.next({ type: eventList.EXCHANGE_TIME })
                        return
                    }
                    glb_sv.commonEvent.next({ type: eventList.EXCHANGE_TIME })
                    if (nextTimeServer !== this.currentTimeServer) {
                        this.currentTimeServer = nextTimeServer
                        const keyStock = Object.keys(glb_sv.StockMarket)
                        keyStock.forEach((e) => {
                            const stkMsgObj = new InfoStock()
                            stkMsgObj.t55 = e
                            stkMsgObj.U10 = glb_sv.StockMarket[e].U10
                            stkMsgObj.U9 = glb_sv.StockMarket[e].U9
                            stkMsgObj.U34 = glb_sv.StockMarket[e].U34
                            glb_sv.StockMarket[e] = stkMsgObj
                        })
                        const keyIndex = Object.keys(glb_sv.IndexMarket)
                        keyIndex.forEach((e) => {
                            glb_sv.IndexMarket[e].arrMinutes = []
                            glb_sv.IndexMarket[e].indexArr = []
                            glb_sv.IndexMarket[e].indexValueChang = 0
                            glb_sv.IndexMarket[e].indexRatioChang = 0
                        })
                        glb_sv.eventMarket.next({ type: eventList.RESET_DATA })
                        return
                    }
                    return
                }
                // dùng để show đồ thị giá tỉ lệ khối lượng
                else if (data.topic.includes('AVG_MATCH_INTRADAY')) {
                    glb_sv.eventMarket.next({ type: eventList.AVG_MATCH_INTRADAY, msgKey: data.data.S, ...data.data })
                } else if (
                    data.topic.includes('TOP_PRI_DOWN') || 
                    data.topic.includes('TOP_QTY_UP') || 
                    data.topic.includes('TOP_VAL_UP') || 
                    data.topic.includes('TOP_PRI_UP') ||
                    data.topic.includes('FRG_VAL_UP') ||
                    data.topic.includes('FRG_BUY_UP') ||
                    data.topic.includes('FRG_SELL_UP')
                ) {
                    glb_sv.eventMarket.next({ type: eventList.MKT_TOP, topic: data.topic, ...data.data })
                    const InfoHisResponse = data.data
                    // Update dữ liệu vào glb store
                    glb_sv.StatisticMarket.updateStatisticData({
                        key: InfoHisResponse['EX'],
                        time: '1D', // Realtime
                        topic: InfoHisResponse['TOP'],
                        data: data.data.ITEMS,
                    })
                } else if (data.topic.includes('PT_BOARD')) {
                    glb_sv.eventMarket.next({ type: eventList.MKT_PT, topic: data.topic, data: data.data })
                } else if (data.topic.includes('INTRADAY_1s')) {
                    // !!! {Dung}  MKT_INTRADAY Không dùng, hỏi lại anh Tuấn xem xét xóa, sửa cho đỡ rối
                    // glb_sv.appendDataIntraday(data.data)
                    // dùng để realtime đồ thị xu hướng watchlist
                    const newData = data.data
                    glb_sv.eventMarket.next({
                        type: eventList.MKT_INTRADAY,
                        topic: 'INTRADAY_1s',
                        ...data.data,
                        msgKey: newData.S,
                    })
                } else if (data.topic.includes('INTRADAY_5s')) {
                    // glb_sv.appendDataIntraday(data.data)
                    glb_sv.eventMarket.next({ type: eventList.MKT_INTRADAY, topic: data.topic, ...data.data })
                } else if (data.topic.includes('INTRADAY_15s')) {
                    // glb_sv.appendDataIntraday(data.data)
                    glb_sv.eventMarket.next({ type: eventList.MKT_INTRADAY, topic: data.topic, ...data.data })
                } else if (data.topic.includes('INTRADAY_30s')) {
                    // glb_sv.appendDataIntraday(data.data)
                    glb_sv.eventMarket.next({ type: eventList.MKT_INTRADAY, topic: data.topic, ...data.data })
                } else if (data.topic.includes('INTRADAY_1m')) {
                    const newData = data.data
                    const T = glb_sv.timeServer + '-' + newData.T
                    const time = moment(T, 'YYYYMMDD-HH:mm:ss:SSSSSS').valueOf()
                    const dataConverted = { ...newData, T, time }
                    const msgKey = data.topic ? data.topic.split('|')[1] : '';
                    if (msgKey) {
                        const isIndex = ['HSXIndex', 'HNXIndex', 'HNXUpcomIndex', 'HNX30', 'VN30', 'VNXALL'].includes(msgKey);
                        if (isIndex) {
                            glb_sv.IndexMarket.setIndexData({
                                key: newData.S,
                                time: '1D', // Realtime trong ngày
                                topic: 'INTRADAY_1m',
                                data: dataConverted,
                                mode: 'append', // Get History thì mode là overide hoặc concat
                            })
                        } else {
                            glb_sv.StockMarket.setStockData({
                                key: msgKey,
                                time: '1D', // Realtime trong ngày
                                topic: 'INTRADAY_1m',
                                data: dataConverted,
                                mode: 'append', // Data realtime thì mode là append
                            })
                        }
                    }
                    // console.log('dataConverted', dataConverted, data)
                    // glb_sv.appendDataIntraday(data.data)
                    glb_sv.eventMarket.next({
                        type: eventList.MKT_INTRADAY,
                        topic: 'INTRADAY_1m',
                        ...data.data,
                        msgKey: newData.S,
                    })
                } else if (data.topic.includes('INTRADAY_5m')) {
                    // glb_sv.appendDataIntraday(data.data)
                    glb_sv.eventMarket.next({ type: eventList.MKT_INTRADAY, topic: data.topic, ...data.data })
                } else if (data.topic.includes('INTRADAY_15m')) {
                    // glb_sv.appendDataIntraday(data.data)
                    glb_sv.eventMarket.next({ type: eventList.MKT_INTRADAY, topic: data.topic, ...data.data })
                } else if (data.topic.includes('INTRADAY_30m')) {
                    // glb_sv.appendDataIntraday(data.data)
                    glb_sv.eventMarket.next({ type: eventList.MKT_INTRADAY, topic: data.topic, ...data.data })
                } else if (data.topic.includes('INTRADAY_1h')) {
                    // glb_sv.appendDataIntraday(data.data)
                    glb_sv.eventMarket.next({ type: eventList.MKT_INTRADAY, topic: data.topic, ...data.data })
                } else if (data.topic.includes('BUY_SELL_FLOW')) {
                    const msgKey = data.topic ? data.topic.split('|')[1] : ''
                    if (msgKey) {
                        const isIndex = ['HSXIndex', 'HNXIndex', 'HNXUpcomIndex'].includes(msgKey)
                        if (isIndex) {
                            if (!glb_sv.IndexMarket[msgKey]) return
                            const lastTime = glb_sv.IndexMarket[msgKey].BUY_SELL_FLOW.T.acl[0]
                                ? glb_sv.IndexMarket[msgKey].BUY_SELL_FLOW.T.acl[0][1]
                                : null
                            const timeStream = moment(data.data.T, 'HH:mm:ss').add(7, 'hours').valueOf()

                            if (lastTime) {
                                if (timeStream <= lastTime) return
                                glb_sv.IndexMarket[msgKey].BUY_SELL_FLOW.T.acl.push([
                                    timeStream,
                                    Number((data.data.TBA - data.data.TSA).toFixed(2)),
                                ])
                                glb_sv.IndexMarket[msgKey].BUY_SELL_FLOW.T.rt.push([
                                    timeStream,
                                    Number((data.data.BA - data.data.SA).toFixed(2)),
                                ])
                            } else {
                                glb_sv.IndexMarket[msgKey].BUY_SELL_FLOW.T.acl.push([
                                    timeStream,
                                    Number((data.data.TBA - data.data.TSA).toFixed(2)),
                                ])
                                glb_sv.IndexMarket[msgKey].BUY_SELL_FLOW.T.rt.push([
                                    timeStream,
                                    Number((data.data.BA - data.data.SA).toFixed(2)),
                                ])
                            }

                            glb_sv.eventMarket.next({ type: eventList.BUY_SELL_FLOW, msgKey, isHistory: true })
                        } else {
                            if (!glb_sv.StockMarket[msgKey]) return
                            const lastTime = glb_sv.StockMarket[msgKey].BUY_SELL_FLOW.T.acl[0]
                                ? glb_sv.StockMarket[msgKey].BUY_SELL_FLOW.T.acl[0][1]
                                : null
                            const timeStream = moment(data.data.T, 'HH:mm:ss').add(7, 'hours').valueOf()

                            if (lastTime) {
                                if (timeStream <= lastTime) return
                                glb_sv.StockMarket[msgKey].BUY_SELL_FLOW.T.acl.push([
                                    timeStream,
                                    Number((data.data.TBA - data.data.TSA).toFixed(2)),
                                ])
                                glb_sv.StockMarket[msgKey].BUY_SELL_FLOW.T.rt.push([
                                    timeStream,
                                    Number((data.data.BA - data.data.SA).toFixed(2)),
                                ])
                            } else {
                                glb_sv.StockMarket[msgKey].BUY_SELL_FLOW.T.acl.push([
                                    timeStream,
                                    Number((data.data.TBA - data.data.TSA).toFixed(2)),
                                ])
                                glb_sv.StockMarket[msgKey].BUY_SELL_FLOW.T.rt.push([
                                    timeStream,
                                    Number((data.data.BA - data.data.SA).toFixed(2)),
                                ])
                            }

                            glb_sv.eventMarket.next({ type: eventList.BUY_SELL_FLOW, msgKey, isHistory: true })
                        }
                    }
                } else if (data.topic.includes('MKT_INC_DEC')) {
                    const msgKey = data.topic ? data.topic.split('|')[1] : ''
                    if (msgKey) {
                        glb_sv.eventMarket.next({ type: eventList.MKT_INC_DEC, msgKey, data: data.data })
                    }
                } else if (data.topic.includes('MDDS|CBI')) {
                    console.log('🚀 ~ SocketService ~ socket.on ~ data:', data.data)
                    glb_sv.updCBI_Msg2MrkInfoMap(data.data)
                } else if (data.topic.includes('MDDS|BBI')) {
                    console.log('🚀 ~ SocketService ~ socket.on ~ data:', data.data)
                    glb_sv.updBBI_Msg2MrkInfoMap(data.data)
                } else {
                    glb_sv.sprocess_ForOneMsg(data.data)
                }
            })

            socket.on(channels.HIST_RES, (data) => {
                const { inputParam, onSuccess, onFailed, ...reqInfoMap } = glb_sv.getReqInfoMapValue(data.ClientSeq)

                // Clear timeout Sau khi nhận phản hồi -------------------------------------------------
                // controlTimeOutKey = String(Command) + "|" + String(seq) + "|" + JSON.stringify(topic) + "|" + JSON.stringify(value) + "|" + String(fromseq) + "|" + String(size)
                // if (inputParam.topic) {
                //     const controlTimeOutKey =
                //         'GET_HIST' +
                //         '|' +
                //         JSON.stringify(inputParam.topic) +
                //         '|' +
                //         JSON.stringify(inputParam.value) +
                //         '|' +
                //         JSON.stringify(inputParam.fromseq) +
                //         '|' +
                //         JSON.stringify(inputParam.size)
                //     control_sv.clearTimeOutRequest(controlTimeOutKey)
                // }

                // console.log('HIST_RES', data, inputParam, reqInfoMap)
                // console.log('HIST_RES', JSON.stringify(data.Data))

                // !!! {Tuan} Em thêm topic để biết msg nào done
                //
                //

                if (data.Result === 1) {
                    if (inputParam.topic) {
                        control_sv.clearTimeOutRequest(reqInfoMap.key)
                    }
                    if (data.Message === 'DONE') {
                        if (inputParam.topic[0] === 'INTRADAY_1s') {
                            // console.log('DONE INTRADAY_1s', glb_sv.StockMarket[inputParam.value[0]].INTRADAY_1s)
                        } else if (inputParam.topic[0] === 'INTRADAY_1m') {
                            if (reqInfoMap.reqFunct === reqFunct.GET_HIST_INDEX) {
                                glb_sv.eventMarket.next({
                                    type: eventList.GET_DATA_I_MINUTES_DONE,
                                    msgKey: inputParam.value[0],
                                })
                            }
                            if (reqInfoMap.reqFunct === reqFunct.GET_HIST_STOCK) {
                                glb_sv.eventMarket.next({
                                    type: eventList.GET_DATA_EP_CHART_DONE,
                                    msgKey: inputParam.value[0],
                                })
                            }
                        } else if (inputParam.topic[0] === 'INTRADAY_30m') {
                            if (reqInfoMap.reqFunct === reqFunct.GET_HIST_INDEX) {
                                glb_sv.eventMarket.next({
                                    type: eventList.GET_DATA_I_DONE,
                                    msgKey: inputParam.value[0],
                                    topic: inputParam.topic,
                                })
                            }
                            if (reqInfoMap.reqFunct === reqFunct.GET_HIST_STOCK) {
                                setTimeout(() => {
                                    glb_sv.eventMarket.next({
                                        type: eventList.GET_DATA_EP_CHART_DONE,
                                        msgKey: inputParam.value[0],
                                        topic: inputParam.topic,
                                    })
                                }, 0)
                            }
                        } else if (inputParam.topic[0] === 'MDDS|I') {
                            glb_sv.eventMarket.next({ type: eventList.SUB_INDEX, isHistory: true })
                        } else if (inputParam.topic[0] === 'MDDS|EP') {
                            setTimeout(() => {
                                glb_sv.eventMarket.next({
                                    type: eventList.GET_DATA_EP_DONE,
                                    msgKey: inputParam.value[0],
                                })
                            }, 0)
                        }
                        return
                    }
                    const list = data.Data
                    if (inputParam.topic[0] === 'INTRADAY_1s') {
                        if (!list.length) return
                        const msgObj = list[0].data
                        if (reqInfoMap.reqFunct === reqFunct.GET_HIST_INDEX) {
                            const data = list.map((e) => {
                                const newData = e.data
                                const T = glb_sv.timeServer + '-' + newData.T
                                const time = moment(T, 'YYYYMMDD-HH:mm:ss:SSSSSS').valueOf()
                                return { ...newData, T, time, seq: e.seq }
                            })
                            if (inputParam.fromseq[0] === 0) {
                                glb_sv.IndexMarket[msgObj.S].INTRADAY_1s = data
                            } else {
                                glb_sv.IndexMarket[msgObj.S].INTRADAY_1s = data.concat(
                                    glb_sv.IndexMarket[msgObj.S].INTRADAY_1s
                                )
                            }
                        } else if (reqInfoMap.reqFunct === reqFunct.GET_HIST_STOCK) {
                            const data = list.map((e) => {
                                const newData = e.data
                                const T = glb_sv.timeServer + '-' + newData.T
                                const time = moment(T, 'YYYYMMDD-HH:mm:ss:SSSSSS').valueOf()
                                return { ...newData, time, seq: e.seq }
                            })
                            if (inputParam.fromseq[0] === 0) {
                                glb_sv.StockMarket[msgObj.S].INTRADAY_1s = data
                            } else {
                                glb_sv.StockMarket[msgObj.S].INTRADAY_1s = data.concat(
                                    glb_sv.StockMarket[msgObj.S].INTRADAY_1s
                                )
                            }
                        }
                    }
                    if (inputParam.topic[0] === 'INTRADAY_1m') {
                        if (!list.length) return
                        const msgObj = list[0].data
                        if (reqInfoMap.reqFunct === reqFunct.GET_HIST_INDEX) {
                            const data = list.map((e) => {
                                const newData = e.data
                                const T = glb_sv.timeServer + '-' + newData.T
                                const time = moment(T, 'YYYYMMDD-HH:mm:ss:SSSSSS').valueOf()
                                return { ...newData, T, time }
                            })
                            glb_sv.IndexMarket[msgObj.S].INTRADAY_1m = data
                        } else if (reqInfoMap.reqFunct === reqFunct.GET_HIST_STOCK) {
                            const data = list.map((e) => {
                                const newData = e.data
                                const T = glb_sv.timeServer + '-' + newData.T
                                const time = moment(T, 'YYYYMMDD-HH:mm:ss:SSSSSS').valueOf()
                                return { ...newData, time }
                            })
                            glb_sv.StockMarket[msgObj.S].INTRADAY_1m = data
                        }
                    }
                    if (inputParam.topic[0] === 'INTRADAY_1h') {
                        // console.log('INTRADAY_1h', list)
                    }
                    if (inputParam.topic[0] === 'MDDS|I') {
                        if (list.length) {
                            const msgKey = list[0].data.t2
                            // console.log("jsndkajsbd sdkashbd skhjd");
                            glb_sv.IndexMarket[msgKey].indexArr = []
                        }
                        for (let i = 0; i < list.length; i++) {
                            const isDone = i === list.length - 1
                            glb_sv.updI_MsgHistory(list[i].data, isDone)
                        }
                    }
                    if (inputParam.topic[0] === 'MDDS|EP') {
                        if (!list.length) return
                        const msgObj = list[0].data

                        if (inputParam.size[0] === 50) {
                            glb_sv.StockMarket[msgObj.t55].EP = list.map((e) => {
                                return { ...e.data, seqHis: e.seq }
                            })
                            // .filter((e) => e['t33'] === 'M1' || e['t33'] === 'M') fix lỗi khi lấy lịch sử EP
                            // data cho du lieu thoi gian thuc
                            glb_sv.eventMarket.next({ type: eventList.GET_DATA_EP_DONE, msgKey: msgObj.t55 })
                            return
                        }

                        if (inputParam.size[0] === 200) {
                            const newList = glb_sv.StockMarket[msgObj.t55].EP
                            glb_sv.StockMarket[msgObj.t55].EP = list
                                .map((e) => {
                                    return { ...e.data, seqHis: e.seq }
                                })
                                .concat(newList)
                            return
                        }
                        if (inputParam.size[0] === 500) {
                            const newList = glb_sv.StockMarket[msgObj.t55].EP
                            glb_sv.StockMarket[msgObj.t55].EP = list
                                .map((e) => {
                                    return { ...e.data, seqHis: e.seq }
                                })
                                .concat(newList)
                            glb_sv.eventMarket.next({ type: eventList.GET_MORE_DATA_EP_DONE, msgKey: msgObj.t55 })
                            return
                        }
                    }
                    if (inputParam.topic[0] === 'MDDS|BI') {
                        // console.log('>>>>>>>>>>>>>>>>', data, inputParam)
                    }
                    if (inputParam.topic[0] === 'BUY_SELL_FLOW') {
                        if (!list.length) return
                        const msgKey = list[0].data.S

                        if (reqInfoMap.reqFunct === reqFunct.GET_HIST_INDEX) {
                            if (!glb_sv.IndexMarket[msgKey]) return
                            glb_sv.IndexMarket[msgKey].BUY_SELL_FLOW.T.acl = list.map((e) => [
                                moment(e.data.T, 'HH:mm:ss').add(7, 'hours').valueOf(),
                                Number((e.data.TBA - e.data.TSA).toFixed(2)),
                            ])
                            glb_sv.IndexMarket[msgKey].BUY_SELL_FLOW.T.rt = list.map((e) => [
                                moment(e.data.T, 'HH:mm:ss').add(7, 'hours').valueOf(),
                                Number((e.data.BA - e.data.SA).toFixed(2)),
                            ])

                            glb_sv.eventMarket.next({ type: eventList.BUY_SELL_FLOW, msgKey, isHistory: true })
                        } else if (reqInfoMap.reqFunct === reqFunct.GET_HIST_STOCK) {
                            if (!glb_sv.StockMarket[msgKey]) return
                            glb_sv.StockMarket[msgKey].BUY_SELL_FLOW.T.acl = list.map((e) => [
                                moment(e.data.T, 'HH:mm:ss').add(7, 'hours').valueOf(),
                                Number((e.data.TBA - e.data.TSA).toFixed(2)),
                            ])
                            glb_sv.StockMarket[msgKey].BUY_SELL_FLOW.T.rt = list.map((e) => [
                                moment(e.data.T, 'HH:mm:ss').add(7, 'hours').valueOf(),
                                Number((e.data.BA - e.data.SA).toFixed(2)),
                            ])

                            glb_sv.eventMarket.next({ type: eventList.BUY_SELL_FLOW, msgKey, isHistory: true })
                        }

                        return
                    }

                    // !!! {Tuan} Em test xử lý dữ liệu theo cách mới xíu cho phù hợp với dữ liệu mà em cần
                    if (reqInfoMap.reqFunct === reqFunct.GET_HIST_INDEX) {
                        if (data.Data.length > 0) {
                            // Nếu có dữ liệu
                            const InfoHisResponse = data.Data[0].topic.split('|')
                            // Convert dữ liệu theo chuẩn
                            const dataConverted = data.Data.map((e) => {
                                const newData = e.data
                                const T = glb_sv.timeServer + '-' + newData.T
                                const time = moment(T, 'YYYYMMDD-HH:mm:ss:SSSSSS').valueOf()
                                // console.log(
                                //     'YYYYMMDD-HH:mm:ss:SSSSSS',
                                //     T,
                                //     time,
                                //     moment('20210727-10:54:00', 'YYYYMMDD-HH:mm:ss:SSSSSS').valueOf(),
                                //     moment('20210727-10:54:00', 'YYYYMMDD-HH:mm:ss').valueOf()
                                // )
                                return { ...newData, T, time }
                            })
                            // Append dữ liệu vào array Global
                            let key = ''
                            let topic = ''
                            if (InfoHisResponse.length > 2) {
                                topic = InfoHisResponse[0] + '|' + InfoHisResponse[1]
                                key = InfoHisResponse[2]
                            } else {
                                topic = InfoHisResponse[0]
                                key = InfoHisResponse[1]
                            }

                            glb_sv.IndexMarket.setIndexData({
                                key: key,
                                time: '1D', // Realtime trong ngày
                                topic: topic,
                                data: dataConverted,
                                mode: topic === 'MDDS|BI' ? 'concat' : 'override', // Get History thì mode là overide hoặc concat
                            })
                            /***
                             *
                             * Xét data.Data[0].seq (Tương ứng với số điểm dữ liệu còn thiếu) nếu > 0 nghĩa là lấy tiếp
                             * */
                            setTimeout(() => {
                                if (data.Data[0].seq === 0) {
                                    // Nếu seq === 0 nghĩa là không còn dữ liệu để lấy nữa
                                    // Bắn event thông báo đã getHist xong
                                    glb_sv.eventMarket.next({
                                        type: eventList.GET_HIST_INDEX_DONE,
                                        key,
                                        time: '1D',
                                        topic,
                                    })
                                    return
                                }
                                glb_sv.eventMarket.next({
                                    type: eventList.REQ_GET_ANOTHER_HIST_INDEX_VAL,
                                    key,
                                    time: '1D',
                                    topic,
                                    nextSeq: data.Data[0].seq,
                                })
                            }, 10)
                            // console.log('InfoHisResponse', InfoHisResponse, glb_sv.IndexMarket)
                        }
                    } else if (reqInfoMap.reqFunct === reqFunct.GET_HIST_STOCK) {
                        // Nếu có dữ liệu
                        const InfoHisResponse = data.Data[0].topic.split('|')
                        // Convert dữ liệu theo chuẩn
                        const dataConverted = data.Data.map((e) => {
                            const newData = e.data
                            const T = glb_sv.timeServer + '-' + newData.T
                            const time = moment(T, 'YYYYMMDD-HH:mm:ss:SSSSSS').valueOf()
                            return { ...newData, T, time }
                        })
                        // Append dữ liệu vào array Global
                        let key = ''
                        let topic = ''
                        if (InfoHisResponse.length > 2) {
                            topic = InfoHisResponse[0] + '|' + InfoHisResponse[1]
                            key = InfoHisResponse[2]
                        } else {
                            topic = InfoHisResponse[0]
                            key = InfoHisResponse[1]
                        }

                        glb_sv.StockMarket.setStockData({
                            key: key,
                            time: '1D', // Realtime trong ngày
                            topic: topic,
                            data: dataConverted,
                            mode: topic === 'MDDS|EP' ? 'concat' : 'override', // Get History thì mode là overide hoặc concat
                        })
                        /***
                         *
                         * Xét data.Data[0].seq (Tương ứng với số điểm dữ liệu còn thiếu) nếu > 0 nghĩa là lấy tiếp
                         * */
                        setTimeout(() => {
                            if (data.Data[0].seq === 0) {
                                // Nếu seq === 0 nghĩa là không còn dữ liệu để lấy nữa
                                // Bắn event thông báo đã getHist xong
                                glb_sv.eventMarket.next({
                                    type: eventList.GET_HIST_STOCK_DONE,
                                    key,
                                    time: '1D',
                                    topic,
                                })
                                return
                            }
                            glb_sv.eventMarket.next({
                                type: eventList.REQ_GET_ANOTHER_HIST_STOCK_VAL,
                                key,
                                time: '1D',
                                topic,
                                nextSeq: data.Data[0].seq,
                            })
                        }, 10)
                    }
                    onSuccess(data)
                    // !!! {Tuan} Phần em làm đến đây là hết
                } else {
                    onFailed(data)
                }
            })

            socket.on(channels.AUTHEN_RES, (data) => {
                // console.log("this.socket_StartListener_Stream -> data", data)
                if (data.Result === 0) {
                    if (this.timeoutAuthStream) clearTimeout(this.timeoutAuthStream)
                    this.timeoutAuthStream = setTimeout(() => {
                        glb_sv.sendAuthReqSocketStream(glb_sv.dataAuthStream)
                    }, 1000)
                }
            })
        }

        this.socket_StartListener_Trading = (socket, socket_nm = 'socket_trading') => {
            socket.on('connect', (data) => {
                console.log('connect with transport:', socket.io.engine.transport.name, socket_nm, socket.id)
                // Set lại isInternetReachable đề phòng trường hợp thư viện NetInfo check sai
                glb_sv.isInternetReachable = true
                //------------------------------------
                if (glb_sv.LastConnectStatus === 'pre_relogin' || glb_sv.LastConnectStatus === 'authen_success') {
                    // kết nối socket cho việc đăng nhập lại
                    console.log(
                        '<<<<<<<<<<<<<<<<<<<<<<<<<<<<< pre_relogin',
                        socket.io.uri,
                        this.url_trading[this.url_trading_index]
                    )
                    // Yêu cầu đăng nhập lại ---------------------------------------
                    glb_sv.eventConnect.next({
                        type: this.connect_event.REQ_RELOGIN,
                        data: { socket_nm, url: socket.io.uri },
                    })
                }

                if (!this.trading_conect_first) {
                    this.trading_conect_first = true
                    glb_sv.commonEvent.next({ type: eventList.CONNECT_TRADING })
                } else {
                    glb_sv.commonEvent.next({ type: eventList.RECONNECT_TRADING })
                }
            })
            socket.on('connect_error', (data) => {
                console.log(socket_nm, ' connect_error ', data)
                socket.sendBuffer = []
                // Xử lý khi gặp lỗi connect_error
                if (glb_sv.isInternetReachable) {
                    // Trường hợp mạng vẫn đang kết nối ổn định nhưng server sập thì đổi server khác và thông báo để connect lại
                    if (this.switchSocketOnNode(socket_nm)) {
                        console.warn('Server lost', socket_nm, socket.io.uri)
                        // glb_sv.eventConnect.next({ type: this.connect_event.SERVER_LOST, data: socket_nm, uri: socket.io.uri })
                        setTimeout(() => {
                            this.reConnectSocket('reconnect_on_server_lost', { socket_nm })
                        }, 1000)
                    }
                } else {
                    // Trường hợp mất kết nối mạng (Không phải server sập)
                    socket.destroy()
                    // glb_sv.eventConnect.next({ type: this.connect_event.REQ_RECONNECT_ON_CONNECT_ERROR, data: socket_nm, uri: socket.io.uri });
                    setTimeout(() => {
                        this.reConnectSocket('reconnect_on_error', { socket_nm })
                    }, 1000)
                }
            })

            socket.on('disconnect', (data) => {
                console.log(socket_nm, ' disconnect ', data)
                if (this.anotherLoginFlag) return
                socket.sendBuffer = []
                // Gửi event reconnect socket
                // glb_sv.eventConnect.next({ type: this.connect_event.REQ_RECONNECT_ON_DISCONNECT, socket_nm: socket_nm, uri: socket.io.uri })
                setTimeout(() => {
                    this.reConnectSocket('reconnect_on_disconnect', { socket_nm })
                }, 1000)
            })

            //-- Nhận thông báo hệ thống
            socket.on(channels.SYS_MSG, (data) => {
                let mssg
                if (typeof data === 'string') {
                    mssg = JSON.parse(data)
                } else {
                    mssg = data
                }
                if (mssg['Code'] === 'XXXXX6') {
                    this.anotherLoginFlag = true
                }
                glb_sv.commonEvent.next({ type: eventList.MESSAGE_SYSTEM, data: mssg })
            })

            //-- Nhận phản hồi của server cho một request thông thường
            socket.on(channels.RES_MSG, (data: string) => {
                let mssg: IServiceRespone
                if (typeof data === 'string') {
                    mssg = JSON.parse(data)
                } else {
                    mssg = data
                }

                const cltSeqResult = Number(mssg['ClientSeq'])
                if (!cltSeqResult) return
                const reqInfoMap = glb_sv.getReqInfoMapValue(cltSeqResult)
                if (!reqInfoMap) {
                    console.log('RES_MSG timeout', channels.RES_MSG, mssg)
                    return
                }
                // Trycatch parse before send to handleResult
                try {
                    JSON.parse(mssg["Data"])
                } catch (err) {
                    console.warn('err khi parse data', err, mssg)
                    // Chỉ warning chứ không return (tránh bị timeout hoài)
                    // if (mssg["Data"] !== '') return
                }
                // -------------------
                if (reqInfoMap.receiveFunct) reqInfoMap.receiveFunct(reqInfoMap, mssg)
                glb_sv.commonEvent.next({ type: eventList.RES_COMMON_MSG, ...mssg })
                // Clear timeOut
                if (reqInfoMap) {
                    const controlTimeOutKey =
                        reqInfoMap.reqFunct +
                        '|' +
                        reqInfoMap.WorkerName +
                        '|' +
                        reqInfoMap.ServiceName +
                        '|' +
                        JSON.stringify(reqInfoMap.inputParam)
                    control_sv.clearTimeOutRequest(controlTimeOutKey)
                }
            })

            //-- Nhận dữ liệu notify
            socket.on(channels.NTF_MSG, (data) => {
                let mssg
                if (typeof data === 'string') {
                    mssg = JSON.parse(data)
                } else {
                    mssg = data
                }
                glb_sv.commonEvent.next({ type: eventList.NOTIFY_SERVER, data: mssg })
            })
        }

        this.getSendReqKeyMsg = (msgTp) => {
            return this.key_MapReq.get(msgTp)
        }
    }
    socket_StartListener_Stream(socket_stream: any) {
        throw new Error('Method not implemented.')
    }
    socket_StartListener_Trading(socket_trading: any) {
        throw new Error('Method not implemented.')
    }
    setNewConnectionTrading(url_trading_index: number) {
        throw new Error('Method not implemented.')
    }
    setNewConnectionStream(url_stream_index: number) {
        throw new Error('Method not implemented.')
    }
}

const theInstance = new SocketService()
// @ts-expect-error
window.socket_sv = theInstance
export default theInstance
