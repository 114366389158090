const COMMON = {
    BG__NEUTRAL__COLOR: '#605E61',
    EKYC__COLOR: '#2196f3',
}

const DARK = {
    ...COMMON,
};

const LIGHT = {
    ...COMMON,
    BG__NEUTRAL__COLOR: '#c5c5c5',
};

const CN_DARK = {
    ...DARK,
    //--Các value thay đổi cho china

}

const CN_LIGHT = {
    ...LIGHT,
    //--Các value thay đổi cho china

}

export default {
    DARK,
    LIGHT,
    CN_DARK,
    CN_LIGHT
}