const COMMON = {

}

const DARK = {
    ...COMMON,
    PRIMARY: '#d09312',
    EKYC__COLOR: '#d09312',
}

const LIGHT = {
    ...COMMON,
    PRIMARY: '#0e238f',
    EKYC__COLOR: '#0e238f',
}

const CN_DARK = {
    ...DARK,
    //--Các value thay đổi cho china
}

const CN_LIGHT = {
    ...LIGHT,
    //--Các value thay đổi cho china
}

export default {
    DARK,
    LIGHT,
    CN_DARK,
    CN_LIGHT,
}
