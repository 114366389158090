import React, { lazy, Suspense, useEffect, useRef, useState } from 'react'
import { GlobalHotKeys } from 'react-hotkeys'
import { Route, Switch } from 'react-router-dom'
import LoadingView from './components/Loading/View'
import BackgroundService from './layouts/background-service'
import StoreContext from './store'
import StoreTrading from './store-trading'
import { changeTheme } from './styles/script'
import { eventList, InfoIndex, InfoStock, reqFunct, sendRequest, STORE_KEY } from './utils'
import glb_sv from './utils/service/global_service'
import socket_sv from './utils/service/socket_service'

const Pages = lazy(() => import('./views/Pages'))
const Login = lazy(() => import('./views/Login'))
const TradingViewComponent = lazy(() => import('./components/tradingview'))

function sortAsc(a, b) {
    if (a.t55 > b.t55) return 1
    if (a.t55 < b.t55) return -1
    return 0
}
const ServiceInfo = {
    GET_VERSION_STKLIST: {
        reqFunct: reqFunct.GET_VERSION_STKLIST,
        WorkerName: 'FOSqMkt01',
        ServiceName: 'FOSqMkt01_StockListVersion',
        Operation: 'Q',
        ClientSentTime: '0',
    },
    GET_STKLIST: {
        reqFunct: reqFunct.GET_STKLIST,
        WorkerName: 'FOSqMkt01',
        ServiceName: 'FOSqMkt01_StockListVersion',
        Operation: 'Q',
        ClientSentTime: '0',
    },
    REGISTER_DEVICE_ONESIGNAL: {
        reqFunct: reqFunct.REGISTER_DEVICE_ONESIGNAL,
        WorkerName: 'FOSxID01',
        ServiceName: 'FOSxID01_Token_Mgt',
        Operation: 'I',
        ClientSentTime: '0',
    },
}

const OneSignal = window.OneSignal || []

window.launchFullscreen = () => {
    if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
    } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
    } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen()
    } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen()
    }
}
window.exitFullscreen = () => {
    if (document.exitFullscreen) {
        document.exitFullscreen()
    } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
    } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
    }
}

const App = () => {
    const [loading, setLoading] = useState(true)
    const showAlearRedirect = useRef(false)

    const jsonRef = useRef([])
    const [keyMap, setKeyMap] = useState({})

    const [handlersHotkeys, setHandlersHotkeys] = useState({
        advance_order: React.useCallback((e) => {
            e.preventDefault()
            glb_sv.commonEvent.next({ type: eventList.HOTKEY, value: 'advance-order' })
        }, []),
        place_order: React.useCallback((e) => {
            e.preventDefault()
            glb_sv.commonEvent.next({ type: eventList.HOTKEY, value: 'order' })
        }, []),
        place_order_buy: React.useCallback((e) => {
            e.preventDefault()
            glb_sv.commonEvent.next({ type: eventList.HOTKEY, value: 'order', action: 'buy' })
        }, []),
        place_order_sell: React.useCallback((e) => {
            e.preventDefault()
            glb_sv.commonEvent.next({ type: eventList.HOTKEY, value: 'order', action: 'sell' })
        }, []),
        common_notify: React.useCallback((e) => {
            e.preventDefault()
            glb_sv.commonEvent.next({ type: eventList.HOTKEY, value: 'alert', title: 'common_notify' })
        }, []),
        title_order_information: React.useCallback(() => {
            glb_sv.commonEvent.next({
                type: eventList.HOTKEY,
                value: 'orderInformation',
                title: 'title_order_information',
            })
        }, []),
        sb_right_list_info: React.useCallback((e) => {
            e.preventDefault()
            glb_sv.commonEvent.next({ type: eventList.HOTKEY, value: 'rightToBuy', title: 'sb_right_list_info' })
        }, []),
        stock_transfer_internal: React.useCallback((e) => {
            e.preventDefault()
            glb_sv.commonEvent.next({
                type: eventList.HOTKEY,
                value: 'stockTransfer',
                title: 'stock_transfer_internal',
            })
        }, []),
        right_for_buy: React.useCallback((e) => {
            e.preventDefault()
            glb_sv.commonEvent.next({ type: eventList.HOTKEY, value: 'rightForBuy', title: 'right_for_buy' })
        }, []),
        sb_right_expected_info: React.useCallback((e) => {
            e.preventDefault()
            glb_sv.commonEvent.next({
                type: eventList.HOTKEY,
                value: 'rightInfoExpected',
                title: 'sb_right_expected_info',
            })
        }, []),
        sb_orderAdvert: React.useCallback((e) => {
            e.preventDefault()
            glb_sv.commonEvent.next({ type: eventList.HOTKEY, value: 'orderAdvert', title: 'sb_orderAdvert' })
        }, []),
        bank_online: React.useCallback((e) => {
            e.preventDefault()
            glb_sv.commonEvent.next({ type: eventList.HOTKEY, value: 'onlineBank', title: 'bank_online' })
        }, []),
        sb_transfer_internal: React.useCallback((e) => {
            e.preventDefault()
            glb_sv.commonEvent.next({ type: eventList.HOTKEY, value: 'cashTransfer', title: 'sb_transfer_internal' })
        }, []),
        sb_cash_advance: React.useCallback((e) => {
            e.preventDefault()
            glb_sv.commonEvent.next({ type: eventList.HOTKEY, value: 'advanceCash', title: 'sb_cash_advance' })
        }, []),
        title_money_withdrawal: React.useCallback((e) => {
            e.preventDefault()
            glb_sv.commonEvent.next({
                type: eventList.HOTKEY,
                value: 'bankWithDrawal',
                title: 'title_money_withdrawal',
            })
        }, []),
        title_margin_extension: React.useCallback((e) => {
            e.preventDefault()
            glb_sv.commonEvent.next({
                type: eventList.HOTKEY,
                value: 'marginExtension',
                title: 'title_margin_extension',
            })
        }, []),
        title_margin_payback: React.useCallback((e) => {
            e.preventDefault()
            glb_sv.commonEvent.next({ type: eventList.HOTKEY, value: 'marginPayback', title: 'title_margin_payback' })
        }, []),
        title_my_performance: React.useCallback((e) => {
            e.preventDefault()
            glb_sv.commonEvent.next({ type: eventList.HOTKEY, value: 'performance', title: 'title_my_performance' })
        }, []),
        title_statement: React.useCallback((e) => {
            e.preventDefault()
            glb_sv.commonEvent.next({ type: eventList.HOTKEY, value: 'statement', title: 'title_statement' })
        }, []),
        title_research_center: React.useCallback((e) => {
            e.preventDefault()
            glb_sv.commonEvent.next({ type: eventList.HOTKEY, value: 'researchCenter', title: 'title_research_center' })
        }, []),
        title_advisory: React.useCallback((e) => {
            e.preventDefault()
            glb_sv.commonEvent.next({ type: eventList.HOTKEY, value: 'advisory', title: 'title_advisory' })
        }, []),
        common_config_info: React.useCallback((e) => {
            e.preventDefault()
            glb_sv.commonEvent.next({ type: eventList.HOTKEY, value: 'configSystem', title: 'common_config_info' })
        }, []),
        title_technical_analyze: React.useCallback((e) => {
            e.preventDefault()
            glb_sv.commonEvent.next({ type: eventList.HOTKEY, value: 'mkt-view', title: 'title_technical_analyze' })
        }),
        title_my_portfolio: React.useCallback((e) => {
            e.preventDefault()
            glb_sv.commonEvent.next({ type: eventList.HOTKEY, value: 'asset-manage', title: 'title_my_portfolio' })
        }),
        regular_order: React.useCallback((e) => {
            e.preventDefault()
            glb_sv.commonEvent.next({ type: eventList.HOTKEY, value: 'regular_order', title: 'regular_order' })
        }),
        stock_margin_list: React.useCallback((e) => {
            e.preventDefault()
            glb_sv.commonEvent.next({ type: eventList.HOTKEY, value: 'stockMarginList', title: 'stock_margin_list' })
        }, []),
        history_order: React.useCallback((e) => {
            e.preventDefault()
            glb_sv.commonEvent.next({ type: eventList.HOTKEY, value: 'orderInformation', title: 'history_order' })
        }, []),
        title_market_liquidity: React.useCallback((e) => {
            e.preventDefault()
            glb_sv.commonEvent.next({ type: eventList.HOTKEY, value: 'mkt-liquidity', title: 'title_market_liquidity' })
        }, []),
        title_market_cap: React.useCallback((e) => {
            e.preventDefault()
            glb_sv.commonEvent.next({ type: eventList.HOTKEY, value: 'mkt-cap', title: 'title_market_cap' })
        }, []),
        title_foreigner_trade: React.useCallback((e) => {
            e.preventDefault()
            glb_sv.commonEvent.next({
                type: eventList.HOTKEY,
                value: 'foreigner-trade',
                title: 'title_foreigner_trade',
            })
        }, []),
        user_guide: React.useCallback((e) => {
            e.preventDefault()
            glb_sv.commonEvent.next({
                type: eventList.CHANGE_ROUTER,
                key: 'user_guide',
                routerLink: 'userGuide',
                title: 'user_guide',
            })
        }),
        take_profit_stop_loss: React.useCallback((e) => {
            e.preventDefault()
            glb_sv.commonEvent.next({ type: eventList.HOTKEY, value: 'take-profit-stop-loss', title: 'take_profit_stop_loss' })
        }, []),
        profit_loss_calculator_title: React.useCallback((e) => {
            e.preventDefault()
            glb_sv.commonEvent.next({ type: eventList.HOTKEY, value: 'profitLossCalculator', title: 'profit_loss_calculator_title' })
        }, []),
    })

    useEffect(() => {
        let search = window.location.search
        let params = new URLSearchParams(search)
        if (params.get('macaddress')) {
            localStorage.setItem('macaddress', params.get('macaddress'))
            // localStorage.setItem('REQUEST_HARDWARE_PLUGIN', JSON.stringify(false))
            //chỉ có thể close được khi mở từ plugin vì restrict của browser
            window.close()
            return
        }
        const languageURL = params.get('language')
        if (languageURL && ['VI', 'EN', 'ZH', 'CN', 'KO'].includes(languageURL)) {
            localStorage.setItem(STORE_KEY.LANGAGE, languageURL)
        }
        const themeURL = params.get('theme')
        const currentTheme = localStorage.getItem(STORE_KEY.THEME)
        changeTheme('NULL', themeURL || currentTheme)

        const langStore = localStorage.getItem(STORE_KEY.LANGAGE)
        if (langStore) {
            glb_sv.language = langStore
        } else {
            glb_sv.language = 'VI'
        }

        // glb_sv.deviceMACAddress = localStorage.getItem(STORE_KEY.UUID)
        // if (!glb_sv.deviceMACAddress) {
        //     glb_sv.deviceMACAddress = uuidv4()
        //     localStorage.setItem(STORE_KEY.UUID, glb_sv.deviceMACAddress)
        // }
        // console.log('glb_sv.deviceMACAddress: ', glb_sv.deviceMACAddress)

        // -- check mobile or not
        if (window.navigator.userAgent.toLowerCase().indexOf('android') > -1) {
            glb_sv.isMobile = true
            glb_sv.mobileTp = 'ANDROID'
        } else if (
            window.navigator.userAgent.toLowerCase().indexOf('iphone') > -1 ||
            window.navigator.userAgent.toLowerCase().indexOf('ipad') > -1
        ) {
            glb_sv.mobileTp = 'IOS'
            glb_sv.isMobile = true
        }

        readConfigInfo()
        const commonEvent = glb_sv.commonEvent.subscribe((msg) => {
            if (msg.type === eventList.CONNECT_TRADING) {
                checkVersionStockList()
            }
            if (msg.type === eventList.CHANGE_CONFIG_HOTKEY) {
                const localKeyMap = JSON.parse(localStorage.getItem('local_hotkeys'))
                const newKeyMap = { ...localKeyMap, ...msg.value }
                setKeyMap((e) => {
                    return { ...e, ...msg.value }
                })

                localStorage.setItem(`local_hotkeys`, JSON.stringify(newKeyMap))
                localStorage.setItem(`local_config_hotkeys`, JSON.stringify(glb_sv.hotKeys))
            }
        })

        return () => {
            commonEvent.unsubscribe()
        }
    }, [])

    const getInitOneSignal = (appId) => {
        if (window.location.pathname === '/tradingview' || window.location.pathname.includes('html')) return
        Notification.requestPermission((permission) => {
            if (permission === 'denied') {
                glb_sv.permissNotify = false
            } else {
                glb_sv.permissNotify = true
            }
        })

        // fetch('https://onesignal.com/api/v1/players', {
        //     method: 'POST', headers: {
        //         'Content-Type': 'application/json'
        //         // 'Content-Type': 'application/x-www-form-urlencoded',
        //     }, body: JSON.stringify({
        //         app_id: '1875f036-fd1f-435c-ab07-cf2c798a011b',
        //         device_type: 5,
        //         notification_types: 1
        //     })
        // }).then(res => {
        //     return res.json()
        // }).then(data => console.log(data))

        window.OneSignal.push(async function () {
            await window.OneSignal.init({
                appId,
                notifyButton: {
                    enable: false,
                },
                allowLocalhostAsSecureOrigin: true,
                autoResubscribe: true,
                autoRegister: true,
                notificationClickHandlerMatch: 'origin',
                notificationClickHandlerAction: 'focus',
            })

            window.OneSignal.setSubscription(true)

            window.OneSignal.registerForPushNotifications()

            window.OneSignal.push(['setSubscription', true])

            window.OneSignal.isPushNotificationsEnabled(function (isEnabled) {
                if (isEnabled) console.log('Push notifications are enabled!')
                else console.log('Push notifications are not enabled yet.')
            })

            window.OneSignal.on('notificationDisplay', (event) => {
                setTimeout(() => {
                    glb_sv.commonEvent.next({ type: eventList.ONESIGNAL_NOTIFICATION })
                }, 1000)
            })

            window.OneSignal.push([
                'addListenerForNotificationOpened',
                (e) => {
                    console.log('addListenerForNotificationOpened', e)
                },
            ])
        })

        try {
            window.navigator?.serviceWorker.addEventListener('message', (event) => {
                const data = event?.data
                if (data?.command === 'notification.displayed') {
                    console.log('Notification message OneSignal:', data?.payload)
                    setTimeout(() => {
                        glb_sv.commonEvent.next({ type: eventList.ONESIGNAL_NOTIFICATION })
                    }, 1000)
                }
            })
        } catch (e) {
            console.log(e)
        }

        const ONESIGNAL_DEVICE_ID = localStorage.getItem(`ONESIGNAL_DEVICDE_ID_${glb_sv.activeCode}`)
        let count = 0,
            getOk = false
        let intervalGetOnsId = setInterval(() => {
            window.OneSignal &&
                window.OneSignal.getIdsAvailable((e) => {
                    console.log('Check valid idDevice', e.userId, ONESIGNAL_DEVICE_ID)
                    if (ONESIGNAL_DEVICE_ID && ONESIGNAL_DEVICE_ID == e.userId) {
                        glb_sv.notifyOnesignal.userId = e.userId
                        registerDeviceToOneSignal(e.userId)
                        clearInterval(intervalGetOnsId)
                        getOk = true
                    } else if (e.userId && !!e.userId.trim()) {
                        glb_sv.notifyOnesignal.userId = e.userId
                        localStorage.setItem(`ONESIGNAL_DEVICDE_ID_${glb_sv.activeCode}`, e.userId)
                        registerDeviceToOneSignal(e.userId)
                        clearInterval(intervalGetOnsId)
                        getOk = true
                    }
                })
            count++
            if (getOk || count >= 2) clearInterval(intervalGetOnsId)
        }, 500)

        if (ONESIGNAL_DEVICE_ID) {
            if (getOk) {
                clearInterval(intervalGetOnsId)
            }
            registerDeviceToOneSignal(ONESIGNAL_DEVICE_ID)
            glb_sv.notifyOnesignal.userId = ONESIGNAL_DEVICE_ID
            console.log('ONESIGNAL_DEVICE_ID', glb_sv.notifyOnesignal.userId)
        }
    }

    const registerDeviceToOneSignal = async (idDevice) => {
        const type = await window.OneSignal.getNotificationPermission()
        const inputParams = [type === 'granted' ? 'noti_on' : 'noti_off', idDevice]
        sendRequest(ServiceInfo.REGISTER_DEVICE_ONESIGNAL, inputParams, handleRegisterDeviceToOneSignal)
    }

    const handleRegisterDeviceToOneSignal = (reqInfoMap, message) => {
        console.log('handleRegisterDeviceToOneSignal', reqInfoMap, message)
    }

    const readConfigInfo = async () => {
        // -- sau 3s sẽ reload nếu không đọc được file config
        let Reload = setTimeout(() => {
            window.location.reload(false)
        }, 3000)
        await fetch('/assets/config.json', {
            mode: 'cors',
        })
            .then((r) => r.json())
            .then((resp) => {
                window.clearTimeout(Reload)
                const activeCd = resp['active_code']
                if (activeCd && resp[activeCd]) {
                    document.title = resp[activeCd].webtitle
                    glb_sv.configInfo = resp[activeCd]
                    glb_sv.configInfo.menuConfig = resp.menu[activeCd]
                    glb_sv.activeCode = activeCd
                    glb_sv.commonEvent.next({ type: eventList.CHANGE_ACTIVE_CODE })
                    glb_sv.listIndex.config = resp[activeCd].list_index || []
                    resp[activeCd].list_index.forEach((e) => {
                        if (!glb_sv.IndexMarket[e]) glb_sv.IndexMarket[e] = new InfoIndex()
                    })
                    // glb_sv.configInfo['isRightForBuy'] = resp[activeCd].isRightForBuy
                    /**
                     * ************** Navigate to mobile web ***********
                     */
                    const ua = window.navigator.userAgent;
                    const isShare = window.location.pathname + window.location.search
                    if (resp[activeCd]?.mobile_web_domain && window.location.pathname !== '/tradingview') {
                        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(window.navigator.userAgent)) {
                            glb_sv.showAlertRedirectTableIpad = true;
                            glb_sv.commonEvent.next({ type: eventList.SHOW_MODAL_REDIRECT_TABLET_IPAD })
                        } else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
                            // Mobile
                            window.location.href = resp[activeCd]?.mobile_web_domain + (isShare ? isShare : '')
                            return
                        }
                    }

                    window.licenseKey = glb_sv.configInfo.bkvLicenseKey;

                    // if (
                    //     window.navigator.userAgent.toLowerCase().indexOf('android') > -1 ||
                    //     window.navigator.userAgent.toLowerCase().indexOf('iphone') > -1 ||
                    //     window.navigator.userAgent.toLowerCase().indexOf('ipad') > -1
                    // ) {
                    //     if (resp[activeCd]?.mobile_web_domain && window.location.pathname !== '/tradingview') {
                    //         const isShare = window.location.pathname + window.location.search
                    //         window.location.href = resp[activeCd]?.mobile_web_domain + (isShare ? isShare : '')
                    //         return
                    //     }
                    // }
                    // *************************************************
                    setConfigNodeServer()
                    //-- khởi tạo kết nối tới server
                    socket_sv.setNewConnectionStream(0)
                    socket_sv.setNewConnectionTrading(0)
                }

                if (activeCd && resp['menu'] && resp['menu'][activeCd]) {
                    glb_sv.configInfo['menuConfig'] = resp['menu'][activeCd]
                }

                // read config hotkeys
                const versionChange = localStorage.getItem('versionChange')

                if (versionChange != glb_sv.versionChange || !versionChange) {
                    localStorage.setItem('versionChange', glb_sv.versionChange)
                    localStorage.removeItem(`local_hotkeys`)
                    localStorage.removeItem(`local_config_hotkeys`)
                }

                const localHotkeysTemp = JSON.parse(localStorage.getItem(`local_hotkeys`))
                const configInfoTemp = JSON.parse(localStorage.getItem(`local_config_hotkeys`))

                if (!localHotkeysTemp || !configInfoTemp) {
                    const configDefaultHotkeys = resp['hotkeys'][activeCd]
                    glb_sv.hotKeys = configDefaultHotkeys

                    let localHotkeys = {}

                    Object.keys(configDefaultHotkeys).map((key, index) => {
                        localHotkeys = { ...localHotkeys, ...configDefaultHotkeys[key] }
                    })

                    setKeyMap((prev) => ({ ...prev, ...localHotkeys }))

                    localStorage.setItem(`local_hotkeys`, JSON.stringify(localHotkeys))
                    localStorage.setItem(`local_config_hotkeys`, JSON.stringify(configDefaultHotkeys))
                } else {
                    const configDefaultHotkeys = resp['hotkeys'][activeCd]
                    glb_sv.hotKeys = configInfoTemp

                    setKeyMap((prev) => ({ ...prev, ...localHotkeysTemp }))
                }

                // fontfamily theo từng công ty
                if (activeCd === '081') {
                    document.body.style.fontFamily = 'OneShinhan, sans-serif'
                    const styles = `
                        .stk_nm {
                            font-family: OneShinhan, sans-serif;
                            font-weight: bold;
                        }
                        .cursor-resize {
                            cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAALAgMAAADQN0+IAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAACVBMVEX////Om1H///+Aao5NAAAAAXRSTlMAQObYZgAAAAFiS0dEAmYLfGQAAAAHdElNRQflBBYCAzOd1mipAAAAJ0lEQVQI12NgcGBgYAxgYGANYWAQDWVgCA0FCjBAaBAfJA6SB6oDAGM0BH288jrtAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIxLTA0LTIyVDAyOjAzOjUxKzAwOjAwxLyx7AAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMS0wNC0yMlQwMjowMzo1MSswMDowMLXhCVAAAAAASUVORK5CYII=),auto;
                        }
                        .lds-grid {
                            display: none;
                        }
                        .loading-shinhan {
                            display: flex;
                        }
                    `

                    const styleSheet = document.createElement('style')
                    styleSheet.type = 'text/css'
                    styleSheet.innerText = styles
                    document.head.appendChild(styleSheet)
                }

                setTimeout(() => {
                    // getInitOneSignal(resp[activeCd]?.appIdOnesignal)
                }, 1000)
            })
    }

    const setConfigNodeServer = () => {
        const node_server = localStorage.getItem(STORE_KEY.SERVER) ? Number(localStorage.getItem(STORE_KEY.SERVER)) : 0
        socket_sv.server_node_list = glb_sv.configInfo.server_node_list
        socket_sv.current_server_node = node_server
        socket_sv.url_stream = glb_sv.configInfo.server_node_list[node_server].stream_server.ip_address
        socket_sv.url_trading = glb_sv.configInfo.server_node_list[node_server].trading_server.ip_address
    }

    const loadStkListLocal = () => {
        const STOCK_INFO_HSX = localStorage.getItem(STORE_KEY.STOCK_INFO_HSX)
            ? JSON.parse(localStorage.getItem(STORE_KEY.STOCK_INFO_HSX))
            : null
        const STOCK_INFO_HNX = localStorage.getItem(STORE_KEY.STOCK_INFO_HNX)
            ? JSON.parse(localStorage.getItem(STORE_KEY.STOCK_INFO_HNX))
            : null
        const STOCK_INFO_UPC = localStorage.getItem(STORE_KEY.STOCK_INFO_UPC)
            ? JSON.parse(localStorage.getItem(STORE_KEY.STOCK_INFO_UPC))
            : null

        const data = []
        if (glb_sv.language === 'VI') {
            data[0] = STOCK_INFO_HSX || require('./assets/STOCK_INFO.OTS.01.json')
            data[1] = STOCK_INFO_HNX || require('./assets/STOCK_INFO.OTS.03.json')
            data[2] = STOCK_INFO_UPC || require('./assets/STOCK_INFO.OTS.05.json')
        } else if (glb_sv.language === 'CN' || glb_sv.language === 'ZH') {
            data[0] = STOCK_INFO_HSX || require('./assets/STOCK_INFO.OTS.01.CN.json')
            data[1] = STOCK_INFO_HNX || require('./assets/STOCK_INFO.OTS.03.CN.json')
            data[2] = STOCK_INFO_UPC || require('./assets/STOCK_INFO.OTS.05.CN.json')
        } else {
            data[0] = STOCK_INFO_HSX || require('./assets/STOCK_INFO.OTS.01.EN.json')
            data[1] = STOCK_INFO_HNX || require('./assets/STOCK_INFO.OTS.03.EN.json')
            data[2] = STOCK_INFO_UPC || require('./assets/STOCK_INFO.OTS.05.EN.json')
        }
        if (data && data.length === 3) {
            glb_sv.IndexMarket['HSXIndex'] = new InfoIndex()
            glb_sv.IndexMarket['HNXIndex'] = new InfoIndex()
            glb_sv.IndexMarket['HNXUpcomIndex'] = new InfoIndex()
            glb_sv.IndexMarket['LIS_BRD_BOND'] = new InfoIndex()
            glb_sv.IndexMarket['VN30'] = new InfoIndex()
            glb_sv.IndexMarket['HNX30'] = new InfoIndex()

            glb_sv.IndexMarket['HSXIndex'].STOCK_INFO = data[0].filter((e) => e.t167 === 'ST').sort(sortAsc)
            glb_sv.IndexMarket['HSXIndex'].STOCK_INFO_ODDLOT = data[0].filter((e) => e.t167 !== 'BO').sort(sortAsc)
            glb_sv.IndexMarket['HSXIndex'].warrant_list = data[0].filter((e) => e.t167 === 'CW').sort(sortAsc)
            glb_sv.IndexMarket['HSXIndex'].bonds_list = data[0].filter((e) => e.t167 === 'BO').sort(sortAsc)
            glb_sv.IndexMarket['HSXIndex'].etf_list = data[0]
                .filter((e) => e.t167 === 'EF' || e.t167 === 'MF')
                .sort(sortAsc)

            glb_sv.IndexMarket['HNXIndex'].STOCK_INFO = data[1].filter((e) => e.t167 === 'ST').sort(sortAsc)
            glb_sv.IndexMarket['HNXIndex'].STOCK_INFO_ODDLOT = data[1].filter((e) => e.t167 !== 'BO').sort(sortAsc)
            glb_sv.IndexMarket['HNXIndex'].bonds_list = data[1].filter((e) => e.t167 === 'BO').sort(sortAsc)
            glb_sv.IndexMarket['HNXIndex'].etf_list = data[1]
                .filter((e) => e.t167 === 'EF' || e.t167 === 'MF')
                .sort(sortAsc)

            glb_sv.IndexMarket['HNXUpcomIndex'].STOCK_INFO = data[2].sort(sortAsc)
            glb_sv.IndexMarket['HNXUpcomIndex'].STOCK_INFO_ODDLOT = data[2].sort(sortAsc)

            glb_sv.mrk_stklist = data[0].concat(data[1].concat(data[2]))
            glb_sv.mrk_stklist_lang = glb_sv.language

            glb_sv.mrk_stklist_select = glb_sv.mrk_stklist.map((e) => {
                return {
                    t55: e.t55,
                    value: e.t55,
                    label: e.t55 + ' - ' + getEchange(e) + ' - ' + e.U9,
                }
            })

            glb_sv.mrk_stklist.forEach((msgObj) => {
                let stkMsgObj = glb_sv.StockMarket[msgObj.t55]
                if (!stkMsgObj) {
                    stkMsgObj = new InfoStock()
                    stkMsgObj.t55 = msgObj.t55
                    stkMsgObj.U10 = msgObj.U10
                    stkMsgObj.U9 = msgObj.U9
                    stkMsgObj.U34 = msgObj.U34
                    stkMsgObj.U24 = msgObj.U24 ? msgObj.U24.trim() : ''
                    glb_sv.StockMarket[msgObj.t55] = stkMsgObj
                }
            })
            hanldeDataTradingview(glb_sv.mrk_stklist)
        }

        setTimeout(() => {
            setLoading(false)
            showAlearRedirect.current = true
        }, 0)
        jsonRef.current = []
    }

    const checkVersionStockList = () => {
        if (glb_sv.activeCode === '888') {
            loadStkListLocal()
            return
        }
        const InputParams = ['version']
        sendRequest(
            ServiceInfo.GET_VERSION_STKLIST,
            InputParams,
            checkVersionStockListResult,
            true,
            checkVersionStockListTimeout
        )
    }

    const checkVersionStockListTimeout = () => {
        getStockList('01,03,05')
    }

    const checkVersionStockListResult = (reqInfoMap, message) => {
        if (Number(message['Result']) === 0) {
            checkVersionStockListTimeout()
        } else {
            let jsondata = []
            try {
                jsondata = message.Data ? JSON.parse(glb_sv.filterStrBfParse(message.Data)) : []
            } catch (err) {
                checkVersionStockListTimeout()
                return
            }
            if (jsondata.length) {
                const jsonHSX = jsondata.find((e) => e.c0 === 'HSX') || { c1: '' }
                const jsonHNX = jsondata.find((e) => e.c0 === 'HNX') || { c1: '' }
                const jsonUPC = jsondata.find((e) => e.c0 === 'UPC') || { c1: '' }
                let inputC1 = ''
                const localVERSION_STKLIST = localStorage.getItem(STORE_KEY.VERSION_STKLIST)
                if (localVERSION_STKLIST) {
                    glb_sv.VERSION_STKLIST = JSON.parse(localVERSION_STKLIST)
                }
                if (
                    glb_sv.VERSION_STKLIST.HSX !== jsonHSX.c1 &&
                    glb_sv.VERSION_STKLIST.HNX !== jsonHNX.c1 &&
                    glb_sv.VERSION_STKLIST.UPC !== jsonUPC.c1
                ) {
                    inputC1 = '01,03,05'
                } else if (glb_sv.VERSION_STKLIST.HSX !== jsonHSX.c1 && glb_sv.VERSION_STKLIST.HNX !== jsonHNX.c1) {
                    inputC1 = '01,03'
                } else if (glb_sv.VERSION_STKLIST.HSX !== jsonHSX.c1 && glb_sv.VERSION_STKLIST.UPC !== jsonUPC.c1) {
                    inputC1 = '01,05'
                } else if (glb_sv.VERSION_STKLIST.HNX !== jsonHNX.c1 && glb_sv.VERSION_STKLIST.UPC !== jsonUPC.c1) {
                    inputC1 = '03,05'
                } else if (glb_sv.VERSION_STKLIST.HSX !== jsonHSX.c1) {
                    inputC1 = '01'
                } else if (glb_sv.VERSION_STKLIST.HNX !== jsonHNX.c1) {
                    inputC1 = '03'
                } else if (glb_sv.VERSION_STKLIST.UPC !== jsonUPC.c1) {
                    inputC1 = '05'
                }
                if (inputC1) {
                    getStockList(inputC1)
                    glb_sv.VERSION_STKLIST.HSX = jsonHSX.c1 || 'none'
                    glb_sv.VERSION_STKLIST.HNX = jsonHNX.c1 || 'none'
                    glb_sv.VERSION_STKLIST.UPC = jsonUPC.c1 || 'none'
                    localStorage.setItem(STORE_KEY.VERSION_STKLIST, JSON.stringify(glb_sv.VERSION_STKLIST))
                } else if (localStorage.getItem(STORE_KEY.STOCK_INFO_LANG) !== glb_sv.language) {
                    getStockList('01,03,05')
                } else {
                    loadStkListLocal()
                }
            } else {
                checkVersionStockListTimeout()
            }
        }
    }

    const getStockList = (type) => {
        const InputParams = ['download', type]
        sendRequest(ServiceInfo.GET_VERSION_STKLIST, InputParams, getStockListResult, true, getStockListTimeout)
    }

    const getStockListTimeout = () => {
        loadStkListLocal()
        localStorage.removeItem(STORE_KEY.VERSION_STKLIST)
    }

    const getStockListResult = (reqInfoMap, message) => {
        if (Number(message['Result']) === 0) {
            getStockListTimeout()
        } else {
            let jsondata = []
            try {
                jsondata = message.Data ? JSON.parse(glb_sv.filterStrBfParse(message.Data)) : []
                // jsondata = message.Data ? JSON.parse(message.Data) : []
            } catch (err) {
                getStockListTimeout()
                return
            }
            jsonRef.current = jsonRef.current.concat(jsondata)
            if (Number(message.Packet) <= 0) {
                if (jsonRef.current.length) {
                    const dataHSX = jsonRef.current
                        .filter((e) => e.c0 === 'HSX')
                        .map((e) => {
                            return {
                                t55: e.c1,
                                U9: e.c2,
                                U10: e.c0 === 'HSX' ? '01' : e.c0 === 'HNX' ? '03' : '05',
                                t167: e.c3,
                                U24: e.c4,
                                U34: e.c5,
                            }
                        })
                    const dataHNX = jsonRef.current
                        .filter((e) => e.c0 === 'HNX')
                        .map((e) => {
                            return {
                                t55: e.c1,
                                U9: e.c2,
                                U10: e.c0 === 'HSX' ? '01' : e.c0 === 'HNX' ? '03' : '05',
                                t167: e.c3,
                                U34: e.c5,
                            }
                        })
                    const dataUPC = jsonRef.current
                        .filter((e) => e.c0 === 'UPC')
                        .map((e) => {
                            return {
                                t55: e.c1,
                                U9: e.c2,
                                U10: e.c0 === 'HSX' ? '01' : e.c0 === 'HNX' ? '03' : '05',
                                t167: e.c3,
                                U34: e.c5,
                            }
                        })
                    if (dataHSX.length) localStorage.setItem(STORE_KEY.STOCK_INFO_HSX, JSON.stringify(dataHSX))
                    if (dataHNX.length) localStorage.setItem(STORE_KEY.STOCK_INFO_HNX, JSON.stringify(dataHNX))
                    if (dataUPC.length) localStorage.setItem(STORE_KEY.STOCK_INFO_UPC, JSON.stringify(dataUPC))
                    localStorage.setItem(STORE_KEY.STOCK_INFO_LANG, glb_sv.language)
                    loadStkListLocal()
                } else {
                    getStockListTimeout()
                }
            }
        }
    }

    const hanldeDataTradingview = (stkInfo) => {
        let i = 0,
            tradObj = {},
            stkTradInfo = {}
        for (i = 0; i < stkInfo.length; i++) {
            if (!stkInfo[i]) continue
            tradObj = {}
            stkTradInfo = {}
            let ls_sanGd
            if (stkInfo[i]['U10'] === '05') {
                ls_sanGd = 'UPC'
            }
            if (stkInfo[i]['U10'] === '01') {
                ls_sanGd = 'HOSE'
            }
            if (stkInfo[i]['U10'] === '03') {
                ls_sanGd = 'HNX'
            }
            if (stkInfo[i]['U10'] === '90') {
                ls_sanGd = 'TPN'
            }
            const stkfullNm = stkInfo[i]['t55'].trim() + ' - ' + ls_sanGd + ' - ' + stkInfo[i]['U9'].trim()
            if (
                stkInfo[i]['t55'] != null &&
                stkInfo[i]['t55'] !== undefined &&
                stkInfo[i]['U9'] != null &&
                stkInfo[i]['U9'] !== undefined
            ) {
                if (glb_sv.tradview_StkList.findIndex((x) => x.symbol === stkInfo[i]['t55']) < 0) {
                    tradObj['symbol'] = stkInfo[i]['t55']
                    tradObj['full_name'] = stkInfo[i]['t55']
                    tradObj['description'] = stkfullNm
                    tradObj['exchange'] = ls_sanGd
                    tradObj['type'] = 'stock'
                    glb_sv.tradview_StkList.push(tradObj)
                    // -- đẩy dữ liệu vào hashmap thông tin CK
                    stkTradInfo['name'] = stkInfo[i]['t55']
                    stkTradInfo['full_name'] = ls_sanGd + ':' + stkInfo[i]['t55']
                    stkTradInfo['exchange'] = ls_sanGd
                    stkTradInfo['exchange-traded'] = ls_sanGd
                    stkTradInfo['exchange-listed'] = ls_sanGd
                    stkTradInfo['timezone'] = 'Asia/Bangkok'
                    stkTradInfo['minmov'] = 1
                    // stkTradInfo['minmov2'] = 0
                    // stkTradInfo['pointvalue'] = 1
                    stkTradInfo['session'] = '0900-1500'
                    stkTradInfo['has_intraday'] = true
                    stkTradInfo['has_seconds'] = true
                    stkTradInfo['intraday_multipliers'] = ['1']
                    stkTradInfo['seconds_multipliers'] = [1]
                    // stkTradInfo['has_no_volume'] = false
                    stkTradInfo['pricescale'] = 1
                    stkTradInfo['description'] = stkfullNm
                    stkTradInfo['type'] = 'stock'
                    stkTradInfo['currency_code'] = 'VND'
                    stkTradInfo['supported_resolutions'] = [
                        '1S',
                        '15S',
                        '30S',
                        '1',
                        '5',
                        '15',
                        '30',
                        '60',
                        'D',
                        'W',
                        'M',
                        '3M',
                    ]
                    stkTradInfo['ticker'] = stkInfo[i]['t55']
                    stkTradInfo['pro_name'] = ls_sanGd + ':' + stkInfo[i]['t55']
                    glb_sv.stkInfoTradviewMap.set(stkInfo[i]['t55'], stkTradInfo)
                }
            }
        }
        const mapArr = []
        glb_sv.stkInfoTradviewMap.forEach((value, key) => {
            const mapObj = {}
            mapObj[0] = key
            mapObj[1] = value
            mapArr.push(mapObj)
        })

        if (glb_sv.tradingViewFlag) {
            sessionStorage.setItem('tradinglist', JSON.stringify(glb_sv.tradview_StkList))
            sessionStorage.setItem('tradingmap', JSON.stringify(mapArr))
        }
    }

    if (loading) {
        return <LoadingView />
    }

    return (
        <GlobalHotKeys
            keyMap={keyMap}
            handlers={handlersHotkeys}
            allowChanges={true}
            configure={{
                ignoreTags: ['input', 'select', 'textarea'],
                ignoreEventsCondition: function () { },
            }}
        >
            <Suspense fallback={<></>}>
                <StoreContext>
                    <StoreTrading>
                        <Switch>
                            <Route exact path="/login" component={Login} />
                            <Route exact path="/tradingview" component={TradingViewComponent} />
                            <Route path="/" component={Pages} />
                            <Route exact path="/" component={Pages} />
                        </Switch>
                        <BackgroundService />
                    </StoreTrading>
                </StoreContext>
            </Suspense>
        </GlobalHotKeys>
    )
}

function getEchange(e) {
    if (e.U10 === '01') return 'HOSE'
    if (e.U10 === '03') return 'HNX'
    if (e.U10 === '05') return 'UPCOM'
    return '---'
}

export default App
