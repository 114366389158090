import { Input as AntdInput } from 'antd'
import React from 'react'
import { FormatNumber } from '../../../utils'

interface IInputProps {
	type?: 'text' | 'number';
	typeLabel?: 'vertical' | 'horizontal';
	labelRatio?: [number, number];
	labelClassName?: string;
	readOnly: boolean;
	label?: string;
	labelStyle: React.CSSProperties;
	inputStyle: React.CSSProperties;
	textAlign?: 'left' | 'right' | 'center';
	value?: string | number;
	onChange?: React.ChangeEventHandler<HTMLInputElement>;
	onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
	onPressEnter?: React.KeyboardEventHandler<HTMLInputElement>;
	placeholder?: string;
	refs?: React.LegacyRef<AntdInput>;
	className?: string;
	styleRoot?: React.CSSProperties;
	isRequired?: boolean;
}


export const Input = ({
	type = 'text',
	typeLabel = 'vertical',
	labelRatio = [2, 3],
	labelClassName = '',
	readOnly = false,
	label = '',
	labelStyle = {},
	inputStyle = {},
	textAlign = 'left',
	value = '',
	onChange = e => null,
	onKeyPress = e => null,
	onPressEnter = e => null,
	placeholder = '',
	refs,
	isRequired = false,
	className,
	styleRoot = {},
	...props
}: IInputProps) => {
	if (type === 'number') {
        // @ts-expect-error
		value = FormatNumber(value);
	}

	return (
		<div className={`alt-input-label ${typeLabel === 'horizontal' ? 'alt-input-label-horizontal' : ''}`} style={styleRoot}>
			{label ? (
				<div className={`mr-2 alt-label flex-${labelRatio[0]} ${labelClassName}`} style={labelStyle}>
					<span>{label}</span>
					{isRequired ? <span className='alt-input-label-required'>*</span> : null}
				</div>
			) : null}
			<div className={`flex flex-${labelRatio[1]}`}>
                <AntdInput
                    style={inputStyle}
                    className={`alt-input ${'text-' + textAlign} flex-${labelRatio[1]}` + ' ' + className}
                    onChange={onChange}
                    onKeyPress={onKeyPress}
                    onPressEnter={onPressEnter}
                    placeholder={placeholder}
                    readOnly={readOnly}
                    value={value}
                    ref={refs}
                    {...props}
                />
            </div>
		</div>
	);
};
