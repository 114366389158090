import React from 'react';
import styled from 'styled-components';

const FloatingLabelInput = styled.div`
    width: 100%;
    margin-bottom: 24px;
    padding: 8px;
    padding-top: 14px;
    background-color: ${props => (props.inputStyleMode === 'tet_holidays' ? '#F8DFC0' : 'var(--INPUT__BG__LOGIN)')};
    border-radius: 8px;
`;

const FloatingLabelInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    height: 2em;
    font-size: inherit;
`;

const FloatingLabel = styled.label`
    padding: 0;
    margin: 0;
    border: 0;
    position: absolute;
    color: var(--TEXT__1);
    bottom: 0;
    transition: all 0.2s ease-in-out;
    transform-origin: left top;
    font-size: 1rem;
    cursor: text;
    pointer-events: none;
    padding-bottom: 5px;
    transform: ${props =>
            props.active ? 'translate3d(0, -20%, 0) scale(0.70)' : 'none'};
`;

const FloatingInput = styled.input`
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  font-size: 1rem;
  &::placeholder {
    color: var(--TEXT__2);
    opacity: ${props => (props.active ? 1 : 0)};
    transition: opacity 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  }
`;

export default class InputFloatingLabel extends React.PureComponent {
    constructor(props) {
        super(props);
        if (!props.id && !props.name) {
            throw new Error('expected id but none present');
        }

        this.state = {
            active: props.value && props.value.length > 0
        };

        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.active && this.props.value && prevProps.value !== this.props.value) {
            this.setState({ active: true });
        }
    }

    onFocus(event) {
        this.setState({ active: true });
        if (this.props.onFocus) {
            this.props.onFocus(event);
        }
    }

    onBlur(event) {
        this.setState({ active: event.target.value.length !== 0 });
        if (this.props.onBlur) {
            this.props.onBlur(event);
        }
    }

    handleFocusInput = () => {
        const ele = document.getElementById(this.props.id);
        if (ele) ele.focus();
    }

    render() {
        const { id, label, onBlur, onFocus, type, refs, value, onChange, className, rightComponent = null, inputStyleMode, styleFloatingLabelInput, ...otherProps } = this.props;
        const { active } = this.state;

        return (
            <FloatingLabelInput inputStyleMode={inputStyleMode} style={styleFloatingLabelInput || {}} onClick={this.handleFocusInput}>
                <FloatingLabelInputContainer className={className}>
                    <FloatingLabel className={className} htmlFor={id} active={active}>
                        {label}
                    </FloatingLabel>
                    <FloatingInput
                        active={active}
                        className={className}
                        id={id}
                        onBlur={this.onBlur}
                        onFocus={this.onFocus}
                        ref={refs}
                        type={type}
                        value={value || ''}
                        onChange={onChange}
                        {...otherProps}
                    />
                    {rightComponent}
                </FloatingLabelInputContainer>
            </FloatingLabelInput>
        );
    }
}