import {
    Button,
    Checkbox,
    Collapse,
    DatePicker,
    message,
    Modal,
    Select,
    Spin,
    Input,
} from 'antd'
import moment from 'moment'
import React, { memo, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StoreContext } from '../../store'
import { StoreTrading } from '../../store-trading'
import { dimensions } from '../../styles'
import { eventList, glb_sv, reqFunct, sendRequest, allowCompanyRender } from '../../utils'
import {
    InfoCircleFilled
} from '@ant-design/icons';
import CONFIG_EKYC from './config'
import './index.scss'
import ic_upload from '../../assets/images/ic_upload.svg';
import ic_upload_dark from '../../assets/images/ic_upload_dark.svg';
import ic_next from '../../assets/images/ic_next.svg';

const { Option } = Select
const { Panel } = Collapse

const ServiceInfo = {
    EKYC_UPDATE: {
        reqFunct: reqFunct.EKYC_UPDATE,
        WorkerName: 'FOSxID01',
        ServiceName: 'FOSxID01_EKYCMgt',
        ClientSentTime: '0',
        Operation: 'U',
    },
    API_UPLOAD: {
        reqFunct: reqFunct.API_UPLOAD,
        WorkerName: 'FOSxCommon02',
        ServiceName: 'FOSxCommon02_Token_Mgt',
        ClientSentTime: '0',
        Operation: 'Q',
    },
    GET_BROKER_REMISIER_LIST: {
        reqFunct: reqFunct.GET_BROKER_REMISIER_LIST,
        WorkerName: 'FOSqID02',
        ServiceName: 'FOSqID02_HOSUserInformation',
        ClientSentTime: '0',
        Operation: 'Q',
    },
    QUERY_USER_INFORMATION: {
        reqFunct: reqFunct.QUERY_USER_INFORMATION,
        WorkerName: 'FOSqID02',
        ServiceName: 'FOSqID02_UserInformation',
        ClientSentTime: '0',
        Operation: 'Q',
    },
    GET_LIST_BANK: {
        reqFunct: reqFunct.GET_LIST_BANK,
        WorkerName: 'FOSqAccount',
        ServiceName: 'FOSqAccount_Common',
        ClientSentTime: '0',
        Operation: 'Q',
    },
    GET_INSTITUATION: {
        reqFunct: reqFunct.GET_INSTITUATION,
        WorkerName: 'FOSqAccount',
        ServiceName: 'FOSqAccount_Common_1',
        ClientSentTime: '0',
        Operation: 'Q',
    },
    GET_REFER_NAME: {
        reqFunct: reqFunct.GET_REFER_NAME,
        WorkerName: 'FOSqAccount',
        ServiceName: 'FOSqAccount_Common_1',
        ClientSentTime: '0',
        Operation: 'Q',
    },
    CHECK_DUPLICATE_INFO: {
        reqFunct: reqFunct.CHECK_DUPLICATE_INFO,
        WorkerName: 'FOSqAccount',
        ServiceName: 'FOSqAccount_Common_3',
        ClientSentTime: '0',
        Operation: 'Q',
    },
}

function stopStreamedVideo(videoElem) {
    const stream = videoElem.srcObject
    if (!stream) return
    const tracks = stream.getTracks()

    tracks.forEach(function (track) {
        track.stop()
    })

    videoElem.srcObject = null
}

function EkycModal() {
    const { styles } = useContext(StoreContext)
    const { setUserInfo } = useContext(StoreTrading)
    const { t } = useTranslation()
    const configUIEkyc = CONFIG_EKYC(glb_sv.activeCode)

    const [visible, setVisible] = useState(false)

    const [method, setMethod] = useState('ekyc')
    const [type, setType] = useState('ekyc')

    const [id, setID] = useState('-')
    const [name, setName] = useState('-')
    const [birthday, setBirthday] = useState(moment().subtract(20, 'year'))
    const [addr, setAddr] = useState('-')
    const [addrContact, setAddrContact] = useState('-')
    const [gender, setGender] = useState('O')
    const [idDate, setIDDate] = useState(moment())
    const [idPlace, setIDPlace] = useState('-')

    const [isAuthen, setAuthen] = useState(true)
    const [imageFront, setImageFront] = useState('')
    const [imageBack, setImageBack] = useState('')
    const [imageFace, setImageFace] = useState('')

    const [checkNote, setCheckNote] = useState(false)

    const card_type = useRef(null)

    const flagEkycUpdate = useRef(false)

    const [loading, setLoading] = useState(false)

    const [listMessage, setListMessage] = useState([])
    const [eKYCWarningMessage, setEKYCWarningMessage] = useState([])

    const validDate = useRef('')

    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [listOther, setListOther] = useState([])
    const [remisierObj, setRemisierObj] = useState(null)

    const [objUserInfo, setObjUserInfo] = useState(null)

    const tempList = useRef([])

    const [loadingFaceVnpt, setLoadingFaceVNPT] = useState(false)

    const dataRef = useRef({
        imageHashFront: '',
        imageHashBack: '',
        imageHashFace: '',
        compareFace: 0,
    })

    const [isBankOnline, setBankOnline] = useState(2)
    const [bankCd, setBankCd] = useState('')
    const [bankAct, setBankAct] = useState('')

    const [bankList, setBankList] = useState([])
    const [bankCdList, setBankCdList] = useState([])
    const bankCdListTemple = useRef([])
    const bankListTemple = useRef([])

    const [bankCode, setBankCode] = useState('')

    const [institutionalList, setInstitutionalList] = useState([])
    const [institutional, setInstitutional] = useState('')

    const [referrerCode, setReferrerCode] = useState('')
    const [referrerName, setReferrerName] = useState('')

    const [checkMargin, setCheckMargin] = useState(configUIEkyc.margin_check_default || false)

    const [referrerCodeError, setReferrerCodeError] = useState(false)
    const referrerCodeRef = useRef('')
    const institutionalRef = useRef('')
    const timeoutGetReferrerName = useRef(null)

    const [brokerCode, setBrokerCode] = useState('')
    const [isBrokerService, setBrokerService] = useState(configUIEkyc.brokerage_services_check_default || false)

    useEffect(() => {
        const commonEvent = glb_sv.commonEvent.subscribe((msg) => {
            if (msg.type === eventList.OPEN_EKYC) {
                if (window.gtag) {
                    window.gtag('event', 'ekyc_web_start');
                }
                setVisible(true)
                if (msg.method === 'normal') {
                    // setMethod('sign');
                    setMethod('confirm')
                    setType('normal')
                    card_type.current = ''
                } else {
                    setMethod('ekyc')
                    setLoadingFaceVNPT(true)
                    // loadEkycSDK()
                }

                getUserInfo()
                getBrokerList()
                getListCodeBanks()
                getListBanks()
                getInstituation()

                if (glb_sv.openAccountEkyc) {
                    setBrokerCode(glb_sv.openAccountEkyc.brokerid)
                    setRemisierObj(glb_sv.openAccountEkyc.remiseid)
                    setInstitutional(glb_sv.openAccountEkyc.institutional)
                    setReferrerCode(glb_sv.openAccountEkyc.referCode)
                    setReferrerName(glb_sv.openAccountEkyc.referName)
                    institutionalRef.current = glb_sv.openAccountEkyc.institutional
                    referrerCodeRef.current = glb_sv.openAccountEkyc.referCode
                    getReferrerName()
                }
            }
        })



        return () => {
            commonEvent.unsubscribe()
            if (timeoutGetReferrerName.current) clearTimeout(timeoutGetReferrerName.current)
        }
    }, [])

    const loadEkycSDK = async () => {
        await window.FaceVNPTBrowserSDK.init()
        const VNPT_CDN = "https://ekyc-web.vnpt.vn"
        let initObj = {
            BACKEND_URL: "https://api.idg.vnpt.vn/",
            AUTHORIZION: (glb_sv.dataEkycInfo?.c4 || '').replaceAll(
                'bearer ',
                ''
            ),
            TOKEN_ID: glb_sv.dataEkycInfo?.c5 || '',
            TOKEN_KEY: glb_sv.dataEkycInfo?.c6 || '',
            PARRENT_ID: 'ekyc_sdk_intergrated',
            FLOW_TYPE: 'DOCUMENT', // DOCUMENT, FACE
            SHOW_HELP: true,
            SHOW_TRADEMARK: false,
            CHECK_LIVENESS_CARD: true,
            CHECK_LIVENESS_FACE: true,
            CHECK_MASKED_FACE: true,
            COMPARE_FACE: true,
            LANGUAGE: glb_sv.language === 'VI' ? 'vi' : 'en',
            LIST_ITEM: [-1, 9],
            TYPE_DOCUMENT: 99,
            USE_WEBCAM: true,
            USE_UPLOAD: glb_sv.configInfo.ekyc_not_upload ? false : true,
            ADVANCE_LIVENESS_FACE: true,
            ASYNC_LOAD_AI: true,
            LIST_CHOOSE_STYLE: {
                item_active_color: 'var(--EKYC__COLOR)',
                background_icon: 'var(--EKYC__COLOR)',
                id_icon: VNPT_CDN + "/images/si/id_card.svg",
                passport_icon: VNPT_CDN + "/images/si/passport.svg",
                drivecard_icon: VNPT_CDN + "/images/si/drivecard.svg",
                army_id_icon: VNPT_CDN + "/images/si/other_doc.svg",
                id_chip_icon: VNPT_CDN + "/images/si/id_chip.svg",
                start_button_background: 'var(--EKYC__COLOR)',
                start_button_color: "#FFF",
                text_color: 'var(--PRIMARY__CONTENT__COLOR)'
            },
            CAPTURE_IMAGE_STYLE: {
                big_title_color: "var(--PRIMARY__CONTENT__COLOR)",
                description1_color: "var(--PRIMARY__CONTENT__COLOR)",
                capture_btn_background: 'var(--EKYC__COLOR)',
                // capture_btn_color: "#FFF",
                // capture_btn_icon: '../assets/images/capture_ekyc.svg',
                tutorial_btn_icon: VNPT_CDN + "/images/hdbank/help.gif",
                upload_btn_background: "white",
                upload_btn_color: "#000",
                upload_btn_boder: "2px solid var(--PRIMARY)",
                // upload_btn_icon: '../assets/images/upload_ekyc.svg',
                recapture_btn_background: 'var(--EKYC__COLOR)',
                recapture_btn_color: "#FFF",
                recapture_btn_border: "2px solid var(--PRIMARY)",
                // recapture_btn_icon: '../assets/images/capture_ekyc.svg',
                nextstep_btn_background: 'var(--EKYC__COLOR)',
                // nextstep_btn_color: "#FFF",
                // nextstep_btn_icon: VNPT_CDN + "/images/hdbank2/next_icon.svg",
                capture_and_upload_wrapper_bg: "transparent",
                capture_and_upload_wrapper_bg_img: '../assets/images/capture_and_upload_wrapper_bg_img.svg',

                upload_btn_icon: ic_upload_dark,
                recapture_btn_icon: VNPT_CDN + "/images/camera.svg",
                nextstep_btn_color: "white",
                nextstep_btn_icon: ic_next,
                capture_btn_color: 'white',
                capture_btn_icon: VNPT_CDN + "/images/camera.svg",
            },
            UPLOAD_IMAGE_STYLE: {
                upload_btn_background: "var(--EKYC__COLOR)",
                upload_btn_color: "white",
                upload_btn_icon: ic_upload,
                upload_btn_boder: "2px solid var(--EKYC__COLOR)"
            },
            MODAL_DOC_STYLE: {
                touch_icon: VNPT_CDN + "/altiss/touch_cmt.svg",
                close_icon: VNPT_CDN + "/altiss/close_icon.svg",
                notice1_icon: VNPT_CDN + "/altiss/cmt_notice1.svg",
                notice2_icon: VNPT_CDN + "/altiss/cmt_notice2.svg",
                notice3_icon: VNPT_CDN + "/altiss/cmt_notice3.svg",
            },
            MODAL_FACE_STYLE: {
                title_color: "var(--PRIMARY)",
                face_icon: VNPT_CDN + "/altiss/face_icon.svg",
                close_icon: VNPT_CDN + "/altiss/close_icon.svg",
                notice1_icon: VNPT_CDN + "/altiss/cmt_notice1.svg",
                notice2_icon: VNPT_CDN + "/altiss/cmt_notice2.svg",
                notice3_icon: VNPT_CDN + "/altiss/cmt_notice3.svg",
            },
            OTHER_CONFIG: {
                loading_icon: VNPT_CDN + "/images/hdbank2/loading.gif",
                loading_styles: "background-color: var(--PRIMARY__CONTENT__COLOR); opacity: 0.7",
                oval_web: "../assets/web-oval.json",
                oval_mobile: VNPT_CDN + "/kbsv/mobile_border.json",
                notice_ani: '../assets/oval-animation.json',
                oval_title_color: "var(--PRIMARY__CONTENT__COLOR)",
                description_oval_content: t('remove_glass_authen'),
                description_oval: "text-align: center; color: red; font-weight: bold",
                video_tutorial_oval: VNPT_CDN + "/animation/video_tutorial_oval_dark.mp4",
            }
        }

        setTimeout(() => {
            try {
                setLoadingFaceVNPT(false)
                window.ekycsdk.init(initObj, (res) => null, call_after_end_flow)
            } catch (err) {
                console.log('loadEkycSDK -> err', err)
            }
        }, 100)

        function call_after_end_flow(data) {
            const vnpt_ekyc = document.getElementById('vnpt_ekyc')
            vnpt_ekyc.parentNode.removeChild(vnpt_ekyc)
            window.ekycsdk.init(
                {
                    ...initObj,
                    FLOW_TYPE: 'FACE',
                    TYPE_DOCUMENT: data.type_document,
                },
                (res2) => {
                    setInfoEkyc2(
                        data.ocr,
                        res2.compare,
                        {
                            ...data.base64_doc_img,
                            ...res2.base64_face_img,
                        },
                        {
                            ...data.ocr.imgs,
                            img_face: res2.compare.imgs.img_face,
                        },
                        data.liveness_card_back,
                        data.liveness_card_front,
                        res2.liveness_face,
                        res2.masked
                    )
                }
            )
        }
    }

    const setInfoEkyc2 = (
        infos,
        compares,
        image,
        hash_img,
        liveness_card_back,
        liveness_card_front,
        liveNess,
        masked
    ) => {

        // Giảm thiểu log gửi lên Service
        compares.dataBase64 = ''
        compares.dataSign = ''

        liveNess.dataBase64 = ''
        liveNess.dataSign = ''

        masked.dataBase64 = ''
        masked.dataSign = ''

        liveness_card_front.dataBase64 = ''
        liveness_card_front.dataSign = ''

        liveness_card_back.dataBase64 = ''
        liveness_card_back.dataSign = ''

        infos.dataBase64 = ''
        infos.dataSign = ''
        // END Giảm thiểu log gửi lên Service

        const sEKYCLog = JSON.stringify({
            _infoObj: glb_sv.removeTrashObjEkyc(infos),
            _compares: glb_sv.removeTrashObjEkyc(compares),
            _liveness_card_front: glb_sv.removeTrashObjEkyc(liveness_card_front),
            _liveness_card_back: glb_sv.removeTrashObjEkyc(liveness_card_back),
            _faceLiveNess: glb_sv.removeTrashObjEkyc(liveNess),
            _maskedFace: glb_sv.removeTrashObjEkyc(masked)
        })

        const object = infos ? infos.object : null
        if (image) {
            setImageFront(image.img_front)
            setImageBack(image.img_back)
            setImageFace(image.img_face_near)
        }

        // Thêm check điều kiện warning quá 3 thì reject cho KH xác thực lại
        let generalWarningArr = []
        if (Array.isArray(object?.general_warning)) {
            generalWarningArr = object.general_warning.filter(item => item != 'dia_chi_thuong_tru_khong_khop_noi_cap')
            setListMessage(generalWarningArr)
            if (generalWarningArr.length >= 2
                || generalWarningArr.findIndex(x => (
                    x === 'anh_dau_vao_mat_goc'
                    || x === 'id_mo_nhoe'
                    || x === 'ngay_sinh_mo_nhoe'
                    || x === 'anh_mat_truoc_bi_che'
                    || x === 'ngay_cap_mo_nhoe'
                )) > -1
            ) {
                setAuthenAndSendLog(sEKYCLog, false)
                setAuthen(false)
                setMethod('confirm')
                return;
            }
        }

        if (object.hasOwnProperty('name_fake_warning')) {
            if (object.name_fake_warning === 'fake') {
                setListMessage(['document_invalid'])
                setAuthen(false)
                setMethod('confirm')
                setAuthenAndSendLog(sEKYCLog, false)
                return
            }
        }

         // ALT-1717 - Bổ sung logic từ chối với bright_spot_likelihood = likely và blurred_likelihood = likely (ảnh mờ nhòe và lóa)
         if (object?.bright_spot_likelihood === 'likely') {
            setListMessage(['unknow_error_ekyc'])
            setAuthen(false)
            setMethod('confirm')
            setAuthenAndSendLog(sEKYCLog, false)
            return;
          }
      
          if (object?.blurred_likelihood === 'likely') {
            setListMessage(['unknow_error_ekyc'])
            setAuthen(false)
            setMethod('confirm')
            setAuthenAndSendLog(sEKYCLog, false)
            return;
          }
      // END

        // Thêm check điều kiện warning quá 3 thì reject cho KH xác thực lại
        let warningArr = []
        if (Array.isArray(object?.warning)) {
            warningArr = object.warning.filter(item => item != 'dia_chi_thuong_tru_khong_khop_noi_cap')
            if (warningArr.length >= 2) {
                setListMessage(warningArr)
                setAuthenAndSendLog(sEKYCLog, false)
                setAuthen(false)
                setMethod('confirm')
                return
            }
        }

        // Check tampering (Giả mạo)
        let tamperingWarningArr = []
        if (Array.isArray(object?.tampering?.warning)) {
            tamperingWarningArr = object?.tampering?.warning
            if (tamperingWarningArr.length >= 2) {
                setListMessage(tamperingWarningArr)
                setAuthen(false)
                setMethod('confirm')
                setAuthenAndSendLog(sEKYCLog, false)
                return;
            }
            //#region SYNC MTS
            if (tamperingWarningArr.includes('id_ko_hop_le')) {
                setListMessage(tamperingWarningArr)
                setAuthen(false)
                setMethod('confirm')
                setAuthenAndSendLog(sEKYCLog, false)
                return;
            }
        }

        if (liveNess && liveNess.errors && liveNess.errors.length > 0) {
            setListMessage(['face_is_not_match_with_document'])
            setAuthen(false)
            setMethod('confirm')
            setAuthenAndSendLog(sEKYCLog, false)
            return;
        }

        if (liveNess && liveNess.object && liveNess.object?.liveness === 'failure') {
            setListMessage(['face_is_not_match_with_document'])
            setAuthen(false)
            setMethod('confirm')
            setAuthenAndSendLog(sEKYCLog, false)
            return;
        }

        if (liveNess && liveNess.object?.blur_face == 'yes') {
            setListMessage(['face_is_not_match_with_document'])
            setAuthen(false)
            setMethod('confirm')
            setAuthenAndSendLog(sEKYCLog, false)
            return;
        }

        if (masked && masked.object && masked.object.masked === 'yes') {
            setListMessage(['face_is_not_match_with_document'])
            setAuthen(false)
            setMethod('confirm')
            setAuthenAndSendLog(sEKYCLog, false)
            return;
        }

        if (!compares.object || compares.object.msg !== 'MATCH') {
            setListMessage(['face_not_match'])
            setAuthen(false)
            setMethod('confirm')
            setAuthenAndSendLog(sEKYCLog, false)
            return;
        }

        // Bổ sung fake_print_photo
        // if (liveness_card_front.object && liveness_card_front.object?.fake_print_photo) {
        //     setListMessage(['front_image_id_invalid'])
        //     setAuthen(false)
        //     setMethod('confirm')
        //     setAuthenAndSendLog(sEKYCLog, false)
        //     return;
        // }
        // if (liveness_card_back.object && liveness_card_back.object?.fake_print_photo) {
        //     setListMessage(['back_image_id_invalid'])
        //     setAuthen(false)
        //     setMethod('confirm')
        //     setAuthenAndSendLog(sEKYCLog, false)
        //     return;
        // }
        // END Bổ sung fake_print_photo

        if (!object || !object.id) {
            if (infos.errors) {
                if (infos.errors.includes('anh_mat_truoc_bi_che')) {
                    setListMessage(['anh_mat_truoc_bi_che'])
                    setAuthen(false)
                    setMethod('confirm')
                    setAuthenAndSendLog(sEKYCLog, false)
                    return
                }

                if (stringToSlug(infos.errors[0]) === 'loai-giay-to-khong-hop-le') {
                    setListMessage(['document_invalid'])
                } else {
                    setListMessage(infos.errors)
                }
            } else {
                setListMessage(['service_handle_error'])
            }
            setAuthen(false)
            setMethod('confirm')
            setAuthenAndSendLog(sEKYCLog, false)
            return
        }

        let listMessageError = []
        if (Array.isArray(object.general_warning)) {
            listMessageError = object.general_warning;
        }

        if (liveness_card_front?.object?.liveness === 'failure') {
            listMessageError.push('front_image_id_invalid')
            setListMessage(listMessageError)
            setAuthen(false)
            setMethod('confirm')
            setAuthenAndSendLog(sEKYCLog, false)
            return
        }

        if (liveness_card_back?.object?.liveness === 'failure') {
            listMessageError.push('back_image_id_invalid')
            setListMessage(listMessageError)
            setAuthen(false)
            setMethod('confirm')
            setAuthenAndSendLog(sEKYCLog, false)
            return
        }

        listMessageError = listMessageError.filter(e => e !== 'qua_han_mat_truoc' && e !== 'qua_han_mat_sau')
        setListMessage(listMessageError)

        //#endregion SYNC MTS
        // If warning_msg > 2 warning => Reject
        let warningMessage = []
        if (Array.isArray(object?.warning_msg)) {
            warningMessage = object.warning_msg
            setEKYCWarningMessage(warningMessage)
            if (warningMessage.length >= 2) {
                setListMessage(['unknow_error_ekyc'])
                setAuthen(false)
                setMethod('confirm')
                setAuthenAndSendLog(sEKYCLog, false)
                return;
            }
        }

        //#region comment để theo flow MTS
        // // ALT-1031 - Bổ sung logic từ chối với match_front_back.match_id = no
        // if (object?.match_front_back?.match_id == 'no') {
        //     setListMessage(['document_invalid'])
        //     setAuthen(false)
        //     setMethod('confirm')
        //     setAuthenAndSendLog(sEKYCLog, false)
        //     return;
        // }

        // // END

        // if (object.hasOwnProperty('name_fake_warning')) {
        //     if (object.name_fake_warning === 'fake') {
        //         setListMessage(['document_invalid'])
        //         setAuthen(false)
        //         setMethod('confirm')
        //         setAuthenAndSendLog(sEKYCLog, false)
        //         return
        //     }
        // }

        // if (liveNess && liveNess.object && liveNess.object.liveness === 'failure') {
        //     setListMessage(['face_is_not_match_with_document'])
        //     setAuthen(false)
        //     setMethod('confirm')
        //     setAuthenAndSendLog(sEKYCLog, false)
        //     return;
        // }
        // if (liveNess && liveNess.errors && liveNess.errors.length > 0) {
        //     setListMessage(['face_is_not_match_with_document'])
        //     setAuthen(false)
        //     setMethod('confirm')
        //     setAuthenAndSendLog(sEKYCLog, false)
        //     return;
        // }
        // if (masked && masked.object && masked.object.masked === 'yes') {
        //     setListMessage(['face_is_not_match_with_document'])
        //     setAuthen(false)
        //     setMethod('confirm')
        //     setAuthenAndSendLog(sEKYCLog, false)
        //     return;
        // }
        // if (!object) {
        //     if (infos.errors) {
        //         if (glb_sv.stringToSlug(infos.errors[0]) === 'loai-giay-to-khong-hop-le') {
        //             setListMessage(['document_invalid'])
        //         } else {
        //             setListMessage(infos.errors)
        //         }
        //     } else {
        //         setListMessage(['service_handle_error'])
        //     }
        //     setAuthen(false)
        //     setMethod('confirm')
        //     setAuthenAndSendLog(sEKYCLog, false)
        //     return
        // }

        // let listMessageError = []
        // if (liveness_card_front?.object?.liveness === 'failure') {
        //     listMessageError.push('front_image_id_invalid')
        //     setListMessage(listMessageError)
        //     setAuthen(false)
        //     setMethod('confirm')
        //     setAuthenAndSendLog(sEKYCLog, false)
        //     return
        // }
        // if (liveness_card_back?.object?.liveness === 'failure') {
        //     listMessageError.push('back_image_id_invalid')
        //     setListMessage(listMessageError)
        //     setAuthen(false)
        //     setMethod('confirm')
        //     setAuthenAndSendLog(sEKYCLog, false)
        //     return
        // }
        // // Bổ sung fake_print_photo
        // // if (liveness_card_front.object && liveness_card_front.object?.fake_print_photo) {
        // //     setListMessage(['front_image_id_invalid'])
        // //     setAuthen(false)
        // //     setMethod('confirm')
        // //     setAuthenAndSendLog(sEKYCLog, false)
        // //     return;
        // // }
        // // if (liveness_card_back.object && liveness_card_back.object?.fake_print_photo) {
        // //     setListMessage(['back_image_id_invalid'])
        // //     setAuthen(false)
        // //     setMethod('confirm')
        // //     setAuthenAndSendLog(sEKYCLog, false)
        // //     return;
        // // }
        // // END Bổ sung fake_print_photo
        // if (!compares.object || compares.object.msg !== 'MATCH') {
        //     listMessageError.push('face_not_match')
        // }
        // listMessageError = listMessageError.filter(e => e !== 'qua_han_mat_truoc' && e !== 'qua_han_mat_sau')
        // setListMessage(listMessageError)
        // checkUserInfoExist(object.name, object.birth_day)

        //#endregion comment để theo flow MTS

        // Backend
        // 01	CMND
        // 02	Hộ chiếu
        // 03	GPKD
        // 04	Chứng thư khác
        // 05	Trading Code (TCNN)
        // 06	Trading Code (CNNN)
        // 07	Cơ quan chính phủ
        // 08	Căn cước công dân

        // EKYC
        // "0: CM9
        // 1: CCCD hoặc CM12
        // 2: HC
        // 3: CMQD
        // 4: BLX
        // 5: CCGC"

        if (String(object.type_id) === '0') {
            card_type.current = '1'
        } else if (String(object.type_id) === '1') {
            if (glb_sv.stringToSlug(object.card_type).includes('chung-minh-nhan-dan')) {
                card_type.current = '1'
            } else {
                card_type.current = '8'
            }
        } else if (String(object.type_id) === '2') {
            card_type.current = '2'
        } else if (String(object.type_id) === '3' || String(object.type_id) === '4') {
            card_type.current = '4'
        } else if (String(object.type_id) === '5') {
            card_type.current = '8'
        } else {
            card_type.current = '4'
        }

        if (object.gender === 'Nam' || object.gender === 'Male') {
            setGender('M')
        } else if (glb_sv.stringToSlug(object.gender) === 'nu' || object.gender === 'Female') {
            setGender('F')
        }
        if (object.valid_date && object.valid_date.length >= 8) {
            validDate.current = moment(object.valid_date, 'DD/MM/YYYY').toDate()
        }
        setID(object.id)
        setName(object.name)
        if (object.birth_day && object.birth_day.length === 10) setBirthday(moment(object.birth_day, 'DD/MM/YYYY'))
        if (object.birth_day && object.birth_day.length === 4) setBirthday(moment(object.birth_day + '0101', 'YYYYMMDD'))
        setAddr(object.recent_location?.replaceAll('\n', ' '))
        if (object.issue_date && object.issue_date.length === 10) setIDDate(moment(object.issue_date, 'DD/MM/YYYY'))
        setIDPlace(object.issue_place)
        if (hash_img) {
            dataRef.current.imageHashFront = hash_img.img_front
            dataRef.current.imageHashBack = hash_img.img_back
            dataRef.current.imageHashFace = hash_img.img_face
        }
        if (compares) {
            dataRef.current.compareFace = compares.object?.prob
        }
        if (object?.match_front_back?.match_id == 'no') {
            setListMessage(['document_invalid'])
            setAuthen(false)
            setMethod('confirm')
            setAuthenAndSendLog(sEKYCLog, false)
            return;
        }
        if (
            object.id_fake_warning === 'no' &&
            object.name_prob > 0.99 &&
            liveness_card_back?.object?.liveness === 'success' &&
            liveness_card_front?.object?.liveness === 'success' &&
            (compares.object?.msg === 'MATCH' ||
                compares.object?.prob > Number(glb_sv.dataEkycInfo.c3))
        ) {
            setAuthen(true)
            // setMethod('sign')
            setMethod('confirm')
            setAuthenAndSendLog(sEKYCLog, true)
        } else {
            setListMessage(['unknow_error_ekyc'])
            setAuthen(false)
            setMethod('confirm')
            setAuthenAndSendLog(sEKYCLog, false)
        }
        if (infos?.statusCode === 400) {
            setListMessage(['service_handle_error'])
            setAuthen(false)
            setMethod('confirm')
            setAuthenAndSendLog(sEKYCLog, false)
        }

        if (object.valid_date && object.valid_date.length >= 8) {
            // glb_sv.timeServer YYYYMMDD && valid_date dd/MM/YYYY
            const dValidDate = moment(object.valid_date, 'DD/MM/YYYY').format('YYYYMMDD')
            if (dValidDate <= glb_sv.timeServer) {
                setListMessage(['warning_invalid_date'])
                setAuthen(false)
                setMethod('confirm')
                setAuthenAndSendLog(sEKYCLog, false)
                return
            }
        }

        // Kiểm tra thông tin nên để cuối cùng
        checkUserInfoExist(object.name, object.birth_day)
    }

    const setAuthenAndSendLog = (sEKYCLog, isAuthen = true) => {
        if (isAuthen) {
            glb_sv.sendEKYCLog(sEKYCLog)
        } else {
            glb_sv.sendEKYCLog(sEKYCLog, '4')
        }
    }
    const updateUserInfo = () => {
        const compareFace = dataRef.current.compareFace
        if (!id || !id.trim() || id === '-') {
            glb_sv.focusInput('idRef_ekyc')
            message.warning(t('warning_id_require'))
            return
        }
        if (!name || !name.trim() || name === '-') {
            glb_sv.focusInput('nameRef_ekyc')
            message.warning(t('warning_full_name_require'))
            return
        }
        if (!addr || !addr.trim() || addr === '-') {
            glb_sv.focusInput('addrRef_ekyc')
            message.warning(t('warning_addr_regit_per_require'))
            return
        }
        if (!addrContact || !addrContact.trim() || addrContact === '-') {
            glb_sv.focusInput('addrContactRef_ekyc')
            message.warning(t('warning_contact_addr_require'))
            return
        }
        if (!gender) {
            glb_sv.focusInput('gender_ekyc')
            message.warning(t('warning_gender_require'))
            return
        }
        if (!idPlace || !idPlace.trim() || idPlace === '-') {
            glb_sv.focusInput('idPlaceRef_ekyc')
            message.warning(t('warning_issue_place_require'))
            return
        }
        if (isBankOnline) {
            if (!bankCd && isBankOnline === 2) {
                glb_sv.focusInput('bank-code-ekyc')
                message.warning(t('warning_bank_code_require'))
                return
            }
            if (!bankAct) {
                glb_sv.focusInput('bank-account-ekyc')
                message.warning(t('warning_bank_account_require'))
                return
            }
        }
        if (!checkNote) return
        if (flagEkycUpdate.current) return
        let bankCdOnline = bankCd;
        if (allowCompanyRender(['081', '888'])) {
            if (isBankOnline === 1) {
                bankCdOnline = '0028'
            }
        }
        flagEkycUpdate.current = true
        setLoading(true)
        const inval = [
            'ekyc',
            glb_sv.credentials.username,
            glb_sv.credentials.password,
            type === 'normal' ? (id.trim().length < 12 ? '1' : '8') : card_type.current,
            id.trim(),
            name.trim(),
            birthday.format('YYYYMMDD'),
            addr.replace(/\n/g, ' ').trim(),
            addrContact.replace(/\n/g, ' ').trim(),
            idDate.format('YYYYMMDD'),
            idPlace.replace(/\n/g, ' ').trim(),
            gender,
            type === 'normal' ? 'no_image' : dataRef.current.imageHashFace,
            type === 'normal' ? 'no_image' : dataRef.current.imageHashFront,
            type === 'normal' ? 'no_image' : dataRef.current.imageHashBack,
            type === 'normal' ? '100' : String(compareFace),
            '', // Signture 1
            '', // Signture 2
            email.trim().toLowerCase(), // email
            phone.trim(), // mobile
            brokerCode || '', // Broker
            remisierObj || '', // Remisier
            validDate.current &&
                moment(validDate.current).format('YYYYMMDD') !== 'Invalid date'
                ? moment(validDate.current).format('YYYYMMDD')
                : '',
            isBankOnline ? bankAct : '',
            isBankOnline ? name.trim() : '',
            isBankOnline ? bankCdOnline : '',
            isBankOnline === 1 ? 'Y' : (isBankOnline === 2 ? 'N' : ''),
            institutional,
            referrerCode,
            referrerName,
            checkMargin ? 'Y' : 'N',
            '', // nơi sinh
            'N', //  32: Auto PIA Y/N
            '', // 33: ID chữ ký
            '', // 34: Job ID
            '', // 35: Fata (Y/N)
            '', // 36: DS mã CK liên quan, cách nhau dấu ;
            '', // 37: Mã khuyến mãi
            configUIEkyc.brokerage_services_check ? (isBrokerService ? '1' : '0') : '0', // 38 Register for brokerage services
            isBrokerService ? '1' : '0', // 38: Dịch vụ môi giới
            eKYCWarningMessage.join(';'), // 39: Warning msg từ VNPT trả ra
        ]
        sendRequest(
            ServiceInfo.EKYC_UPDATE,
            inval,
            updateUserInfoResult,
            true,
            updateUserInfoTimeout
        )
        if (window.gtag) {
            window.gtag('event', 'ekyc_web_end');
        }
    }

    const updateUserInfoTimeout = ({ type }) => {
        flagEkycUpdate.current = false
        setLoading(false)
        glb_sv.showAlert({
            title: t('common_notify'),
            content: t('request_hanlde_not_success_try_again'),
            type: 'warn',
        })
    }

    const updateUserInfoResult = (reqInfoMap, message) => {
        flagEkycUpdate.current = false
        setLoading(false)
        if (Number(message['Result']) === 0) {
            glb_sv.showAlert({
                title: t('common_notify'),
                content: message.Message,
                type: 'warn',
                zIndex: 999999,
            })
        } else {
            localStorage.removeItem('openAccountEkyc')
            glb_sv.showAlert({
                title: t('common_notify'),
                content: message.Message,
                type: 'success',
                linkCallback: () => setVisible(false),
                zIndex: 999999,
            })

            let json
            try {
                json = JSON.parse(message.Data)[0]
            } catch (err) {
                console.log(err)
                if (glb_sv.objShareGlb.userInfo)
                    glb_sv.objShareGlb.userInfo.c9 = 'W'
                glb_sv.commonEvent.next({ type: eventList.EKYC_SUCCESS })
            }
            if (json && json.c0 === 'W') {
                if (glb_sv.objShareGlb.userInfo)
                    glb_sv.objShareGlb.userInfo.c9 = 'W'
                glb_sv.commonEvent.next({ type: eventList.EKYC_SUCCESS })
            } else {
                storeAccountInfo(json)
                if (glb_sv.objShareGlb.userInfo)
                    glb_sv.objShareGlb.userInfo.c9 = 'A'
                glb_sv.commonEvent.next({ type: eventList.EKYC_SUCCESS })
            }

            try {
                const cname = '';
                const ctel = phone;
                const cemail = '';
                const cinfo = '';
                const price = 0;

                window.rtAsyncInit = function () {
                    return {
                        sid: 500,
                        pid: 143783,
                        price: price,
                        reward: -1,
                        cname: cname,
                        ctel: ctel,
                        cemail: cemail,
                        cinfo: cinfo
                    };
                }
            } catch (err) {
                console.log("updateUserInfoResult -> err", err)
            }
        }
    }

    const validate = () => {
        if (!id || id === '-') return false
        if (!name || name === '-') return false
        if (!birthday) return false
        if (!addr || addr === '-') return false
        if (!addrContact || addrContact === '-') return false
        if (!idDate || idDate.format('YYYYMMDD') === 'Invalid Date')
            return false
        if (!idPlace || idPlace === '-') return false
        if (!checkNote) return false
        if (!isAuthen) return false
        if (!gender) return false
        return true
    }

    const authenEkyc = () => {
        setImageFront('')
        setImageBack('')
        setImageFace('')

        setMethod('ekyc')
        setTimeout(() => {
            // loadEkycSDK()
        }, 100)
    }

    const [signature, setSign] = useState(null)
    const [signature2, setSign2] = useState(null)
    const [page, setPage] = useState(['1'])

    const imagePath1 = useRef('')
    const imagePath2 = useRef('')

    const signRef1 = useRef(null)
    const signRef2 = useRef(null)

    // const getApiUpdload = () => {
    //     const inval = ['CHECK', ''];
    //     sendRequest(ServiceInfo.API_UPLOAD, inval, getApiUpdloadResult, true, getApiUpdloadTimeout);
    // }

    // const getApiUpdloadTimeout = ({ type }) => {

    // }

    // const getApiUpdloadResult = (reqInfoMap, message) => {
    //     console.log("getApiUpdloadResult -> message", message)
    //     if (Number(message['Result']) === 0) {
    //     } else {
    //         let datajson;
    //         try {
    //             datajson = message.Data ? JSON.parse(message.Data)[0] : {};
    //         } catch (err) {
    //             console.log('checkEkycResult', err);
    //             return
    //         }

    //     }
    // }

    const hanldeDoneSign = async () => {
        // imagePath1.current = `${RNFS.TemporaryDirectoryPath}${moment().valueOf()}-signature1.jpg`;
        // await RNFS.writeFile(imagePath1, signature, 'base64');
        // imagePath2.current = `${RNFS.TemporaryDirectoryPath}${moment().valueOf()}-signature2.jpg`;
        // await RNFS.writeFile(imagePath2, signature2, 'base64');

        setMethod('confirm')
    }

    const storeAccountInfo = (userInfo) => {
        const accountInfo = userInfo.c0
        const array_acnt = accountInfo.split('|').filter((e) => e !== '')
        const js_acntInfo = []
        const js_acntList = []
        // const js_acntListAll = []
        let acntMain = '',
            acntMainName = ''
        for (let i = 0; i < array_acnt.length; i++) {
            const acntInfo = array_acnt[i]
            const arr_info = acntInfo.split(',')

            if (arr_info[4] === 'Y') {
                acntMain = arr_info[0]
                acntMainName = arr_info[2]
            }
            const js_acnt = {
                AcntNo: arr_info[0],
                SubNo: arr_info[1],
                AcntNm: arr_info[2],
                IsOwnAcnt: arr_info[4],
                MarginYN: arr_info[3] === '2',
                ActType: arr_info[3],
            }
            const js_acntObj = {
                id: arr_info[0] + '.' + arr_info[1],
                name: arr_info[0] + '.' + arr_info[1] + ' - ' + arr_info[2],
            }
            js_acntInfo.push(js_acnt)
            js_acntList.push(js_acntObj)
            // js_acntListAll.push(js_acntObj)
        }

        glb_sv.objShareGlb['acntNoInfo'] = js_acntInfo
        glb_sv.objShareGlb['acntNoList'] = js_acntList
        // glb_sv.objShareGlb['acntNoListAll'] = js_acntListAll
        // --------------------
        glb_sv.objShareGlb['AcntMain'] = acntMain // -- chủ tài khoản lưu ký
        glb_sv.objShareGlb['AcntMainNm'] = acntMainName // -- Tên chủ tài khoản
        glb_sv.objShareGlb['workDate'] = userInfo.c16 // -- Ngày làm việc
        // glb_sv.objShareGlb['brokerId'] = userInfo['c24'] // -- ID nhân viên môi giới
        // glb_sv.objShareGlb['brokerNm'] = userInfo['c25'] // -- Tên nhân viên môi giới
        // glb_sv.objShareGlb['brokerEmail'] = userInfo['c26'] // -- Email nhân viên môi giới
        // glb_sv.objShareGlb['brokerMobile'] = userInfo['c27'] // -- Mobile nhân viên môi giới
        // glb_sv.objShareGlb['secCode'] = userInfo['c9'] // -- Tên nhân viên môi giới
        // glb_sv.objShareGlb['prdYN'] = userInfo['c31'] // -- User trình chiếu bảng điện hay không
        glb_sv.objShareGlb['verify'] = Number(userInfo['c37']) || 0 // -- verify > 0 => Phải xác thực chứng chỉ số
        glb_sv.objShareGlb['serialnum'] = userInfo['c38'] // -- serialNumber

        const sub_list = []
        const actn_list = []
        js_acntInfo.forEach((e) => {
            if (e.AcntNo === acntMain) sub_list.push(e.SubNo)
            if (!actn_list.find((temp) => temp.AcntNo === e.AcntNo))
                actn_list.push({
                    AcntNo: e.AcntNo,
                    actn_name: e.AcntNm,
                    key: e.AcntNo,
                    label: e.AcntNo + ' (' + e.AcntNm + ')',
                })
        })
        const isMargin = glb_sv.objShareGlb['acntNoInfo'].find(
            (e) => e.AcntNo === acntMain && e.SubNo === sub_list[0]
        )?.MarginYN
        setUserInfo({
            actn_curr: acntMain,
            sub_curr: sub_list[0],
            actn_name: acntMainName,
            sub_list,
            actn_list,
            actn_full: acntMain + '.00',
            isMargin: isMargin || false,
        })
    }

    const onCancel = () => {
        glb_sv.showAlert({
            title: t('common_notify'),
            content: t('msg_you_sure_exit_sign_up'),
            type: 'warn',
            linkCallback: () => {
                const camera_attach_video = document.getElementById(
                    'camera_attach_video'
                )
                if (camera_attach_video) {
                    stopStreamedVideo(camera_attach_video)
                }
                const video = document.getElementById('video')
                if (video) {
                    stopStreamedVideo(video)
                }
                const ekyc_sdk_intergrated = document.getElementById(
                    'ekyc_sdk_intergrated'
                )
                if (ekyc_sdk_intergrated) {
                    ekyc_sdk_intergrated.childNodes[1] &&
                        ekyc_sdk_intergrated.removeChild(
                            ekyc_sdk_intergrated.childNodes[1]
                        )
                    ekyc_sdk_intergrated.childNodes[0] &&
                        ekyc_sdk_intergrated.removeChild(
                            ekyc_sdk_intergrated.childNodes[0]
                        )
                }
                setVisible(false)
                setMethod('')
            },
            showCancel: true,
        })
    }

    const getBrokerList = () => {
        const inval = ['brk_rem']
        sendRequest(
            ServiceInfo.GET_BROKER_REMISIER_LIST,
            inval,
            onGetBrokerListResult
        )
        tempList.current = []
    }

    const onGetBrokerListResult = (reqInfoMap, message) => {
        if (Number(message['Result']) === 0) {
            return
        } else {
            let jsondata = []
            try {
                jsondata = message['Data'] ? JSON.parse(message['Data']) : []
            } catch (err) { }
            tempList.current = tempList.current.concat(jsondata)
            if (Number(message['Packet']) <= 0) {
                setListOther([...tempList.current])
            }
        }
    }

    const getUserInfo = () => {
        const InputParams = ['info']
        sendRequest(
            ServiceInfo.QUERY_USER_INFORMATION,
            InputParams,
            handleGetUserInfo
        )
    }

    const handleGetUserInfo = (reqInfoMap, message) => {
        if (Number(message['Result']) === 0) {
            return
        } else {
            let jsondata
            if (!message['Data']) return
            try {
                jsondata = JSON.parse(message['Data'])
                glb_sv.userInfoServer = jsondata[0]
                setObjUserInfo(jsondata[0])
                setPhone(
                    jsondata[0] && jsondata[0].c35 ? jsondata[0].c35.trim() : ''
                )
                setEmail(
                    jsondata[0] && jsondata[0].c32 ? jsondata[0].c32.trim() : ''
                )
            } catch (err) {
                return
            }
        }
    }

    const getListCodeBanks = () => {
        const InputParams = ['07', '%']
        sendRequest(
            ServiceInfo.GET_LIST_BANK,
            InputParams,
            handleGetListCodeBanks
        )
    }


    const handleGetListCodeBanks = (reqInfoMap, message) => {
        if (Number(message['Result']) === 0) {
            return
        } else {
            let jsondata = []
            try {
                if (!message['Data']) {
                } else {
                    jsondata = JSON.parse(glb_sv.filterStrBfParse(message['Data']))
                }
            } catch (err) {
                return
            }
            bankCdListTemple.current = bankCdListTemple.current.concat(jsondata)
            if (Number(message['Packet']) <= 0) {
                setBankCdList(bankCdListTemple.current)
            }
        }
    }

    const getListBanks = () => {
        const InputParams = ['18', '%', '%']
        sendRequest(
            ServiceInfo.GET_LIST_BANK,
            InputParams,
            handleGetListBanks
        )
    }

    const handleGetListBanks = (reqInfoMap, message) => {
        if (Number(message['Result']) === 0) {
            return
        } else {
            let jsondata = []
            try {
                if (!message['Data']) {
                } else {
                    jsondata = JSON.parse(glb_sv.filterStrBfParse(message['Data']))
                }
            } catch (err) {
                return
            }
            bankListTemple.current = bankListTemple.current.concat(jsondata)
            if (Number(message['Packet']) <= 0) {
                setBankList(bankListTemple.current)
            }
        }
    }

    const getInstituation = () => {
        const inval = ['06', '%']
        sendRequest(ServiceInfo.GET_INSTITUATION, inval, getInstituationResult)
    }

    const getInstituationResult = (reqInfoMap, message) => {
        if (Number(message['Result']) === 0) {
        } else {
            let jsondata = [];
            try {
                jsondata = message['Data'] ? JSON.parse(message['Data']) : [];
            }
            catch (err) {
                return
            }
            setInstitutionalList(jsondata)
        }
    }

    const getReferrerName = () => {
        setReferrerCodeError(false)
        if (institutionalRef.current === '02' && referrerCodeRef.current) {
            const inval = ['07', institutionalRef.current, referrerCodeRef.current]
            sendRequest(ServiceInfo.GET_REFER_NAME, inval, getReferrerNameResult, true, getReferrerNameTimeout, '', 0, 'equal_service')
        }
    }

    const getReferrerNameTimeout = () => { }

    const getReferrerNameResult = (reqInfoMap, message) => {
        if (Number(message['Result']) === 0) {
            setReferrerCodeError(true)
        } else {
            let jsondata = [];
            try {
                jsondata = message['Data'] ? JSON.parse(message['Data']) : [];
                setReferrerName(jsondata[0].c0)
                setReferrerCodeError(false)
            }
            catch (err) {
                setReferrerCodeError(true)
                return
            }
        }
    }

    const checkUserInfoExist = (name, birday) => {
        const inval = ['CHK_NAME_BIRDT', name, birday && birday.length === 10 ? moment(birday, 'DD/MM/YYYY').format('YYYYMMDD') : birday]
        sendRequest(ServiceInfo.CHECK_DUPLICATE_INFO, inval, checkUserInfoExistResult)
    }

    const checkUserInfoExistResult = (reqInfoMap, message) => {
        if (Number(message['Result']) === 0) {
        } else {
            let jsondata = [];
            try {
                jsondata = message['Data'] ? JSON.parse(message['Data']) : [];
                if (jsondata[0].c0 === 'Y') {
                    glb_sv.showAlert({
                        title: t('common_notify'),
                        content: t('notify_info_ekyc_is_duplicate'),
                        linkCallback: () => null,
                        showCancel: true,
                        cancelCallBack: () => {
                            setVisible(false)
                            setMethod('')
                        }
                    })
                }
            }
            catch (err) {
                return
            }
        }
    }

    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            footer={null}
            wrapClassName={
                method === 'ekyc' ? 'modal-ekyc' : 'modal-ekyc-final'
            }
            width={method === 'ekyc' ? 1000 : 600}
            style={{ top: 20 }}
        >
            <div className='flex'>
                <span className='flex flex-1 justify-content-center fs-medium font-600 text-primary'>
                    {t('common_register_open_account_ekyc')}
                </span>
                <span
                    onClick={onCancel}
                    className='fs-big text-primary cursor-pointer'
                    style={{
                        fontSize: 24,
                        paddingRight: 12,
                        paddingLeft: 12,
                        color: method === 'ekyc' ? 'white' : '',
                    }}
                >
                    ×
                </span>
            </div>

            {method === 'ekyc' ? (
                <div id='ekyc_sdk_intergrated'>
                    {loadingFaceVnpt && (
                        <Spin size='large' style={{ margin: '250px 460px' }} />
                    )}
                </div>
            ) : null}

            {/* {method === 'sign' ? <div>
                <div className='flex'>
                    <div className='flex flex-3 align-items-start'>
                        <span className='fs-medium fs-600 text-primary'>
                            {t('add_sign_authen')}
                        </span>
                    </div>
                    <div className='flex'>
                        {signature && signature2 ?
                            <Button
                                ghost
                                className='text-brand fs-medium fw-500'
                                onClick={hanldeDoneSign}>
                                {t('continue')}
                            </Button> : null}
                    </div>
                </div>
                <Collapse activeKey={page} onChange={value => setPage(value)}>
                    <Panel header={t('signature') + ' 1'} key="1">
                        <SignatureCanvas
                            canvasProps={{ width: 918, height: 500, className: 'sigCanvas' }}
                            ref={signRef1} />
                        <div>
                            <Button onClick={() => signRef1.current.clear()}>
                                {t('common_clear')}
                            </Button>
                            <Button
                                type="primary"
                                onClick={() => {
                                    const data = signRef1.current.getTrimmedCanvas()
                                        .toDataURL('image/png');
                                    setSign(data)
                                    setPage(['2'])
                                }}>
                                {t('save')}
                            </Button>
                        </div>
                    </Panel>
                    <Panel header={t('signature') + ' 2'} key="2">
                        <SignatureCanvas
                            canvasProps={{ width: 918, height: 500, className: 'sigCanvas' }}
                            ref={signRef2} />
                        <div>
                            <Button onClick={() => signRef2.current.clear()}>
                                {t('common_clear')}
                            </Button>
                            <Button
                                type="primary"
                                onClick={() => {
                                    const data = signRef2.current.getTrimmedCanvas()
                                        .toDataURL('image/png');
                                    setSign2(data)
                                }}>
                                {t('save')}
                            </Button>

                            {signature && signature2 ?
                                <Button
                                    ghost
                                    className='text-brand fs-medium fw-500'
                                    onClick={hanldeDoneSign}>
                                    {t('continue')}
                                </Button> : null}
                        </div>
                    </Panel>

                </Collapse>
            </div> : null} */}

            {method === 'confirm' ? (
                <div className='flex flex-direction-column'>

                    <div className='row-ekyc'>
                        <span className='flex flex-4 text-second fs-normal align-items-center'>
                            {t('common_full_name')}
                            <span className='price-down'>*</span>
                        </span>
                        <div style={{ width: 5 }} />
                        <span className='text-primary fs-normal flex flex-5'>
                            <Input
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value)
                                }}
                                disabled={type === 'ekyc'}
                                onFocus={() =>
                                    name === '-' ? setName('') : null
                                }
                                onBlur={() =>
                                    name === '' ? setName('-') : null
                                }
                                id='nameRef_ekyc'
                                className='padding-input'
                            />
                        </span>
                    </div>
                    <div className='row-ekyc'>
                        <span className='flex flex-4 text-second fs-normal align-items-center'>
                            {t('phone_number')}
                            <span className='price-down'>*</span>
                        </span>
                        <div style={{ width: 5 }} />
                        <span className='text-primary fs-normal flex flex-5'>
                            <Input
                                onChange={(e) => {
                                    setPhone(e.target.value)
                                }}
                                disabled={
                                    objUserInfo
                                        ? objUserInfo.c35.trim() === ''
                                            ? false
                                            : objUserInfo.c36 === 'Y'
                                                ? false
                                                : true
                                        : false
                                }
                                value={phone}
                                onFocus={() =>
                                    phone === '-' ? setPhone('') : null
                                }
                                onBlur={() =>
                                    phone === '' ? setPhone('-') : null
                                }
                                maxLength={10}
                                id='phone_ekyc'
                                className='padding-input flex-1'
                            />
                        </span>
                    </div>
                    <div className='row-ekyc'>
                        <span className='flex flex-4 text-second fs-normal align-items-center'>
                            {t('email')}
                            <span className='price-down'>*</span>
                        </span>
                        <div style={{ width: 5 }} />
                        <span className='text-primary fs-normal flex flex-5'>
                            <Input
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value)
                                }}
                                disabled={
                                    objUserInfo
                                        ? objUserInfo.c32.trim() === ''
                                            ? false
                                            : objUserInfo.c33 === 'Y'
                                                ? false
                                                : true
                                        : false
                                }
                                onFocus={() =>
                                    email === '-' ? setEmail('') : null
                                }
                                onBlur={() =>
                                    email === '' ? setEmail('-') : null
                                }
                                id='email_ekyc'
                                className='padding-input flex-1'
                            />
                        </span>
                    </div>

                    <div className='row-ekyc'>
                        <span className='flex flex-4 text-second fs-normal align-items-center'>
                            {t('id_card_number')}
                            <span className='price-down'>*</span>
                        </span>
                        <div style={{ width: 5 }} />
                        <span className='text-primary fs-normal flex flex-5'>
                            <Input
                                value={id}
                                onChange={(e) => {
                                    setID(e.target.value)
                                }}
                                disabled={type === 'ekyc'}
                                onFocus={() => (id === '-' ? setID('') : null)}
                                onBlur={() => (id === '' ? setID('-') : null)}
                                id='idRef_ekyc'
                                className='padding-input flex-1'
                            />
                        </span>
                    </div>

                    <div className='row-ekyc'>
                        <span className='flex flex-4 text-second fs-normal align-items-center'>
                            {t('common_register_date')}
                            <span className='price-down'>*</span>
                        </span>
                        <div style={{ width: 5 }} />
                        <span className='text-primary fs-normal flex flex-5'>
                            <DatePicker
                                value={idDate}
                                size='middle'
                                onChange={(value) => setIDDate(value)}
                                format={'DD/MM/YYYY'}
                            />
                        </span>
                    </div>

                    <div className='row-ekyc'>
                        <span className='flex flex-4 text-second fs-normal align-items-center'>
                            {t('common_register_place')}
                            <span className='price-down'>*</span>
                        </span>
                        <div style={{ width: 5 }} />
                        <span className='text-primary fs-normal flex flex-5'>
                            <Input
                                value={idPlace}
                                onChange={(e) => {
                                    setIDPlace(e.target.value)
                                }}
                                onFocus={() =>
                                    idPlace === '-' ? setIDPlace('') : null
                                }
                                onBlur={() =>
                                    idPlace === '' ? setIDPlace('-') : null
                                }
                                id='idPlaceRef_ekyc'
                                className='padding-input flex flex-1'
                            />
                        </span>
                    </div>

                    <div className='row-ekyc'>
                        <span className='flex flex-4 text-second fs-normal align-items-center'>
                            {t('common_birthday')}
                            <span className='price-down'>*</span>
                        </span>
                        <div style={{ width: 5 }} />
                        <span className='text-primary fs-normal flex flex-5'>
                            <DatePicker
                                value={birthday}
                                size='middle'
                                onChange={(value) => setBirthday(value)}
                                format={'DD/MM/YYYY'}
                            />
                        </span>
                    </div>

                    <div className='row-ekyc'>
                        <span className='text-second fs-normal flex flex-4 align-items-center'>
                            {t('addr_regit_per')}
                            <span className='price-down'>*</span>
                        </span>
                        <div style={{ width: 5 }} />
                        <span className='text-primary fs-normal flex flex-5'>
                            <Input
                                value={addr}
                                onChange={(e) => {
                                    setAddr(e.target.value)
                                }}
                                onFocus={() =>
                                    addr === '-' ? setAddr('') : null
                                }
                                onBlur={() =>
                                    addr === '' ? setAddr('-') : null
                                }
                                id='addrRef_ekyc'
                                className='padding-input flex flex-1'
                                maxLength={100}
                            />
                        </span>
                    </div>

                    <div className='row-ekyc'>
                        <span className='text-second fs-normal flex flex-4 align-items-center'>
                            {t('contact_address')}
                            <span className='price-down'>*</span>
                        </span>
                        <div style={{ width: 5 }} />
                        <span className='text-primary fs-normal flex flex-5'>
                            <Input
                                value={addrContact}
                                onChange={(e) => {
                                    setAddrContact(e.target.value)
                                }}
                                onFocus={() =>
                                    addrContact === '-'
                                        ? setAddrContact('')
                                        : null
                                }
                                onBlur={() =>
                                    addrContact === ''
                                        ? setAddrContact('-')
                                        : null
                                }
                                id='addrContactRef_ekyc'
                                className='padding-input flex flex-1'
                                maxLength={100}
                                suffix={addrContact.length + '/100'}
                            />
                        </span>
                    </div>

                    <div className='row-ekyc'>
                        <span className='flex flex-4 text-second fs-normal align-items-center'>
                            {t('common_your_sex')}
                            <span className='price-down'>*</span>
                        </span>
                        <div style={{ width: 5 }} />
                        <span className='text-primary fs-normal flex flex-5'>
                            <Select
                                onChange={(value) => setGender(value)}
                                value={gender}
                                size='middle'
                                id='gender_ekyc'
                            >
                                <Option value='M'>{t('common_male')}</Option>
                                <Option value='F'>{t('common_female')}</Option>
                                <Option value='O'>{t('common_other')}</Option>
                            </Select>
                        </span>
                    </div>

                    {type === 'ekyc' ? (
                        <div className='row-ekyc align-items-center'>
                            <span className='flex flex-4 text-second fs-normal'>
                                {t('authen_face')}
                            </span>
                            <div style={{ width: 5 }} />
                            <div
                                className='flex flex-5'
                                style={{
                                    backgroundColor: isAuthen
                                        ? '#E3F6E3'
                                        : '#F6E3E3',
                                    borderRadius: 5,
                                    padding: dimensions.moderate(8),
                                }}
                            >
                                <span
                                    className='fs-normal'
                                    style={{
                                        color: isAuthen
                                            ? styles.UP__COLOR
                                            : styles.DOWN__COLOR,
                                    }}
                                >
                                    {isAuthen
                                        ? t('cancel_success')
                                        : t('cancel_failure')}
                                </span>
                            </div>
                        </div>
                    ) : null}

                    {type === 'ekyc' && listMessage.length ? (
                        <div
                            className='flex flex-direction-column'
                            style={{
                                marginTop: dimensions.moderate(8),
                                color: 'var(--WARN__COLOR)'
                            }}
                        >
                            {listMessage.map((item, index) =>
                                <div className='flex' key={index}>
                                    {item ? '- ' + t(item) : ''}
                                </div>
                            )}
                        </div>
                    ) : null}

                    {type === 'ekyc' && (
                        <div
                            className='flex flex-direction-row justify-content-center'
                            style={{ marginTop: 6 }}
                        >
                            <div style={{ marginRight: 5, marginLeft: 5 }}>
                                <img
                                    style={{
                                        height: 120,
                                        width: 200,
                                        borderRadius: 10,
                                    }}
                                    src={imageFront}
                                />
                            </div>
                            <div style={{ marginRight: 5, marginLeft: 5 }}>
                                <img
                                    style={{
                                        height: 120,
                                        width: 200,
                                        borderRadius: 10,
                                    }}
                                    src={imageBack}
                                />
                            </div>
                            <div style={{ marginRight: 5, marginLeft: 5 }}>
                                <img
                                    style={{
                                        height: 120,
                                        width: 120,
                                        borderRadius: 60,
                                    }}
                                    src={imageFace}
                                />
                            </div>
                        </div>
                    )}

                    <div className='fw-600' style={{ marginTop: 5 }}>
                        {t('extend_service')}
                    </div>

                    {configUIEkyc.brokerage_services_check && (
                        <div className='flex flex-direction-row align-items-center'
                            style={{
                                marginTop: dimensions.vertical(8),
                            }}
                        >
                            <Checkbox
                                checked={isBrokerService}
                                onChange={() => setBrokerService((value) => !value)}
                            >
                                <span className='fs-normal text-primary'>
                                    {' '} {t('brokerage_services')}
                                </span>
                            </Checkbox>
                        </div>
                    )}

                    <div className='flex flex-direction-row align-items-center'
                        style={{
                            marginTop: dimensions.vertical(8),
                        }}
                    >
                        <Checkbox
                            checked={checkMargin}
                            onChange={() => setCheckMargin((value) => !value)}
                        >
                            <span className='fs-normal text-primary cursor-pointer'>
                                {t('active_act_trade_margin')}
                            </span>
                        </Checkbox>
                    </div>

                    <div className='fw-600' style={{ marginTop: 5 }}>
                        {t('bank_account_information')}
                        {glb_sv.dataEkycInfo?.c7 &&
                            <a
                                href={glb_sv.dataEkycInfo.c7}
                                target='_blank'
                                rel="noreferrer"
                            >
                                {' '}
                                <InfoCircleFilled />
                            </a>
                        }
                    </div>

                    <div className='row-ekyc'>
                        <span className='flex flex-4 text-second fs-normal  align-items-center'>
                            {t('type_bank_account')}
                        </span>
                        <div style={{ width: 5 }} />
                        <span className='text-primary fs-normal flex flex-5'>
                            <Checkbox
                                checked={isBankOnline === 1}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setBankOnline(1)
                                        setBankCd(t('shinhan_bank'))
                                        setBankCode('0028')
                                        glb_sv.focusInput('bank-account-ekyc')
                                    } else {
                                        setBankOnline(0)
                                    }
                                }}
                            >
                                SSTA
                            </Checkbox>

                            <Checkbox
                                checked={isBankOnline === 2}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setBankOnline(2)
                                        setBankCd('')
                                    } else {
                                        setBankOnline(0)
                                    }
                                }}
                            >
                                {t('individual_account')}
                            </Checkbox>
                        </span>
                    </div>

                    <div className='row-ekyc' style={{ visibility: isBankOnline === 0 ? 'hidden' : '' }}>
                        <div className='flex flex-4 text-primary fs-normal align-items-center'>
                            <span className='text-second text-nowrap'>
                                {t('bank_cd')}
                            </span>
                        </div>
                        <div style={{ width: 5 }} />
                        <div className='text-primary fs-normal flex flex-5'>
                            <Select
                                showSearch
                                onChange={(value) => setBankCode(value)}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                value={bankCode}
                                size='middle'
                                id='bank-branch-ekyc'
                                disabled={isBankOnline === 1}
                                className='bank-select-ekyc'
                                dropdownClassName='list-bank-cd'
                                style={{ width: '100%' }}
                            >
                                {bankCdList
                                    .map((item) => (
                                        <Option key={item.c0} value={item.c0}>
                                            {item.c3 + ' - ' + (glb_sv.language === 'VI' ? item.c1 : item.c2)}
                                        </Option>
                                    ))}
                            </Select>
                        </div>
                    </div>

                    <div className='row-ekyc' style={{ display: bankCode && isBankOnline === 2 ? 'flex' : 'none' }}>
                        <div className='flex flex-4 text-primary fs-normal align-items-center'>
                            <span className='text-second text-nowrap'>
                                {t('branch')}
                            </span>
                        </div>
                        <div style={{ width: 5 }} />
                        <div className='text-primary fs-normal flex flex-5'>
                            <Select
                                showSearch
                                onChange={(value) => {
                                    setBankCd(value)
                                    glb_sv.focusInput('bank-account-ekyc')
                                }}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                value={bankCd}
                                size='middle'
                                id='bank-code-ekyc'
                                disabled={isBankOnline === 1}
                                className='bank-select-ekyc'
                                dropdownClassName='list-bank-cd'
                                style={{ width: '100%' }}
                            >
                                {bankList.filter(e => e.c0.includes(bankCode))
                                    .map((item) => (
                                        <Option key={item.c0} value={item.c0}>
                                            {item.c1}
                                        </Option>
                                    ))}
                            </Select>
                        </div>
                    </div>

                    <div className='row-ekyc' style={{ visibility: isBankOnline === 0 ? 'hidden' : '' }}>
                        <div className='flex flex-4 text-primary fs-normal align-items-center'>
                            <span className='text-second text-nowrap'>
                                {t('bank_acnt')}
                            </span>
                        </div>
                        <div style={{ width: 5 }} />
                        <div className='text-primary fs-normal flex flex-5 align-items-center'>
                            <Input
                                value={bankAct}
                                id='bank-account-ekyc'
                                onChange={(e) => {
                                    setBankAct(e.target.value)
                                }}
                                className='padding-input flex flex-1'
                            />
                        </div>
                    </div>

                    <div className='flex fw-600' style={{ marginTop: 5 }}>
                        {t('referral_info')}
                    </div>

                    <div className='row-ekyc'>
                        <span className='flex flex-4 text-second fs-normal align-items-center'>
                            {t('institutional')}
                        </span>
                        <div style={{ width: 5 }} />
                        <div className='text-primary fs-normal flex flex-5'>
                            <Select
                                onChange={(value) => {
                                    setInstitutional(value)
                                    institutionalRef.current = value
                                    setReferrerCode('')
                                    setReferrerName('')
                                }}
                                value={institutional}
                                size='middle'
                                id='institutional_ekyc'
                                disabled={glb_sv.openAccountEkyc && glb_sv.openAccountEkyc.institutional}
                            >
                                {institutionalList.map(item =>
                                    <Option key={item.c0} value={item.c0}>{item.c1}</Option>
                                )}
                            </Select>
                        </div>
                    </div>

                    {/* <div className='row-ekyc' style={{ display: institutional === '02' ? 'flex' : 'none' }}>
                        <span className='flex flex-4 text-second fs-normal align-items-center'>
                            {t('broker_staff')}
                        </span>
                        <div style={{ width: 5 }} />
                        <div className='text-primary fs-normal flex flex-5'>
                            <Select
                                onChange={(value) => setBrokerObj(value)}
                                value={brokerObj}
                                size='middle'
                                id='broker_staff_ekyc'
                                disabled={glb_sv.openAccountEkyc && glb_sv.openAccountEkyc.brokerid}
                            >
                                {listOther
                                    .filter((e) => e.c2 === '1')
                                    .map((item) => (
                                        <Option key={item.c0} value={item.c0}>
                                            {item.c1}
                                        </Option>
                                    ))}
                            </Select>
                        </div>
                    </div> */}

                    <div className='row-ekyc' style={{ display: institutional === '02' && institutional ? 'flex' : 'none' }}>
                        <span className='flex flex-4 text-second fs-normal align-items-center'>
                            {t('code_broker')}
                        </span>
                        <div style={{ width: 5 }} />
                        <div className='text-primary fs-normal flex flex-5'>
                            <Input
                                value={brokerCode}
                                onChange={e => {
                                    setBrokerCode(e.target.value.trim().toLowerCase())
                                }}
                                placeholder={t('enter_broker_code')}
                                className='padding-input flex flex-1'
                            />
                        </div>
                    </div>

                    <div className='row-ekyc' style={{ display: institutional === '02' && institutional ? 'flex' : 'none' }}>
                        <span className='flex flex-4 text-second fs-normal align-items-center'>
                            {t('name_broker')}
                        </span>
                        <div style={{ width: 5 }} />
                        <div className='text-primary fs-normal flex flex-5'>
                            <Input
                                value={brokerCode ? listOther.find((e) => e.c2 === '1' && e.c0 === brokerCode)?.c1 : ''}
                                className='padding-input flex flex-1'
                                disabled={true}
                            />
                        </div>
                    </div>

                    <div className='row-ekyc' style={{ display: institutional === '06' ? 'flex' : 'none' }}>
                        <span className='flex flex-4 text-second fs-normal align-items-center'>
                            {t('remisier_staff')}
                        </span>
                        <div style={{ width: 5 }} />
                        <div className='text-primary fs-normal flex flex-5'>
                            <Select
                                onChange={(value) => setRemisierObj(value)}
                                value={remisierObj}
                                size='middle'
                                id='remisier_staff_ekyc'
                                disabled={glb_sv.openAccountEkyc && glb_sv.openAccountEkyc.remiseid}
                            >
                                {listOther
                                    .filter((e) => e.c2 === '2')
                                    .map((item) => (
                                        <Option key={item.c0} value={item.c0}>
                                            {item.c1}
                                        </Option>
                                    ))}
                            </Select>
                        </div>
                    </div>

                    <div className='row-ekyc' style={{ display: institutional !== '06' && institutional ? 'flex' : 'none' }}>
                        <span className='flex flex-4 text-second fs-normal align-items-center'>
                            {t('referrer_code')}
                        </span>
                        <div style={{ width: 5 }} />
                        <div className='text-primary fs-normal flex flex-5'>
                            <Input
                                value={referrerCode}
                                onChange={(e) => {
                                    setReferrerCodeError(false)
                                    setReferrerCode(e.target.value.trim())
                                    referrerCodeRef.current = e.target.value.trim()
                                    if (timeoutGetReferrerName.current) clearTimeout(timeoutGetReferrerName.current)
                                    timeoutGetReferrerName.current = setTimeout(() => {
                                        getReferrerName()
                                    }, 2000);
                                }}
                                placeholder={t('enter_referrer_code')}
                                id='referrer_code_ekyc'
                                className='padding-input flex flex-1'
                                disabled={glb_sv.openAccountEkyc && glb_sv.openAccountEkyc.referCode}
                            />
                        </div>
                    </div>
                    <div className='row-ekyc' style={{ display: referrerCodeError ? 'flex' : 'none' }}>
                        <span className='price-ref'>
                            {t('referrer_code_not_exists')}
                        </span>
                    </div>

                    <div className='row-ekyc' style={{ display: institutional !== '06' && institutional ? 'flex' : 'none' }}>
                        <span className='flex flex-4 text-second fs-normal align-items-center'>
                            {t('referrer_name')}
                        </span>
                        <div style={{ width: 5 }} />
                        <div className='text-primary fs-normal flex flex-5'>
                            <Input
                                value={referrerName}
                                onChange={(e) => {
                                    setReferrerName(e.target.value)
                                }}
                                placeholder={institutional === '02' ? '' : t('enter_referrer_name')}
                                id='referrer_name_ekyc'
                                className='padding-input flex flex-1'
                                disabled={(glb_sv.openAccountEkyc && glb_sv.openAccountEkyc.referName) || institutional === '02'}
                            />
                        </div>
                    </div>

                    <div className='fw-600'>
                        {glb_sv.dataEkycInfo?.c9?.split('NEW')[0] || t('ssv_ekyc_note_title')}
                    </div>

                    <div className='row-ekyc align-items-center'>
                        <div className='circle-style' />
                        <span className='text-primary fs-normal flex flex-5'>
                            {glb_sv.dataEkycInfo?.c9?.split('NEW')[1] || t('ssv_ekyc_note_1')}
                        </span>
                    </div>

                    <div className='row-ekyc align-items-center'>
                        <div className='circle-style' />
                        <span className='text-primary fs-normal flex flex-5'>
                            {glb_sv.dataEkycInfo?.c9?.split('NEW')[2] || t('ssv_ekyc_note_2')}
                        </span>
                    </div>

                    {checkMargin && <div className='row-ekyc align-items-center'>
                        <div className='circle-style' />
                        <span className='text-primary fs-normal flex flex-5'>
                            {glb_sv.dataEkycInfo?.c10?.split('NEW')[0] || t('ssv_ekyc_check_1')} {glb_sv.dataEkycInfo?.c10?.split('NEW')[1] || t('ssv_ekyc_check_1')}
                        </span>
                    </div>}

                    {isBankOnline === 1 && <div className='row-ekyc align-items-center'>
                        <div className='circle-style' />
                        <span className='text-primary fs-normal flex flex-5'>
                            {glb_sv.dataEkycInfo?.c12?.split('NEW')[0] || t('ssv_ekyc_check_3')} {glb_sv.dataEkycInfo?.c12?.split('NEW')[1] || t('ssv_ekyc_check_3')}
                        </span>
                    </div>}

                    {isBankOnline === 2 && <div className='row-ekyc align-items-center'>
                        <div className='circle-style' />
                        <span className='text-primary fs-normal flex flex-5'>
                            {glb_sv.dataEkycInfo?.c11?.split('NEW')[0] || t('ssv_ekyc_check_2')} {glb_sv.dataEkycInfo?.c11?.split('NEW')[1] || t('ssv_ekyc_check_2')}
                        </span>
                    </div>}

                    <div className='flex flex-direction-row align-items-center'
                        style={{
                            marginTop: dimensions.vertical(8),
                        }}
                    >
                        <Checkbox
                            checked={checkNote}
                            onChange={() => setCheckNote((value) => !value)}
                        >
                            <span className='fs-normal text-primary cursor-pointer'>
                                {glb_sv.dataEkycInfo?.c13 || t('ssv_agree_terms')}
                            </span>
                        </Checkbox>
                    </div>

                    {isAuthen && type === 'ekyc' ? (
                        <Button
                            onClick={updateUserInfo}
                            block
                            loading={loading}
                            className='no-height'
                            size='large'
                            style={{
                                backgroundColor: validate()
                                    ? styles.PRIMARY
                                    : styles.PRIMARY + '4d',
                                padding: dimensions.moderate(6),
                                marginTop: 12,
                            }}
                        >
                            <span
                                className='fs-medium'
                                style={{
                                    color: validate()
                                        ? '#FFF'
                                        : 'var(--SECOND__CONTENT__COLOR)',
                                }}
                            >
                                {t('register')}
                            </span>
                        </Button>
                    ) : null}

                    {!isAuthen && type === 'ekyc' ? (
                        <Button
                            onClick={authenEkyc}
                            block
                            className='no-height'
                            size='large'
                            danger
                            type='primary'
                            style={{
                                padding: dimensions.moderate(6),
                                marginTop: 12,
                            }}
                        >
                            <span className='fs-medium'>
                                {t('reauthen_ekyc')}
                            </span>
                        </Button>
                    ) : null}

                    {type !== 'ekyc' ? (
                        <Button
                            onClick={updateUserInfo}
                            disabled={!validate()}
                            block
                            loading={loading}
                            className='no-height'
                            size='large'
                            style={{
                                backgroundColor: validate()
                                    ? styles.PRIMARY
                                    : styles.PRIMARY + '4d',
                                padding: dimensions.moderate(6),
                                marginTop: 12,
                            }}
                        >
                            <span
                                className='fs-medium'
                                style={{
                                    color: validate()
                                        ? '#FFF'
                                        : 'var(--SECOND__CONTENT__COLOR)',
                                }}
                            >
                                {t('register')}
                            </span>
                        </Button>
                    ) : null}
                </div>
            ) : null}
        </Modal>
    )
}
export default memo(EkycModal)

function stringToSlug(str) {
    if (!str) return ''
    // remove accents vietnames
    const from = "àáãảạăằắẳẵặâầấẩẫậèéẻẽẹêềếểễệđùúủũụưừứửữựòóỏõọôồốổỗộơờớởỡợìíỉĩịäëïîöüûñçýỳỹỵỷ",
        to = "aaaaaaaaaaaaaaaaaeeeeeeeeeeeduuuuuuuuuuuoooooooooooooooooiiiiiaeiiouuncyyyyy";
    let res = str;
    for (let i = 0, l = from.length; i < l; i++) {
        res = res.replace(RegExp(from[i], "gi"), to[i]);
    }

    res = res.toLowerCase()
        .trim()
        .replace(/[^a-z0-9\-]/g, '-')
        .replace(/-+/g, '-');

    return res;
}
