export default {
    SERVER: 'SERVER',
    LANGAGE: 'LANGAGE',
    THEME: 'THEME',
    USER_ID: 'USER_ID',
    RECENT_STOCK_LIST: 'RECENT_STOCK_LIST',
    LIST_ACTIVE_INDEX: 'LIST_ACTIVE_INDEX',
    LIST_TOP_MARKET: 'LIST_TOP_MARKET',
    LIST_HEADER_PRICEBOARD: 'LIST_HEADER_PRICEBOARD_1',
    NOTE_MARKET: 'NOTE_MARKET',
    SAVE_LOGIN: 'SAVE_LOGIN',
    AUTHEN_BIOMETRIC: 'AUTHEN_BIOMETRIC',
    TIMEOUT_CONNECT: 'TIMEOUT_CONNECT',
    AutoLoginMode: 'AutoLoginMode',
    CONFIG_QTY: 'CONFIG_QTY',
    CONFIG_PRICE: 'CONFIG_PRICE',
    CONFIG_PRICE_ORDER: 'CONFIG_PRICE_ORDER',
    LAST_TIME_LOGIN: 'LAST_TIME_LOGIN',
    DATA_MARKET_TOP: 'DATA_MARKET_TOP',
    STOCK_LIST: 'STOCK_LIST',
    TYPE_TOP_MARKET: 'TYPE_TOP_MARKET',
    USER_INFO: 'USER_INFO',
    FLAG_iOTP: 'FLAG_iOTP',
    VERSION_STKLIST: 'VERSION_STKLIST_3',
    STOCK_INFO_HSX: 'STOCK_INFO_HSX_3',
    STOCK_INFO_HNX: 'STOCK_INFO_HNX_3',
    STOCK_INFO_UPC: 'STOCK_INFO_UPC_3',
    NUMBER_ERRORS: 'NUMBER_ERRORS',
    TIMEOUT_AUTHEN: 'TIMEOUT_AUTHEN',
    CONFIG_STOCK: 'CONFIG_STOCK',
    APP_LOGIN_ID: 'APP_LOGIN_ID',
    USER_NAME: 'USER_NAME',
    INDEX_PINNED: 'INDEX_PINNED_1',
    STOCK_INFO_LANG: 'STOCK_INFO_LANG',
    LOCAL_APPLICATION_SETTINGS: 'LOCAL_APPLICATION_SETTINGS',
    ID_LOGIN: 'ID_LOGIN',
    SORT_WL_PRICEBOARD: 'SORT_WL_PRICEBOARD',
    SORT_OTHER_PRICEBOARD: 'SORT_OTHER_PRICEBOARD',
    SORT_ASYNC_PRICEBOARD: 'SORT_ASYNC_PRICEBOARD',
    REQUEST_HARDWARE_PLUGIN: 'REQUEST_HARDWARE_PLUGIN',
}