import { Button, Modal } from 'antd';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AlertIcon } from '../../assets/images/common/alert_icon.svg';
import { dimensions } from '../../styles';
import { eventList, glb_sv } from '../../utils';
import './index.scss';


function AlertModal() {
    const { t } = useTranslation();

    const [visible, setVisible] = useState(false)
    const [params, setParams] = useState({})
    const screenRequest = useRef('')

    const handleConfirm = () => {
        setVisible(false)
        if (params.linkCallback) params.linkCallback()
    }

    const handleCancel = () => {
        setVisible(false)
        if (params.cancelCallBack) params.cancelCallBack()
        else if (!params.showCancel) {
            if (params.linkCallback) params.linkCallback()
        }
    }

    useEffect(() => {
        const commonEvent = glb_sv.commonEvent.subscribe(msg => {
            if (msg.type === eventList.ALERT_MODAL) {
                setParams(msg.params);
                setVisible(true);
                screenRequest.current = msg.params.screen
                glb_sv.focusInput('modal-button-cfm', 100)
            }
            if (msg.type === eventList.CLOSE_ALERT_MODAL) {
                // tránh tắt nhầm alert của màn hình khác
                if (msg.screen === screenRequest.current) {
                    setVisible(false);
                    screenRequest.current = ''
                }
            }
        });

        return () => {
            commonEvent.unsubscribe();
        }
    }, [])

    return (
        <Modal
            visible={visible}
            onCancel={handleCancel}
            footer={null}
            width={400}
            zIndex={999999}
        >
            <div className={'flex flex-1 flex-direction-column align-items-center justify-content-center modal-alert ' + params.type}>
                {params.icon || <AlertIcon />}
                <span
                    className='text-primary fs-600 fs-medium'
                    style={{
                        marginTop: dimensions.vertical(16),
                        marginBottom: dimensions.vertical(16),
                        ...params.titleStyle
                    }}>
                    {params.title}
                </span>

                <span
                    className='text-center text-primary'
                    style={{ marginBottom: dimensions.moderate(32), opacity: 0.64 }}>
                    {params.content}
                </span>
                {params.layoutAfterContentRender && params.layoutAfterContentRender()}

                <Button
                    style={{ backgroundColor: params.type === 'warn' ? 'var(--WARN__COLOR)' : 'var(--PRIMARY)' }}
                    block
                    size='large'
                    onClick={handleConfirm}
                    id='modal-button-cfm'
                >
                    <span className='text-white'>
                        {params.titleConfirm ? t(params.titleConfirm) : t('common_Ok')}
                    </span>
                </Button>

                {params.showCancel ? <Button
                    ghost
                    block
                    size='large'
                    onClick={handleCancel}
                    className='text-primary'
                >
                    <span style={{ opacity: 0.34 }}>
                        {params.titleCancel ? t(params.titleCancel) : t('common_Cancel')}
                    </span>
                </Button>
                    : null}
            </div>
        </Modal>
    )
}
export default memo(AlertModal)