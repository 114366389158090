import React, { useState, useRef, useEffect, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { fontSizes, fontWeights, dimensions } from '../../styles';
import moment from 'moment'
import { ReactComponent as ClockIcon } from '../../assets/images/common/ic_clock.svg'
import { InputFloatingLabel } from '../../common'
import { Button, Modal } from 'antd'
import { dataCryption, glb_sv, reqFunct, sendRequest } from '../../utils'
import { CloseCircleOutlined } from '@ant-design/icons';

const ServiceInfo = {
    CHECK_PHONE_OTP: {
        reqFunct: reqFunct.CHECK_PHONE_OTP,
        WorkerName: 'FOSxID01',
        ServiceName: 'FOSxID01_Management',
        ClientSentTime: '0',
        Operation: 'I',
    },
    SEND_OTP_FUNCTION: {
        reqFunct: reqFunct.SEND_OTP_FUNCTION,
        WorkerName: 'FOSxID01',
        ServiceName: 'FOSxID01_OTPManagement',
        Operation: 'U',
        ClientSentTime: '0'
    },
}

function ModalAuthenOTP({ onSuccess, onCancel, visible, setVisible }) {

    const { t } = useTranslation();

    const [code, setCode] = useState('')
    const [timeOTP, setTimeOTP] = useState(0)
    const timeOTPRef = useRef(0)

    const [phone, setPhone] = useState('')

    const flag_sendAuthenOTP = useRef(null);

    const otpRef = useRef(null);

    const timeoutTimingOTP = useRef(null)

    const [error, setError] = useState('')

    const [loading, setLoading] = useState(false)
    const [isShowOtp, setShowOTP] = useState(false)

    useEffect(() => {
        checkPhone();
        return () => {
            if (timeoutTimingOTP.current) clearTimeout(timeoutTimingOTP.current)
        }
    }, [])

    const checkPhone = () => {
        const InputParams = ['chkphone', phone.length > 9 ? phone : ''];
        sendRequest(ServiceInfo.CHECK_PHONE_OTP, InputParams, checkPhoneResult, true, checkPhoneTimeout)
        setError('')
    }

    const checkPhoneTimeout = () => {
        console.log('checkPhoneTimeout')
    }

    const checkPhoneResult = (reqInfoMap, message) => {
        console.log("checkPhoneResult -> message", message)
        setVisible(true)
        if (Number(message['Result']) === 0) {
            setError(message.Message)
        } else {
            let jsondata = {}
            try {
                if (message['Data']) {
                    jsondata = JSON.parse(message['Data'])[0]
                }
            } catch (err) {
                console.error('resendAuthenOTPResult error', err)
                return
            }
            if (jsondata.c0) {
                setPhone(jsondata.c0)
                setTimeOTP(Number(jsondata.c1));
                setCode('')
                timeOTPRef.current = Number(jsondata.c1);
                glb_sv.timeAuthenOtp = {
                    timeGetOTP: moment(),
                    numberOTP: timeOTPRef.current
                }
                timingOTP();
                setTimeout(() => {
                    otpRef.current.focus();
                }, 100);
                setShowOTP(true)
            }
        }
    }

    const timingOTP = () => {
        if (timeOTPRef.current > 0) {
            if (timeoutTimingOTP.current) clearTimeout(timeoutTimingOTP.current)
            timeoutTimingOTP.current = setTimeout(() => {
                const remainTimeOTP = Math.max(0, glb_sv.timeAuthenOtp.numberOTP - Math.round((moment() - glb_sv.timeAuthenOtp.timeGetOTP) / 1000))
                timeOTPRef.current = remainTimeOTP
                setTimeOTP(remainTimeOTP)
                timingOTP();
            }, 1000);
        }
    }

    const sendAuthenOTP = value => {
        if (flag_sendAuthenOTP.current) return;
        const codeEncryt = dataCryption.encryptString(value || code);
        const InputParams = ['confirm_phone', codeEncryt];
        sendRequest(ServiceInfo.SEND_OTP_FUNCTION, InputParams, sendAuthenOTPResult, true, sendAuthenOTPResultTimeout)
        flag_sendAuthenOTP.current = true;
        setLoading(true);
    }

    const sendAuthenOTPResultTimeout = () => {
        console.log("sendAuthenOTPResultTimeout")
        flag_sendAuthenOTP.current = false;
        setLoading(false);
    }

    const sendAuthenOTPResult = (reqInfoMap, message) => {
        console.log("sendAuthenOTPResult -> message", message)
        flag_sendAuthenOTP.current = false;
        setLoading(false);
        if (Number(message['Result']) === 0) {
            setError(message.Message)
            otpRef.current && otpRef.current.focus();
            if (message.Code === '010013') {
                if (timeoutTimingOTP.current) clearTimeout(timeoutTimingOTP.current)
                setTimeOTP(0);
                timeOTPRef.current = 0;
            }
        } else {
            if (timeoutTimingOTP.current) clearTimeout(timeoutTimingOTP.current)
            setVisible(false);
            onSuccess()
        }
    }

    const validateInput = () => {
        if (!code) return false
        if (!phone || phone.length < 10) return false
        if (!timeOTP) return false
        return true
    }

    const hideModal = () => {
        if (timeoutTimingOTP.current) clearTimeout(timeoutTimingOTP.current)
        setVisible(false)
        setPhone('')
        onCancel()
        return
    }

    return (
        <Modal visible={visible} footer={null} closeIcon={null} keyboard onCancel={hideModal} width={400}>
            <div
                className="flex flex-1 flex-direction-column justify-content-center align-items-center"
                style={UI.container}
            >
                <ClockIcon />

                <span
                    className="fs-medium text-primary"
                    style={{ marginTop: dimensions.vertical(12), marginBottom: dimensions.vertical(12) }}
                >
                    {t('title_authen_phone')}
                </span>

                <span style={{ fontSize: 'var(--small)', color: 'var(--ERROR__COLOR)' }}>
                    {error}
                </span>

                <InputFloatingLabel
                    id='phone-modal-authen-otp-phone'
                    name='phone-modal-authen-otp-phone'
                    label={t('enter_phone_register')}
                    className="input__component"
                    onChange={(e) => {
                        const value = e.target.value
                        setPhone(value.trim())
                        setError('')
                        setTimeOTP(0)
                        setCode('')
                        timeOTPRef.current = 0;
                        if (timeoutTimingOTP.current) clearTimeout(timeoutTimingOTP.current)
                        setShowOTP(false)
                    }}
                    maxLength={10}
                    value={phone}
                    onKeyDown={e => {
                        if (e.key === 'Enter' && phone.length === 10) {
                            checkPhone()
                        }
                    }}
                    rightComponent={
                        <div onClick={() => {
                            setPhone('')
                            setTimeOTP(0)
                            setCode('')
                            setError('')
                            setShowOTP(false)
                            timeOTPRef.current = 0;
                            if (timeoutTimingOTP.current) clearTimeout(timeoutTimingOTP.current)
                        }}
                            className='flex position-absolute cursor-pointer'
                            style={{ right: dimensions.halfIndent }}>
                            <CloseCircleOutlined style={{ fontSize: 20 }} />
                        </div>
                    }
                    styleFloatingLabelInput={{
                        marginBottom: 5
                    }}
                />

                <InputFloatingLabel
                    refs={otpRef}
                    id='code-modal-authen-otp-phone'
                    name='code-modal-authen-otp-phone'
                    label={t('label_code_otp')}
                    className="input__component"
                    onChange={(e) => {
                        const value = e.target.value
                        setCode(value)
                        setError('')
                        if (value.length === 6) {
                            sendAuthenOTP(value)
                        }
                    }}
                    style={{ WebkitTextSecurity: 'disc' }}
                    maxLength={6}
                    rightComponent={
                        <div className='flex' style={{ position: 'absolute', right: dimensions.halfIndent }}>
                            <span style={{ color: 'var(--TEXT__2)', fontSize: fontSizes.normal, padding: 0 }}>
                                {timeOTP}s
                            </span>
                        </div>
                    }
                    value={code}
                    onKeyDown={e => {
                        if (e.key === 'Enter' && code.length === 6) {
                            checkPhone()
                        }
                    }}
                    styleFloatingLabelInput={{
                        marginBottom: 5,
                        visibility: isShowOtp ? 'visible' : 'hidden'
                    }}
                />

                <span
                    onClick={checkPhone}
                    className='cursor-pointer text-center fw-600'
                    style={{ paddingTop: 12, paddingBottom: 12, visibility: timeOTP ? 'hidden' : (phone.length === 10 ? 'visible' : 'hidden') }}>
                    {t('send_code_otp')}
                </span>

                <Button
                    onClick={sendAuthenOTP}
                    disabled={!validateInput()}
                    loading={loading}
                    block
                    type="ghost"
                    shape="round"
                    size="large"
                    style={{ backgroundColor: !validateInput() ? 'var(--PRIMARY__DISABLED)' : 'var(--PRIMARY)' }}
                >
                    <span
                        style={{
                            color: !validateInput() ? 'var(--TEXT__2)' : '#FFF',
                            fontSize: 'var(--normal)',
                            fontWeight: '500',
                        }}
                    >
                        {t('authen')}
                    </span>
                </Button>

                <Button onClick={hideModal} block type="ghost" size="large" shape="round">
                    <span style={{ color: 'var(--TEXT__1)', fontSize: 'var(--normal)', opacity: 0.34 }}>
                        {t('common_Cancel')}
                    </span>
                </Button>
            </div>
        </Modal>
    );
}
export default memo(ModalAuthenOTP)

const UI = {
    container: {
        borderRadius: 12,
        borderColor: 'rgba(0, 0, 0, 0.1)',
    },
    tab: {
        fontSize: fontSizes.small,
        fontWeight: fontWeights.medium,
        marginLeft: 0,
        marginRight: 0,
    },
    row: {
        flexDirection: 'row',
        marginTop: 10,
        paddingHorizontal: dimensions.halfIndent,
        borderRadius: 200,
    },
    codeInput: {
        fontSize: fontSizes.normal,
        fontWeight: fontWeights.semiBold,
        flex: 1,
    },
}

function validateEmailRegex(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}