import React, { useState } from 'react';


export interface IStoreTrading {
	userInfo: IUserInfo;
	setUserInfo: (userInfo: IUserInfo) => void;
	authFlag: boolean;
	setAuth: React.Dispatch<React.SetStateAction<boolean>>;
	filterOption: {
		exchangeType: '01' | '03' | '05' | string;
		queryTime: '1D' | string;
	};
	setFilterOption: React.Dispatch<
		React.SetStateAction<{
			exchangeType: '01' | '03' | '05' | string;
			queryTime: '1D' | string;
		}>
	>;
	showQuickOrder: boolean;
	setShowQuickOrder: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface IUserInfo {
	actn_curr: string;
	sub_curr: string;
	actn_name: string;
	actn_full: string;
	// acc_type: '1', // Account type (1: cash account, 2: Margin, 3:Bonds account, 4: Derivate)
	sub_list: string[];
	actn_list: { AcntNo: string; label: string }[] | string[];
	isMargin: 'Y' | 'N' | '';
}


export const StoreTrading = React.createContext<IStoreTrading>(null);

export default ({ children }) => {

    const [authFlag, setAuth] = useState<boolean>(false)
    const [showQuickOrder, setShowQuickOrder] = useState(false)

    // userInfo
    const [userInfo, setUserInfo] = useState<IUserInfo>({
			actn_curr: '',
			sub_curr: '',
			actn_name: '',
			sub_list: [],
			actn_list: [],
			actn_full: '',
			isMargin: ''
		});

    const [filterOption, setFilterOption] = useState({
        exchangeType: '01',
        queryTime: '1D',
    })

    const store: IStoreTrading = {
			userInfo,
			setUserInfo,
			authFlag,
			setAuth,
			filterOption,
			setFilterOption,
			showQuickOrder,
			setShowQuickOrder
		};

    return (
        <StoreTrading.Provider value={store}>
            {children}
        </StoreTrading.Provider>
    );
};