const COMMON = {

}

const DARK = {
    ...COMMON,
    PRIMARY: '#f68e35',
    EKYC__COLOR: '#f68e35',
}

const LIGHT = {
    ...COMMON,
    PRIMARY: '#0b497e',
    EKYC__COLOR: '#0b497e',
}

const CN_DARK = {
    ...DARK,
    //--Các value thay đổi cho china
}

const CN_LIGHT = {
    ...LIGHT,
    //--Các value thay đổi cho china
}

export default {
    DARK,
    LIGHT,
    CN_DARK,
    CN_LIGHT,
}
