import { DatePicker } from 'antd'
import moment from 'moment'
import { ReactComponent as IC_CALENDAR } from '../../../assets/images/ic_calendar.svg'
import React from 'react'

const dateFormatList = 'YYYYMMDD'

interface IInputDatePickerProps {
	label?: string;
	labelClassName?: string;
	value?: string;
	defaultValue?: string;
	labelRatio?: [number, number];
	onValueChange?: (formatedResult: string) => void;
	placeholder?: string;
	labelStyle?: React.CSSProperties;
	typeLabel?: 'horizontal' | 'vertical';
	style?: React.CSSProperties;
	readOnly?: boolean;
	suffixIcon?: React.ReactNode
    isRow?: boolean;
    refs?: any;
    formatMonth?: boolean;
}

const InputDatePicker = ({
	label = '',
	labelClassName = '',
	value = '20210325',
	defaultValue = '20210325',
	labelRatio = [2, 3],
	onValueChange = e => null,
	placeholder = '',
	labelStyle = {},
	typeLabel = 'horizontal',
	style = {},
	readOnly = false,
	suffixIcon = <IC_CALENDAR fill='var(--TEXT__1)' />,
	isRow = false,
	refs,
	formatMonth,
	...props
}: IInputDatePickerProps) => {
	const onChange = (_, dateOutPut) => {
		const formatResult = moment(dateOutPut, formatMonth ? 'MM-YYYY' : 'DD/MM/YYYY').format('YYYYMMDD');
		onValueChange(formatResult);
	};
	return (
		<div
			className={
				'alt-date-input ' +
				(isRow ? 'flex align-items-center' : '') +
				`${typeLabel === 'horizontal' ? 'alt-input-label-horizontal' : ''}`
			}>
			{label ? (
				<div className={`alt-label flex-${labelRatio[0]} mr-1 flex align-items-center ${labelClassName}`} style={labelStyle}>
					{label}
				</div>
			) : null}
			<DatePicker
				ref={refs}
				className={`alt-date-picker flex-${labelRatio[1]}`}
				defaultValue={moment(defaultValue, formatMonth ? 'MM-YYYY' : dateFormatList)}
				value={value ? moment(value, dateFormatList) : null}
				format={[formatMonth ? 'MM-YYYY' : 'DD/MM/YYYY']}
				onChange={onChange}
				inputReadOnly={readOnly}
				disabled={readOnly}
				placeholder={placeholder}
				style={style}
				allowClear={false}
				suffixIcon={suffixIcon}
				panelRender={panelNode => <div style={{ borderRadius: '0.5rem' }}>{panelNode}</div>}
				{...props}
			/>
		</div>
	);
};

export default InputDatePicker