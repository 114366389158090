import { Select as SelectAntd } from 'antd'
import React, { useState } from 'react'
import { ReactComponent as IC_DOWN } from '../../../assets/images/ic_arr_down.svg'
import { ReactComponent as IC_CLEAR } from '../../../assets/images/ic_clear.svg'

interface IInputSelectProps {
	onSelect?: (value: any, option: any) => void;
	onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
	allowClear?: boolean;
	dataSelect?: any[];
	label?: string;
	placeholder?: string;
	labelStyle?: React.CSSProperties;
	inputStyle?: React.CSSProperties;
	keyOption?: 'value' | 'label' | string;
	value: string;
	className?: string;
	renderSuggestion?: (item: any) => React.ReactNode;
	refs?: React.Ref<any>;
	dropdownStyle?: React.CSSProperties;
	labelRatio?: [number, number];
	typeLabel?: 'vertical' | 'horizontal';
	keyOptionSearch?: string;
	disabled?: boolean;
	styleRoot?: React.CSSProperties
}

export const InputSelect = ({
    onSelect = (value, selected) => null,
    onKeyDown = (e) => null,
    allowClear = true,
    dataSelect = [],
    label = '',
    placeholder = '',
    labelStyle = {},
    inputStyle = {},
    keyOption = 'value',
    value = '',
    className,
    renderSuggestion = (item) => item.label,
    refs,
    dropdownStyle,
    labelRatio = [2, 3],
    typeLabel = 'vertical',
    keyOptionSearch = '',
    disabled,
    styleRoot={},
    ...props
}: IInputSelectProps) => {
    return (
        <div className={`alt-input-select ${typeLabel === 'horizontal' ? 'alt-input-label-horizontal' : ''}`} style={styleRoot}>
            {label ? (
                <div className={`alt-label flex-${labelRatio[0]} mr-2`} style={labelStyle}>
                    {label}
                </div>
            ) : null}
            <SelectAntd
                disabled={disabled}
                ref={refs}
                placeholder={placeholder}
                className={'alt-input' + ' ' + className + ' ' + `flex-${labelRatio[1]}`}
                allowClear={allowClear}
                clearIcon={<IC_CLEAR fill='var(--TEXT__1)' />}
                suffixIcon={<IC_DOWN style={{ color: 'var(--TEXT__1)' }} />}
                style={inputStyle}
                showSearch
                optionFilterProp={keyOptionSearch !== '' ? 'selected' : 'children'}
                onSelect={onSelect}
                onKeyDown={onKeyDown}
                value={value}
                filterSort={(a, b) => (a < b ? -1 : 1)}
                filterOption={(input, option) => {
                    if (keyOptionSearch === '') {
                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    } else {
                        return option.selected[keyOptionSearch].toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                }}
                dropdownStyle={dropdownStyle}
                {...props}>
                {dataSelect.map((item, index) => {
                    return (
                        <SelectAntd.Option key={String(index)} selected={item} value={item[keyOption]} className='alt-input-option' {...props}>
                            {renderSuggestion(item)}
                        </SelectAntd.Option>
                    )
                })}
            </SelectAntd>
        </div>
    )
}
