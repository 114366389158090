import React from 'react'
import App from './App'
import './styles'
import './i18n'
import { BrowserRouter } from 'react-router-dom'
import * as serviceWorker from './serviceWorker'
import { render } from 'react-dom';

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev code
} else {
    console.log = () => {}
    console.warn = () => {}
    console.error = () => {}
}
// TODO: Dont use React 18 because not support react-sortable-hoc (findDOMNode)
const container = document.getElementById('root');
// const root = createRoot(container!); 
render(
	<React.Fragment>
		{/* @ts-expect-error */}
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</React.Fragment>,
	container
)

serviceWorker.register()