// @flow weak

// import React      from 'react';
import PropTypes from 'prop-types';
import { glb_sv } from '..';

const FormatNumberAC = (value: number, fractionSize?: number, empty?: 1) => {

    if (!value || isNaN(value)) {
        if (empty === 1) { return ''; }
        else return 0;
    }
    const DECIMAL_SEPARATOR = '.';
    const THOUSANDS_SEPARATOR = ',';
    let [integer, fraction = ''] = (value || '').toString()
        .split('.');
    fraction = fractionSize > 0
        ? DECIMAL_SEPARATOR + (fraction + '000000').substring(0, fractionSize)
        : '';
    integer = (Number(integer)).toString();
    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, THOUSANDS_SEPARATOR);
    if (glb_sv.configInfo.fraction_qty === '10') {
        let fractionFix = (value/10 || '').toString()
        .split('.')[1] || 0;
        if (fractionSize === 1) return integer.substr(0, integer.length - 1) + '.' + fractionFix;
        else return integer.substr(0, integer.length - 1) + fraction;
    } else {
        let fractionFix = (value/100 || '').toString()
        .split('.')[1] || 0;
        if (fractionSize === 1) return integer.substr(0, integer.length - 1) + '.' + fractionFix;
        else return integer.substr(0, integer.length - 2) + fraction;
    }
}
FormatNumberAC.propTypes = {
    value: PropTypes.any,
    fractionSize: PropTypes.number,
    empty: PropTypes.number
};

FormatNumberAC.defaultProps = {
    fractionSize: 0,
    empty: 1
};

export default FormatNumberAC