/*
    singature
    job_selection
    organization (ref_code)
    margin_check
    is_FATCA
*/
const getConfigUIEkyc = (secCode) => {
    switch (secCode) {
        case '888':
            return {
                is_singature_required: true,

                singature: true,
                job_selection: true,
                job_selection_required: true,

                refInfo: true,
                refInfo_showRefCode: true,
                refInfo_showRefName: true,

                // Include: auto_PIA, margin_check (if is_show_extension_services = false, auto_PIA & margin_check is false too)
                is_show_extension_services: true,

                auto_PIA: true,
                auto_PIA_default: false,

                margin_check: true,
                margin_check_default: false,

                is_FATCA: true,
                is_FATCA_default: false,

                // Danh sách mã chứng khoán công bố thông tin
                information_disclosure_stock_list: true,

                // Mã khuyển mãi
                voucher_code: true,

                // Broker Remisier UI Style (input | select)
                broker_remisier_style: 'select',

                // Dịch vụ môi giới
                brokerage_services_check: true,
                brokerage_services_check_default: false,

                // Phải xác thực SDT trước khi ekyc
                is_otp_authen_before_ekyc: true
            }
        case '075':
            return {
                is_singature_required: true,
                singature: true,
                job_selection: false,

                refInfo: true,
                refInfo_showRefCode: true,
                refInfo_showRefName: true,

                // Include: auto_PIA, margin_check (if is_show_extension_services = false, auto_PIA & margin_check is false too)
                is_show_extension_services: false,

                auto_PIA: false,
                auto_PIA_default: false,

                margin_check: false,
                margin_check_default: false,

                is_FATCA: false,
                is_FATCA_default: false,

                // Danh sách mã chứng khoán công bố thông tin
                information_disclosure_stock_list: false,

                // Mã khuyển mãi
                voucher_code: false,
                // Broker Remisier UI Style (input | select)
                broker_remisier_style: 'select',
                is_show_broker: true,
                is_show_remisier: false,
            }
        case '028':
            return {
                is_singature_required: true,
                singature: true,
                job_selection: false,

                refInfo: true,
                refInfo_showRefCode: true,
                refInfo_showRefName: true,

                // Include: auto_PIA, margin_check (if is_show_extension_services = false, auto_PIA & margin_check is false too)
                is_show_extension_services: false,

                auto_PIA: false,
                auto_PIA_default: false,

                margin_check: false,
                margin_check_default: false,

                is_FATCA: false,
                is_FATCA_default: false,

                // Danh sách mã chứng khoán công bố thông tin
                information_disclosure_stock_list: false,

                // Mã khuyển mãi
                voucher_code: false,
                // Broker Remisier UI Style (input | select)
                broker_remisier_style: 'select'
            }
        case '061':
            return {
                singature: false,
                job_selection: true,
                job_selection_required: true,

                refInfo: false,
                refInfo_showRefCode: true,
                refInfo_showRefName: true,

                // Include: auto_PIA, margin_check (if is_show_extension_services = false, auto_PIA & margin_check is false too)

                is_show_extension_services: true,

                auto_PIA: false,
                auto_PIA_default: false,

                margin_check: true,
                margin_check_default: false,

                is_FATCA: true,
                is_FATCA_default: false,

                information_disclosure_stock_list: true, // Danh sách mã chứng khoán công bố thông tin

                // Mã khuyển mãi
                voucher_code: false,

                // Broker Remisier UI Style (input | select)
                broker_remisier_style: 'input',
                is_show_broker: true,
                is_show_remisier: false,

                is_permanent_address_editable: false, // Địa chỉ thường trú có cho edit không? True = edit
                is_issue_date_editable: false, // Ngày cấp có edit hay không
                is_issue_place_editable: false, // Nơi cấp có edit hay không
                is_birthday_editable: false, // Ngày sinh có edit hay không

                // Mã người giới thiệu
                is_cus_ref_code: true,

                //Chọn số tài khoản
                is_account_number_choose:true


            }
        case '020':
            return {
                is_singature_required: true,
                singature: true,
                job_selection: false,

                refInfo: true,
                refInfo_showRefCode: true,
                refInfo_showRefName: true,

                // Include: auto_PIA, margin_check (if is_show_extension_services = false, auto_PIA & margin_check is false too)
                is_show_extension_services: true,

                auto_PIA: false,
                auto_PIA_default: false,

                margin_check: true,
                margin_check_default: false,

                bond_check: false,
                bond_check_default: false,

                is_FATCA: true,
                is_FATCA_default: false,

                information_disclosure_stock_list: false, // Danh sách mã chứng khoán công bố thông tin
                // Mã khuyển mãi
                voucher_code: true,
                // Broker Remisier UI Style (input | select)
                broker_remisier_style: 'input',
                is_show_broker: true,
                // Phải xác thực SDT trước khi ekyc
                is_otp_authen_before_ekyc: true
            }
        case '036':
            return {
                singature: true,
                signature_handwrite: false,
                is_singature_required: true,

                job_selection: false,

                refInfo: false,
                refInfo_showRefCode: false,
                refInfo_showRefName: false,

                // Include: auto_PIA, margin_check (if is_show_extension_services = false, auto_PIA & margin_check is false too)

                is_show_extension_services: false,

                auto_PIA: false,
                auto_PIA_default: false,

                margin_check: false,
                margin_check_default: false,

                is_FATCA: false,
                is_FATCA_default: false,

                information_disclosure_stock_list: false, // Danh sách mã chứng khoán công bố thông tin
                // Broker Remisier UI Style (input | select)
                broker_remisier_style: 'select',

                is_show_broker: true,
                is_show_remisier: true,
            }
        case '023':
            return {
                singature: false,
                job_selection: false,

                refInfo: false,
                refInfo_showRefCode: false,
                refInfo_showRefName: false,

                // Include: auto_PIA, margin_check (if is_show_extension_services = false, auto_PIA & margin_check is false too)

                is_show_extension_services: false,

                auto_PIA: false,
                auto_PIA_default: false,

                margin_check: false,
                margin_check_default: false,

                is_FATCA: false,
                is_FATCA_default: false,

                information_disclosure_stock_list: false, // Danh sách mã chứng khoán công bố thông tin
                // Broker Remisier UI Style (input | select)
                broker_remisier_style: 'select',
            }
        case '102':
            return {
                singature: false,
                job_selection: false,

                refInfo: false,
                refInfo_showRefCode: true,
                refInfo_showRefName: true,

                // Include: auto_PIA, margin_check (if is_show_extension_services = false, auto_PIA & margin_check is false too)

                is_show_extension_services: true,

                auto_PIA: false,
                auto_PIA_default: false,

                margin_check: true,
                margin_check_default: false,

                is_FATCA: false,
                is_FATCA_default: false,

                information_disclosure_stock_list: false, // Danh sách mã chứng khoán công bố thông tin
                // Broker Remisier UI Style (input | select)
                broker_remisier_style: 'select',
                is_show_broker: false,
                is_show_remisier: true,
                is_show_broker_remisier_id: true, // true: Chỉ show ID của CTV - false: show Tên (ID) của CTV

                is_permanent_address_editable: false, // Địa chỉ thường trú có cho edit không? True = edit
                is_issue_date_editable: false, // Ngày cấp có edit hay không
                is_issue_place_editable: false, // Nơi cấp có edit hay không
                is_birthday_editable: false, // Ngày sinh có edit hay không
            }
        case '099':
            return {
                singature: false,
                job_selection: false,

                refInfo: false,
                refInfo_showRefCode: true,
                refInfo_showRefName: true,

                // Include: auto_PIA, margin_check (if is_show_extension_services = false, auto_PIA & margin_check is false too)

                is_show_extension_services: false,

                auto_PIA: false,
                auto_PIA_default: false,

                // Mở tk giao dịch kí quỹ
                margin_check: false,
                margin_check_default: false,

                is_FATCA: false,
                is_FATCA_default: false,

                information_disclosure_stock_list: false, // Danh sách mã chứng khoán công bố thông tin
                // Broker Remisier UI Style (input | select)
                broker_remisier_style: 'input',

                // Dịch vụ môi giới
                brokerage_services_check: false,
                brokerage_services_check_default: false,
            }
        case '081':
            return {
                // Config riêng cho O81
                brokerage_services_check: true,
                brokerage_services_check_default: false,
                is_otp_authen_before_ekyc: true, // Xác thực số điện thoại trước quy trình eKYC
                margin_check_default: false,
                is_no_quire_bank_branch: false,
            }
        default:
            return {
                singature: false,
                signature_handwrite: false, // Hiện canvas ký trực tiếp trên app
                job_selection: false,

                refInfo: false,
                refInfo_showRefCode: true,
                refInfo_showRefName: true,

                // Include: auto_PIA, margin_check (if is_show_extension_services = false, auto_PIA & margin_check is false too)

                is_show_extension_services: false,

                auto_PIA: false,
                auto_PIA_default: false,

                margin_check: false,
                margin_check_default: false,

                bond_check: false,
                bond_check_default: false,

                is_FATCA: false,
                is_FATCA_default: false,

                information_disclosure_stock_list: false, // Danh sách mã chứng khoán công bố thông tin
                // Broker Remisier UI Style (input | select)
                broker_remisier_style: 'input',

                // Dịch vụ môi giới
                brokerage_services_check: false,
                brokerage_services_check_default: false,
                is_show_broker_remisier_id: false, // true: Chỉ show ID của CTV - false: show Tên (ID) của CTV

                is_permanent_address_editable: true, // Địa chỉ thường trú có cho edit không? True = edit // Mặc định cho edit
                is_issue_date_editable: true, // Ngày cấp có edit hay không
                is_issue_place_editable: true, // Nơi cấp có edit hay không
                is_birthday_editable: true, // Ngày sinh có edit hay không
            }
    }
}

export default getConfigUIEkyc;