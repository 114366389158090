export default {
    TEST_CONTROL_SUB: 'TEST_CONTROL_SUB', // !!! {Dung} nhớ xóa nếu không dùng nữa
    PRICE_BOARD: 'PRICE_BOARD', //-- Dùng cho màn hình bảng điện
    FOREIGNER_TRADE: 'FOREIGNER_TRADE',
    MARKET_CAP: 'MARKET_CAP',
    MARKET_LIQUIDITY: 'MARKET_LIQUIDITY',
    MARKET_VIEW: 'MARKET_VIEW',
    STOCK_INFO_DETAIL: 'STOCK_INFO_DETAIL',
    BACKGROUND_SERVICE: 'BACKGROUND_SERVICE',
    ORDER_BOOK: 'ORDER_BOOK',
    HEADER_BAR: 'HEADER_BAR',
    //---------
    ALERT_LAYOUT: 'ALERT_LAYOUT',
    //----------
    CHART_STOCK_CODE: 'CHART_STOCK_CODE',
    ASSET_INFO: 'ASSET_INFO',
    INDIVIDUAL_BOND_INFO_DETAIL: 'INDIVIDUAL_BOND_INFO_DETAIL',
}
