/**
 * Defined all of colors that using for project
 */
import DEFAULT from './default.theme'
import THEME_888 from './theme_888.theme'
import THEME_004 from './theme_004.theme'
import THEME_061 from './theme_061.theme'
import THEME_081 from './theme_081.theme'
import THEME_075 from './theme_075.theme'
import THEME_099 from './theme_099.theme'
import THEME_102 from './theme_102.theme'
import THEME_020 from './theme_020.theme'
import THEME_023 from './theme_023.theme'
import THEME_036 from './theme_036.theme'
import THEME_082 from './theme_082.theme'
import THEME_028 from './theme_028.theme'
import THEME_012 from './theme_012.theme'
import { merge } from 'lodash'

const activeCode = window.secCodeTheme // <<<<<<<<<<<<<<<<<<<<<<< Thay đổi ở file public/index.html

const themes = {
  "888": THEME_888,
  "004": THEME_004,
  "061": THEME_061,
  "081": THEME_081,
  "075": THEME_075,
  "099": THEME_099,
  "102": THEME_102,
  "020": THEME_020,
  "023": THEME_023,
  "036": THEME_036,
  "082": THEME_082,
  "028": THEME_028,
  "012": THEME_012,
}

const colors = merge(DEFAULT, themes[activeCode] || {})

export default colors
