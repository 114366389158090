import React from 'react'
import { ReactComponent as IC_REFRESH } from '../../../assets/images/ic_refresh.svg'

/**
 * @params {}
 */
interface IButtonProps {
	type?: 'primary' | 'default' | 'cancel';
	hasLoading?: boolean;
	loading?: boolean;
	full?: boolean;
	disabled?: boolean;
	onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
	children?: React.ReactNode;
	style?: React.CSSProperties;
	transparent?: boolean;
	className?: string;
	refs?: React.LegacyRef<HTMLButtonElement>;
}


export const Button = ({
    type = 'primary',
    hasLoading = false,
    loading = false,
    full = false,
    disabled = false,
    onClick,
    children,
    style = {},
    transparent = false,
    className = '',
    refs,
    ...props
}: IButtonProps) => {
    return (
        <button
            ref={refs}
            className={`alt-btn alt-btn-${type}` + ' ' + className}
            disabled={disabled}
            onClick={onClick}
            style={{
                ...style,
                backgroundColor: transparent ? 'transparent' : `var(--${type.toUpperCase()}__BG)`,
                opacity: disabled ? 0.65 : 1,
                width: full ? '100%' : 'auto',
                color: transparent && 'var(--TEXT__1)',
                cursor: disabled && 'not-allowed'
            }}
            {...props}>
            {hasLoading ? (
                <span>
                    <IC_REFRESH className={loading ? 'spin-element' : ''} style={{ display: 'block' }} />
                </span>
            ) : loading && (
                <span>
                    <IC_REFRESH style={{ display: 'block' }} />
                </span>
            )}
            <span className={hasLoading && children ? 'ml-1' : ''}>{children}</span>
        </button>
    )
}
