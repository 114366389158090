import { glb_sv } from '.'
import eventList from './constant/eventList'
import { subcribeFunctWithControl } from './service/subcribe_function'

export const openStockDetail = (stockCode: string) => {
    glb_sv.commonEvent.next({ type: eventList.STOCK_INFO_DETAIL, value: stockCode })
    subcribeFunctWithControl({
        component: 'STOCK_INFO',
        // onTimeout: null,
        time: null,
        Command: 'SUB',
        topic: ['MDDS|SI', 'MDDS|TP', 'MDDS|EP'],
        value: [stockCode],
    })
}
