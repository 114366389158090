/**
 * Defined all event ---
 */
export default {
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGOUT: 'LOGOUT',
    CONNECT_SERVER: 'CONNECT_SERVER',
    REQ_GET_MKT_INF_FIRST: 'REQ_GET_MKT_INF_FIRST', // Yêu cầu lấy các thông tin market khi connect server lần đầu
    REQ_RE_GET_MKT_INF: 'REQ_RE_GET_MKT_INF', // Yêu cầu lấy lại thông tin market khi reconnect Server
    SUB_INDEX: 'SUB_INDEX',
    SUB_STOCK: 'SUB_STOCK',
    MKT_TOP: 'MKT_TOP',
    SUBSCRIBE_DONE: 'SUBSCRIBE_DONE',
    UPDATE_STOCK_TV: 'UPDATE_STOCK_TV',
    UPDATE_STOCK_FAV: 'UPDATE_STOCK_FAV',
    CHANGE_LIST_INDEX_CHART: 'CHANGE_LIST_INDEX_CHART',
    NOTIFY: 'NOTIFY',
    NOTIFY_SERVER: 'NOTIFY_SERVER',
    PRC_BOARD_STATUS: 'PRC_BOARD_STATUS',
    MESSAGE_SYSTEM: 'MESSAGE_SYSTEM',
    MISSION_RECONNECT: 'MISSION_RECONNECT',
    LOADING_FAV: 'LOADING_FAV',
    RES_COMMON_MSG: 'RES_COMMON_MSG',
    CHANGE_LIST_ACTIVE: 'CHANGE_LIST_ACTIVE',
    CHANGE_LIST_STOCK: 'CHANGE_LIST_STOCK',
    CHANGE_LIST_FAV: 'CHANGE_LIST_FAV',
    RECONNECT_MARKET: 'RECONNECT_MARKET',
    GET_LIST_INDEX: 'GET_LIST_INDEX',
    STORE_IS_READY: 'STORE_IS_READY',
    DONE_CONFIG_NODE: 'DONE_CONFIG_NODE',
    GET_DATA_EP_DONE: 'GET_DATA_EP_DONE',
    REFRESH_ORDER_LIST: 'REFRESH_ORDER_LIST',
    MKT_INTRADAY: 'MKT_INTRADAY',
    SUB_RES: 'SUB_RES',
    GET_DATA_I_MINUTES_DONE: 'GET_DATA_I_MINUTES_DONE',
    GET_DATA_I_DONE: 'GET_DATA_I_DONE',
    REQ_AFTER_SUB_INFO: 'REQ_AFTER_SUB_INFO',
    SUB_INFO_ERROR: 'SUB_INFO_ERROR',
    GET_DATA_EP_CHART_DONE: 'GET_DATA_EP_CHART_DONE',
    GET_MORE_DATA_EP_DONE: 'GET_MORE_DATA_EP_DONE',
    MKT_PT: 'MKT_PT',
    RESET_DATA: 'RESET_DATA',
    AVG_MATCH_INTRADAY: 'AVG_MATCH_INTRADAY',
    RECONNECT_TRADING: 'RECONNECT_TRADING',
    CONNECT_TRADING: 'CONNECT_TRADING',
    EKYC_SUCCESS: 'EKYC_SUCCESS',
    CONNECT_MARKET: 'CONNECT_MARKET',
    EXCHANGE_TIME: 'EXCHANGE_TIME',
    OPEN_EKYC: 'OPEN_EKYC',
    ALERT_MODAL: 'ALERT_MODAL',
    OTP_MODAL: 'OTP_MODAL',
    GET_INDEXS_DONE: 'GET_INDEXS_DONE',
    REQ_AFTER_SUB_INDEX: 'REQ_AFTER_SUB_INDEX',
    ORDER: 'ORDER',
    NOTIFY_HOTKEY: 'NOTIFY_HOTKEY',
    OPEN_MAJOR_FUNCTION: 'OPEN_MAJOR_FUNCTION',
    OPEN_NOTIFICATION_POPUP: 'OPEN_NOTIFICATION_POPUP',
    STOCK_INFO_DETAIL: 'STOCK_INFO_DETAIL',
    FEEDBACK: 'FEEDBACK',
    CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
    EXTERNAL_FUNCTION: 'EXTERNAL_FUNCTION',
    CLOSE_MODAL: 'CLOSE_MODAL',
    RESET_DATA_MODAL: 'RESET_DATA_MODAL',
    GET_HIST_FEEDBACK: 'GET_HIST_FEEDBACK',
    GET_HIST_INDEX_DONE: 'GET_HIST_INDEX_DONE',
    GET_HIST_STOCK_DONE: 'GET_HIST_STOCK_DONE',
    ADVERT_ORDER: 'ADVERT_ORDER', //-- event ban message lenh quang cao
    CHANGE_CONFIG_HOTKEY: 'CHANGE_CONFIG_HOTKEY',
    UPDATE_STATISTIC: 'UPDATE_STATISTIC',
    UPDATE_INDEX_DATA: 'UPDATE_INDEX_DATA',
    UPDATE_STOCK_DATA: 'UPDATE_STOCK_DATA',
    ADD_STK_FROM_FAVORITE: 'ADD_STK_FROM_FAVORITE',
    REMOVE_STK_FROM_FAVORITE: 'REMOVE_STK_FROM_FAVORITE',
    AUTO_FILL: 'AUTO_FILL',
    EXPIRE_SESSION: 'EXPIRE_SESSION',
    HOTKEY: 'HOTKEY',
    CHANGE_EXPIRE_TIME: 'CHANGE_EXPIRE_TIME',
    ONESIGNAL_NOTIFICATION: 'ONESIGNAL_NOTIFICATION',
    CONTROL_CHART_PRICEBOARD: 'CONTROL_CHART_PRICEBOARD',
    UPDATE_NOTIFICATION: 'UPDATE_NOTIFICATION',
    NOTIFICATION_POPOUT: 'NOTIFICATION_POPOUT',
    OPEN_ADD_NEW_FAV_LIST: 'OPEN_ADD_NEW_FAV_LIST',
    MSG_ADD_NEW_STK_2FAV: 'MSG_ADD_NEW_STK_2FAV',
    MSG_SEARCH_STK_IN_BRICE_BOARD: 'MSG_SEARCH_STK_IN_BRICE_BOARD',
    CHANGE_INDEX_OF_PRICE_BOARD: 'CHANGE_INDEX_OF_PRICE_BOARD',
    CHANGE_STOCK_FROM_TRADINGVIEW: 'CHANGE_STOCK_FROM_TRADINGVIEW',
    CHANGE_LANGUAGE_STOCK_TRADINGVIEW: 'CHANGE_LANGUAGE_STOCK_TRADINGVIEW',
    SUB_TRADINGVIEW: 'SUB_TRADINGVIEW',
    HIDE_STOCK_INFO: 'HIDE_STOCK_INFO',
    TRADING_VIEW_MODAL: 'TRADING_VIEW_MODAL',
    HIDE_TRADING_VIEW: 'HIDE_TRADING_VIEW',
    SHOW_LOADING: 'SHOW_LOADING',
    SORT_PRICEBOARD: 'SORT_PRICEBOARD',
    PIN_TOP_STOCK_PB: 'PIN_TOP_STOCK_PB',
    NOT_SELECT_PRICE_BOARD: 'NOT_SELECT_PRICE_BOARD',
    REQ_GET_ANOTHER_HIST_INDEX_VAL: 'REQ_GET_ANOTHER_HIST_INDEX_VAL', // Yêu cầu lấy thêm lịch sử chart index
    REQ_GET_ANOTHER_HIST_STOCK_VAL: 'REQ_GET_ANOTHER_HIST_STOCK_VAL', // Yêu cầu lấy thêm lịch sử chart stock
    CHANGE_INDEX_IN_MENU: 'CHANGE_INDEX_IN_MENU',
    REFRESH_TAB_ONE: 'REFRESH_TAB_ONE',
    SEARCH_STOCK_PB: 'SEARCH_STOCK_PB',
    REFRESH_STATE: 'REFRESH_STATE',
    OPEN_CONFIG: 'OPEN_CONFIG',
    CHANGE_SUB: 'CHANGE_SUB',
    CHANGE_ACTIVE_CODE: 'CHANGE_ACTIVE_CODE',

    HISTORY_ORDER: 'HISTORY_ORDER',
    BUY_SELL_FLOW: 'BUY_SELL_FLOW',
    MKT_INC_DEC: 'MKT_INC_DEC',
    CHANGE_ROUTER: 'CHANGE_ROUTER',
    ADD_TICK_TRADINGVIEW: 'ADD_TICK_TRADINGVIEW',
    UNSUB_INTRADAY_TRADINGVIEW: 'UNSUB_INTRADAY_TRADINGVIEW',
    HEIGHT_PRICEBOARD: 'HEIGHT_PRICEBOARD',
    INVISIBLE_ROW_TABLE_PRICEBOARD: 'INVISIBLE_ROW_TABLE_PRICEBOARD',
    AUTO_SCROLL: 'AUTO_SCROLL',
    ORDER_BONDS: 'ORDER_BONDS',
    REFRESH_BONDS_PRICEBOARD: 'REFRESH_BONDS_PRICEBOARD',
    CHANGE_SIZE_QUICK_ORDER: 'CHANGE_SIZE_QUICK_ORDER',
    GET_DATA_TAKE_PROFIT_STOP_LOSS: 'GET_DATA_TAKE_PROFIT_STOP_LOSS',
    UPDATE_SESSION_INFO: 'UPDATE_SESSION_INFO',
    RESET_TIME_MATCH_DATA: 'RESET_TIME_MATCH_DATA', // CSI: Reset data phần khớp lệnh trong chi tiết mã CK
    LOGIN_SUCCESSFUL: 'LOGIN_SUCCESSFUL', // Dùng cho việc lấy event market để show banner/thông báo (LOGIN_SUCCESS: gửi khi tự động login khi mạng kết nối kém => show lại banner)
    OTP_AUTHEN_REQUEST: 'OTP_AUTHEN_REQUEST', // Dùng cho các màn hình nhiệp vụ => Xác nhận OTP trước rồi mới thực thi các nghiệp vụ về tiền,...
    RESET_DATA_REGISTER_BANK: 'RESET_DATA_REGISTER_BANK',
    SHOW_MODAL_REDIRECT_TABLET_IPAD: 'SHOW_MODAL_REDIRECT_TABLET_IPAD',
    STOCK_CODE_CHANGE_FROM_HTS: 'STOCK_CODE_CHANGE_FROM_HTS',
    DATA_FEEDBACK_NOTI: 'DATA_FEEDBACK_NOTI',
    OPEN_FEEDBACK_SUBJECT: 'OPEN_FEEDBACK_SUBJECT',
    REFRESH_BROKER_HISTORY:'REFRESH_BROKER_HISTORY',
    POPUP_EVALUATE_REQUEST:"POPUP_EVALUATE_REQUEST",
    CHANGE_INDEX_FROM_TRADINGVIEW: 'CHANGE_INDEX_FROM_TRADINGVIEW',
    OPEN_INDIVIDUAL_BOND_INFO_DETAIL: 'OPEN_INDIVIDUAL_BOND_INFO_DETAIL',
    SUB_INDIVIDUAL_BOND: 'SUB_INDIVIDUAL_BOND',
    OPEN_MODAL_REGIST_PRO_INVESTOR: 'OPEN_MODAL_REGIST_PRO_INVESTOR',
    OPEN_PRINT_BOND_CONTRACT_MODAL: 'OPEN_PRINT_BOND_CONTRACT_MODAL',
    REQUEST_BOND_CONTRACT_MODAL: 'REQUEST_BOND_CONTRACT_MODAL',
    REFERRAL_ID:'REFERRAL_ID',
    CLOSE_ALERT_MODAL: 'CLOSE_ALERT_MODAL',
    OPEN_PLUGIN_POPUP: 'OPEN_PLUGIN_POPUP',
    OPEN_MODAL_PERSONAL_POLICY: 'OPEN_MODAL_PERSONAL_POLICY'
}
