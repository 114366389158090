import {
    Button,
    Checkbox,
    Collapse,
    DatePicker,
    message,
    Modal,
    Select,
    Spin,
    Input,
} from 'antd'
import moment from 'moment'
import React, { memo, useContext, useEffect, useRef, useState, useCallback, useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { StoreContext } from '../../store'
import { StoreTrading } from '../../store-trading'
import { dimensions } from '../../styles'
import { eventList, glb_sv, reqFunct, sendRequest, allowCompanyRender } from '../../utils'
import {
    InfoCircleFilled
} from '@ant-design/icons';
import CONFIG_EKYC from './config'
import UploadSignature from './UploadSignature'
import './index.scss'
import ModalAuthenOtpPhone from './modal-authen-otp-phone'
import ic_upload from '../../assets/images/ic_upload.svg';
import ic_upload_dark from '../../assets/images/ic_upload_dark.svg';
import ic_next from '../../assets/images/ic_next.svg';

const { Option } = Select

const ServiceInfo = {
    EKYC_UPDATE: {
        reqFunct: reqFunct.EKYC_UPDATE,
        WorkerName: 'FOSxID01',
        ServiceName: 'FOSxID01_EKYCMgt',
        ClientSentTime: '0',
        Operation: 'U',
    },
    API_UPLOAD: {
        reqFunct: reqFunct.API_UPLOAD,
        WorkerName: 'FOSxCommon02',
        ServiceName: 'FOSxCommon02_Token_Mgt',
        ClientSentTime: '0',
        Operation: 'Q',
    },
    GET_BROKER_REMISIER_LIST: {
        reqFunct: reqFunct.GET_BROKER_REMISIER_LIST,
        WorkerName: 'FOSqID02',
        ServiceName: 'FOSqID02_HOSUserInformation',
        ClientSentTime: '0',
        Operation: 'Q',
    },
    QUERY_USER_INFORMATION: {
        reqFunct: reqFunct.QUERY_USER_INFORMATION,
        WorkerName: 'FOSqID02',
        ServiceName: 'FOSqID02_UserInformation',
        ClientSentTime: '0',
        Operation: 'Q',
    },
    GET_LIST_BANK: {
        reqFunct: reqFunct.GET_LIST_BANK,
        WorkerName: 'FOSqAccount',
        ServiceName: 'FOSqAccount_Common',
        ClientSentTime: '0',
        Operation: 'Q',
    },
    GET_JOB_LIST: {
        reqFunct: reqFunct.GET_JOB_LIST,
        WorkerName: 'FOSqAccount',
        ServiceName: 'FOSqAccount_Common',
        Operation: 'Q',
    },
    CHECK_PROMO_CODE: {
        reqFunct: reqFunct.CHECK_PROMO_CODE,
        WorkerName: 'FOSxAccount',
        ServiceName: 'FOSxAccount_0107_1',
        Operation: 'E',
    },
    CHECK_DUPLICATE_INFO: {
        reqFunct: reqFunct.CHECK_DUPLICATE_INFO,
        WorkerName: 'FOSqAccount',
        ServiceName: 'FOSqAccount_Common_3',
        ClientSentTime: '0',
        Operation: 'Q',
    },
    GET_ACCOUNT_NUMBER_OPEN_VIA_EKYC: {
        reqFunct: reqFunct.GET_ACCOUNT_NUMBER_OPEN_VIA_EKYC,
        WorkerName: 'FOSqAccount',
        ServiceName: 'FOSqAccount_Common_3',
        ClientSentTime: '0',
        Operation: 'Q',
    }
}

function stopStreamedVideo(videoElem) {
    const stream = videoElem.srcObject
    if (!stream) return
    const tracks = stream.getTracks()

    tracks.forEach(function (track) {
        track.stop()
    })

    videoElem.srcObject = null
}

function EkycModal() {
    const { styles } = useContext(StoreContext)
    const { setUserInfo } = useContext(StoreTrading)
    const { t } = useTranslation()

    const [visible, setVisible] = useState(false)

    const [method, setMethod] = useState('ekyc')
    const [type, setType] = useState('ekyc')

    const [id, setID] = useState('-')

    const [name, setName] = useState('-')
    const [birthday, setBirthday] = useState(moment().subtract(20, 'year'))
    const [addr, setAddr] = useState('-')
    const [addrContact, setAddrContact] = useState('-')
    const [gender, setGender] = useState('O')
    const [idDate, setIDDate] = useState(moment())
    const [idPlace, setIDPlace] = useState('-')

    const [isAuthen, setAuthen] = useState(true)
    const [imageFront, setImageFront] = useState('')
    const [imageBack, setImageBack] = useState('')
    const [imageFace, setImageFace] = useState('')

    const [checkNote, setCheckNote] = useState(false)

    const card_type = useRef(null)

    const flagEkycUpdate = useRef(false)

    const [loading, setLoading] = useState(false)

    const [listMessage, setListMessage] = useState([])
    const [eKYCWarningMessage, setEKYCWarningMessage] = useState([])

    const validDate = useRef('')

    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [listOther, setListOther] = useState([])
    const [brokerObj, setBrokerObj] = useState(null)
    const [remisierObj, setRemisierObj] = useState(null)

    const [objUserInfo, setObjUserInfo] = useState({})

    const tempList = useRef([])

    const [loadingFaceVnpt, setLoadingFaceVNPT] = useState(false)

    const dataRef = useRef({
        imageHashFront: '',
        imageHashBack: '',
        imageHashFace: '',
        compareFace: 0,
    })

    const [isBankOnline, setBankOnline] = useState(2)
    const [bankCd, setBankCd] = useState('')
    const [bankAct, setBankAct] = useState('')

    const [bankList, setBankList] = useState([])
    const bankListTemple = useRef([])

    const [bankCode, setBankCode] = useState('')
    const [bankCdList, setBankCdList] = useState([])
    const bankCdListTemple = useRef([])

    const [promoCode, setPromoCode] = useState('')

    const configUIEkyc = CONFIG_EKYC(glb_sv.activeCode)
    const [checkMargin, setCheckMargin] = useState(configUIEkyc.margin_check_default)
    const [checkRegisBond, setCheckRegisBond] = useState(configUIEkyc.bond_check_default || false)
    const [checkAutoPIA, setCheckAutoPIA] = useState(configUIEkyc.auto_PIA_default)

    const [jobsList, setJobsList] = useState([])
    const [selectedJob, setSelectedJob] = useState('')

    const [referrerCode, setReferrerCode] = useState('')
    const [referrerName, setReferrerName] = useState('')

    const [referrerCodeError, setReferrerCodeError] = useState(false)
    const referrerCodeRef = useRef('')

    const [checkFATCA, setCheckFATCA] = useState(configUIEkyc.is_FATCA_default);
    const [stockListInfo, setStockListInfo] = useState('')
    const signatureID = useRef('')

    const timeoutGetName = useRef(null)
    const [brokerName, setBrokerName] = useState('')
    const [remisierName, setRemisierName] = useState('')

    const flagCheckPromoCode = useRef(false)
    const promoCodeRef = useRef('')

    const [isBrokerService, setBrokerService] = useState(configUIEkyc.brokerage_services_check_default)

    const [visibleModalAuthenOtpPhone, setModalAuthenOtpPhone] = useState(false)
    
    const [referralID, setReferralID] = useState('');

    const AccGenerationType = {
        AUTOMATIC: '1',
        BY_DATE_OF_BIRTH: '2',
        BY_PHONE_NUMBER: '3',
        CHOOSE_OTHER_NUMBER: '4',
        BY_MANAGER: '5',
    };
    const optionSelectAccountList = [
        {
          label: `1.${t('automatic')}`, 
          value: AccGenerationType.AUTOMATIC,
        },
        {
          label: `2.${t('by_date_of_birth')}`,
          value: AccGenerationType.BY_DATE_OF_BIRTH,
        },
        {  
          label: `3.${t('by_phone_number')}`,
          value: AccGenerationType.BY_PHONE_NUMBER,
        },
        {
          label: `4.${t('choose_other_number')}`,
          value: AccGenerationType.CHOOSE_OTHER_NUMBER,
        }
    ]
    const [activeAccountNumberOption,setActiveAccountNumberOption] = useState(optionSelectAccountList[0].value)
    const [accountNumberByOption,setAccountNumberByOption] = useState('')
    const timeoutGetAccountNumber = useRef(null)
    const [accountNumberExistMessage, setAccountNumberExistMessage] = useState(false)
    const [brokerPrefix,setBrokerPrefix] = useState('') // lưu đầu số broker khi chọn stk
    const [isDisableInputAccountNumber,setIsDisableInputAccountNumber] = useState(true) //disable field stk nếu không bị trùng (ngày sinh, sdt) 
    const [focusedPhone, setFocusedPhone] = useState(false)
    const [focusedAccountNumber,setFocusedAccountNumber] = useState(false)
    const [accountNumberExistCount,setAccountNumberExistCount] = useState(0) // lưu số lần nhập stk bị trùng 
    const [recommendAccountNumber,setRecommendAccountNumber] = useState('') // lưu stk đề xuất khi nhập bị trùng 5 lần


    //#region Config cho phép chỉnh sửa các field
    const permanentAddressEditable = configUIEkyc.hasOwnProperty('is_permanent_address_editable') ? configUIEkyc.is_permanent_address_editable : true // Chỉnh sửa địa chỉ thường trú
    const issueDateEditable = configUIEkyc.hasOwnProperty('is_issue_date_editable') ? configUIEkyc.is_issue_date_editable : true
    const issuePlaceEditable = configUIEkyc.hasOwnProperty('is_issue_place_editable') ? configUIEkyc.is_issue_place_editable : true
    const birthdayEditable = configUIEkyc.hasOwnProperty('is_birthday_editable') ? configUIEkyc.is_birthday_editable : true
    //#endregion

    useEffect(() => {
        const commonEvent = glb_sv.commonEvent.subscribe((msg) => {
            if (msg.type === eventList.OPEN_EKYC) {
                if (configUIEkyc.is_otp_authen_before_ekyc && msg.method !== 'normal') {
                    setModalAuthenOtpPhone(true)
                    return;
                }

                if (window.gtag) {
                    window.gtag('event', 'ekyc_web_start');
                }

                setVisible(true)
                if (msg.method === 'normal') {
                    // setMethod('sign');
                    setMethod('confirm')
                    setType('normal')
                    card_type.current = ''
                } else {
                    setMethod('ekyc')
                    // setLoadingFaceVNPT(true)
                    // loadEkycSDK()
                }

                getUserInfo()
                getBrokerList()
                getListCodeBanks()
                getListBanks()

                if (configUIEkyc.job_selection) {
                    getJobsList()
                }

                if (glb_sv.openAccountEkyc) {
                    setBrokerObj(glb_sv.openAccountEkyc.brokerid)
                    setRemisierObj(glb_sv.openAccountEkyc.remiseid)
                    setPromoCode(glb_sv.openAccountEkyc.codeEkyc)
                    promoCodeRef.current = glb_sv.openAccountEkyc.codeEkyc
                    setReferrerCode(glb_sv.openAccountEkyc.referCode)
                    setReferrerName(glb_sv.openAccountEkyc.referName)

                    console.log("check", glb_sv.openAccountEkyc.referralID)
                    setReferralID(glb_sv.openAccountEkyc.referralID)
                }
            }
        })



        return () => {
            commonEvent.unsubscribe()
        }
    }, [])

    const loadEkycSDK = async () => {
        await window.FaceVNPTBrowserSDK.init()
        const VNPT_CDN = "https://ekyc-web.vnpt.vn"
        let initObj = {
            BACKEND_URL: 'https://api.idg.vnpt.vn/',

            AUTHORIZION: (glb_sv.dataEkycInfo?.c4 || '').replaceAll(
                'bearer ',
                ''
            ),
            TOKEN_ID: glb_sv.dataEkycInfo?.c5 || '',
            TOKEN_KEY: glb_sv.dataEkycInfo?.c6 || '',
            ENABLE_GGCAPCHAR: true,
            PARRENT_ID: 'ekyc_sdk_intergrated',
            FLOW_TYPE: 'DOCUMENT', // DOCUMENT, FACE
            SHOW_RESULT: false,
            SHOW_HELP: true,
            SHOW_TRADEMARK: false,
            CHECK_LIVENESS_CARD: true,
            CHECK_LIVENESS_FACE: true,
            CHECK_MASKED_FACE: true,
            COMPARE_FACE: true,
            LANGUAGE: glb_sv.language === 'VI' ? 'vi' : 'en',
            LIST_ITEM: [-1, 9],
            TYPE_DOCUMENT: 99,
            USE_WEBCAM: true,
            USE_UPLOAD: glb_sv.configInfo.ekyc_not_upload ? false : true,
            ADVANCE_LIVENESS_FACE: true,
            ASYNC_LOAD_AI: true,
            LIST_CHOOSE_STYLE: {
                background: '#FFF',
                text_color: '#FFF',
                border_item: '',
                item_active_color: 'var(--PRIMARY)',
                background_icon: 'var(--PRIMARY)',
                id_icon: VNPT_CDN + '/images/si/id_card.svg',
                passport_icon: VNPT_CDN + '/images/si/passport.svg',
                drivecard_icon: VNPT_CDN + '/images/si/drivecard.svg',
                army_id_icon: VNPT_CDN + '/images/si/other_doc.svg',
                id_chip_icon: VNPT_CDN + '/images/si/id_chip.svg',
                start_button_background: 'var(--PRIMARY)',
                start_button_color: '#FFF',
            },
            CAPTURE_IMAGE_STYLE: {
                big_title_color: "#FFF",
                description1_color: "#FFF",
                capture_btn_background: "var(--PRIMARY)",
                // capture_btn_color: "#000000",
                // capture_btn_icon: VNPT_CDN + "/images/hdbank2/capture.svg",
                tutorial_btn_icon: VNPT_CDN + "/images/hdbank/help.gif",
                upload_btn_background: "white",
                upload_btn_color: "#000000",
                upload_btn_boder: "2px solid var(--PRIMARY)",
                // upload_btn_icon: VNPT_CDN + "/images/altiss/upload.svg",
                recapture_btn_background: "var(--PRIMARY)",
                recapture_btn_color: "#fff",
                recapture_btn_border: "2px solid var(--PRIMARY)",
                // recapture_btn_icon: VNPT_CDN + "/images/hdbank2/capture.svg",
                nextstep_btn_background: "var(--PRIMARY)",
                // nextstep_btn_color: "black",
                // nextstep_btn_icon: VNPT_CDN + "/images/hdbank2/next_icon.svg",
                capture_and_upload_wrapper_bg: "rgba(23, 24, 28, 0.7);",
                capture_and_upload_wrapper_bg_img: VNPT_CDN + "/altiss/bg-img.svg",

                upload_btn_icon: ic_upload_dark,
                recapture_btn_icon: VNPT_CDN + "/images/camera.svg",
                nextstep_btn_color: "white",
                nextstep_btn_icon: ic_next,
                capture_btn_color: 'white',
                capture_btn_icon: VNPT_CDN + "/images/camera.svg",
            },
            UPLOAD_IMAGE_STYLE: {
                upload_btn_background: "var(--PRIMARY)",
                upload_btn_color: "white",
                upload_btn_icon: ic_upload,
                upload_btn_boder: "2px solid var(--PRIMARY)"
            },
            MODAL_DOC_STYLE: {
                touch_icon: VNPT_CDN + "/altiss/touch_cmt.svg",
                close_icon: VNPT_CDN + "/altiss/close_icon.svg",
                notice1_icon: VNPT_CDN + "/altiss/cmt_notice1.svg",
                notice2_icon: VNPT_CDN + "/altiss/cmt_notice2.svg",
                notice3_icon: VNPT_CDN + "/altiss/cmt_notice3.svg",
            },
            MODAL_FACE_STYLE: {
                title_color: "var(--PRIMARY)",
                face_icon: VNPT_CDN + "/altiss/face_icon.svg",
                close_icon: VNPT_CDN + "/altiss/close_icon.svg",
                notice1_icon: VNPT_CDN + "/altiss/cmt_notice1.svg",
                notice2_icon: VNPT_CDN + "/altiss/cmt_notice2.svg",
                notice3_icon: VNPT_CDN + "/altiss/cmt_notice3.svg",
            },
            OTHER_CONFIG: {
                loading_icon: VNPT_CDN + "/images/hdbank2/loading.gif",
                loading_styles: "background-color: var(--PRIMARY__CONTENT__COLOR); opacity: 0.7",
                oval_web: VNPT_CDN + "/kbsv/web_border.json",
                oval_mobile: VNPT_CDN + "/kbsv/mobile_border.json",
                notice_ani: VNPT_CDN + "/animation/cautionHDB.json",
                oval_title_color: "#FFF",
                description_oval_content: t('remove_glass_authen'),
                description_oval: "text-align: center; color: red; font-weight: bold",
                video_tutorial_oval: VNPT_CDN + "/animation/video_tutorial_oval_dark.mp4",
            }
        }

        setTimeout(() => {
            try {
                setLoadingFaceVNPT(false)
                window.ekycsdk.init(initObj, (res) => null, call_after_end_flow)
            } catch (err) {
                console.log('loadEkycSDK -> err', err)
            }
        }, 100)

        function call_after_end_flow(data) {
            const vnpt_ekyc = document.getElementById('vnpt_ekyc')
            vnpt_ekyc.parentNode.removeChild(vnpt_ekyc)
            window.ekycsdk.init(
                {
                    ...initObj,
                    FLOW_TYPE: 'FACE',
                    TYPE_DOCUMENT: data.type_document,
                },
                (res2) => {
                    console.log('call_after_end_flow', data, res2)
                    setInfoEkyc2(
                        data.ocr,
                        res2.compare,
                        {
                            ...data.base64_doc_img,
                            ...res2.base64_face_img,
                        },
                        {
                            ...data.ocr.imgs,
                            img_face: res2.compare.imgs.img_face,
                        },
                        data.liveness_card_back,
                        data.liveness_card_front,
                        res2.liveness_face,
                        res2.masked
                    )
                }
            )
        }
    }

    const setInfoEkyc2 = (
        infos,
        compares,
        image,
        hash_img,
        liveness_card_back,
        liveness_card_front,
        liveNess,
        masked
    ) => {

        // Giảm thiểu log gửi lên Service
        compares.dataBase64 = ''
        compares.dataSign = ''

        liveNess.dataBase64 = ''
        liveNess.dataSign = ''

        masked.dataBase64 = ''
        masked.dataSign = ''

        liveness_card_front.dataBase64 = ''
        liveness_card_front.dataSign = ''

        liveness_card_back.dataBase64 = ''
        liveness_card_back.dataSign = ''

        infos.dataBase64 = ''
        infos.dataSign = ''
        // END Giảm thiểu log gửi lên Service

        const sEKYCLog = JSON.stringify({
            _infoObj: glb_sv.removeTrashObjEkyc(infos),
            _compares: glb_sv.removeTrashObjEkyc(compares),
            _liveness_card_front: glb_sv.removeTrashObjEkyc(liveness_card_front),
            _liveness_card_back: glb_sv.removeTrashObjEkyc(liveness_card_back),
            _faceLiveNess: glb_sv.removeTrashObjEkyc(liveNess),
            _maskedFace: glb_sv.removeTrashObjEkyc(masked)
        })

        const object = infos ? infos.object : null
        if (image) {
            setImageFront(image.img_front)
            setImageBack(image.img_back)
            setImageFace(image.img_face_near)
        }
        // Thêm check điều kiện warning quá 3 thì reject cho KH xác thực lại
        let generalWarningArr = []
        if (Array.isArray(object?.general_warning)) {
            generalWarningArr = object.general_warning
            setListMessage(generalWarningArr)
            if (generalWarningArr.length >= 2
                || generalWarningArr.findIndex(x => (
                    x === 'anh_dau_vao_mat_goc'
                    || x === 'id_mo_nhoe'
                    || x === 'ngay_sinh_mo_nhoe'
                    || x === 'anh_mat_truoc_bi_che'
                )) > -1
            ) {
                setAuthenAndSendLog(sEKYCLog, false)
                setAuthen(false)
                setMethod('confirm')
                return;
            }
        }

        let warningArr = []
        if (Array.isArray(object?.warning)) {
            warningArr = object.warning
            if (warningArr.length >= 3) {
                setListMessage(warningArr)
                setAuthenAndSendLog(sEKYCLog, false)
                setAuthen(false)
                setMethod('confirm')
                return
            }
        }

        // Check tampering (Giả mạo)
        let tamperingWarningArr = []
        if (Array.isArray(object?.tampering?.warning)) {
            tamperingWarningArr = object?.tampering?.warning
            if (tamperingWarningArr.length >= 3) {
                setListMessage(tamperingWarningArr)
                setAuthen(false)
                setMethod('confirm')
                setAuthenAndSendLog(sEKYCLog, false)
                return;
            }
        }

        // If warning_msg > 2 warning => Reject
        let warningMessage = []
        if (Array.isArray(object?.warning_msg)) {
            warningMessage = object.warning_msg
            setEKYCWarningMessage(warningMessage)
            if (warningMessage.length >= 3) {
                setListMessage(['unknow_error_ekyc'])
                setAuthen(false)
                setMethod('confirm')
                setAuthenAndSendLog(sEKYCLog, false)
                return;
            }
        }

        // ALT-1031 - Bổ sung logic từ chối với match_front_back.match_id = no
        if (object?.match_front_back?.match_id == 'no') {
            setListMessage(['document_invalid'])
            setAuthen(false)
            setMethod('confirm')
            setAuthenAndSendLog(sEKYCLog, false)
            return;
        }

        // END

         // ALT-1717 - Bổ sung logic từ chối với bright_spot_likelihood = likely và blurred_likelihood = likely (ảnh mờ nhòe và lóa)
         if (object?.bright_spot_likelihood === 'likely') {
            setListMessage(['unknow_error_ekyc'])
            setAuthen(false)
            setMethod('confirm')
            setAuthenAndSendLog(sEKYCLog, false)
            return;
          }
      
          if (object?.blurred_likelihood === 'likely') {
            setListMessage(['unknow_error_ekyc'])
            setAuthen(false)
            setMethod('confirm')
            setAuthenAndSendLog(sEKYCLog, false)
            return;
          }
      // END

        if (object.hasOwnProperty('name_fake_warning')) {
            if (object.name_fake_warning === 'fake') {
                setListMessage(['document_invalid'])
                setAuthen(false)
                setMethod('confirm')
                setAuthenAndSendLog(sEKYCLog, false)
                return
            }
        }

        if (liveNess && liveNess.object && liveNess.object.liveness === 'failure') {
            setListMessage(['face_is_not_match_with_document'])
            setAuthen(false)
            setMethod('confirm')
            setAuthenAndSendLog(sEKYCLog, false)
            return;
        }
        if (liveNess && liveNess.errors && liveNess.errors.length > 0) {
            setListMessage(['face_is_not_match_with_document'])
            setAuthen(false)
            setMethod('confirm')
            setAuthenAndSendLog(sEKYCLog, false)
            return;
        }
        if (masked && masked.object && masked.object.masked === 'yes') {
            setListMessage(['face_is_not_match_with_document'])
            setAuthen(false)
            setMethod('confirm')
            setAuthenAndSendLog(sEKYCLog, false)
            return;
        }
        if (!object) {
            if (infos.errors) {
                if (glb_sv.stringToSlug(infos.errors[0]) === 'loai-giay-to-khong-hop-le') {
                    setListMessage(['document_invalid'])
                } else {
                    setListMessage(infos.errors)
                }
            } else {
                setListMessage(['service_handle_error'])
            }
            setAuthen(false)
            setMethod('confirm')
            setAuthenAndSendLog(sEKYCLog, false)
            return
        }

        let listMessageError = []

        if (liveness_card_front?.object?.liveness === 'failure') {
            listMessageError.push('front_image_id_invalid')
            setListMessage(listMessageError)
            setAuthen(false)
            setMethod('confirm')
            setAuthenAndSendLog(sEKYCLog, false)
            return
        }
        if (liveness_card_back?.object?.liveness === 'failure') {
            listMessageError.push('back_image_id_invalid')
            setListMessage(listMessageError)
            setAuthen(false)
            setMethod('confirm')
            setAuthenAndSendLog(sEKYCLog, false)
            return
        }
        // Bổ sung fake_print_photo
        // if (liveness_card_front.object && liveness_card_front.object?.fake_print_photo) {
        //     setListMessage(['front_image_id_invalid'])
        //     setAuthen(false)
        //     setMethod('confirm')
        //     setAuthenAndSendLog(sEKYCLog, false)
        //     return;
        // }
        // if (liveness_card_back.object && liveness_card_back.object?.fake_print_photo) {
        //     setListMessage(['back_image_id_invalid'])
        //     setAuthen(false)
        //     setMethod('confirm')
        //     setAuthenAndSendLog(sEKYCLog, false)
        //     return;
        // }
        // END Bổ sung fake_print_photo


        if (!compares.object || compares.object.msg !== 'MATCH') {
            listMessageError.push('face_not_match')
        }
        listMessageError = listMessageError.filter(e => e !== 'qua_han_mat_truoc' && e !== 'qua_han_mat_sau')
        setListMessage(listMessageError)
        checkUserInfoExist(object.name, object.birth_day)

        
        // Backend
        // 01	CMND
        // 02	Hộ chiếu
        // 03	GPKD
        // 04	Chứng thư khác
        // 05	Trading Code (TCNN)
        // 06	Trading Code (CNNN)
        // 07	Cơ quan chính phủ
        // 08	Căn cước công dân

        // EKYC
        // "0: CM9
        // 1: CCCD hoặc CM12
        // 2: HC
        // 3: CMQD
        // 4: BLX
        // 5: CCGC"

        if (String(object.type_id) === '0') {
            card_type.current = '1'
        } else if (String(object.type_id) === '1') {
            if (glb_sv.stringToSlug(object.card_type).includes('chung-minh-nhan-dan')) {
                card_type.current = '1'
            } else {
                card_type.current = '8'
            }
        } else if (String(object.type_id) === '2') {
            card_type.current = '2'
        } else if (String(object.type_id) === '3' || String(object.type_id) === '4') {
            card_type.current = '4'
        } else if (String(object.type_id) === '5') {
            card_type.current = '8'
        } else {
            card_type.current = '4'
        }

        if (object.gender === 'Nam' || object.gender === 'Male') {
            setGender('M')
        } else if (glb_sv.stringToSlug(object.gender) === 'nu' || object.gender === 'Female') {
            setGender('F')
        }
        if (object.valid_date && object.valid_date.length >= 8) {
            validDate.current = moment(object.valid_date, 'DD/MM/YYYY').toDate()
        }
        setID(object.id)
        setName(object.name)
        if (object.birth_day && object.birth_day.length === 10) setBirthday(moment(object.birth_day, 'DD/MM/YYYY'))
        if (object.birth_day && object.birth_day.length === 4) setBirthday(moment(object.birth_day + '0101', 'YYYYMMDD'))
        setAddr(object.recent_location?.replaceAll('\n', ' '))
        if (object.issue_date && object.issue_date.length === 10) setIDDate(moment(object.issue_date, 'DD/MM/YYYY'))
        setIDPlace(object.issue_place)
        if (hash_img) {
            dataRef.current.imageHashFront = hash_img.img_front
            dataRef.current.imageHashBack = hash_img.img_back
            dataRef.current.imageHashFace = hash_img.img_face
        }
        if (compares) {
            dataRef.current.compareFace = compares.object?.prob
        }
        if (
            object.id_fake_warning === 'no' &&
            object.name_prob > 0.99 &&
            liveness_card_back?.object?.liveness === 'success' &&
            liveness_card_front?.object?.liveness === 'success' &&
            (compares.object?.msg === 'MATCH' ||
                compares.object?.prob > Number(glb_sv.dataEkycInfo.c3))
        ) {
            setAuthen(true)
            // setMethod('sign')
            setMethod('confirm')
            setAuthenAndSendLog(sEKYCLog, true)
        } else {
            setAuthen(false)
            setMethod('confirm')
            setAuthenAndSendLog(sEKYCLog, false)
        }
        if (infos?.statusCode === 400) {
            setAuthen(false)
            setMethod('confirm')
            setAuthenAndSendLog(sEKYCLog, false)
        }
    }

    const setAuthenAndSendLog = (sEKYCLog, isAuthen = true) => {
        if (isAuthen) {
            glb_sv.sendEKYCLog(sEKYCLog)
        } else {
            glb_sv.sendEKYCLog(sEKYCLog, '4')
        }
    }

    const checkPromoCode = () => {
        // VISE check xem mã khuyến mãi còn khả dụng không
        if (promoCode && allowCompanyRender(['888', '020'])) {
            if (flagCheckPromoCode.current) return;
            flagCheckPromoCode.current = true
            const inval = [promoCode]
            sendRequest(
                ServiceInfo.CHECK_PROMO_CODE,
                inval,
                checkPromoCodeResult,
                true,
                checkPromoCodeResultTimeout
            )
        } else {
            updateUserInfo()
        }
    }

    const checkPromoCodeResultTimeout = () => {
        flagCheckPromoCode.current = false
        glb_sv.showAlert({
            title: t('common_notify'),
            content: t('request_hanlde_not_success_try_again'),
            type: 'warn',
        })
    }

    const checkPromoCodeResult = (reqInfoMap, message) => {
        flagCheckPromoCode.current = false
        if (Number(message['Result']) === 0) {
            if (message.Code === '080027' || message.Code === '010502') {
                glb_sv.showAlert({
                    title: t('common_notify'),
                    content: t('promo_code_is_error_expire'),
                    type: 'warn',
                    zIndex: 999999,
                    showCancel: true,
                    linkCallback: () => {
                        setPromoCode('')
                        promoCodeRef.current = ''
                        requestAnimationFrame(() => updateUserInfo());
                    }
                })
            } else {
                glb_sv.showAlert({
                    title: t('common_notify'),
                    content: message.Message,
                    type: 'warn',
                    zIndex: 999999,
                })
            }
        } else {
            updateUserInfo()
        }
    }

    const updateUserInfo = () => {
        const compareFace = dataRef.current.compareFace
        if (!id || !id?.trim() || id === '-') {
            glb_sv.focusInput('idRef_ekyc')
            message.warning(t('warning_id_require'))
            return
        }
        if (!name || !name?.trim() || name === '-') {
            glb_sv.focusInput('nameRef_ekyc')
            message.warning(t('warning_full_name_require'))
            return
        }
        if (!addr || !addr?.trim() || addr === '-') {
            glb_sv.focusInput('addrRef_ekyc')
            message.warning(t('warning_addr_regit_per_require'))
            return
        }
        if (!addrContact || !addrContact?.trim() || addrContact === '-') {
            glb_sv.focusInput('addrContactRef_ekyc')
            message.warning(t('warning_contact_addr_require'))
            return
        }
        if (!gender) {
            glb_sv.focusInput('gender_ekyc')
            message.warning(t('warning_gender_require'))
            return
        }
        if (!idPlace || !idPlace?.trim() || idPlace === '-') {
            glb_sv.focusInput('idPlaceRef_ekyc')
            message.warning(t('warning_issue_place_require'))
            return
        }
        if (bankCode) {
            if (!bankCd) {
                glb_sv.focusInput('bank-code-ekyc')
                message.warning(t('warning_bank_code_require'))
                return
            }
            if (!bankAct) {
                glb_sv.focusInput('bank-account-ekyc')
                message.warning(t('warning_bank_account_require'))
                return
            }
        }
        if (brokerObj) {
            const info = tempList.current.filter((e) => e.c2 === '1').find(e => e.c0 === brokerObj);
            if (!info && tempList.current.length) {
                message.warning(t('warn_broker_code_is_not_valid'))
                return
            }
        }
        if (remisierObj) {
            const info = tempList.current.filter((e) => e.c2 === '2').find(e => e.c0 === remisierObj);
            if (!info && tempList.current.length) {
                message.warning(t('warn_remisier_code_is_not_valid'))
                return
            }
        }
        if (configUIEkyc.is_singature_required && !signatureID.current) {
            message.warning(t('warning_signature_require'))
            return
        }
        if (configUIEkyc.job_selection_required) {
            if (selectedJob?.trim() === '') {
                message.warning(t('note_job_is_empty'))
                return
            }
        }
        if (configUIEkyc.is_account_number_choose) {
            if (activeAccountNumberOption === '4' && accountNumberByOption.length !== 10) {
                message.warning(t('invalid_account_number_length'))
                return
            }
            const reg = /^-?\d*(\.\d*)?$/;
            const defaultValue = glb_sv.activeCode + 'C';
            const valueRemainNumber = accountNumberByOption.substring(defaultValue.length);
            if (!reg.test(valueRemainNumber)) {
                message.warning(t('invalid_account_number'))
                return;
            }
            if (accountNumberExistMessage) {
                message.warning(t('already_account_number_exists'))
                return
            }
        }

        if (!checkNote) return
        if (flagEkycUpdate.current) return
        let bankCdOnline = bankCd;
        if (['888', '081'].includes(glb_sv.activeCode)) {
            if (isBankOnline === 1) {
                bankCdOnline = '0028'
            }
        }
        flagEkycUpdate.current = true
        setLoading(true)

        const infomationDisclosureStock = stockListInfo.replaceAll(' ', '').replaceAll('  ', '').replaceAll(',', ';')

        const inval = [
            'ekyc',
            glb_sv.credentials.username,
            glb_sv.credentials.password,
            type === 'normal' ? (id?.trim().length < 12 ? '1' : '8') : card_type.current,
            id?.trim(),
            name.replaceAll(/(\r\n|\r|\n)/g, ' ')?.trim(),
            birthday.format('YYYYMMDD'),
            addr.replaceAll(/(\r\n|\r|\n)/g, ' ')?.trim(),
            addrContact.replaceAll(/(\r\n|\r|\n)/g, ' ')?.trim(),
            idDate.format('YYYYMMDD'),
            idPlace.replaceAll(/(\r\n|\r|\n)/g, ' ')?.trim(),
            gender,
            type === 'normal' ? 'no_image' : dataRef.current.imageHashFace,
            type === 'normal' ? 'no_image' : dataRef.current.imageHashFront,
            type === 'normal' ? 'no_image' : dataRef.current.imageHashBack,
            type === 'normal' ? '100' : String(compareFace),
            '', // Signture 1
            '', // Signture 2
            email?.trim().toLowerCase(), // email
            phone?.trim(), // mobile
            brokerObj || '', // Broker
            remisierObj || '', // Remisier
            validDate.current &&
                moment(validDate.current).format('YYYYMMDD') !== 'Invalid date'
                ? moment(validDate.current).format('YYYYMMDD')
                : '',
            isBankOnline ? bankAct : '',
            isBankOnline ? name?.trim() : '',
            isBankOnline ? bankCdOnline : '',
            isBankOnline === 1 ? 'Y' : (isBankOnline === 2 ? 'N' : ''),
            '02',
            referrerCode,
            referrerName,
            configUIEkyc.margin_check ? (checkMargin ? 'Y' : 'N') : '', // 30 Open Margin Account
            '', // nơi sinh
            configUIEkyc.auto_PIA ? (checkAutoPIA ? 'Y' : 'N') : '', //  32: Auto PIA Y/N
            signatureID.current, // 33: ID chữ ký
            selectedJob, // 34: Job ID
            configUIEkyc.is_FATCA ? (checkFATCA ? 'Y' : 'N') : '', // 35: Fata (Y/N)
            infomationDisclosureStock, // 36: DS mã CK liên quan, cách nhau dấu ;
            promoCodeRef.current || '', // 37: Mã khuyến mãi
            configUIEkyc.brokerage_services_check ? (isBrokerService ? '1' : '0') : '0', // 38 Register for brokerage services
            eKYCWarningMessage.join(';'), // 39: Warning msg từ VNPT trả ra,
            configUIEkyc.is_account_number_choose ? accountNumberByOption : '' , //40 Số tài khoản 
            '', // 41 inv_purpose
            '', // 42 year_revenue
            '', // 43 risky_rate
            '', // 44 inv_experience
            '', // 45 inv_knowledge
            '', // 46 act_no_other
            '', // 47 cust_hold
            '', // 48 related_person
            '', // 49 fatca_tp
            checkRegisBond ? 'Y' : 'N',
            '', // 51 post_cd
            referralID // 52 Mã giới thiệu
        ]
        sendRequest(
            ServiceInfo.EKYC_UPDATE,
            inval,
            updateUserInfoResult,
            true,
            updateUserInfoTimeout
        )
        if (window.gtag) {
            window.gtag('event', 'ekyc_web_end');
        }
    }

    const updateUserInfoTimeout = ({ type }) => {
        flagEkycUpdate.current = false
        setLoading(false)
        glb_sv.showAlert({
            title: t('common_notify'),
            content: t('request_hanlde_not_success_try_again'),
            type: 'warn',
        })
    }

    const updateUserInfoResult = (reqInfoMap, message) => {
        flagEkycUpdate.current = false
        setLoading(false)
        if (Number(message['Result']) === 0) {
            glb_sv.showAlert({
                title: t('common_notify'),
                content: message.Message,
                type: 'warn',
                zIndex: 999999,
            })
        } else {
            localStorage.removeItem('openAccountEkyc')
            let json
            try {
                json = JSON.parse(message.Data)[0]
            } catch (err) {
                console.log(err)
                if (glb_sv.objShareGlb.userInfo)
                    glb_sv.objShareGlb.userInfo.c9 = 'W'
                glb_sv.commonEvent.next({ type: eventList.EKYC_SUCCESS })
                return;
            }

            glb_sv.showAlert({
                title: t('common_notify'),
                content: allowCompanyRender(['036']) ? t('regist_ekyc_with_econtract') : message.Message,
                type: 'success',
                linkCallback: () => {
                    setVisible(false)
                    if (glb_sv.activeCode === '036') {
                        // Chuyển hướng sang luồng econtract DSS
                        glb_sv.userInfoServer['c53'] = json.c1
                        glb_sv.commonEvent.next({
                            type: eventList.EXTERNAL_FUNCTION,
                            value: 'eContract',
                            title: 'common_e_contract',
                            modalWidthMode: 'full'
                        })
                    } 
                },
                zIndex: 999999,
            })

            if (json && json.c0 === 'W') {
                if (glb_sv.objShareGlb.userInfo)
                    glb_sv.objShareGlb.userInfo.c9 = 'W'
                glb_sv.commonEvent.next({ type: eventList.EKYC_SUCCESS })
            } else {
                storeAccountInfo(json)
                if (glb_sv.objShareGlb.userInfo)
                    glb_sv.objShareGlb.userInfo.c9 = 'A'
                glb_sv.commonEvent.next({ type: eventList.EKYC_SUCCESS })
            }
        }
    }

    const validate = () => {
        if (!id || id === '-') return false
        if (!name || name === '-') return false
        if (!birthday) return false
        if (!addr || addr === '-') return false
        if (!addrContact || addrContact === '-') return false
        if (!idDate || idDate.format('YYYYMMDD') === 'Invalid Date')
            return false
        if (!idPlace || idPlace === '-') return false
        if (!checkNote) return false
        if (!isAuthen) return false
        if (!gender) return false
        return true
    }

    const authenEkyc = () => {
        setImageFront('')
        setImageBack('')
        setImageFace('')

        setMethod('ekyc')
        setTimeout(() => {
            // loadEkycSDK()
        }, 100)
    }

    const storeAccountInfo = (userInfo) => {
        const accountInfo = userInfo.c0
        const array_acnt = accountInfo.split('|').filter((e) => e !== '')
        const js_acntInfo = []
        const js_acntList = []
        // const js_acntListAll = []
        let acntMain = '',
            acntMainName = ''
        for (let i = 0; i < array_acnt.length; i++) {
            const acntInfo = array_acnt[i]
            const arr_info = acntInfo.split(',')

            if (arr_info[4] === 'Y') {
                acntMain = arr_info[0]
                acntMainName = arr_info[2]
            }
            const js_acnt = {
                AcntNo: arr_info[0],
                SubNo: arr_info[1],
                AcntNm: arr_info[2],
                IsOwnAcnt: arr_info[4],
                MarginYN: arr_info[3] === '2',
                ActType: arr_info[3],
            }
            const js_acntObj = {
                id: arr_info[0] + '.' + arr_info[1],
                name: arr_info[0] + '.' + arr_info[1] + ' - ' + arr_info[2],
            }
            js_acntInfo.push(js_acnt)
            js_acntList.push(js_acntObj)
            // js_acntListAll.push(js_acntObj)
        }

        glb_sv.objShareGlb['acntNoInfo'] = js_acntInfo
        glb_sv.objShareGlb['acntNoList'] = js_acntList
        // glb_sv.objShareGlb['acntNoListAll'] = js_acntListAll
        // --------------------
        glb_sv.objShareGlb['AcntMain'] = acntMain // -- chủ tài khoản lưu ký
        glb_sv.objShareGlb['AcntMainNm'] = acntMainName // -- Tên chủ tài khoản
        glb_sv.objShareGlb['workDate'] = userInfo.c16 // -- Ngày làm việc
        // glb_sv.objShareGlb['brokerId'] = userInfo['c24'] // -- ID nhân viên môi giới
        // glb_sv.objShareGlb['brokerNm'] = userInfo['c25'] // -- Tên nhân viên môi giới
        // glb_sv.objShareGlb['brokerEmail'] = userInfo['c26'] // -- Email nhân viên môi giới
        // glb_sv.objShareGlb['brokerMobile'] = userInfo['c27'] // -- Mobile nhân viên môi giới
        // glb_sv.objShareGlb['secCode'] = userInfo['c9'] // -- Tên nhân viên môi giới
        // glb_sv.objShareGlb['prdYN'] = userInfo['c31'] // -- User trình chiếu bảng điện hay không
        // glb_sv.objShareGlb['verify'] = Number(userInfo['c37']) || 0 // -- verify > 0 => Phải xác thực chứng chỉ số
        glb_sv.objShareGlb['verify'] = Number(userInfo['c21']) || 0 // -- verify > 0 => Phải xác thực chứng chỉ số
        glb_sv.objShareGlb['serialnum'] = userInfo['c38'] // -- serialNumber

        const sub_list = []
        const actn_list = []
        js_acntInfo.forEach((e) => {
            if (e.AcntNo === acntMain) sub_list.push(e.SubNo)
            if (!actn_list.find((temp) => temp.AcntNo === e.AcntNo))
                actn_list.push({
                    AcntNo: e.AcntNo,
                    actn_name: e.AcntNm,
                    key: e.AcntNo,
                    label: e.AcntNo + ' (' + e.AcntNm + ')',
                })
        })
        const isMargin = glb_sv.objShareGlb['acntNoInfo'].find(
            (e) => e.AcntNo === acntMain && e.SubNo === sub_list[0]
        )?.MarginYN
        setUserInfo({
            actn_curr: acntMain,
            sub_curr: sub_list[0],
            actn_name: acntMainName,
            sub_list,
            actn_list,
            actn_full: acntMain + '.00',
            isMargin: isMargin || false,
        })
    }

    const onCancel = () => {
        glb_sv.showAlert({
            title: t('common_notify'),
            content: t('msg_you_sure_exit_sign_up'),
            type: 'warn',
            linkCallback: () => {
                const camera_attach_video = document.getElementById(
                    'camera_attach_video'
                )
                if (camera_attach_video) {
                    stopStreamedVideo(camera_attach_video)
                }
                const video = document.getElementById('video')
                if (video) {
                    stopStreamedVideo(video)
                }
                const ekyc_sdk_intergrated = document.getElementById(
                    'ekyc_sdk_intergrated'
                )
                if (ekyc_sdk_intergrated) {
                    ekyc_sdk_intergrated.childNodes[1] &&
                        ekyc_sdk_intergrated.removeChild(
                            ekyc_sdk_intergrated.childNodes[1]
                        )
                    ekyc_sdk_intergrated.childNodes[0] &&
                        ekyc_sdk_intergrated.removeChild(
                            ekyc_sdk_intergrated.childNodes[0]
                        )
                }
                setVisible(false)
                setMethod('')
            },
            showCancel: true,
        })
    }

    const getBrokerList = () => {
        const inval = ['brk_rem']
        sendRequest(
            ServiceInfo.GET_BROKER_REMISIER_LIST,
            inval,
            onGetBrokerListResult
        )
        tempList.current = []
    }

    const onGetBrokerListResult = (reqInfoMap, message) => {
        if (Number(message['Result']) === 0) {
            return
        } else {
            let jsondata = []
            try {
                jsondata = message['Data'] ? JSON.parse(message['Data']) : []
            } catch (err) { }
            tempList.current = tempList.current.concat(jsondata)
            if (Number(message['Packet']) <= 0) {
                setListOther([...tempList.current])

                if (glb_sv.openAccountEkyc?.brokerid) {
                    const info = tempList.current.filter((e) => e.c2 === '1').find(e => e.c0 === glb_sv.openAccountEkyc.brokerid);
                    if (info) {
                        setBrokerName(info.c1)
                    } else {
                        setBrokerName('')
                    }
                }
                if (glb_sv.openAccountEkyc?.remiseid) {
                    const info = tempList.current.filter((e) => e.c2 === '2').find(e => e.c0 === glb_sv.openAccountEkyc.remiseid);
                    if (info) {
                        setRemisierName(info.c1)
                    } else {
                        setRemisierName('')
                    }
                }
            }
        }
    }

    const getUserInfo = () => {
        const InputParams = ['info']
        sendRequest(
            ServiceInfo.QUERY_USER_INFORMATION,
            InputParams,
            handleGetUserInfo
        )
    }

    const handleGetUserInfo = (reqInfoMap, message) => {
        if (Number(message['Result']) === 0) {
            return
        } else {
            let jsondata
            if (!message['Data']) return
            try {
                jsondata = JSON.parse(message['Data'])
                glb_sv.userInfoServer = jsondata[0]
                setObjUserInfo(jsondata[0])
                setPhone(
                    jsondata[0] && jsondata[0].c35 ? jsondata[0].c35?.trim() : ''
                )
                if(configUIEkyc.is_account_number_choose && accountNumberByOption === '3' ){
                    setAccountNumberByOption(jsondata[0] && jsondata[0].c35 ? `${glb_sv.activeCode}C${jsondata[0].c35.trim().substring(phone.length - 6)}` : '')
                }
                setEmail(
                    jsondata[0] && jsondata[0].c32 ? jsondata[0].c32?.trim() : ''
                )
            } catch (err) {
                return
            }
        }
    }

    const getListCodeBanks = () => {
        const InputParams = ['07', '%']
        sendRequest(
            ServiceInfo.GET_LIST_BANK,
            InputParams,
            handleGetListCodeBanks
        )
    }


    const handleGetListCodeBanks = (reqInfoMap, message) => {
        console.log('handleGetListCodeBanks: ', reqInfoMap, message)
        if (Number(message['Result']) === 0) {
            return
        } else {
            let jsondata = []
            try {
                if (!message['Data']) {
                } else {
                    jsondata = JSON.parse(glb_sv.filterStrBfParse(message['Data']))
                }
            } catch (err) {
                return
            }
            bankCdListTemple.current = bankCdListTemple.current.concat(jsondata)
            if (Number(message['Packet']) <= 0) {
                setBankCdList(bankCdListTemple.current)
            }
        }
    }

    const getListBanks = () => {
        const InputParams = ['18', '%', '%']
        sendRequest(
            ServiceInfo.GET_LIST_BANK,
            InputParams,
            handleGetListBanks
        )
        bankListTemple.current = []
    }

    const handleGetListBanks = (reqInfoMap, message) => {
        console.log('handleGetListBanks: ', reqInfoMap, message)
        if (Number(message['Result']) === 0) {
            return
        } else {
            let jsondata = []
            try {
                if (!message['Data']) {
                } else {
                    jsondata = JSON.parse(glb_sv.filterStrBfParse(message['Data']))
                }
            } catch (err) {
                return
            }
            bankListTemple.current = bankListTemple.current.concat(jsondata)
            if (Number(message['Packet']) <= 0) {
                setBankList(bankListTemple.current)
            }
        }
    }

    const getJobsList = () => {
        // IVS sử dụng list nghề nghiệp khác các cty còn lại
        const jobIDParam = allowCompanyRender(['061']) ? 'job_id_ekyc' : 'job_id'
        const inval = ['15', jobIDParam, '%']
        sendRequest(ServiceInfo.GET_JOB_LIST, inval, getJobsListResult)
    }

    const getJobsListResult = (reqInfoMap, message) => {
        if (Number(message['Result']) === 0) {
        } else {
            let jsondata = []
            try {
                jsondata = message['Data'] ? JSON.parse(message['Data']) : []
            } catch (err) {
                return
            }
            setJobsList(jsondata)
        }
    }

    const setUploadSignatureState = useCallback(idImage => {
        signatureID.current = idImage
    }, [])

    const checkUserInfoExist = (name, birday) => {
        const inval = ['CHK_NAME_BIRDT', name, birday && birday.length === 10 ? moment(birday, 'DD/MM/YYYY').format('YYYYMMDD') : birday]
        sendRequest(ServiceInfo.CHECK_DUPLICATE_INFO, inval, checkUserInfoExistResult)
    }

    const checkUserInfoExistResult = (reqInfoMap, message) => {
        if (Number(message['Result']) === 0) {
        } else {
            let jsondata = [];
            try {
                jsondata = message['Data'] ? JSON.parse(message['Data']) : [];
                if (jsondata[0].c0 === 'Y') {
                    glb_sv.showAlert({
                        title: t('common_notify'),
                        content: t('notify_info_ekyc_is_duplicate'),
                        linkCallback: () => null,
                        showCancel: true,
                        cancelCallBack: () => {
                            setVisible(false)
                            setMethod('')
                        }
                    })
                }
            }
            catch (err) {
                return
            }
        }
    }

    const onSuccessAfterAuthenOtpPhone = () => {

        if (window.gtag) {
            window.gtag('event', 'ekyc_web_start');
        }

        setVisible(true)
        setMethod('ekyc')
        // setLoadingFaceVNPT(true)
        // loadEkycSDK()

        getUserInfo()
        getBrokerList()
        getListCodeBanks()
        getListBanks()

        if (configUIEkyc.job_selection) {
            getJobsList()
        }

        if (glb_sv.openAccountEkyc) {
            setBrokerObj(glb_sv.openAccountEkyc.brokerid)
            setRemisierObj(glb_sv.openAccountEkyc.remiseid)
            setPromoCode(glb_sv.openAccountEkyc.codeEkyc)
            promoCodeRef.current = glb_sv.openAccountEkyc.codeEkyc
            setReferrerCode(glb_sv.openAccountEkyc.referCode)
            setReferrerName(glb_sv.openAccountEkyc.referName)
        }
    }

    const onCancelAuthenOtpPhone = () => {
        setModalAuthenOtpPhone(false)
    }

    useEffect(() => {
        if (configUIEkyc.is_account_number_choose) {
            if (accountNumberExistCount === 5) {
                //nếu nhập sai 5 lần ở option chọn theo 2.ngày sinh, 3.sdt ==> đề xuất stk option 1.tự động
                if (activeAccountNumberOption === AccGenerationType.BY_DATE_OF_BIRTH || activeAccountNumberOption === AccGenerationType.BY_PHONE_NUMBER) {
                    getAccountNumberGeneration(AccGenerationType.AUTOMATIC, '', true)
                } else {
                    // nếu nhập 5 lần ở option chọn theo người quản lý
                    getAccountNumberGeneration(AccGenerationType.BY_MANAGER, brokerObj, true)
                }
            }
        }
    }, [accountNumberExistCount, activeAccountNumberOption, brokerObj])

    useEffect(() => {
        if (configUIEkyc.is_account_number_choose) {
            if (id && id !== '-' && activeAccountNumberOption === AccGenerationType.AUTOMATIC) {
                getAccountNumberGeneration(activeAccountNumberOption, brokerObj)
            }
        }
    }, [id])

    const onChooseAccountNumberType = (accountNumberTypeOption) => {
        setActiveAccountNumberOption(accountNumberTypeOption);
        setAccountNumberExistMessage(false)
        setIsDisableInputAccountNumber(true)
        setAccountNumberExistCount(0)
        if (accountNumberTypeOption === AccGenerationType.AUTOMATIC || accountNumberTypeOption === AccGenerationType.CHOOSE_OTHER_NUMBER) {
            getAccountNumberGeneration(accountNumberTypeOption, brokerObj);
            if (accountNumberTypeOption === AccGenerationType.CHOOSE_OTHER_NUMBER) {
                setIsDisableInputAccountNumber(false)
            }
        }
        else if (accountNumberTypeOption === AccGenerationType.BY_DATE_OF_BIRTH) {
            setAccountNumberByOption(`${glb_sv.activeCode}C${moment(birthday).format('DDMMYY')}`)
            checkAccountNumberExist(`${glb_sv.activeCode}C${moment(birthday).format('DDMMYY')}`, accountNumberTypeOption)
        }
        else if (accountNumberTypeOption === AccGenerationType.BY_PHONE_NUMBER) {
            const phoneFormat = `${glb_sv.activeCode}C${phone.substring(phone.length - 6)}`
            setAccountNumberByOption(phoneFormat)
            if (phoneFormat.length !== 10) return // ko đủ 10 số thì ko check stk trùng
            checkAccountNumberExist(phoneFormat, accountNumberTypeOption)
        }

    }
    const getBrokerPrefix = (accountNumberVal) => {
        //Hàm lấy đầu số broker nếu option là 4.stk tự nhập 
        //VD : 061C88XXXXXX ==> return 88
        const endIndex = accountNumberVal.indexOf("X");
        const extractedString = accountNumberVal.substring(4, endIndex);
        setBrokerPrefix(extractedString)
    }

    const getAccountNumberGeneration = (option, brokerId = '', isGenerateRecommendAccount = false) => {
        if (!configUIEkyc.is_account_number_choose) return
        //Hàm tạo stk cho option tự động và tự nhập(cần truyền mã môi giới, ko có truyền rỗng)
        if (isGenerateRecommendAccount) { // generate cho stk đề xuất
            const InputParams = ['AUTO_ACT_TP', brokerId ? AccGenerationType.BY_MANAGER : AccGenerationType.AUTOMATIC, brokerId ? brokerId : '', id]
            sendRequest(
                ServiceInfo.GET_ACCOUNT_NUMBER_OPEN_VIA_EKYC,
                InputParams,
                handleGenerateRecommendAccountNumberResult
            )
            return
        }
        const InputParams = ['AUTO_ACT_TP', option, ((option === AccGenerationType.CHOOSE_OTHER_NUMBER) && brokerId) ? brokerId : '', id]
        sendRequest(
            ServiceInfo.GET_ACCOUNT_NUMBER_OPEN_VIA_EKYC,
            InputParams,
            handleGenerateAccountNumberResult
        )
    }
    const handleGenerateRecommendAccountNumberResult = (reqInfoMap, message) =>{
        if (Number(message['Result']) === 0) {
        } else {
            let jsondata = []
            try {
                jsondata = message['Data'] ? JSON.parse(message['Data']) : []
                if (jsondata[0].c0 !== '') {
                    setRecommendAccountNumber(jsondata[0]?.c0)
                }
            } catch (err) {
                return
            }
        
        }
    }


    const handleGenerateAccountNumberResult = (reqInfoMap, message) =>{
        if (Number(message['Result']) === 0) {
        } else {
            let jsondata = []
            try {
                jsondata = message['Data'] ? JSON.parse(message['Data']) : []
                if (jsondata[0].c0 !== '') {
                    setAccountNumberByOption(jsondata[0].c0)
                    if(reqInfoMap?.inputParam[1] === '4'){
                        getBrokerPrefix(jsondata[0]?.c0) 
                    }
                }
            } catch (err) {
                return
            }
        
        }
    }

    const checkAccountNumberExist = (accountNumber, option = activeAccountNumberOption, phoneNumber = phone) => {
        if (option === AccGenerationType.AUTOMATIC) { //ko cần check stk trùng với option stk tự động
            setIsDisableInputAccountNumber(true)
            return
        }
        const InputParams = ['CHK_ACT', accountNumber]
        sendRequest(
            ServiceInfo.CHECK_DUPLICATE_INFO,
            InputParams,
            (reqInfoMap, messageService) => handleCheckAccountNumberExistResult(reqInfoMap, messageService, option, phoneNumber)
        )
    }
    const handleCheckAccountNumberExistResult = (reqInfoMap, messageService, option, phoneNumber) => {
        setIsDisableInputAccountNumber(false)
        if (Number(messageService['Result']) === 0) {
            setAccountNumberExistMessage(true)
            setIsDisableInputAccountNumber(false) // trùng => ko disable field stk
            if (focusedAccountNumber && accountNumberExistCount <= 4) {
                setAccountNumberExistCount((prev) => prev + 1)
            }
            if (focusedPhone && activeAccountNumberOption === AccGenerationType.BY_PHONE_NUMBER) return // nếu chọn stk theo phone và đang nhập phone thì ko hiện message
            message.warning(t('already_account_number_exists'))
            return
        }
        else {
            setAccountNumberExistMessage(false)
            if (option === AccGenerationType.BY_DATE_OF_BIRTH && moment(birthday).format('DDMMYY') === reqInfoMap.inputParam[1].substring(4)) {
                setIsDisableInputAccountNumber(true)
            }
            if (option === AccGenerationType.BY_PHONE_NUMBER && phoneNumber.substring(phoneNumber.length - 6) === reqInfoMap.inputParam[1].substring(4)) {
                setIsDisableInputAccountNumber(true)
            }
        }
    }

    const handleChangeAccountNumber = (e) => {
        let accountNumber = e.target.value;
        glb_sv.focusInput('account_number_ekyc')
        const reg = /^-?\d*(\.\d*)?$/;
        const defaultValue = glb_sv.activeCode + 'C' + `${activeAccountNumberOption === '4' ? brokerPrefix : ''}`;
        const currentDefaultValue = accountNumber.substring(0, defaultValue.length);
        const valueRemainNumber = accountNumber.substring(defaultValue.length);
        if (valueRemainNumber.includes('X')) {
        } else { // chỉ cho nhập số
            if (!reg.test(valueRemainNumber)) {
                e.preventDefault();
                return;
            }
        }

        if (currentDefaultValue !== defaultValue) { // prefix string bằng active code
            setAccountNumberByOption(defaultValue);
        } else {
            setAccountNumberByOption(accountNumber);
        }

        if (!accountNumber) {
            if (timeoutGetAccountNumber.current) clearTimeout(timeoutGetAccountNumber.current)
            return
        };
        if (timeoutGetAccountNumber.current) clearTimeout(timeoutGetAccountNumber.current)
        timeoutGetAccountNumber.current = setTimeout(() => {
            if (accountNumber.length !== 10) {
                message.warning(t('invalid_account_number_length'))
                return
            }
            if (!reg.test(valueRemainNumber)) {
                message.warning(t('invalid_account_number'))
                return;
            }
            checkAccountNumberExist(accountNumber)
        }, 1000);
    }

    return (<>
        <Modal
            visible={visible}
            onCancel={onCancel}
            footer={null}
            wrapClassName={
                method === 'ekyc' ? 'modal-ekyc-old' : 'modal-ekyc-final'
            }
            width={method === 'ekyc' ? 1000 : 600}
            style={{ top: 20 }}
        >
            <div className='flex'>
                <span
                    className='flex flex-1 justify-content-center fs-medium font-600 text-primary'
                    style={{ color: method === 'ekyc' ? 'white' : '' }}
                >
                    {t('common_register_open_account_ekyc')}
                </span>
                <span
                    onClick={onCancel}
                    className='fs-big text-primary cursor-pointer'
                    style={{
                        fontSize: 24,
                        paddingRight: 12,
                        paddingLeft: 12,
                        color: method === 'ekyc' ? 'white' : '',
                    }}
                >
                    ×
                </span>
            </div>

            {method === 'ekyc' ? (
                <div id='ekyc_sdk_intergrated'>
                    {loadingFaceVnpt && (
                        <Spin size='large' style={{ margin: '250px 460px' }} />
                    )}
                </div>
            ) : null}

            {method === 'confirm' ? (
                <div className='flex flex-direction-column'>
                    <div className='row-ekyc'>
                        <span className='flex flex-4 text-second fs-normal align-items-center'>
                            {t('phone_number')}
                            <span className='price-down'>*</span>
                        </span>
                        <div style={{ width: 5 }} />
                        <span className='text-primary fs-normal flex flex-5'>
                            <Input
                                onChange={(e) => {
                                    setPhone(e.target.value)
                                    if(configUIEkyc.is_account_number_choose && activeAccountNumberOption === AccGenerationType.BY_PHONE_NUMBER){
                                        setAccountNumberByOption(`${glb_sv.activeCode}C${e.target.value.substring(e.target.value.length - 6)}`)
                                        if(activeAccountNumberOption !== AccGenerationType.BY_PHONE_NUMBER) return
                                        if (!e.target.value) {
                                            if (timeoutGetAccountNumber.current) clearTimeout(timeoutGetAccountNumber.current)
                                            return
                                        };
                                        if (timeoutGetAccountNumber.current) clearTimeout(timeoutGetAccountNumber.current)
                                            timeoutGetAccountNumber.current = setTimeout(() => {
                                                checkAccountNumberExist(`${glb_sv.activeCode}C${e.target.value.substring(e.target.value.length - 6)}`,activeAccountNumberOption,e.target.value)
                                        }, 1000);
                                    }
                                }}
                                disabled={
                                    objUserInfo
                                        ? objUserInfo?.c35?.trim() === ''
                                            ? false
                                            : objUserInfo?.c36 === 'Y'
                                                ? false
                                                : true
                                        : false
                                }
                                value={phone}
                                onFocus={() =>{
                                    if(configUIEkyc.is_account_number_choose && activeAccountNumberOption === '3') setFocusedPhone(true)
                                    phone === '-' ? setPhone('') : null
                                }}
                                onBlur={() =>{
                                    if(configUIEkyc.is_account_number_choose && activeAccountNumberOption === '3') setFocusedPhone(false)
                                    phone === '' ? setPhone('-') : null
                                }}
                                id='phone_ekyc'
                                className='padding-input flex-1'
                            />
                        </span>
                    </div>
                    <div className='row-ekyc'>
                        <span className='flex flex-4 text-second fs-normal align-items-center'>
                            {t('email')}
                            <span className='price-down'>*</span>
                        </span>
                        <div style={{ width: 5 }} />
                        <span className='text-primary fs-normal flex flex-5'>
                            <Input
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value)
                                }}
                                disabled={
                                    objUserInfo
                                        ? objUserInfo?.c32?.trim() == ''
                                            ? false
                                            : objUserInfo?.c33 === 'Y'
                                                ? false
                                                : true
                                        : false
                                }
                                id='email_ekyc'
                                className='padding-input flex-1'
                            />
                        </span>
                    </div>
                    <div className='row-ekyc'>
                        <span className='flex flex-4 text-second fs-normal align-items-center'>
                            {t('id_card_number')}
                            <span className='price-down'>*</span>
                        </span>
                        <div style={{ width: 5 }} />
                        <span className='text-primary fs-normal flex flex-5'>
                            <Input
                                value={id}
                                onChange={(e) => {
                                    setID(e.target.value)
                                }}
                                disabled={type === 'ekyc'}
                                onFocus={() => (id === '-' ? setID('') : null)}
                                onBlur={() => (id === '' ? setID('-') : null)}
                                id='idRef_ekyc'
                                className='padding-input flex-1'
                            />
                        </span>
                    </div>

                    <div className='row-ekyc'>
                        <span className='flex flex-4 text-second fs-normal align-items-center'>
                            {t('common_full_name')}
                            <span className='price-down'>*</span>
                        </span>
                        <div style={{ width: 5 }} />
                        <span className='text-primary fs-normal flex flex-5'>
                            <Input
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value)
                                }}
                                disabled={type === 'ekyc'}
                                onFocus={() =>
                                    name === '-' ? setName('') : null
                                }
                                onBlur={() =>
                                    name === '' ? setName('-') : null
                                }
                                id='nameRef_ekyc'
                                className='padding-input'
                            />
                        </span>
                    </div>

                    <div className='row-ekyc'>
                        <span className='flex flex-4 text-second fs-normal align-items-center'>
                            {t('common_birthday')}
                            <span className='price-down'>*</span>
                        </span>
                        <div style={{ width: 5 }} />
                        <span className='text-primary fs-normal flex flex-5'>
                            <DatePicker
                                disabled={!birthdayEditable}
                                value={birthday}
                                size='middle'
                                onChange={(value) => setBirthday(value)}
                                format={'DD/MM/YYYY'}
                            />
                        </span>
                    </div>

                    <div className='row-ekyc'>
                        <span className='text-second fs-normal flex flex-4 align-items-center'>
                            {t('addr_regit_per')}
                            <span className='price-down'>*</span>
                        </span>
                        <div style={{ width: 5 }} />
                        <span className='text-primary fs-normal flex flex-5'>
                            <Input
                                disabled={!permanentAddressEditable}
                                value={addr}
                                onChange={(e) => {
                                    setAddr(e.target.value)
                                }}
                                onFocus={() =>
                                    addr === '-' ? setAddr('') : null
                                }
                                onBlur={() =>
                                    addr === '' ? setAddr('-') : null
                                }
                                id='addrRef_ekyc'
                                className='padding-input flex flex-1'
                                maxLength={100}
                            />
                        </span>
                    </div>

                    <div className='row-ekyc'>
                        <span className='text-second fs-normal flex flex-4 align-items-center'>
                            {t('contact_address')}
                            <span className='price-down'>*</span>
                        </span>
                        <div style={{ width: 5 }} />
                        <span className='text-primary fs-normal flex flex-5'>
                            <Input
                                value={addrContact}
                                onChange={(e) => {
                                    setAddrContact(e.target.value)
                                }}
                                onFocus={() =>
                                    addrContact === '-'
                                        ? setAddrContact('')
                                        : null
                                }
                                onBlur={() =>
                                    addrContact === ''
                                        ? setAddrContact('-')
                                        : null
                                }
                                id='addrContactRef_ekyc'
                                className='padding-input flex flex-1'
                                maxLength={100}
                                suffix={addrContact.length + '/100'}
                            />
                        </span>
                    </div>

                    <div className='row-ekyc'>
                        <span className='flex flex-4 text-second fs-normal align-items-center'>
                            {t('common_your_sex')}
                            <span className='price-down'>*</span>
                        </span>
                        <div style={{ width: 5 }} />
                        <span className='text-primary fs-normal flex flex-5'>
                            <Select
                                onChange={(value) => setGender(value)}
                                value={gender}
                                size='middle'
                                id='gender_ekyc'
                            >
                                <Option value='M'>{t('common_male')}</Option>
                                <Option value='F'>{t('common_female')}</Option>
                                <Option value='O'>{t('common_other')}</Option>
                            </Select>
                        </span>
                    </div>

                    <div className='row-ekyc'>
                        <span className='flex flex-4 text-second fs-normal align-items-center'>
                            {t('common_register_date')}
                            <span className='price-down'>*</span>
                        </span>
                        <div style={{ width: 5 }} />
                        <span className='text-primary fs-normal flex flex-5'>
                            <DatePicker
                                disabled={!issueDateEditable}
                                value={idDate}
                                size='middle'
                                onChange={(value) => setIDDate(value)}
                                format={'DD/MM/YYYY'}
                            />
                        </span>
                    </div>

                    <div className='row-ekyc'>
                        <span className='flex flex-4 text-second fs-normal align-items-center'>
                            {t('common_register_place')}
                            <span className='price-down'>*</span>
                        </span>
                        <div style={{ width: 5 }} />
                        <span className='text-primary fs-normal flex flex-5'>
                            <Input
                                disabled={!issuePlaceEditable}
                                value={idPlace}
                                onChange={(e) => {
                                    setIDPlace(e.target.value)
                                }}
                                onFocus={() =>
                                    idPlace === '-' ? setIDPlace('') : null
                                }
                                onBlur={() =>
                                    idPlace === '' ? setIDPlace('-') : null
                                }
                                id='idPlaceRef_ekyc'
                                className='padding-input flex flex-1'
                            />
                        </span>
                    </div>


                    {/* Nghề nghiệp */}
                    {configUIEkyc.job_selection && <div className='row-ekyc'>
                        <span className='flex flex-4 text-second fs-normal align-items-center'>
                            {t('common_job')}
                        </span>
                        <div style={{ width: 5 }} />
                        <span className='text-primary fs-normal flex flex-5'>
                            <Select
                                onChange={(value) => setSelectedJob(value)}
                                value={selectedJob}
                                size='middle'
                            >
                                {jobsList.map((item) => (
                                    <Option key={item.c0} value={item.c0}>
                                        {item.c1}
                                    </Option>
                                ))}
                            </Select>
                        </span>
                    </div>}

                    {/* Mã khuyến mãi/giới thiệu */}
                    {configUIEkyc.voucher_code && <div className='row-ekyc'>
                        <span className='flex flex-4 text-second fs-normal align-items-center'>
                            {t('promo_code')}
                        </span>
                        <div style={{ width: 5 }} />
                        <span className='text-primary fs-normal flex flex-5'>
                            <Input
                                value={promoCode}
                                disabled={glb_sv.openAccountEkyc && glb_sv.openAccountEkyc.codeEkyc}
                                onChange={(e) => {
                                    setPromoCode(e.target.value)
                                    promoCodeRef.current = e.target.value
                                }}
                                className='padding-input flex flex-1'
                            />
                        </span>
                    </div>}

                    {configUIEkyc.is_FATCA && <div
                        style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginTop: dimensions.vertical(8),
                        }}
                    >
                        <Checkbox
                            checked={checkFATCA}
                            onChange={() => setCheckFATCA((value) => !value)}
                        >
                            <span className='fs-normal text-primary cursor-pointer'>
                                {t('FATCA_privacy')}
                            </span>
                        </Checkbox>
                    </div>}

                    {type === 'ekyc' ? (
                        <div className='row-ekyc align-items-center'>
                            <span className='flex flex-4 text-second fs-normal'>
                                {t('authen_face')}
                            </span>
                            <div style={{ width: 5 }} />
                            <div
                                className='flex flex-5'
                                style={{
                                    backgroundColor: isAuthen
                                        ? '#E3F6E3'
                                        : '#F6E3E3',
                                    borderRadius: 5,
                                    padding: dimensions.moderate(8),
                                }}
                            >
                                <span
                                    className='fs-normal'
                                    style={{
                                        color: isAuthen
                                            ? styles.UP__COLOR
                                            : styles.DOWN__COLOR,
                                    }}
                                >
                                    {isAuthen
                                        ? t('cancel_success')
                                        : t('cancel_failure')}
                                </span>
                            </div>
                        </div>
                    ) : null}

                    {type === 'ekyc' && listMessage.length ? (
                        <div
                            className='flex flex-direction-column'
                            style={{
                                marginTop: dimensions.moderate(8),
                                color: 'var(--WARN__COLOR)'
                            }}
                        >
                            {listMessage.map((item, index) =>
                                <div className='flex' key={index}>
                                    {item ? '- ' + t(item) : ''}
                                </div>
                            )}
                        </div>
                    ) : null}

                    {type === 'ekyc' && (
                        <div
                            className='flex flex-direction-row justify-content-center'
                            style={{ marginTop: 6 }}
                        >
                            <div style={{ marginRight: 5, marginLeft: 5 }}>
                                <img
                                    style={{
                                        height: 120,
                                        width: 200,
                                        borderRadius: 10,
                                    }}
                                    src={imageFront}
                                />
                            </div>
                            <div style={{ marginRight: 5, marginLeft: 5 }}>
                                <img
                                    style={{
                                        height: 120,
                                        width: 200,
                                        borderRadius: 10,
                                    }}
                                    src={imageBack}
                                />
                            </div>
                            <div style={{ marginRight: 5, marginLeft: 5 }}>
                                <img
                                    style={{
                                        height: 120,
                                        width: 120,
                                        borderRadius: 60,
                                    }}
                                    src={imageFace}
                                />
                            </div>
                        </div>
                    )}


                    {/* Extend services */}
                    {configUIEkyc.is_show_extension_services && <div className='fw-600' style={{ marginTop: 5 }}>
                        {t('extend_service')}
                    </div>}
                    {configUIEkyc.auto_PIA && <div className='flex flex-direction-row align-items-center'
                        style={{
                            marginTop: dimensions.vertical(8),
                        }}
                    >
                        <Checkbox
                            checked={checkAutoPIA}
                            onChange={() => setCheckAutoPIA((value) => !value)}
                        >
                            <span className='fs-normal text-primary cursor-pointer'>
                                {t('auto_pia')}
                            </span>
                        </Checkbox>
                    </div>}
                    {configUIEkyc.margin_check && <div className='flex flex-direction-row align-items-center'
                        style={{
                            marginTop: dimensions.vertical(8),
                        }}
                    >
                        <Checkbox
                            checked={checkMargin}
                            onChange={() => setCheckMargin((value) => !value)}
                        >
                            <span className='fs-normal text-primary cursor-pointer'>
                                {t('active_act_trade_margin')}
                            </span>
                        </Checkbox>
                    </div>}
                    {configUIEkyc.bond_check && <div className='flex flex-direction-row align-items-center'
                        style={{
                            marginTop: dimensions.vertical(8),
                        }}
                    >
                        <Checkbox
                            checked={checkRegisBond}
                            onChange={() => setCheckRegisBond((value) => !value)}
                        >
                            <span className='fs-normal text-primary cursor-pointer'>
                                {t('bond_trade_register')}
                            </span>
                        </Checkbox>
                    </div>}
                    {/* END extend services */}

                    {/* Bank Info */}
                    <div className='fw-600' style={{ marginTop: 5 }}>
                        {t('bank_account_information')}
                        {glb_sv.dataEkycInfo?.c7 &&
                            <a
                                href={glb_sv.dataEkycInfo.c7}
                                target='_blank'
                                rel="noreferrer"
                            >
                                {' '}
                                <InfoCircleFilled />
                            </a>
                        }
                    </div>

                    <div className='row-ekyc' style={{ visibility: isBankOnline === 0 ? 'hidden' : '' }}>
                        <div className='flex flex-4 text-primary fs-normal align-items-center'>
                            <span className='text-second text-nowrap'>
                                {t('bank_cd')}
                            </span>
                        </div>
                        <div style={{ width: 5 }} />
                        <div className='text-primary fs-normal flex flex-5'>
                            <Select
                                showSearch
                                allowClear
                                onChange={(value) => setBankCode(value)}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                value={bankCode}
                                size='middle'
                                id='bank-branch-ekyc'
                                disabled={isBankOnline === 1}
                                className='bank-select-ekyc'
                                dropdownClassName='list-bank-cd'
                                style={{ width: '100%' }}
                            >
                                {bankCdList
                                    .map((item) => (
                                        <Option key={item.c0} value={item.c0}>
                                            {item.c3 + ' - ' + (glb_sv.language === 'VI' ? item.c1 : item.c2)}
                                        </Option>
                                    ))}
                            </Select>
                        </div>
                    </div>

                    <div className='row-ekyc' style={{ display: bankCode && isBankOnline === 2 ? 'flex' : 'none' }}>
                        <div className='flex flex-4 text-primary fs-normal align-items-center'>
                            <span className='text-second text-nowrap'>
                                {t('branch')}
                            </span>
                        </div>
                        <div style={{ width: 5 }} />
                        <div className='text-primary fs-normal flex flex-5'>
                            <Select
                                showSearch
                                onChange={(value) => {
                                    setBankCd(value)
                                    glb_sv.focusInput('bank-account-ekyc')
                                }}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                value={bankCd}
                                size='middle'
                                id='bank-code-ekyc'
                                disabled={isBankOnline === 1}
                                className='bank-select-ekyc'
                                dropdownClassName='list-bank-cd'
                                style={{ width: '100%' }}
                            >
                                {bankList.filter(e => e.c0.includes(bankCode))
                                    .map((item) => (
                                        <Option key={item.c0} value={item.c0}>
                                            {item.c1}
                                        </Option>
                                    ))}
                            </Select>
                        </div>
                    </div>

                    <div className='row-ekyc' style={{ display: bankCode && isBankOnline === 2 ? 'flex' : 'none' }}>
                        <div className='flex flex-4 text-primary fs-normal align-items-center'>
                            <span className='text-second text-nowrap'>
                                {t('bank_acnt')}
                            </span>
                        </div>
                        <div style={{ width: 5 }} />
                        <div className='text-primary fs-normal flex flex-5 align-items-center'>
                            <Input
                                value={bankAct}
                                id='bank-account-ekyc'
                                onChange={(e) => {
                                    setBankAct(e.target.value)
                                }}
                                className='padding-input flex flex-1'
                            />
                        </div>
                    </div>
                    {/* ENND Bank Info */}

                   

                    <div className='fw-600' style={{ marginTop: 5 }}>
                        {t('additional_information')}
                    </div>
                    {configUIEkyc.broker_remisier_style === 'select' ?
                        <>
                            {configUIEkyc.is_show_broker && (
                                <div className='row-ekyc'>
                                    <span className='flex flex-4 text-second fs-normal align-items-center'>
                                        {t('broker_staff')}
                                    </span>
                                    <div style={{ width: 5 }} />
                                    <span className='text-primary fs-normal flex flex-5'>
                                        <Select
                                            onChange={(value) => setBrokerObj(value)}
                                            value={brokerObj}
                                            size='middle'
                                            id='broker_staff_ekyc'
                                            disabled={glb_sv.openAccountEkyc && glb_sv.openAccountEkyc.brokerid}
                                        >
                                            {listOther
                                                .filter((e) => e.c2 === '1')
                                                .map((item) => (
                                                    <Option key={item.c0} value={item.c0}>
                                                        {item.c1} ({item.c0})
                                                    </Option>
                                                ))}
                                        </Select>
                                    </span>
                                </div>
                            )}

                            {configUIEkyc.is_show_remisier && (
                                <div className='row-ekyc'>
                                    <span className='flex flex-4 text-second fs-normal align-items-center'>
                                        {t('remisier_staff')}
                                    </span>
                                    <div style={{ width: 5 }} />
                                    <span className='text-primary fs-normal flex flex-5'>
                                        <Select
                                            onChange={(value) => setRemisierObj(value)}
                                            value={remisierObj}
                                            size='middle'
                                            id='remisier_staff_ekyc'
                                            disabled={glb_sv.openAccountEkyc && glb_sv.openAccountEkyc.remiseid}
                                        >
                                            {listOther
                                                .filter((e) => e.c2 === '2')
                                                .map((item) => (
                                                    <Option key={item.c0} value={item.c0}>
                                                        {configUIEkyc.is_show_broker_remisier_id
                                                            ? item.c0
                                                            : `${item.c1} (${item.c0})`}
                                                    </Option>
                                                ))}
                                        </Select>
                                    </span>
                                </div>
                            )}
                        </> : <>
                            {configUIEkyc.is_show_broker && (<>
                                <div className='row-ekyc'>
                                    <span className='flex flex-4 text-second fs-normal align-items-center'>
                                        {t('code_broker')}
                                    </span>
                                    <div style={{ width: 5 }} />
                                    <div className='text-primary fs-normal flex flex-5'>
                                        <Input
                                            value={brokerObj}
                                            onChange={(e) => {
                                                setBrokerObj(e.target.value?.trim())
                                                const broker_id = e.target.value?.trim()
                                                if (!broker_id) {
                                                    if (timeoutGetName.current) clearTimeout(timeoutGetName.current)
                                                    getAccountNumberGeneration(activeAccountNumberOption,'')
                                                    return
                                                };
                                                if (timeoutGetName.current) clearTimeout(timeoutGetName.current)
                                                timeoutGetName.current = setTimeout(() => {
                                                    const info = listOther.filter((e) => e.c2 === '1').find(e => e.c0 === broker_id);
                                                    if (info) {
                                                        setBrokerName(info.c1)
                                                        if(configUIEkyc.is_account_number_choose && activeAccountNumberOption === '4'){
                                                            getAccountNumberGeneration(activeAccountNumberOption,broker_id)
                                                        }
                                                    } else {
                                                        setBrokerName('')
                                                        if(configUIEkyc.is_account_number_choose && activeAccountNumberOption === '4'){
                                                            console.log("====")
                                                            getAccountNumberGeneration(activeAccountNumberOption,'')
                                                        }
                                                        message.warning(t('warn_broker_code_is_not_valid'))
                                                    }
                                                }, 1000);
                                            }}
                                            placeholder={t('enter_broker_code')}
                                            className='padding-input flex flex-1'
                                            disabled={glb_sv.openAccountEkyc && glb_sv.openAccountEkyc.brokerid}
                                        />
                                    </div>
                                </div>
                                <div className='row-ekyc'>
                                    <span className='flex flex-4 text-second fs-normal align-items-center'>
                                        {t('name_broker')}
                                    </span>
                                    <div style={{ width: 5 }} />
                                    <div className='text-primary fs-normal flex flex-5'>
                                        <Input
                                            value={brokerName}
                                            disabled
                                            className='padding-input flex flex-1'
                                        />
                                    </div>
                                </div>
                            </>)}
                            {configUIEkyc.is_show_remisier && (<>
                                <div className='row-ekyc'>
                                    <span className='flex flex-4 text-second fs-normal align-items-center'>
                                        {t('remisier_code')}
                                    </span>
                                    <div style={{ width: 5 }} />
                                    <div className='text-primary fs-normal flex flex-5'>
                                        <Input
                                            value={remisierObj}
                                            onChange={(e) => {
                                                setRemisierObj(e.target.value?.trim())
                                                const remisier_id = e.target.value?.trim()
                                                if (!remisier_id) {
                                                    if (timeoutGetName.current) clearTimeout(timeoutGetName.current)
                                                    return;
                                                }
                                                if (timeoutGetName.current) clearTimeout(timeoutGetName.current)
                                                timeoutGetName.current = setTimeout(() => {
                                                    const info = listOther.filter((e) => e.c2 === '2').find(e => e.c0 === remisier_id);
                                                    if (info) {
                                                        setRemisierName(info.c1)
                                                    } else {
                                                        setRemisierName('')
                                                        message.warning(t('warn_remisier_code_is_not_valid'))
                                                    }
                                                }, 1000);
                                            }}
                                            placeholder={t('enter_remisier_code')}
                                            className='padding-input flex flex-1'
                                            disabled={glb_sv.openAccountEkyc && glb_sv.openAccountEkyc.remiseid}
                                        />
                                    </div>
                                </div>
                                <div className='row-ekyc'>
                                    <span className='flex flex-4 text-second fs-normal align-items-center'>
                                        {t('remisier_name')}
                                    </span>
                                    <div style={{ width: 5 }} />
                                    <div className='text-primary fs-normal flex flex-5'>
                                        <Input
                                            value={remisierName}
                                            disabled
                                            className='padding-input flex flex-1'
                                        />
                                    </div>
                                </div>
                            </>)}
                        </>}

                    {configUIEkyc.information_disclosure_stock_list && <>
                        <div className='row-ekyc'>
                            <span className='text-second fs-normal flex flex-4 align-items-center'>
                                {t('information_disclosure_stock_list')}
                            </span>
                            <div style={{ width: 5 }} />
                            <span className='text-primary fs-normal flex flex-5'>
                                <Input
                                    value={stockListInfo}
                                    onChange={(e) => {
                                        setStockListInfo(e.target.value?.trim().toUpperCase())
                                    }}
                                    className='padding-input flex flex-1'
                                />
                            </span>
                        </div>
                        <span className='price-ref fs-verySmall font-italic'>
                            {t('information_disclosure_stock_note')}
                        </span>
                    </>}

                    {configUIEkyc.refInfo && <>
                        <div className='fw-600' style={{ marginTop: 5 }}>
                            {t('referral_info')}
                        </div>

                        <div className='row-ekyc'>
                            <span className='flex flex-4 text-second fs-normal align-items-center'>
                                {t('referrer_code')}
                            </span>
                            <div style={{ width: 5 }} />
                            <div className='text-primary fs-normal flex flex-5'>
                                <Input
                                    value={referrerCode}
                                    onChange={(e) => {
                                        setReferrerCodeError(false)
                                        setReferrerCode(e.target.value?.trim())
                                        referrerCodeRef.current = e.target.value?.trim()
                                    }}
                                    placeholder={t('enter_referrer_code')}
                                    id='referrer_code_ekyc'
                                    className='padding-input flex flex-1'
                                    disabled={glb_sv.openAccountEkyc && glb_sv.openAccountEkyc.referCode}
                                />
                            </div>
                        </div>
                        <div className='row-ekyc'>
                            <span className='flex flex-4 text-second fs-normal align-items-center'>
                                {t('referrer_name')}
                            </span>
                            <div style={{ width: 5 }} />
                            <div className='text-primary fs-normal flex flex-5'>
                                <Input
                                    value={referrerName}
                                    onChange={(e) => {
                                        setReferrerName(e.target.value)
                                    }}
                                    placeholder={t('enter_referrer_name')}
                                    id='referrer_name_ekyc'
                                    className='padding-input flex flex-1'
                                    disabled={glb_sv.openAccountEkyc && glb_sv.openAccountEkyc.referName}
                                />
                            </div>
                        </div>
                    </>}

                    {configUIEkyc.is_cus_ref_code && <>
                        <div className='fw-600' style={{ marginTop: 5 }}>
                            {t('referral_ID')}
                        </div>

                        <div className='row-ekyc'>
                            <span className='flex flex-4 text-second fs-normal align-items-center'>
                                {t('referral_ID')}
                            </span>
                            <div style={{ width: 5 }} />
                            <div className='text-primary fs-normal flex flex-5'>
                                <Input
                                    value={referralID}
                                    onChange={(e) => {
                                        setReferralID(e.target.value?.trim())
                                    }}
                                    placeholder={t('ekyc_ivs_enter_referral_ID')}
                                    id='referral_id_ekyc'
                                    className='padding-input flex flex-1'
                                    // disabled={glb_sv.openAccountEkyc && glb_sv.openAccountEkyc.referralID}
                                />
                            </div>
                        </div>
                    </>}

                    {/* Start Chọn số tài khoản giao dịch chứng khoán */}
                    {configUIEkyc.is_account_number_choose && 
                        <>
                            <div className='fw-600' style={{ marginTop: 5 }}>
                                {t('securities_trading_account_number')}
                            </div>

                            <div className='row-ekyc'>
                                    <div className='text-primary fs-normal flex flex-4' >
                                        <Select
                                            allowClear={false}
                                            onChange={onChooseAccountNumberType}
                                            optionFilterProp="children"
                                            value={activeAccountNumberOption}
                                            size='middle'
                                            id='choose-account-number-ekyc'
                                            className='bank-select-ekyc'
                                            dropdownClassName='choose-account-number'
                                            style={{ width: '100%',maxWidth:'60%',maxHeight:28 }}
                                        >
                                            {optionSelectAccountList
                                                .map((item) => (
                                                    <Option key={item.label} value={item.value}>
                                                        {item.label}
                                                    </Option>
                                                ))}
                                        </Select>
                                    </div>
                                    <div style={{ width: 5 }} />
                                    <span className='text-primary fs-normal flex flex-5 flex-direction-column'>
                                        <Input
                                            onChange={handleChangeAccountNumber}
                                            maxLength={10}
                                            disabled={isDisableInputAccountNumber}
                                            value={accountNumberByOption}
                                            onFocus={() =>{
                                                setFocusedAccountNumber(true)
                                                accountNumberByOption === '-' ? setAccountNumberByOption('') : null
                                            }}
                                            onBlur={() =>{
                                                setFocusedAccountNumber(false)
                                                accountNumberByOption === '' ? setAccountNumberByOption('-') : null
                                            }}
                                            id='account_number_ekyc'
                                            className='padding-input flex-1'
                                        />
                                        {(accountNumberExistCount >= 5 && activeAccountNumberOption !== 1) &&
                                            <span className='price-ref fs-verySmall font-italic pl-1 pt-2'>
                                                {t('duplicate_account_recommendation')} : {recommendAccountNumber}
                                            </span>
                                        }
                                    </span>
                            </div>
                        </>
                    }
                    {/* End Chọn số tài khoản giao dịch chứng khoán */}     

                    {/* Signature */}
                    {configUIEkyc.singature ? (
                        <UploadSignature 
                            setUploadSignatureState={setUploadSignatureState} 
                            handSignature={configUIEkyc.signature_handwrite} 
                        />
                    ) : null}

                    {/* {Dịch vụ môi giới} */}
                    {configUIEkyc.brokerage_services_check && <div className='flex flex-direction-row align-items-center'
                        style={{
                            marginTop: dimensions.vertical(8),
                        }}
                    >
                        <Checkbox
                            checked={isBrokerService}
                            onChange={() => setBrokerService((value) => !value)}
                        >
                            <span className='fs-normal text-primary'>
                                {' '} {t('brokerage_services')}
                            </span>
                        </Checkbox>
                    </div>}

                    <div
                        className='flex align-items-center'
                        style={{
                            marginTop: dimensions.vertical(8),
                        }}
                    >
                        <Checkbox
                            checked={checkNote}
                            onChange={() => setCheckNote((value) => !value)}
                        >
                            <span className='fs-normal text-primary cursor-pointer'>
                                {glb_sv.dataEkycInfo?.c1 || t('note_ekyc')}
                                {glb_sv.dataEkycInfo?.c2 ? (
                                    <a
                                        className='fs-normal text-brand fw-500'
                                        href={glb_sv.dataEkycInfo.c2}
                                        target='_blank'
                                        rel="noreferrer"
                                    >
                                        {' '}
                                        {t('contact_detail')}
                                    </a>
                                ) : null}
                            </span>
                        </Checkbox>
                    </div>

                    {isAuthen && type === 'ekyc' ? (
                        <Button
                            onClick={checkPromoCode}
                            block
                            loading={loading}
                            className='no-height'
                            size='large'
                            style={{
                                backgroundColor: validate()
                                    ? styles.PRIMARY
                                    : styles.PRIMARY + '4d',
                                padding: dimensions.moderate(6),
                                marginTop: 12,
                            }}
                        >
                            <span
                                className='fs-medium'
                                style={{
                                    color: validate()
                                        ? '#FFF'
                                        : 'var(--SECOND__CONTENT__COLOR)',
                                }}
                            >
                                {t('register')}
                            </span>
                        </Button>
                    ) : null}

                    {!isAuthen && type === 'ekyc' ? (
                        <Button
                            onClick={authenEkyc}
                            block
                            className='no-height'
                            size='large'
                            danger
                            type='primary'
                            style={{
                                padding: dimensions.moderate(6),
                                marginTop: 12,
                            }}
                        >
                            <span className='fs-medium'>
                                {t('reauthen_ekyc')}
                            </span>
                        </Button>
                    ) : null}

                    {type !== 'ekyc' ? (
                        <Button
                            onClick={checkPromoCode}
                            disabled={!validate()}
                            block
                            loading={loading}
                            className='no-height'
                            size='large'
                            style={{
                                backgroundColor: validate()
                                    ? styles.PRIMARY
                                    : styles.PRIMARY + '4d',
                                padding: dimensions.moderate(6),
                                marginTop: 12,
                            }}
                        >
                            <span
                                className='fs-medium'
                                style={{
                                    color: validate()
                                        ? '#FFF'
                                        : 'var(--SECOND__CONTENT__COLOR)',
                                }}
                            >
                                {t('register')}
                            </span>
                        </Button>
                    ) : null}
                </div>
            ) : null}
        </Modal>

        {visibleModalAuthenOtpPhone &&
            <ModalAuthenOtpPhone
                onSuccess={onSuccessAfterAuthenOtpPhone}
                onCancel={onCancelAuthenOtpPhone}
                visible={visibleModalAuthenOtpPhone}
                setVisible={setModalAuthenOtpPhone} />}
    </>)
}
export default memo(EkycModal)
