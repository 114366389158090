const COMMON = {
    EKYC__COLOR: '#e84e49',
}

const DARK = {
    ...COMMON,
    PRIMARY: '#e84e49',
}

const LIGHT = {
    ...COMMON,
    PRIMARY: '#ba2822',
}

const CN_DARK = {
    ...DARK,
    //--Các value thay đổi cho china
}

const CN_LIGHT = {
    ...LIGHT,
    //--Các value thay đổi cho china
}

export default {
    DARK,
    LIGHT,
    CN_DARK,
    CN_LIGHT,
}
