import { Button, Carousel, Checkbox, Modal, notification } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ReactComponent as IC_CLOSE } from '../../assets/images/common/ic_close.svg';
import { eventList, glb_sv, reqFunct, sendRequest } from '../../utils';

const ServiceInfo = {
    CHECK_EVENT: {
        reqFunct: reqFunct.CHECK_EVENT,
        WorkerName: 'FOSqCommon',
        ServiceName: 'FOSqCommon_0820_04_Online',
        Operation: 'Q',
        ClientSentTime: '0',
    },
}

const fakeData = {
    one_time_per_date: {
        date: moment().format('YYYYMMDD'),
        list_id: [],
    },
    block_list: [],
}

const EventModal = ({ }) => {
    const { t } = useTranslation()
    const history = useHistory()
    const [shouldShowModal, setShouldShowModal] = useState(false)
    const [dataModal, setDataModal] = useState({})
    const [checkedDontShow, setCheckedDontShow] = useState(false)

    const [dataShowList, setDataShowList] = useState([])
    const dataList = useRef([])

    const callbackFunc = useRef(null)

    useEffect(() => {
        let pathName = history.location.pathname;
        let params = new URLSearchParams(window.location.search)
        const isScroll = params.get('autoscroll') === ''
        if (![
            '/login',
            '/tradingview',
            '/order',
            '/technical-analyze',
            '/asset-manage',
            '/performance',
            '/take-profit-stop-loss',
            '/advance-order',
            '/mkt-view',
            '/mkt-liquidity',
            '/mkt-cap',
            '/foreigner-trade',
            '/fundamental-analysis',
            '/company-profile'
        ].includes(pathName) && !isScroll) {
            checkEvent()
        }
        const commonEvent = glb_sv.commonEvent.subscribe(msg => {
            if (msg.type === eventList.CHECK_EVENT && !!msg.value) {
                checkEvent(msg.value)
                if (!!msg.callback) {
                    callbackFunc.current = msg.callback
                }
            }
            if (msg.type === eventList.LOGIN_SUCCESSFUL) {
                checkEvent('2') // Gọi event sau khi đăng nhập
            }
        })

        return () => {
            commonEvent.unsubscribe()
        }
    }, [])

    const checkEvent = (type = '3') => {
        if (!glb_sv.configInfo['show_event']) return // Chỉ mở cho SSV - DEV
        // type = 1: Trước khi đăng nhập
        // type = 2: Sau khi đăng nhập
        // type = 3: Khi mở website/apps
        const InputParams = [String(type)]
        sendRequest(ServiceInfo.CHECK_EVENT, InputParams, handleCheckEventResult, true, handleCheckEventTimeout)
        setShouldShowModal(false)
        setCheckedDontShow(false)
    }

    const handleCheckEventResult = (reqInfoMap, message) => {
        console.log('handleCheckEventResult: ', reqInfoMap, message)
        if (Number(message['Result']) === 0) {
            callbackFunc.current && callbackFunc.current()
            resetCallback()
            return
        } else {
            let dataArr = []
            try {
                dataArr = JSON.parse(glb_sv.replaceStrToHTML(message['Data']));
            } catch (error) {
                console.log('try catch data checkEvent error: ', error)
                callbackFunc.current && callbackFunc.current()
                resetCallback()
                return
            }

            if (Number(message['Packet']) <= 0) {
                // validateData(dataArr.filter(x => (!!x.c4 && !!x.c5)))
                validateData(dataArr)
            }
        }
    }

    const validateData = (dataArr) => {
        if (dataArr.length <= 0) {
            callbackFunc.current && callbackFunc.current()
            resetCallback()
            return
        }
        console.log('validate: ', dataArr)
        let checkedData = []
        // let data = orderBy(dataArr, ['c1'], ['asc']) // Seq nhỏ đưa lên trước
        let localDataBlockEvent = getDataBlockLocal();
        if (!!localDataBlockEvent) {
            const objDataBlockEvent = (!!localDataBlockEvent && typeof (localDataBlockEvent) === 'object') ? localDataBlockEvent : {}
            // Local đã có lưu data block
            // Tiến hành check block_list
            // Tiến hành check đã hiện thị data này ở lần trước (của ngày hôm nay) chưa?
            // Có => loại bỏ ra khỏi danh sách show pop-up
            // Không => Set lại data default ngày hôm nay block_list = [] === Ngày hôm trước đã xoá
            const listIDBlock = objDataBlockEvent['block_list']
            const dateOfOneTimePerDate = objDataBlockEvent['one_time_per_date']?.['date']
            const listIdOneTimePerDate = objDataBlockEvent['one_time_per_date']?.['list_id']

            if (!!listIDBlock && listIDBlock.length > 0) {
                // Có tồn tại danh sách block_list => loại bỏ các item có id thuộc block_list
                checkedData = dataArr.filter(x => !listIDBlock.includes(x.c0))
                if (checkedData.length > 0) {
                    // Check xong vẫn còn dữ liệu => Tiến hành check data hiển thị trong ngày
                    if (dateOfOneTimePerDate !== moment().format('YYYYMMDD')) {
                        // KH vô lần đầu của ngày
                        localStorage.setItem('ID_BLOCK_EVENT', JSON.stringify({
                            ...localDataBlockEvent,
                            ...{
                                one_time_per_date: {
                                    date: moment().format('YYYYMMDD'),
                                    list_id: [],
                                }
                            }
                        }))
                    } else {
                        // Check điều kiện KH vô lần n + 1 trong ngày
                        if (listIdOneTimePerDate.length > 0) {
                            // Lọc bỏ các id bị trùng đã ẩn không cho hiện lại
                            checkedData = checkedData.filter(x => !listIdOneTimePerDate.includes(x.c0))
                        }
                    }
                }
            } else {
                // ID block không có (vẫn có thể có block ID trong ngày)
                if (dateOfOneTimePerDate !== moment().format('YYYYMMDD')) {
                    // KH vô lần đầu của ngày
                    localStorage.setItem('ID_BLOCK_EVENT', JSON.stringify({
                        ...localDataBlockEvent,
                        ...{
                            one_time_per_date: {
                                date: moment().format('YYYYMMDD'),
                                list_id: [],
                            }
                        }
                    }))
                } else {
                    // Check điều kiện KH vô lần n + 1 trong ngày
                    if (listIdOneTimePerDate.length > 0) {
                        // Lọc bỏ các id bị trùng đã ẩn không cho hiện lại
                        checkedData = checkedData.filter(x => !listIdOneTimePerDate.includes(x.c0))
                    } else {
                        // Local chưa có list ID nào thì data vẫn show đầy đủ
                        checkedData = dataArr
                    }
                }
            }
        } else {
            // Local chưa có data => Tiến hành set data default vô local
            // Data hiển thị list pop-up = data lấy từ service
            checkedData = dataArr
            localStorage.setItem('ID_BLOCK_EVENT', JSON.stringify(fakeData))
        }

        if (checkedData.length > 0) {
            dataList.current = checkedData;
            setDataShowList(checkedData)
            setDataModal(dataList.current[0])
            setShouldShowModal(true)
        } else {
            callbackFunc.current && callbackFunc.current()
            resetCallback()
        }
    }

    const handleCheckEventTimeout = (e) => {
        console.log('handleCheckEventResult timeout: ', e)
        callbackFunc.current && callbackFunc.current()
        resetCallback()
    }

    const dontShowAgain = (item) => {
        // console.log('dontShowAgain: ', item)
        let localDataBlockEvent = getDataBlockLocal();
        // Không hiển thị lần sau => thêm vào list block => khi data trả về nếu nằm trong list này thì remove tất cả
        localDataBlockEvent['block_list'] = localDataBlockEvent['block_list'].concat(item['c0'])
        localStorage.setItem('ID_BLOCK_EVENT', JSON.stringify(localDataBlockEvent))
        takeStepPopUp(dataList.current, item, setDataModal, setDataShowList, setShouldShowModal, t, callbackFunc.current, resetCallback);
        setCheckedDontShow(false)
    }

    const closeOneTime = (item) => {
        // console.log('closeOneTime: ', item)
        let localDataBlockEvent = getDataBlockLocal(); // Lấy data từ local
        if (item.c2 === '1') {
            // Data 1 lần 1 ngày
            localDataBlockEvent['one_time_per_date']['list_id'] = localDataBlockEvent['one_time_per_date']['list_id'].concat([item['c0']]);
        } else if (item.c2 === '3') {
            // Data 1 lần duy nhất
            localDataBlockEvent['block_list'] = localDataBlockEvent['block_list'].concat(item['c0'])
            // Data nhiều lần 1 ngày không cần check
        }
        // Lưu lại data mới vào local
        localStorage.setItem('ID_BLOCK_EVENT', JSON.stringify(localDataBlockEvent))
        takeStepPopUp(dataList.current, item, setDataModal, setDataShowList, setShouldShowModal, t, callbackFunc.current, resetCallback);
    }

    const onChangeDontShowAgain = (e) => {
        setCheckedDontShow(e.target.checked)
    }

    const resetCallback = () => {
        setTimeout(() => {
            callbackFunc.current = null
        }, 10);
    }

    return (
        <Modal
            className="modal-major"
            visible={shouldShowModal}
            footer={
                <div className='flex align-items-center justify-content-between'>
                    <div className='flex justify-content-start'>
                        {(dataModal['c2'] !== '3') && <Checkbox checked={checkedDontShow} onChange={onChangeDontShowAgain}>{t('common_dont_show_again')}</Checkbox>}
                    </div>
                    <div className='flex justify-content-end'>
                        <Button
                            type='primary'
                            style={{
                                maxWidth: 300,
                            }}
                            onClick={() => {
                                if (!checkedDontShow) {
                                    closeOneTime(dataModal)
                                } else {
                                    dontShowAgain(dataModal)
                                }
                            }}
                        >
                            {t('common_Close')}
                        </Button>
                    </div>
                </div>
            }
            closable={false}
            closeIcon={<IC_CLOSE fill="var(--TEXT__1)" />}
            // onCancel={() => setShouldShowModal(false)}
            title={<></>}
        >
            {dataShowList.length > 0 && (<>
                <div
                    className={`${!!dataModal['c6'] ? 'cursor-pointer' : ''}`}
                    style={{ maxHeight: 'calc(100vh - 170px)' }}
                    onClick={() => {
                        if (!!dataModal['c6']) {
                            window.open(dataModal['c6'])
                            return
                        }
                        return
                    }}
                >
                    <img
                        style={{
                            width: '100%',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                        }}
                        src={dataModal['c5']}
                    />
                    <div
                        className='pt-2 pb-2'
                        style={{
                            fontSize: '1.25rem',
                            color: 'var(--SECOND__CONTENT__COLOR)',
                            textAlign: 'left'
                        }}
                        dangerouslySetInnerHTML={{ __html: dataModal['c4'] }}
                    >
                    </div>
                </div>
                {/* <Carousel>
                    <div
                        className={`w-100 ${!!dataModal['c6'] ? 'cursor-pointer' : null}`}
                        onClick={() => {
                            if (!!dataModal['c6']) {
                                window.open(dataModal['c6'])
                                return
                            }
                            return
                        }}
                    >
                        <img
                            style={{
                                width: '100%',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                            }}
                            src={dataModal['c5']}
                        />
                        <div
                            style={{
                                background: 'linear-gradient(170.57deg, rgb(49 49 49 / 39%) 92.14%, rgb(32 32 32 / 38%) 92.14%)',
                                height: '100%',
                                borderRadius: 10,
                                paddingLeft: 10,
                            }}
                        >
                            <p
                                className='title-carousel'
                                style={{
                                    paddingLeft: 10,
                                    bottom: 5,
                                    top: 5,
                                    fontSize: '1.75rem',
                                    position: 'absolute',
                                    color: 'var(--SECOND__CONTENT__COLOR)',
                                    width: '45%',
                                    textAlign: 'left'
                                }}
                            >
                                {dataModal['c4']}
                            </p>
                        </div>
                    </div>
                </Carousel> */}
            </>)}
        </Modal>
    )
}

export default EventModal;

const getDataBlockLocal = () => {
    let localDataBlockEvent;
    try {
        localDataBlockEvent = JSON.parse(localStorage.getItem('ID_BLOCK_EVENT'));
    } catch (error) {
        console.log('getDataBlockLocal error: ', error);
    }
    return localDataBlockEvent;
}

const takeStepPopUp = (listData, item, setDataModal, setDataShowList, setShouldShowModal, t, callback, resetCallback) => {
    const indexF = listData.findIndex(x => x.c0 === item.c0);
    if (indexF > -1) {
        listData.splice(indexF, 1);
        if (listData.length > 0) {
            setDataModal(listData[0]);
            setDataShowList(listData);
        } else {
            setShouldShowModal(false);
            setDataModal({});
            setDataShowList([]);
            // Đã close hết tất cả các pop-up => gọi lại func callback (nếu có)
            callback && callback()
            resetCallback && resetCallback()
        }
    } else {
        notification.warning({
            message: t('common_notify'),
            description: t('close_event_failure'),
            duration: 3,
        });
    }
}
