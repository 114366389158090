import { Input, Space } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import './textarea.scss'

interface IInputTextAreaProps {
	defaultLayout?: 'horizontal' | 'vertical';
	label: string;
	placeholder: string;
	isHiddenLabel?: boolean;
	labelStyle?: React.CSSProperties;
	onChange?: Function;
	value: string;
	minRows?: number;
	maxRows?: number;
	refs?: React.Ref<any>;
}

const InputTextArea = ({
	defaultLayout = 'vertical',
	label = 'Nội dung',
	placeholder = 'enter_content_feedback',
	isHiddenLabel = false,
	labelStyle = {},
	onChange = e => console.log(e),
	value = '',
	minRows = 2,
	maxRows = 6,
	refs,
	...props
}: IInputTextAreaProps) => {
	const { t } = useTranslation();

	const onChangeCallback = e => {
		onChange(e.target.value);
	};
	return (
		<div className='alt-input-textarea'>
            {/* @ts-expect-error */}
			<Space direction={defaultLayout}>
				{!isHiddenLabel && (
					<div className='alt-label' style={labelStyle}>
						{label}
					</div>
				)}
				<Input.TextArea
					className='alt-input'
					placeholder={t(placeholder)}
					autoSize={{ minRows, maxRows }}
					ref={refs}
					value={value}
					{...props}
					onChange={onChangeCallback}
				/>
			</Space>
		</div>
	);
};

export default InputTextArea