import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'

import vi from './assets/translation/vi.json'
import en from './assets/translation/en.json'
import cn from './assets/translation/cn.json'
import zh from './assets/translation/zh.json'
import ko from './assets/translation/ko.json'

import vi_099 from './assets/translation/099_vi.json'
import en_099 from './assets/translation/099_en.json'
import cn_099 from './assets/translation/099_cn.json'
import zh_099 from './assets/translation/099_zh.json'
import ko_099 from './assets/translation/099_ko.json'

import vi_081 from './assets/translation/081_vi.json'
import en_081 from './assets/translation/081_en.json'
import cn_081 from './assets/translation/081_cn.json'
import zh_081 from './assets/translation/081_zh.json'
import ko_081 from './assets/translation/081_ko.json'

import vi_102 from './assets/translation/102_vi.json'
import en_102 from './assets/translation/102_en.json'
import cn_102 from './assets/translation/102_cn.json'
import zh_102 from './assets/translation/102_zh.json'
import ko_102 from './assets/translation/102_ko.json'

import vi_061 from './assets/translation/061_vi.json'
import en_061 from './assets/translation/061_en.json'
import cn_061 from './assets/translation/061_cn.json'
import zh_061 from './assets/translation/061_zh.json'
import ko_061 from './assets/translation/061_ko.json'

import vi_020 from './assets/translation/020_vi.json'
import en_020 from './assets/translation/020_en.json'
import cn_020 from './assets/translation/020_cn.json'
import zh_020 from './assets/translation/020_zh.json'
import ko_020 from './assets/translation/020_ko.json'

import vi_075 from './assets/translation/075_vi.json'
import en_075 from './assets/translation/075_en.json'
import cn_075 from './assets/translation/075_cn.json'
import zh_075 from './assets/translation/075_zh.json'
import ko_075 from './assets/translation/075_ko.json'

import vi_036 from './assets/translation/036_vi.json'
import en_036 from './assets/translation/036_en.json'

i18n.use(Backend)
    .use(initReactI18next)
    .init({
        // resources,
        fallbackLng: 'VI',
        debug: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    })

const getFile = (lang: 'vi' | 'en' | 'cn' | 'zh' | 'ko') => {
    if (window.secCodeTheme === '099') {
        switch (lang) {
            case 'vi':
                return vi_099
            case 'en':
                return en_099
            case 'cn':
                return cn_099
            case 'zh':
                return zh_099
            case 'ko':
                return ko_099
        }
    }

    if (window.secCodeTheme === '102') {
        switch (lang) {
            case 'vi':
                return vi_102
            case 'en':
                return en_102
            case 'cn':
                return cn_102
            case 'zh':
                return zh_102
            case 'ko':
                return ko_102
        }
    }

    if (window.secCodeTheme === '061') {
        switch (lang) {
            case 'vi':
                return vi_061
            case 'en':
                return en_061
            case 'cn':
                return cn_061
            case 'zh':
                return zh_061
            case 'ko':
                return ko_061
        }
    }

    if (window.secCodeTheme === '020') {
        switch (lang) {
            case 'vi':
                return vi_020
            case 'en':
                return en_020
            case 'cn':
                return cn_020
            case 'zh':
                return zh_020
            case 'ko':
                return ko_020
        }
    }
    if (window.secCodeTheme === '075') {
        switch (lang) {
            case 'vi':
                return vi_075
            case 'en':
                return en_075
            case 'cn':
                return cn_075
            case 'zh':
                return zh_075
            case 'ko':
                return ko_075
        }
    }
    if (window.secCodeTheme === '036') {
        switch (lang) {
            case 'vi':
                return vi_036
            case 'en':
                return en_036
            case 'cn':
                return cn
            case 'zh':
                return zh
            case 'ko':
                return ko
        }
    }

    if (window.secCodeTheme === '888') {
        switch (lang) {
            case 'vi':
                return vi
            case 'en':
                return en
            case 'cn':
                return cn
            case 'zh':
                return zh
            case 'ko':
                return ko
        }
    }

    switch (lang) {
        case 'vi':
            return vi_081
        case 'en':
            return en_081
        case 'cn':
            return cn_081
        case 'zh':
            return zh_081
        case 'ko':
            return ko_081
    }
}
i18n.addResourceBundle('VI', 'translation', { ...vi, ...getFile('vi') }, true, true)
i18n.addResourceBundle('EN', 'translation', { ...en, ...getFile('en') }, true, true)
i18n.addResourceBundle('CN', 'translation', { ...cn, ...getFile('cn') }, true, true)
i18n.addResourceBundle('ZH', 'translation', { ...zh, ...getFile('zh') }, true, true)
i18n.addResourceBundle('KO', 'translation', { ...ko, ...getFile('ko') }, true, true)

export default i18n
